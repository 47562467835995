import { Button, HStack, RInput, VStack } from '@ramp/components';
import { LayoutGrid } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import NetworkDeviceScanCard from './NetworkDeviceScanCard';
import RebootCard from './RebootCard';
import RemoteControlCard from './RemoteControlCard';
import UpdateAppsCard from './UpdateAppsCard';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WindowsRemoteControlProps {}

const WindowsRemoteControlTab: React.FC<WindowsRemoteControlProps> = () => {
  const { t } = useTranslation();

  const [appId, setAppId] = useState<string>('');

  return (
    <HStack w="full" flexDirection={{ md: 'column', lg: 'row' }} spacing={4} alignItems="start" justifyContent="center">
      <VStack w={{ md: 'full', lg: '50%' }} spacing={4}>
        <RebootCard />
        <NetworkDeviceScanCard />
        <RemoteControlCard
          title={t('admin.devices.detail.remoteControl.wipe.title')}
          description={t('admin.devices.detail.remoteControl.wipe.description')}
          rightContent={
            <Button variant="brand" isDisabled>
              {t('admin.devices.detail.remoteControl.wipe.button')}
            </Button>
          }
        />
        <RemoteControlCard
          title={t('admin.devices.detail.remoteControl.rdp.title')}
          description={t('admin.devices.detail.remoteControl.rdp.description')}
          rightContent={
            <Button variant="brand" isDisabled>
              {t('admin.devices.detail.remoteControl.rdp.button')}
            </Button>
          }
        />
      </VStack>
      <VStack w={{ md: 'full', lg: '50%' }} spacing={4}>
        <UpdateAppsCard />
        <RemoteControlCard
          title={t('admin.devices.detail.remoteControl.installUpdates.title')}
          description={t('admin.devices.detail.remoteControl.installUpdates.description')}
          rightContent={
            <Button variant="brand" isDisabled>
              {t('admin.devices.detail.remoteControl.installUpdates.button')}
            </Button>
          }
        />
        <RemoteControlCard
          title={t('admin.devices.detail.remoteControl.installApp.title')}
          description={t('admin.devices.detail.remoteControl.installApp.description')}
          rightContent={
            <HStack spacing={4}>
              <RInput
                id="app_id"
                size="md"
                value={appId}
                onChange={e => setAppId(e.target.value as string)}
                label={t('admin.devices.detail.remoteControl.installApp.label')}
                icon={<LayoutGrid size={16} />}
                inputProps={{ autocomplete: 'off' }}
                onCard
                disabled
              />
              <Button variant="brand" isDisabled>
                {t('admin.devices.detail.remoteControl.installApp.button')}
              </Button>
            </HStack>
          }
        />
      </VStack>
    </HStack>
  );
};

export default WindowsRemoteControlTab;
