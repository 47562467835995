import { colorHex } from '@ramp/theme';
import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

import { useColorModeValue } from '@ramp/components';
import { RiskScore } from 'types';

interface RiskScoreChartProps {
  riskScores: RiskScore[];
}

const RiskScoreChart: React.FC<RiskScoreChartProps> = ({ riskScores }) => {
  const [data] = useState<ApexOptions>({
    series: [
      {
        name: 'Risk Score',
        data: riskScores.map(score => score.global_score)
      }
    ],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        gradientToColors: ['transparent'],
        stops: [0, 90, 100]
      }
    },
    chart: {
      type: 'area',
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      strokeWidth: 0,
      hover: {
        size: 6
      }
    },
    stroke: {
      curve: 'smooth'
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: val => val.toFixed(2)
      },
      theme: useColorModeValue('light', 'dark')
    },
    legend: {
      show: false,
      labels: {
        colors: colorHex('gray.400')
      }
    },
    xaxis: {
      categories: riskScores.map(score => dayjs(score.date).format('DD/MM')),
      labels: {
        style: {
          colors: colorHex('gray.400')
        }
      },
      crosshairs: {
        show: false
      },
      axisBorder: {
        show: false
      },
      tickAmount: 16
    },
    grid: {
      show: false
    },
    yaxis: {
      show: true,
      labels: {
        style: {
          colors: colorHex('gray.400')
        },
        formatter: val => val.toFixed(2)
      },
      tickAmount: 4,
      min: Math.round(
        (riskScores.reduce((a, b) => ((a.global_score || 0) < (b.global_score || 0) ? a : b)).global_score || 1) - 2
      ),
      max: Math.round(
        (riskScores.reduce((a, b) => ((a.global_score || 0) > (b.global_score || 0) ? a : b)).global_score || 1) + 1
      )
    },
    colors: [colorHex('brand.500')]
  });

  return <ReactApexChart options={data} series={data.series} type="area" height={230} />;
};

export default RiskScoreChart;
