import { Button, ButtonProps, Tooltip } from '@ramp/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiCopy } from 'react-icons/fi';

import { copyToClipboard } from 'utils/utils';

interface CopyButtonProps extends ButtonProps {
  dataToCopy: string;
}

const CopyButton: React.FC<CopyButtonProps> = ({ dataToCopy }) => {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const onClick = () => {
    copyToClipboard(dataToCopy);
    setIsOpened(true);
    setTimeout(() => setIsOpened(false), 1500);
  };

  return (
    <Tooltip
      color="white"
      placement="top"
      label={t('components.copyButton.label')}
      borderRadius={4}
      isOpen={isOpened}
      bgColor="gray.500"
    >
      <Button variant="borderless" size="sm" onClick={onClick}>
        <FiCopy />
      </Button>
    </Tooltip>
  );
};

export default CopyButton;
