import { Box, RTab, RTabList, RTabPanel, RTabPanels, RTabs } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import NoDataTab from 'components/NoDataTab';

import { AndroidScan, IosScan } from 'types/api/devices';
import { useDeviceDetail } from '../DeviceDetailProvider';
import AppsTab from './AppsTab';
import DeviceTab from './DeviceTab';
import MacOSAppsTab from './MacOSAppsTab';
import NetworksTab from './NetworksTab';
import OverviewTab from './OverviewTab';
import PrivacyTab from './PrivacyTab';
import WindowsAppsTab from './WindowsAppsTab';
import WindowsRemoteControlTab from './WindowsRemoteControlTab';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EvaluationProps {}

const Evaluation: React.FC<EvaluationProps> = () => {
  const { t } = useTranslation();
  const { platform, scan } = useDeviceDetail();

  if (!platform || !scan) return null;

  const { privacy } = scan;
  const { applications: apps } = scan as AndroidScan | IosScan;

  if (!scan && !apps && !privacy) return null;

  return (
    <Box w="full" mt="2rem !important">
      <RTabs isLazy>
        <RTabList mx="auto">
          <RTab key="overview">{t('admin.devices.detail.evaluation.overview')}</RTab>
          <RTab key="device">{t('admin.devices.detail.scorePanel.device')}</RTab>
          <RTab key="network">{t('admin.devices.detail.scorePanel.network')}</RTab>
          <RTab key="privacy">{t('admin.devices.detail.scorePanel.privacy')}</RTab>
          <RTab key="apps">{t('admin.devices.detail.scorePanel.apps')}</RTab>
          {platform === 'windows' && (
            <RTab key="remoteControl" whiteSpace="nowrap">
              {t('admin.devices.detail.scorePanel.remoteControl')}
            </RTab>
          )}
        </RTabList>
        <RTabPanels mt={4}>
          <RTabPanel key="overview">{scan ? <OverviewTab /> : <NoDataTab />}</RTabPanel>
          <RTabPanel key="device">
            <DeviceTab />
          </RTabPanel>
          <RTabPanel key="network">{scan ? <NetworksTab /> : <NoDataTab />}</RTabPanel>
          <RTabPanel key="privacy">{privacy ? <PrivacyTab /> : <NoDataTab />}</RTabPanel>
          <RTabPanel key="apps">
            {platform !== 'windows' ? platform === 'macos' ? <MacOSAppsTab /> : <AppsTab /> : <WindowsAppsTab />}
          </RTabPanel>
          {platform === 'windows' && (
            <RTabPanel key="remoteControl">
              <WindowsRemoteControlTab />
            </RTabPanel>
          )}
        </RTabPanels>
      </RTabs>
    </Box>
  );
};

export default Evaluation;
