import { ColumnsType, RTable, SeverityNumTag, Text, Tooltip } from '@ramp/components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Vulnerability } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';

interface DeviceAdvancedIssuesTableProps {
  id: string;
  defaultPageSize?: number;
  issues?: Vulnerability[];
  loading?: boolean;
}

interface IssueRow extends Vulnerability {
  key: number;
}

const DeviceAdvancedIssuesTable: React.FC<DeviceAdvancedIssuesTableProps> = ({
  id,
  issues = [],
  defaultPageSize = 50,
  loading
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const columns: ColumnsType<IssueRow> = [
    {
      title: t('components.advancedIssuesTable.name'),
      key: 'cve',
      width: '200px',
      align: 'center',
      render: cve => <Text fontWeight={500}>{cve}</Text>
    },
    {
      title: t('components.advancedIssuesTable.severity'),
      key: 'score',
      align: 'center',
      width: '120px',
      sorting: true,
      sortingOptions: {
        dataType: 'number',
        defaultSortOrder: 'desc',
        sortingFn: (a, b) => a.original.score - b.original.score
      },
      render: (_, issue) => <SeverityNumTag severityScore={issue.score || 0} />
    },
    {
      title: t('components.advancedIssuesTable.published'),
      key: 'published',
      width: '180px',
      align: 'center',
      render: published => <Text>{published}</Text>
    },
    {
      title: t('components.advancedIssuesTable.description'),
      key: 'description',
      render: (value: string) => (
        <Tooltip label={value}>
          <Text noOfLines={1}>{value}</Text>
        </Tooltip>
      )
    }
  ];

  const formattedIssues: IssueRow[] = useMemo(() => {
    return issues.map((issue, key) => ({ key, ...issue }));
  }, [issues]);

  return (
    <RTable
      columns={columns}
      data={formattedIssues}
      loading={loading}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
      }}
    />
  );
};

export default DeviceAdvancedIssuesTable;
