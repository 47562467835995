import { Box, HStack, VStack } from '@ramp/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import BasePageLeftPanel from 'components/BasePageLeftPanel';
import { useAuthStore } from 'store';
import { PRODUCT_NAME } from 'utils/constants';

import SetupPassword from './SetupPassword';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FirmAdminVerificationProps {}

const FirmAdminVerification: React.FC<FirmAdminVerificationProps> = () => {
  const { t } = useTranslation();
  const logoutUser = useAuthStore(store => store.logoutUser);

  useEffect(() => {
    window.document.title = t('base.firmAdminVerification.title', { productName: PRODUCT_NAME });
    logoutUser();
  }, []);

  return (
    <HStack w="full" h="100vh" spacing="64px" p="32px">
      <BasePageLeftPanel w={['500px', '500px', '320px', '360px', '480px']} />
      <VStack
        pos="relative"
        w={['100%', '100%', 'calc(100% - 320px)', 'calc(100% - 360px)', 'calc(100% - 480px)']}
        marginInline={['0 !important', '0 !important', '64px 0px', '64px 0px', '64px 0px']}
        h="full"
        alignItems="center"
        justifyContent="center"
      >
        <SetupPassword />
        <Box
          pos="absolute"
          zIndex={1}
          top="60%"
          w="200px"
          h="200px"
          filter="blur(125px)"
          bg="linear-gradient(156.26deg, rgba(214, 9, 40, 0.6) 8.79%, rgba(246, 40, 71, 0.35) 91.67%)"
        />
      </VStack>
    </HStack>
  );
};

export default FirmAdminVerification;
