import isEqual from 'lodash/isEqual';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { TablePageSize } from 'types';
import { FirmTheme } from 'types/api/firms';

export interface LayoutStore {
  loading: boolean;
  setLoading: (value: boolean) => void;
  tablesPageSizes: TablePageSize[];
  setTablePageSize: (tablesPageSizes: TablePageSize[]) => void;
  theme: FirmTheme;
  confirmedMobileUnsupported: boolean;
  setConfirmedMobileUnsupported: (value: boolean) => void;
  noUsersInCompany: boolean;
  setNoUsersInCompany: (value: boolean) => void;
  noScansInCompany: boolean;
  setNoScansInCompany: (value: boolean) => void;
  setTheme: (theme: FirmTheme) => void;
  setDefaultTheme: () => void;
}

const MEDIA_URL = (import.meta.env.VITE_API_URL! as string).replace('api/', 'media/logos');

export const useLayoutStore = create<LayoutStore>()(
  devtools((set, getState) => ({
    loading: true,
    setLoading: value => set({ loading: value }),
    tablesPageSizes: JSON.parse(window.localStorage.getItem('sc_tables_page_sizes') || '[]'),
    setTablePageSize: tablesPageSizes => set({ tablesPageSizes }),
    theme: {
      logo_primary_light: `${MEDIA_URL}/logo_primary_light.svg`,
      logo_primary_dark: `${MEDIA_URL}/logo_primary_dark.svg`,
      logo_secondary_light: `${MEDIA_URL}/logo_secondary_light.svg`,
      logo_secondary_dark: `${MEDIA_URL}/logo_secondary_dark.svg`,
      theme: 'all',
      color_scheme: {
        50: '#FFFAFB',
        100: '#FEDDE2',
        200: '#FB9DAB',
        300: '#F86279',
        400: '#F62847',
        500: '#D60928',
        600: '#AB0720',
        700: '#7F0518',
        800: '#53040F',
        900: '#2C0208'
      }
    },
    confirmedMobileUnsupported: false,
    setConfirmedMobileUnsupported: (value: boolean) => set({ confirmedMobileUnsupported: value }),
    noUsersInCompany: false,
    setNoUsersInCompany: (value: boolean) => set({ noUsersInCompany: value }),
    noScansInCompany: false,
    setNoScansInCompany: (value: boolean) => set({ noScansInCompany: value }),
    setTheme: newTheme => {
      const state = getState();

      if (!isEqual(state.theme, newTheme)) {
        set({ theme: newTheme });
      }
    },
    setDefaultTheme: () => {
      set({
        theme: {
          logo_primary_light: `${MEDIA_URL}/logo_primary_light.svg`,
          logo_primary_dark: `${MEDIA_URL}/logo_primary_dark.svg`,
          logo_secondary_light: `${MEDIA_URL}/logo_secondary_light.svg`,
          logo_secondary_dark: `${MEDIA_URL}/logo_secondary_dark.svg`,
          theme: 'all',
          color_scheme: {
            50: '#FFFAFB',
            100: '#FEDDE2',
            200: '#FB9DAB',
            300: '#F86279',
            400: '#F62847',
            500: '#D60928',
            600: '#AB0720',
            700: '#7F0518',
            800: '#53040F',
            900: '#2C0208'
          }
        }
      });
    }
  }))
);
