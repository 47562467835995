import { RPage, VStack } from '@ramp/components';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import NoDataPage from 'components/NoDataPage';
import UserInfoCard from 'components/UserInfoCard';
import UserRiskScoreCard from 'components/UserRiskScoreCard';
import { useAuthStore, useLayoutStore } from 'store';
import { PRODUCT_NAME } from 'utils/constants';

import DevicesCard from './DevicesCard';
import IssuesCard from './IssuesCard';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {
  const { t } = useTranslation();
  const { user_id: id } = useAuthStore(store => store.user!);
  const { noUsersInCompany, noScansInCompany, setNoScansInCompany } = useLayoutStore(store => store);

  const startDate = useMemo(() => dayjs().add(-1, 'year').format('YYYY-MM-DD'), []);
  const endDate = useMemo(() => dayjs().format('YYYY-MM-DD'), []);

  const userQuery = useQuery({
    queryKey: ['users', id],
    queryFn: () => api.users.getUser(id),
    cacheTime: 0,
    onSuccess: data => setNoScansInCompany(data.devices.length === 0)
  });

  const issuesQuery = useQuery({
    queryKey: ['issues', id],
    queryFn: () => api.users.getIssues(id, { start_date: startDate, end_date: endDate })
  });

  if (noScansInCompany) {
    return (
      <NoDataPage
        heading={t('admin.dashboard.heading')}
        pageLoading={userQuery.isLoading}
        noUsersInCompany={noUsersInCompany}
        noScansInCompany={noScansInCompany}
      />
    );
  }

  return (
    <RPage
      px={6}
      pt={8}
      loading={userQuery.isLoading}
      heading={t('admin.dashboard.heading')}
      title={t('admin.dashboard.title', { productName: PRODUCT_NAME })}
    >
      <VStack w="full" spacing={2}>
        <UserInfoCard user={userQuery.data} />

        <UserRiskScoreCard title={t('admin.dashboard.riskScore.title')} userId={id} />

        <IssuesCard issues={issuesQuery.data} />

        <DevicesCard devices={userQuery.data?.devices} />
      </VStack>
    </RPage>
  );
};

export default Dashboard;
