import { Navigate, NavigateProps, useSearchParams } from 'react-router-dom';

const NavigateBaseWrapper = ({ to, ...props }: NavigateProps) => {
  const pathname = window.location.pathname;

  const params = new URLSearchParams({ redirect: pathname });
  const search = params.toString();

  return <Navigate to={`${to}?${search}`} {...props} />;
};

const NavigateWrapper = ({ to, ...props }: NavigateProps) => {
  const [searchParams] = useSearchParams();

  if (searchParams.has('redirect') && (searchParams.get('redirect') ?? '').trim().length !== 0)
    return <Navigate to={searchParams.get('redirect') as string} />;

  return <Navigate to={to} {...props} />;
};

export { NavigateBaseWrapper, NavigateWrapper };
