import {
  Button,
  Description,
  DescriptionItem,
  HStack,
  Spinner,
  Tag,
  Text,
  useColorModeValue,
  VStack
} from '@ramp/components';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ScanInvokedTag from 'components/ScanInvokedTag';
import SensorAppVersion from 'components/SensorAppVersion';
import UserTooltip from 'components/UserTooltip';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { AndroidOs, AndroidScan, IosDevice, IosOs, IosScan } from 'types/api/devices';
import { serializePlatform, serializeVendor } from 'utils/serializators';
import { parseRoute, toCapitalize, usePlatformIcon } from 'utils/utils';

import { useDeviceDetail } from '../DeviceDetailProvider';
import AndroidExtendedInfo from './AndroidExtendedInfo';
import IosExtendedInfo from './IosExtendedInfo';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeviceInfoCardProps {}

const DeviceInfoCard: React.FC<DeviceInfoCardProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useAuthStore(store => store.user!);
  const { platform, scan, recommendations } = useDeviceDetail();

  const [isExpanded, setIsExpanded] = useState(false);

  if (!platform || !scan || !recommendations) return <Spinner size="md" color="brand.500" />;

  const renderPlatformVersionTag = () => {
    // eslint-disable-next-line max-len
    const platformVersion = scan
      ? scan.device
        ? scan.device.platform === 'android'
          ? (scan.os as AndroidOs).version
          : (scan.os as IosOs).patch
        : t('admin.devices.detail.platformVersionUnknown')
      : t('admin.devices.detail.platformVersionUnknown');

    if (recommendations.length > 0) {
      const endOfLife = recommendations.find(r => r.group_codename === 'os_end_of_life');
      if (endOfLife) {
        switch (endOfLife.severity) {
          case 'risk':
            return <Tag colorScheme="error">{platformVersion}</Tag>;
          case 'warning':
            return <Tag colorScheme="warning">{platformVersion}</Tag>;
          case 'safe':
            return <Tag colorScheme="success">{platformVersion}</Tag>;
          case 'unknown':
            return <Tag colorScheme="unknown">{platformVersion}</Tag>;
          default:
            return <Tag colorScheme="unknown">{platformVersion}</Tag>;
        }
      }

      return <Tag colorScheme="unknown">{platformVersion}</Tag>;
    }

    return <Tag colorScheme="unknown">{platformVersion}</Tag>;
  };

  return (
    <VStack w="full" spacing={0} pos="relative">
      <Description size="md" columns={{ sm: 1, md: 1, lg: 2, xl: 4, '2xl': 4 }}>
        <DescriptionItem label={t('admin.devices.detail.platform')}>
          <HStack justify="start" spacing={2}>
            <Text fontSize="1.2rem">{usePlatformIcon(platform)}</Text>
            <Text>{serializePlatform(platform)}</Text>
          </HStack>
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.platformVersion', { platform: serializePlatform(platform) })}>
          <HStack justify="start" spacing={2}>
            <Text>{renderPlatformVersionTag()}</Text>
          </HStack>
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.vendor')}>
          {serializeVendor(scan.device!.manufacturer)}
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.model')}>
          {scan.device!.marketing_name || scan.device!.model || scan.device!.device}
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.deviceSupport.text')}>
          {(platform === 'ios' || platform === 'ipados' || platform === 'android') && scan.device ? (
            (scan.device as IosDevice).end_of_life ? (
              <Tag colorScheme="error">{t('admin.devices.detail.deviceSupport.notSupported')}</Tag>
            ) : (
              <Tag colorScheme="success">{t('admin.devices.detail.deviceSupport.supported')}</Tag>
            )
          ) : (
            <Tag colorScheme="gray">{toCapitalize(t('admin.devices.detail.device.unknown'))}</Tag>
          )}
          {platform === 'macos' && (
            <Tag colorScheme="gray">{toCapitalize(t('admin.devices.detail.device.unknown'))}</Tag>
          )}
          {platform === 'linux' && (
            <Tag colorScheme="gray">{toCapitalize(t('admin.devices.detail.device.unknown'))}</Tag>
          )}
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.sensorVersion')}>
          <SensorAppVersion version={scan.app_version} latestVersion={scan.latest_available_app_version} />
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.scanInvoked')}>
          <ScanInvokedTag type={scan.invoked_by} />
        </DescriptionItem>
        <DescriptionItem label={t('admin.devices.detail.user')}>
          {scan.user ? (
            <HStack
              cursor="pointer"
              onClick={() =>
                navigate(
                  parseRoute(
                    user,
                    ROUTES.ADMIN.USERS.DETAIL,
                    { id: scan.user!.id! },
                    { 'users_devices.status': 'active' }
                  )
                )
              }
            >
              <UserTooltip userId={scan.user.id!} userName={scan.user.name!} />
            </HStack>
          ) : (
            <i>{toCapitalize(t('admin.devices.detail.device.unknown'))}</i>
          )}
        </DescriptionItem>
      </Description>

      {/* Space for "Show Extended Info" button */}
      {(scan as AndroidScan).extended_device_info && <div style={{ height: '1rem' }} />}

      {/* Extended Device Info */}
      {(scan as AndroidScan).extended_device_info && (
        <AnimatePresence initial={false}>
          {isExpanded && (
            <motion.div
              style={{ width: '100%' }}
              initial={{ opacity: 0, height: 0 }}
              animate={{
                opacity: 1,
                height: 'auto',
                transition: {
                  height: {
                    duration: 0.2
                  },
                  opacity: {
                    delay: 0.15,
                    duration: 0.1
                  }
                }
              }}
              exit={{
                opacity: 0,
                height: 0,
                transition: {
                  height: {
                    duration: 0.15,
                    delay: 0.1
                  },
                  opacity: {
                    duration: 0.2
                  }
                }
              }}
            >
              {platform === 'android' && <AndroidExtendedInfo info={(scan as AndroidScan).extended_device_info!} />}
              {(platform === 'ios' || platform === 'ipados') && (
                <IosExtendedInfo info={(scan as IosScan).extended_device_info!} />
              )}
              <div style={{ height: '1rem' }} />
            </motion.div>
          )}
        </AnimatePresence>
      )}

      {(scan as AndroidScan).extended_device_info && (
        <Button
          size="sm"
          variant="solid"
          pos="absolute"
          bottom="-2rem"
          leftIcon={
            <ChevronDown
              size={16}
              style={{
                transition: 'all .25s ease-in-out',
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            />
          }
          bg={useColorModeValue('white', 'gray.750')}
          _hover={{ bg: useColorModeValue('gray.10', 'gray.700') }}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? t('admin.devices.detail.hide') : t('admin.devices.detail.show')}&nbsp;
          {t('admin.devices.detail.extendedInfo')}
        </Button>
      )}
    </VStack>
  );
};

export default DeviceInfoCard;
