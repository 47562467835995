import { RCard, VStack } from '@ramp/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BasicIssuesFilter from 'components/BasicIssuesFilter';
import BasicIssuesTable from 'components/tables/BasicIssuesTable';
import { Issue } from 'types';

interface BasicIssuesCardProps {
  issues?: Issue[];
  isLoading: boolean;
}

const BasicIssuesCard: React.FC<BasicIssuesCardProps> = ({ issues, isLoading }) => {
  const { t } = useTranslation();

  const [filteredIssues, setFilteredIssues] = useState<Issue[]>(issues || []);

  useEffect(() => {
    console.log('filteredIssues', filteredIssues);
  }, [filteredIssues]);

  return (
    <VStack w="full" spacing={0}>
      <BasicIssuesFilter issues={issues} setIssues={setFilteredIssues} />
      <RCard w="full" titleOutside={false} title={t('admin.devices.detail.overview.basicIssues')}>
        <BasicIssuesTable id="issues_basic" issues={filteredIssues} defaultPageSize={50} loading={isLoading} />
      </RCard>
    </VStack>
  );
};

export default BasicIssuesCard;
