import { RSelectOptionV2, RSelectV2, RSelectV2Props } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getCountryCode } from 'utils/utils';

import CountryFlag from '../CountryFlag';

interface LanguageSelectProps extends Omit<RSelectV2Props, 'value' | 'onChange' | 'icon' | 'label' | 'children'> {
  style?: React.CSSProperties;
}

const LanguageSelect: React.FC<LanguageSelectProps> = ({ style, ...props }) => {
  const { t, i18n } = useTranslation();

  const getCountryValue = (language: string) => {
    const parsedLanguage = language.includes('-') ? language.split('-')[0].toLowerCase() : language.toLowerCase();

    return ['en', 'cs', 'de'].includes(parsedLanguage) ? parsedLanguage : 'en';
  };

  const updateLanguage = (language: string) => {
    i18n.changeLanguage(language);
    window.location.reload();
  };

  return (
    <div style={style}>
      <RSelectV2
        size={props.size || 'sm'}
        width="140px"
        label={t('components.languageSelect.label')}
        icon={<CountryFlag countryCode={getCountryCode(i18n.language)} style={{ marginRight: '0.5rem' }} />}
        value={getCountryValue(i18n.language)}
        onChange={language => updateLanguage(language as string)}
        {...props}
      >
        <RSelectOptionV2 icon={<CountryFlag countryCode="gb" />} value="en">
          English
        </RSelectOptionV2>
        <RSelectOptionV2 icon={<CountryFlag countryCode="cz" />} value="cs">
          Čeština
        </RSelectOptionV2>
        <RSelectOptionV2 icon={<CountryFlag countryCode="de" />} value="de">
          Deutsch
        </RSelectOptionV2>
      </RSelectV2>
    </div>
  );
};

export default LanguageSelect;
