import { Tag, Text, TextProps } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BreachStatusProps {
  size?: string | 'sm' | 'md' | 'lg';
  status?: 'active' | 'resolved';
}

const severityColors = {
  none: 'unknown',
  low: 'success',
  medium: 'warning',
  high: 'error'
};

const textProps: TextProps = {
  pos: 'relative',
  top: '1px',
  fontWeight: '500',
  fontSize: '0.875rem',
  lineHeight: '1rem'
};

const BreachStatus: React.FC<BreachStatusProps> = ({ size = 'md', status }) => {
  const { t } = useTranslation();

  if (!status) {
    return (
      <Tag size={size} colorScheme={severityColors.none} whiteSpace="nowrap">
        <Text {...textProps}>{t('components.breachStatus.unknown')}</Text>
      </Tag>
    );
  }

  if (status === 'active') {
    return (
      <Tag size={size} colorScheme="error">
        <Text {...textProps}>{t('components.breachStatus.active')}</Text>
      </Tag>
    );
  }

  return (
    <Tag size={size} colorScheme="success">
      <Text {...textProps}>{t('components.breachStatus.archived')}</Text>
    </Tag>
  );
};

export default BreachStatus;
