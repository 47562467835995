import { UseToastOptions } from '@ramp/components';
import { UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { APIError } from 'types';
import { notify } from 'utils/notifications';

/**
 * Hook to notify the user when a tanstack query to the API fails, and trigger a toast notification
 * @param query - The tanstack query result
 * @param toastOptions - The options for the toast notification
 */
const useOnQueryError = (query: UseQueryResult<any, APIError>, toastOptions?: UseToastOptions) => {
  const { t } = useTranslation();
  const { title, description } = toastOptions || {
    title:
      query.error && query.error.code
        ? query.error.description
          ? `[${query.error.code}] ${query.error.description}`
          : `[${query.error.code}] ${t('admin.components.notification.error.title')}`
        : t('admin.components.notification.error.title'),
    description:
      query.error && query.error.description
        ? query.error.description
        : t('admin.components.notification.error.description')
  };

  useEffect(() => {
    if (query.isError) {
      console.log('query.error', query.error);
      notify.error({
        title,
        description
      });
    }
  }, [query.isError]);
};

export default useOnQueryError;
