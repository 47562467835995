import {
  Box,
  DateRangePickerButton,
  FilterBar,
  IFilter,
  RRangeSlider,
  RSelect,
  RSelectOption,
  StyleProps,
  VStack
} from '@ramp/components';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Smartphone } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineApple, AiOutlineWindows } from 'react-icons/ai';
import {
  FiArchive,
  FiCalendar,
  FiCheckSquare,
  FiGlobe,
  FiHeart,
  FiSmartphone,
  FiSquare,
  FiUnderline,
  FiXSquare
} from 'react-icons/fi';
import { RiAndroidLine } from 'react-icons/ri';
import { TbBuildingFactory2 } from 'react-icons/tb';
import { VscTerminalLinux } from 'react-icons/vsc';

import api from 'api';
import { useAuthStore } from 'store';
import { DeviceUserEvaluation } from 'types';
import { serializeVendor } from 'utils/serializators';
import { compareAppVersions } from 'utils/utils';

interface DevicesFilterProps extends StyleProps {
  tableId?: string;
  devices?: DeviceUserEvaluation[];
  setDevices: React.Dispatch<React.SetStateAction<DeviceUserEvaluation[]>>;
  showSearch: boolean;
}

dayjs.extend(isBetween);

const DevicesFilter: React.FC<DevicesFilterProps> = ({
  tableId = 'users_devices',
  devices = [],
  setDevices,
  showSearch,
  ...props
}) => {
  const { t } = useTranslation();
  const entityId = useAuthStore(store => store.user!.entity_id!);

  const filters: IFilter[] = [
    {
      id: 'platform',
      type: 'select',
      label: t('components.devicesFilter.platform'),
      icon: FiGlobe,
      disabled: () => false,
      active: () => true,
      items: [
        { icon: RiAndroidLine, value: 'android', content: 'Android' },
        { icon: AiOutlineApple, value: 'ios', content: 'iOS' },
        { icon: AiOutlineApple, value: 'ipados', content: 'iPadOS' },
        { icon: AiOutlineWindows, value: 'windows', content: 'Windows' },
        { icon: AiOutlineApple, value: 'macos', content: 'macOS' },
        { icon: VscTerminalLinux, value: 'linux', content: 'Linux' }
      ],
      resetOnChange: ['vendor', 'model'],
      onFilter: (data: DeviceUserEvaluation[], value) => data.filter(device => device.platform === value),
      render: (filter, _, onValueChange) => (
        <RSelect
          size="sm"
          filterId={filter.id}
          label={filter.label}
          value={filter.selected ? filter.selected.value : undefined}
          onChange={newValue => {
            onValueChange(filter.id, newValue);
            // An attempt to set the vendor automatically (for ios, ipad and macos)
            // if (['ios', 'ipados', 'macos'].includes(newValue as string)) {
            //   onValueChange('vendor', 'apple');
            // } else {
            //   onValueChange('vendor', undefined);
            // }
            // onValueChange('model', undefined);
          }}
          placeholder={t('admin.dashboard.issues.filter.platformSelect')}
          leftIcon={filter.icon && <filter.icon />}
        >
          {filter.items &&
            filter.items.map(item => (
              <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                {item.content}
              </RSelectOption>
            ))}
        </RSelect>
      )
    },
    {
      id: 'vendor',
      type: 'select',
      label: t('components.devicesFilter.manufacturer'),
      icon: TbBuildingFactory2,
      disabled: (_filters: IFilter[], onValueChange) => {
        const platformFilter = _filters.find(f => f.id === 'platform')!;

        if (!platformFilter.selected) {
          const vendorFilter = _filters.find(f => f.id === 'vendor')!;
          if (vendorFilter.selected) onValueChange('vendor', undefined);

          return true;
        }

        return false;
      },
      active: () => true,
      items: [],
      fetchItems: async _filters => {
        const platformFilter = _filters.find(filter => filter.id === 'platform')!;

        if (platformFilter!.selected) {
          const manufacturers = await api.clients.getDevicesManufacturers(entityId, platformFilter.selected.value);
          return manufacturers.map(man => ({
            value: man.toLowerCase(),
            icon: TbBuildingFactory2,
            content: serializeVendor(man)
          }));
        }

        return [];
      },
      resetOnChange: ['model'],
      onFilter: (data: DeviceUserEvaluation[], value) => data.filter(device => device.manufacturer === value),
      render: (filter, _filters, onValueChange) => {
        return (
          <RSelect
            size="sm"
            filterId={filter.id}
            label={filter.label}
            value={filter.selected ? filter.selected.value : undefined}
            onChange={newValue => onValueChange(filter.id, newValue)}
            placeholder={t('components.devicesFilter.manufacturerSelect')}
            leftIcon={filter.icon && <filter.icon />}
            disabled={filter.disabled(_filters, onValueChange)}
          >
            {filter.items &&
              filter.items.map(item => (
                <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                  {item.content}
                </RSelectOption>
              ))}
          </RSelect>
        );
      }
    },
    {
      id: 'model',
      label: t('components.devicesFilter.model'),
      type: 'select',
      icon: FiSmartphone,
      disabled: (_filters: IFilter[], onValueChange) => {
        const platformFilter = _filters.find(f => f.id === 'platform')!;
        const vendorFilter = _filters.find(f => f.id === 'vendor')!;

        if (!platformFilter.selected || !vendorFilter.selected) {
          const modelFilter = _filters.find(f => f.id === 'model')!;
          if (modelFilter.selected) onValueChange('model', undefined);

          return true;
        }

        return false;
      },
      active: () => true,
      items: [],
      fetchItems: async _filters => {
        const platformFilter = _filters.find(filter => filter.id === 'platform')!;
        const manufacturerFilter = _filters.find(filter => filter.id === 'vendor')!;

        if (platformFilter!.selected) {
          const models = await api.clients.getDevicesModels(
            entityId,
            platformFilter.selected.value,
            manufacturerFilter.selected.value
          );

          return models.map(mod => ({
            value: mod,
            icon: FiSmartphone,
            content: mod
          }));
        }

        return [];
      },
      onFilter: (data: DeviceUserEvaluation[], value) => data.filter(device => device.marketing_name === value),
      render: (filter, _filters, onValueChange) => {
        return (
          <RSelect
            size="sm"
            filterId={filter.id}
            label={filter.label}
            value={filter.selected ? filter.selected.value : undefined}
            onChange={newValue => onValueChange(filter.id, newValue)}
            placeholder={t('components.devicesFilter.modelSelect')}
            leftIcon={filter.icon && <filter.icon />}
            disabled={filter.disabled(_filters, onValueChange)}
          >
            {filter.items &&
              filter.items.map(item => (
                <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                  {item.content}
                </RSelectOption>
              ))}
          </RSelect>
        );
      }
    },
    {
      id: 'deviceScore',
      type: 'slider',
      label: t('components.devicesFilter.deviceScore'),
      icon: FiGlobe,
      disabled: () => false,
      active: () => false,
      onFilter: (data: DeviceUserEvaluation[], value) => {
        return data.filter(device => device.device_score || (0 >= value.min && device.device_score) || 0 <= value.max);
      },
      render: (filter, _, onValueChange) => (
        <VStack w="full" h="full" alignItems="center" justifyContent="flex-start">
          <RRangeSlider
            step={0.5}
            minValue={0}
            maxValue={10}
            width="200px"
            marks={[
              { value: 0, label: '0.0' },
              { value: 2.5, label: '2.5' },
              { value: 5, label: '5.0' },
              { value: 7.5, label: '7.5' },
              { value: 10, label: '10.0' }
            ]}
            onCommit={newValue => onValueChange(filter.id, newValue)}
            values={filter.selected ? { min: filter.selected.min, max: filter.selected.max } : { min: 0, max: 10 }}
          />
        </VStack>
      )
    },
    {
      id: 'appsScore',
      type: 'slider',
      label: t('components.devicesFilter.appsScore'),
      icon: FiSquare,
      disabled: () => false,
      active: () => false,
      onFilter: (data: DeviceUserEvaluation[], value) => {
        return data.filter(
          device => (device.applications_score || 0) >= value.min && (device.applications_score || 0) <= value.max
        );
      },
      render: (filter, _, onValueChange) => (
        <VStack w="full" h="full" alignItems="center" justifyContent="flex-start">
          <RRangeSlider
            step={0.5}
            minValue={0}
            maxValue={10}
            width="200px"
            marks={[
              { value: 0, label: '0.0' },
              { value: 2.5, label: '2.5' },
              { value: 5, label: '5.0' },
              { value: 7.5, label: '7.5' },
              { value: 10, label: '10.0' }
            ]}
            onCommit={newValue => onValueChange(filter.id, newValue)}
            values={filter.selected ? { min: filter.selected.min, max: filter.selected.max } : { min: 0, max: 10 }}
          />
        </VStack>
      )
    },
    {
      id: 'lastScan',
      type: 'date',
      label: t('components.devicesFilter.lastScan'),
      icon: FiCalendar,
      disabled: () => false,
      active: () => false,
      items: [],
      onFilter: (data: DeviceUserEvaluation[], value) => {
        const { startDate, endDate } = value as { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs };

        return data.filter(dev => dayjs(dev.created).isBetween(startDate, endDate, 'day', '[]'));
      },
      render: (filter, _filters, onValueChange) => {
        return (
          <DateRangePickerButton
            startDate={filter.selected?.startDate}
            endDate={filter.selected?.endDate}
            onChange={(startDate, endDate) => {
              onValueChange(filter.id, { startDate, endDate });
            }}
          />
        );
      }
    },
    {
      id: 'appVersion',
      label: t('components.devicesFilter.updatedSensor'),
      type: 'select',
      icon: FiSquare,
      disabled: () => false,
      active: () => false,
      items: [
        { icon: FiCheckSquare, value: 'updated', content: 'Updated' },
        { icon: FiXSquare, value: 'notUpdated', content: 'Not Updated' }
      ],
      onFilter: (data: DeviceUserEvaluation[], value) =>
        data.filter(device => {
          if (value === 'updated') {
            return [0, 1].includes(
              compareAppVersions(device.app_version || '0', device.latest_available_app_version || '0')
            );
          } else {
            return [-1].includes(
              compareAppVersions(device.app_version || '0', device.latest_available_app_version || '0')
            );
          }
        }),
      render: (filter, _filters, onValueChange) => {
        return (
          <RSelect
            size="sm"
            filterId={filter.id}
            label={filter.label}
            value={filter.selected ? filter.selected.value : undefined}
            onChange={newValue => onValueChange(filter.id, newValue)}
            placeholder="Select Updated Sensor"
            leftIcon={filter.icon && <filter.icon />}
            disabled={filter.disabled(_filters, onValueChange)}
          >
            {filter.items &&
              filter.items.map(item => (
                <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                  {item.content}
                </RSelectOption>
              ))}
          </RSelect>
        );
      }
    },
    {
      id: 'status',
      type: 'select',
      label: t('components.devicesFilter.deviceStatus'),
      icon: FiCheckSquare,
      disabled: () => false,
      active: () => true,
      items: [
        { icon: () => <Smartphone size={14} />, value: 'all', content: t('components.devicesFilter.all') },
        { icon: FiCheckSquare, value: 'active', content: t('components.devicesFilter.active') },
        { icon: FiArchive, value: 'archived', content: t('components.devicesFilter.archived') }
      ],
      defaultValue: 'active',
      onFilter: (data: DeviceUserEvaluation[], value) => {
        if (value === 'all') return data;
        return data.filter(device => device.status === value);
      },
      render: (filter, _, onValueChange) => (
        <RSelect
          size="sm"
          filterId={filter.id}
          label={filter.label}
          defaultValue={filter.selected ? filter.selected.value || filter.defaultValue : filter.defaultValue}
          value={filter.selected ? filter.selected.value : undefined}
          onChange={newValue => onValueChange(filter.id, newValue)}
          placeholder={t('components.devicesFilter.selectStatus')}
          leftIcon={filter.icon && <filter.icon />}
        >
          {filter.items &&
            filter.items.map(item => (
              <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                {item.content}
              </RSelectOption>
            ))}
        </RSelect>
      )
    },
    {
      id: 'supported',
      type: 'select',
      label: t('components.devicesFilter.deviceSupport'),
      icon: FiHeart,
      disabled: () => false,
      active: () => true,
      items: [
        { icon: FiCheckSquare, value: 'supported', content: t('admin.devices.detail.deviceSupport.supported') },
        { icon: FiXSquare, value: 'unsupported', content: t('admin.devices.detail.deviceSupport.notSupported') },
        { icon: FiUnderline, value: 'unknown', content: t('admin.devices.detail.deviceSupport.unknown') }
      ],
      onFilter: (data: DeviceUserEvaluation[], value) => {
        switch (value) {
          case 'supported':
            return data.filter(device => device.device_supported === true);
          case 'unsupported':
            return data.filter(device => device.device_supported === false);
          case 'unknown':
            return data.filter(device => device.device_supported === null);
        }
      },
      render: (filter, _, onValueChange) => (
        <RSelect
          size="sm"
          filterId={filter.id}
          label={filter.label}
          value={filter.selected ? filter.selected.value : undefined}
          onChange={newValue => onValueChange(filter.id, newValue)}
          placeholder={t('components.devicesFilter.selectStatus')}
          leftIcon={filter.icon && <filter.icon />}
        >
          {filter.items &&
            filter.items.map(item => (
              <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                {item.content}
              </RSelectOption>
            ))}
        </RSelect>
      )
    }
  ];

  return (
    <Box w="full" {...props}>
      <FilterBar<DeviceUserEvaluation>
        tableId={tableId}
        filters={filters}
        data={devices}
        setData={filteredDevices => setDevices(filteredDevices)}
        searchFilter={
          showSearch
            ? {
                placeholder: t('admin.networks.detail.usersTable.searchBy'),
                onFilter: (data: DeviceUserEvaluation[], searchedValue) =>
                  data.filter(dev => dev.user_name?.toLowerCase().includes(searchedValue.toLowerCase()))
              }
            : undefined
        }
      />
    </Box>
  );
};

export default DevicesFilter;
