import i18next from 'i18next';
import React from 'react';

import { ReactComponent as AppStoreEN } from 'assets/badges/appstore_en.svg';
import GooglePlayEN from 'assets/badges/googleplay_en.png';
import { ReactComponent as MicrosoftStoreEN } from 'assets/badges/microsoftstore_en.svg';

import { ReactComponent as AppStoreCS } from 'assets/badges/appstore_cs.svg';
import GooglePlayCS from 'assets/badges/googleplay_cs.png';
import { ReactComponent as MicrosoftStoreCS } from 'assets/badges/microsoftstore_cs.svg';

import { VStack } from '@ramp/components';
import { ReactComponent as AppStoreDE } from 'assets/badges/appstore_de.svg';
import GooglePlayDE from 'assets/badges/googleplay_de.png';
import { ReactComponent as MicrosoftStoreDE } from 'assets/badges/microsoftstore_de.svg';

interface AppDownloadBadgeProps {
  store: 'app-store' | 'google-play' | 'microsoft-store';
  architecture?: '32bit' | '64bit';
}

const getStoreLink = (
  language: string,
  store: 'app-store' | 'google-play' | 'microsoft-store',
  architecture?: '32bit' | '64bit'
) => {
  const windowsLink =
    architecture === '32bit'
      ? 'https://windows.redamp.io/32/Redamp-io-latest-stable-32-bit-Setup.exe'
      : 'https://windows.redamp.io/64/Redamp-io-latest-stable-64-bit-Setup.exe';

  const googlePlayLink = 'https://play.google.com/store/apps/details?id=com.redampsecurity.redampio';

  switch (language) {
    case 'en':
      switch (store) {
        case 'app-store':
          return 'https://apps.apple.com/us/app/redamp-io/id6450367130';
        case 'google-play':
          return googlePlayLink;
        case 'microsoft-store':
          return windowsLink;
      }
      break;
    case 'cs':
      switch (store) {
        case 'app-store':
          return 'https://apps.apple.com/cz/app/redamp-io/id6450367130';
        case 'google-play':
          return googlePlayLink;
        case 'microsoft-store':
          return windowsLink;
      }
      break;
    case 'de':
      switch (store) {
        case 'app-store':
          return 'https://apps.apple.com/de/app/redamp-io/id6450367130';
        case 'google-play':
          return googlePlayLink;
        case 'microsoft-store':
          return windowsLink;
      }
      break;
  }
};

const AppDownloadBadge: React.FC<AppDownloadBadgeProps> = ({ store, architecture }) => {
  let finalBadge = null;

  switch (i18next.language) {
    case 'en':
      switch (store) {
        case 'app-store':
          finalBadge = <AppStoreEN style={{ width: '168px', height: 'auto' }} />;
          break;
        case 'google-play':
          finalBadge = <img src={GooglePlayEN} alt="Google Play" style={{ width: '184px', height: 'auto' }} />;
          break;
        case 'microsoft-store':
          finalBadge = <MicrosoftStoreEN style={{ width: '200px', height: 'auto' }} />;
          break;
      }
      break;
    case 'cs':
      switch (store) {
        case 'app-store':
          finalBadge = <AppStoreCS style={{ width: '168px', height: 'auto' }} />;
          break;
        case 'google-play':
          finalBadge = <img src={GooglePlayCS} alt="Google Play" style={{ width: '184px', height: 'auto' }} />;
          break;
        case 'microsoft-store':
          finalBadge = <MicrosoftStoreCS style={{ width: '200px', height: 'auto' }} />;
          break;
      }
      break;
    case 'de':
      switch (store) {
        case 'app-store':
          finalBadge = <AppStoreDE style={{ width: '168px', height: 'auto' }} />;
          break;
        case 'google-play':
          finalBadge = <img src={GooglePlayDE} alt="Google Play" style={{ width: '184px', height: 'auto' }} />;
          break;
        case 'microsoft-store':
          finalBadge = <MicrosoftStoreDE style={{ width: '200px', height: 'auto' }} />;
          break;
      }
      break;
  }

  if (architecture) {
    return (
      <VStack>
        <a href={getStoreLink(i18next.language, store, architecture)} target="_blank">
          {finalBadge}
        </a>
        <span>({architecture})</span>
      </VStack>
    );
  }

  return (
    <a href={getStoreLink(i18next.language, store)} target="_blank">
      {finalBadge}
    </a>
  );
};

export default AppDownloadBadge;
