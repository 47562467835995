import { Text, TextProps, Tooltip } from '@ramp/components';
import React from 'react';

interface TextTruncateTooltipProps extends TextProps {
  text: string;
  maxNumOfChars: number;
  renderChildren?: (truncatedText: string) => React.ReactNode;
}

// @ts-ignore
const TextTruncateTooltip: React.FC<TextTruncateTooltipProps> = ({ text, maxNumOfChars, renderChildren, ...props }) => {
  if (text.length >= maxNumOfChars) {
    const truncatedText = text.substring(0, maxNumOfChars - 3) + '...';

    return (
      <Tooltip placement="top" label={text}>
        {renderChildren ? renderChildren(truncatedText) : <Text {...props}>{truncatedText}</Text>}
      </Tooltip>
    );
  }

  return renderChildren ? renderChildren(text) : <Text {...props}>{text}</Text>;
};

export default TextTruncateTooltip;
