import { RCard, RPage, VStack } from '@ramp/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import NotApprovedClientInfoBox from 'components/NotApprovedClientInfoBox';
import RecommendationsTable from 'components/tables/RecommendationsTable';
import { useAuthStore } from 'store';
import { GeneralRecommendation } from 'types';
import { PRODUCT_NAME } from 'utils/constants';
import { notify } from 'utils/notifications';

import RecommendationsFilter from './RecommendationsFilter';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RecommendationsListProps {}

const RecommendationsList: React.FC<RecommendationsListProps> = () => {
  const { t } = useTranslation();
  const { client_approved: clientApproved } = useAuthStore(store => store.user!);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [defaultRecommendations, setDefaultRecommendations] = useState<GeneralRecommendation[]>([]);
  const [filteredRecommendations, setFilteredRecommendations] = useState<GeneralRecommendation[]>([]);

  useEffect(() => {
    api.recommendations
      .getGeneralRecommendations()
      .then(data => {
        setDefaultRecommendations(data);
        setFilteredRecommendations(data);
        setIsLoading(false);
      })
      .catch(err => {
        notify.error({
          title: t('admin.recommendations.notification.titleError'),
          description: err.description
        });
      });
  }, []);

  return (
    <RPage
      heading={t('admin.recommendations.heading')}
      infoBox={!clientApproved && clientApproved !== null && <NotApprovedClientInfoBox />}
      title={t('admin.recommendations.title', { productName: PRODUCT_NAME })}
      px={6}
      pt={8}
      loading={false}
    >
      <VStack w="full" align="start" spacing={0}>
        <RecommendationsFilter
          recommendations={defaultRecommendations}
          setRecommendations={setFilteredRecommendations}
        />
        <RCard w="full" titleOutside={false}>
          <RecommendationsTable
            id="recommendations_table"
            recommendations={filteredRecommendations}
            loading={isLoading}
          />
        </RCard>
      </VStack>
    </RPage>
  );
};

export default RecommendationsList;
