import { Alert, AlertIcon, HStack, Text } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

const NotApprovedClientInfoBox: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Alert my="1rem !important" status="warning" bg="warning.400" borderRadius="8px">
      <AlertIcon color="black" />
      <HStack w="full" align="center" color="black" spacing={1}>
        <Text fontWeight={500} fontSize=".9rem">
          {t('admin.notApprovedClient.title')}
        </Text>
        <Text fontWeight={400} fontSize=".9rem">
          {t('admin.notApprovedClient.description')}
        </Text>
      </HStack>
    </Alert>
  );
};

export default NotApprovedClientInfoBox;
