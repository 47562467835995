import {
  Box,
  Description,
  DescriptionItem,
  HStack,
  RCard,
  SeverityNumTag,
  Tag,
  Text,
  Tooltip,
  useColorModeValue
} from '@ramp/components';
import dayjs from 'dayjs';
import { Info, Settings } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ClientNetworkTag from 'components/ClientNetworkTag';
import { NetworkScan } from 'types';
import { serializeNetworkType } from 'utils/serializators';

interface NetworkInfoPanelProps {
  network?: NetworkScan;
  openNetworkSettingsModal?: () => void;
}

const NetworkInfoPanel: React.FC<NetworkInfoPanelProps> = ({ network, openNetworkSettingsModal }) => {
  if (!network) return null;

  const { t } = useTranslation();

  return (
    <RCard
      w="full"
      title={t('admin.networks.detail.networkInfo')}
      titleOutside={false}
      titleStyle={{ color: useColorModeValue('black', 'gray.400') }}
    >
      <Description size="md" columns={{ sm: 1, md: 2, lg: 4, xl: 4, '2xl': 4 }}>
        <DescriptionItem label={t('admin.networks.detail.type')}>
          <Text>{serializeNetworkType(network.network_type)}</Text>
        </DescriptionItem>
        <DescriptionItem label={t('admin.networks.detail.clientType')}>
          <HStack w="full" alignItems="center" justifyContent="space-between">
            <ClientNetworkTag type={network.client_type} />
            {openNetworkSettingsModal && (
              <Box
                cursor="pointer"
                color="gray.500"
                transition="color 0.2s ease-in-out"
                onClick={openNetworkSettingsModal}
                _hover={{ color: useColorModeValue('gray.300', 'gray.100'), cursor: 'pointer' }}
              >
                <Settings size={16} />
              </Box>
            )}
          </HStack>
        </DescriptionItem>
        <DescriptionItem label={t('admin.networks.detail.autoVpnActive')}>
          <HStack w="full" alignItems="center" justifyContent="space-between">
            <Tooltip label={t('components.table.networks.autoVpnActiveTooltip')} placement="top">
              <Tag
                colorScheme={network.is_safe ? 'error' : 'success'}
                w="fit-content"
                display="flex"
                alignItems="center"
                gap={1}
              >
                <Info size={12} />
                <Box pos="relative" top="1px">
                  {network.is_safe
                    ? t('admin.networks.detail.externalScanTab.table.no')
                    : t('admin.networks.detail.externalScanTab.table.yes')}
                </Box>
              </Tag>
            </Tooltip>
            <Box
              cursor="pointer"
              color="gray.500"
              transition="color 0.2s ease-in-out"
              onClick={openNetworkSettingsModal}
              _hover={{ color: useColorModeValue('gray.300', 'gray.100'), cursor: 'pointer' }}
            >
              <Settings size={16} />
            </Box>
          </HStack>
        </DescriptionItem>
        <DescriptionItem label={t('admin.networks.detail.riskScore')}>
          <HStack spacing={1} alignItems="end">
            <SeverityNumTag severityScore={network.score} />
            <Text fontSize="14px" fontWeight={400} pos="relative" top="6px">
              /10
            </Text>
          </HStack>
        </DescriptionItem>
        <DescriptionItem label={t('admin.networks.detail.lastScan')}>
          <Text>{dayjs(network.last_scan_done).format('DD. MM. YYYY')}</Text>
        </DescriptionItem>
        <DescriptionItem label={t('admin.networks.detail.defaultGateway')}>
          {network.default_gateway_ip && network.default_gateway_ip.length > 0 ? (
            <Text>{network.default_gateway_ip?.join(', ')}</Text>
          ) : (
            <Text fontStyle="italic">{t('components.networkType.unknown')}</Text>
          )}
        </DescriptionItem>
        <DescriptionItem label="DNS">
          {network.dns && network.dns.length > 0 ? (
            <Text>{network.dns?.join(', ')}</Text>
          ) : (
            <Text fontStyle="italic">{t('components.networkType.unknown')}</Text>
          )}
        </DescriptionItem>
        <DescriptionItem label={`${t('admin.networks.detail.publicIp')} (IPv4)`}>
          <Text>
            {network.public_ip && network.public_ip.ipv4
              ? network.public_ip.ipv4
              : t('admin.notifications.filter.severity.unknown')}
          </Text>
        </DescriptionItem>
        <DescriptionItem label={`${t('admin.networks.detail.publicIp')} (IPv6)`}>
          <Text>
            {network.public_ip && network.public_ip.ipv6
              ? network.public_ip.ipv6
              : t('admin.notifications.filter.severity.unknown')}
          </Text>
        </DescriptionItem>
      </Description>
    </RCard>
  );
};

export default NetworkInfoPanel;
