import { Description, DescriptionItem, HStack, Text } from '@ramp/components';
import byteSize from 'byte-size';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MacOSExtendedDeviceInfo } from 'types/api/devices';
import { toCapitalize } from 'utils/utils';

interface MacOSExtendedInfoProps {
  info: MacOSExtendedDeviceInfo;
}

const MacOSExtendedInfo: React.FC<MacOSExtendedInfoProps> = ({ info }) => {
  const { t } = useTranslation();

  return (
    <Description size="md" columns={{ sm: 1, md: 1, lg: 2, xl: 4, '2xl': 4 }}>
      <DescriptionItem label={t('admin.devices.detail.ram')}>
        <HStack justify="start" spacing={2}>
          {/* eslint-disable-next-line max-len */}
          <Text>
            {((info.ram.actual_usage || 0) / 10e8).toFixed(2)}GB / {((info.ram.total_memory || 0) / 10e8).toFixed(2)}GB
          </Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.storage')}>
        <HStack justify="start" spacing={2}>
          {info.storage.map((s, i) => (
            // eslint-disable-next-line max-len
            <Text>
              {byteSize(s.actual_usage || 0).toString()} / {byteSize(s.total_storage || 0).toString()}
              {i + 1 !== info.storage.length && '; '}
            </Text>
          ))}
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.cpu.name')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.cpu.name}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.cpu.architecture')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.cpu.architecture}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.gpu.name')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.gpu.model}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.gpu.numberOfCores')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.gpu.number_of_cores}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.battery.capacity')}>
        <HStack justify="start" spacing={2}>
          <Text>
            {((info.battery.current_capacity / 100) * info.battery.design_capacity).toFixed(0)}Wh &nbsp;/&nbsp;
            {info.battery.design_capacity}Wh
          </Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.battery.cycleCount')}>
        <HStack justify="start" spacing={2}>
          <Text>
            {info.battery.cycle_count || toCapitalize(t('admin.devices.detail.device.unknown'))}
            &nbsp;/&nbsp;
            {info.battery.design_cycle_count || toCapitalize(t('admin.devices.detail.device.unknown'))}
          </Text>
        </HStack>
      </DescriptionItem>
    </Description>
  );
};

export default MacOSExtendedInfo;
