import { Button, DateRangePicker, HStack, Popover, PopoverContent, PopoverTrigger, Text } from '@ramp/components';
import { colorHex, themeColor } from '@ramp/theme';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { HiOutlineCalendar } from 'react-icons/hi';

interface DateRangePickerButtonProps {
  startDate?: dayjs.Dayjs;
  endDate?: dayjs.Dayjs;
  onChange: (startDate?: dayjs.Dayjs, endDate?: dayjs.Dayjs) => void;
}

const DateRangePickerButton: React.FC<DateRangePickerButtonProps> = ({ startDate, endDate, onChange }) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false);
  const [calendarStartDate, setCalendarStartDate] = useState<string | undefined>(startDate?.format('YYYY-MM-DD'));
  const [calendarEndDate, setCalendarEndDate] = useState<string | undefined>(endDate?.format('YYYY-MM-DD'));

  return (
    <Popover isOpen={isCalendarOpen} onClose={() => setIsCalendarOpen(false)} placement="bottom-end">
      <PopoverTrigger>
        <Button
          size="sm"
          variant="borderless"
          onClick={() => setIsCalendarOpen(!isCalendarOpen)}
          bg={themeColor('gray.25', 'whiteAlpha.50')}
          _hover={{ bg: themeColor('gray.10', 'whiteAlpha.100') }}
        >
          <HStack spacing={2} justify="center">
            <HiOutlineCalendar
              style={{
                color: !calendarStartDate || !calendarEndDate ? colorHex('gray.500') : 'inherit'
              }}
            />
            <Text
              fontWeight={400}
              lineHeight="0.5rem"
              color={!calendarStartDate || !calendarEndDate ? 'gray.500' : 'inherit'}
            >
              {!calendarStartDate || !calendarEndDate ? (
                'Select Last Scan Date'
              ) : (
                <>
                  {dayjs(calendarStartDate).format('DD. MM. YYYY')}
                  &nbsp;-&nbsp;
                  {dayjs(calendarEndDate).format('DD. MM. YYYY')}
                </>
              )}
            </Text>
          </HStack>
        </Button>
      </PopoverTrigger>
      <PopoverContent width="full" border="none">
        <DateRangePicker
          startDate={dayjs(calendarStartDate).toDate()}
          endDate={dayjs(calendarEndDate).toDate()}
          onDateRangeChange={() => {}}
          onDateReset={() => {
            setCalendarStartDate(undefined);
            setCalendarEndDate(undefined);
            onChange(undefined, undefined);
          }}
          onDateRangeApply={(sDate, eDate) => {
            setCalendarStartDate(sDate.format('YYYY-MM-DD'));
            setCalendarEndDate(eDate.format('YYYY-MM-DD'));
            onChange(sDate, eDate);
          }}
          onVisibleChange={visible => setIsCalendarOpen(visible)}
          showBottomPanel
        />
      </PopoverContent>
    </Popover>
  );
};

export default DateRangePickerButton;
