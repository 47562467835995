import { Heading, HStack, RCard, Search, useColorModeValue, VStack } from '@ramp/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';

import VerifiedAppsTable from 'components/tables/VerifiedAppsTable';
import { AppInfo } from 'types';

interface VerifiedAppsProps {
  filteredApps: AppInfo[];
}

const VerifiedApps: React.FC<VerifiedAppsProps> = ({ filteredApps }) => {
  const { t } = useTranslation();
  const verifiedApps = filteredApps.filter(app => app.eval_category === 'verified');

  const [searchInVerified, setSearchInVerified] = React.useState<string>('');
  const [filteredVerified, setFilteredVerified] = React.useState<AppInfo[]>(verifiedApps);

  useEffect(() => {
    const apps = filteredApps.filter(app => app.eval_category === 'verified');
    const filteredVerifiedApps = apps.filter(app => {
      const appName = (app.application_name || app.package_name).toLowerCase();
      return appName.includes(searchInVerified.toLowerCase());
    });

    setFilteredVerified(filteredVerifiedApps);
  }, [filteredApps, searchInVerified]);

  return (
    <VStack w="full" spacing={3}>
      <HStack w="full" justify="space-between" align="end">
        <Element name="verified_apps">
          <Heading
            mt="2rem !important"
            mb="0.25rem !important"
            color={useColorModeValue('black', 'gray.400')}
            fontSize="1.5rem"
            fontWeight={600}
          >
            {t('admin.devices.detail.apps.verified.title')}
          </Heading>
        </Element>
        <Search
          w="360px"
          size="sm"
          value={searchInVerified}
          // eslint-disable-next-line max-len
          placeholder={`${t('admin.devices.detail.apps.risky.searchIn')} '${t('admin.devices.detail.apps.verified.title')}'`}
          onChange={e => setSearchInVerified(e.target.value)}
        />
      </HStack>

      <RCard w="full">
        <VStack w="full" spacing={4}>
          <VerifiedAppsTable id="device_detail_unupdated_apps" apps={filteredVerified} />
        </VStack>
      </RCard>
    </VStack>
  );
};

export default VerifiedApps;
