import { Grid, GridItem, Text, VStack } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ChangePasswordForm from './ChangePasswordForm';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChangePasswordSettingProps {}

const ChangePasswordSetting: React.FC<ChangePasswordSettingProps> = () => {
  const { t } = useTranslation();

  return (
    <Grid w="full" templateColumns="1fr 1.4fr" gap={14}>
      <GridItem w="100%">
        <VStack w="full" align="start">
          <Text fontWeight={600} fontSize="1.25rem">
            {t('admin.accountSettings.passwordChange.title')}
          </Text>
          <Text fontWeight={300} fontSize="1rem">
            {t('admin.accountSettings.passwordChange.description')}
          </Text>
        </VStack>
      </GridItem>
      <GridItem w="100%">
        <ChangePasswordForm />
      </GridItem>
    </Grid>
  );
};

export default ChangePasswordSetting;
