type RolePrefixFunc<T = any> = (rolePrefix: 'user' | 'admin' | 'partneradmin' | 'superadmin') => T;

// --------------
// ROUTING PREFIX
// --------------

const ADMIN_ROUTE_PREFIX: RolePrefixFunc = rolePrefix => `/${rolePrefix}/client/:entityId`;
const PARTNERADMIN_ROUTE_PREFIX = '/partneradmin/:entityId';

// --------------
// AUTH NAMESPACE
// --------------

const BASE = {
  ROOT: '/',
  SIGN_IN: '/',
  CLOSE: '/close',
  SIGN_UP: '/sign-up',
  SIGN_UP_COMPANY: '/sign-up/company',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/change-password',
  VERIFICATION: '/verification/:invitationId',
  FIRM_VERIFICATION: '/firm-verification/:invitationId',
  FIRMADMIN_VERIFICATION: '/firmadmin-verification',
  TEST_ASSIGNMENT: '/test/:assignmentToken',
  GW_IMPORT: '/gwimport',
  OTP_SETUP: '/otp-setup'
};

// --------------
// DASHBOARD NAMESPACE
// --------------

const SUPERADMIN_DASHBOARD = '/dashboard';
const PARTNERADMIN_DASHBOARD = `${PARTNERADMIN_ROUTE_PREFIX}/dashboard`;
const DASHBOARD: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/dashboard`;

// --------------
// DEVICES NAMESPACE
// --------------

const DEVICES_NAMESPACE: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/devices`;
const DEVICES: RolePrefixFunc<{
  ROOT: string;
  LIST: string;
  DETAIL: string;
}> = rolePrefix => ({
  ROOT: DEVICES_NAMESPACE(rolePrefix),
  LIST: DEVICES_NAMESPACE(rolePrefix),
  DETAIL: `${DEVICES_NAMESPACE(rolePrefix)}/:platform/:id`
});

// --------------
// ISSUES NAMESPACE
// --------------

const ISSUES_NAMESPACE: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/issues`;
const ISSUES: RolePrefixFunc<{
  ROOT: string;
  LIST: string;
}> = rolePrefix => ({
  ROOT: ISSUES_NAMESPACE(rolePrefix),
  LIST: ISSUES_NAMESPACE(rolePrefix)
});

// --------------
// NETWORKS NAMESPACE
// --------------

const NETWORKS_NAMESPACE: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/networks`;
const NETWORKS: RolePrefixFunc<{
  ROOT: string;
  LIST: string;
  DETAIL: string;
}> = rolePrefix => ({
  ROOT: NETWORKS_NAMESPACE(rolePrefix),
  LIST: NETWORKS_NAMESPACE(rolePrefix),
  DETAIL: `${NETWORKS_NAMESPACE(rolePrefix)}/:type/:id`
});

// --------------
// SETTINGS NAMESPACE
// --------------

const SUPERADMIN_SETTINGS = '/settings';
const PARTNERADMIN_SETTINGS = `${PARTNERADMIN_ROUTE_PREFIX}/settings`;
const ACCOUNT_SETTINGS: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/account/settings`;
const GLOBAL_SETTINGS: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/global/settings`;

// --------------
// NOTIFICATIONS NAMESPACE
// --------------

const NOTIFICATIONS_NAMESPACE: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/notifications`;
const NOTIFICATIONS: RolePrefixFunc<{
  ROOT: string;
  LIST: string;
  ADD: string;
}> = rolePrefix => ({
  ROOT: NOTIFICATIONS_NAMESPACE(rolePrefix),
  LIST: NOTIFICATIONS_NAMESPACE(rolePrefix),
  ADD: `${NOTIFICATIONS_NAMESPACE(rolePrefix)}/add`
});

// --------------
// USERS NAMESPACE
// --------------

const USERS_NAMESPACE: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/users`;
const USERS: RolePrefixFunc<{
  ROOT: string;
  LIST: string;
  DETAIL: string;
  ADD: string;
}> = rolePrefix => ({
  ROOT: USERS_NAMESPACE(rolePrefix),
  LIST: USERS_NAMESPACE(rolePrefix),
  DETAIL: `${USERS_NAMESPACE(rolePrefix)}/detail/:id`,
  ADD: `${USERS_NAMESPACE(rolePrefix)}/add`
});

// --------------
// CUSTOMERS NAMESPACE
// --------------
const CUSTOMERS_NAMESPACE = `${PARTNERADMIN_ROUTE_PREFIX}/customers`;
const CUSTOMERS = {
  ROOT: CUSTOMERS_NAMESPACE,
  LIST: CUSTOMERS_NAMESPACE,
  ADD: `${CUSTOMERS_NAMESPACE}/add`,
  DETAIL: `${CUSTOMERS_NAMESPACE}/:id`
};

// --------------
// PARTNERS NAMESPACE
// --------------
const PARTNERS_NAMESPACE = '/partners';
const PARTNERS = {
  ROOT: PARTNERS_NAMESPACE,
  LIST: PARTNERS_NAMESPACE,
  ADD: `${PARTNERS_NAMESPACE}/add`,
  DETAIL: `${PARTNERS_NAMESPACE}/:id`
};

// --------------
// COMPLIANCE NAMESPACE
// --------------
const COMPLIANCE_NAMESPACE: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/compliance`;
const COMPLIANCE: RolePrefixFunc<{
  ROOT: string;
  LIST: string;
  ADD: string;
  ADD_NIS2: string;
  DETAIL: string;
}> = rolePrefix => ({
  ROOT: COMPLIANCE_NAMESPACE(rolePrefix),
  LIST: COMPLIANCE_NAMESPACE(rolePrefix),
  ADD: `${COMPLIANCE_NAMESPACE(rolePrefix)}/add`,
  ADD_NIS2: `${COMPLIANCE_NAMESPACE(rolePrefix)}/add/nis2`,
  DETAIL: `${COMPLIANCE_NAMESPACE(rolePrefix)}/detail/nis2`
});

// --------------
// EVENT LOG NAMESPACE
// --------------
const EVENT_LOG: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/event-log`;

// --------------
// PRIVACY-PROTECTION NAMESPACE
// --------------
const PRIVACY_PROTECTION: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/privacy-protection`;

// --------------
// EDUCATION NAMESPACE
// --------------
const EDUCATION_NAMESPACE: RolePrefixFunc = rolePrefix => `${ADMIN_ROUTE_PREFIX(rolePrefix)}/education`;
const EDUCATION: RolePrefixFunc<{
  ROOT: string;
  TESTS: string;
  CREATE_TEST: string;
  RECOMMENDATIONS: string;
  LOCAL_THREATS: string;
  PHISHING_SIMULATION: string;
}> = rolePrefix => ({
  ROOT: EDUCATION_NAMESPACE(rolePrefix),
  TESTS: `${EDUCATION_NAMESPACE(rolePrefix)}/tests`,
  CREATE_TEST: `${EDUCATION_NAMESPACE(rolePrefix)}/create-test`,
  RECOMMENDATIONS: `${EDUCATION_NAMESPACE(rolePrefix)}/recommendations`,
  LOCAL_THREATS: `${EDUCATION_NAMESPACE(rolePrefix)}/local-threats`,
  PHISHING_SIMULATION: `${EDUCATION_NAMESPACE(rolePrefix)}/phishing-simulation`
});

export default {
  BASE,
  USER: {
    DASHBOARD: DASHBOARD('user'),
    DEVICES: DEVICES('user'),
    ISSUES: ISSUES('user'),
    NETWORKS: NETWORKS('user'),
    NOTIFICATIONS: NOTIFICATIONS('user'),
    ACCOUNT_SETTINGS: ACCOUNT_SETTINGS('user'),
    PRIVACY_PROTECTION: PRIVACY_PROTECTION('user'),
    EDUCATION: EDUCATION('user')
  },
  ADMIN: {
    DASHBOARD: DASHBOARD('admin'),
    DEVICES: DEVICES('admin'),
    ACCOUNT_SETTINGS: ACCOUNT_SETTINGS('admin'),
    GLOBAL_SETTINGS: GLOBAL_SETTINGS('admin'),
    ISSUES: ISSUES('admin'),
    NETWORKS: NETWORKS('admin'),
    USERS: USERS('admin'),
    NOTIFICATIONS: NOTIFICATIONS('admin'),
    PRIVACY_PROTECTION: PRIVACY_PROTECTION('admin'),
    EDUCATION: EDUCATION('admin'),
    COMPLIANCE: COMPLIANCE('admin'),
    EVENT_LOG: EVENT_LOG('admin')
  },
  FIRMADMIN: {
    DASHBOARD: PARTNERADMIN_DASHBOARD,
    CUSTOMERS,
    SETTINGS: PARTNERADMIN_SETTINGS,
    ADMINS: {
      ROOT: `${PARTNERADMIN_ROUTE_PREFIX}/admins`,
      LIST: `${PARTNERADMIN_ROUTE_PREFIX}/admins`,
      DETAIL: `${PARTNERADMIN_ROUTE_PREFIX}/admins/detail/:id`,
      ADD: `${PARTNERADMIN_ROUTE_PREFIX}/admins/add`
    },
    GLOBAL_SETTINGS: GLOBAL_SETTINGS('partneradmin')
  },
  SUPERADMIN: {
    DASHBOARD: SUPERADMIN_DASHBOARD,
    PARTNERS,
    SETTINGS: SUPERADMIN_SETTINGS,
    GLOBAL_SETTINGS: GLOBAL_SETTINGS('admin')
  }
};
