import {
  Box,
  Button,
  Divider,
  HStack,
  RCard,
  RInput,
  Text,
  ThemeToggle,
  useColorModeValue,
  VStack
} from '@ramp/components';
import { useFormik } from 'formik';
import { motion } from 'framer-motion';
import { ArrowLeft, AtSign } from 'lucide-react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { object, SchemaOf, string } from 'yup';

import api from 'api';
import BasePageLeftPanel from 'components/BasePageLeftPanel';
import Logo from 'components/Logo';
import { useTranslation } from 'react-i18next';
import ROUTES from 'router/routes';
import { APIError } from 'types';
import { PRODUCT_NAME } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ForgotPasswordProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [error, setError] = React.useState<APIError>();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);

  const CredentialsValidationSchema: SchemaOf<{ email: string }> = object().shape({
    email: string().email(t('base.forgotPassword.badFormat')).required()
  });

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: CredentialsValidationSchema,
    validateOnChange: false,
    onSubmit: credentials => {
      setLoading(true);
      setSuccess(false);
      setError(undefined);
      api.users
        .resetPasswordByEmail(credentials.email)
        .then(() => {
          setLoading(false);
          setSuccess(true);
        })
        .catch((err: APIError) => {
          setLoading(false);
          return setError(err);
        });
      return;
    }
  });

  useEffect(() => {
    window.document.title = `${PRODUCT_NAME} | Forgot Password`;
  }, []);

  return (
    <HStack w="full" h="100vh" spacing={['0', '0', '0', '64px']} p="32px">
      <BasePageLeftPanel w={['500px', '500px', '320px', '360px', '480px']} />
      <VStack
        pos="relative"
        w={['100%', '100%', 'calc(100% - 320px)', 'calc(100% - 360px)', 'calc(100% - 480px)']}
        h="full"
        alignItems="center"
        justifyContent="center"
      >
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          style={{ position: 'relative', zIndex: 10 }}
        >
          <RCard
            p={['16px', '32px', '32px']}
            w={['320px', '440px', '380px', '480px', '480px']}
            pos="relative"
            zIndex={10}
            alignItems="center"
            borderRadius="16px"
            bg={useColorModeValue('white', 'gray.800')}
          >
            <Logo type="partner" style={{ marginBottom: '1.5rem', marginTop: '0.5rem' }} mb="1rem" />
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <VStack w="full" spacing={['1rem']}>
                <RInput
                  h="56px"
                  w="full"
                  size="lg"
                  id="email"
                  name="email"
                  type="text"
                  label={t('base.forgotPassword.email')}
                  icon={<AtSign width="24px" height="24px" />}
                  value={values.email}
                  onChange={handleChange}
                  isError={!!errors.email}
                  error={errors.email}
                  onCard
                />
                {error && (
                  <HStack
                    py={2}
                    px={4}
                    w="full"
                    justify="center"
                    bg={useColorModeValue('gray.10', 'gray.700')}
                    borderRadius="8px"
                  >
                    <Text fontWeight={600} color="brand.500" textAlign="center">
                      {error.description}
                    </Text>
                  </HStack>
                )}
                {success && (
                  <HStack
                    py={2}
                    px={4}
                    w="full"
                    justify="center"
                    bg={useColorModeValue('gray.10', 'gray.700')}
                    borderRadius="8px"
                  >
                    <Text fontWeight={600} color="green.500" textAlign="center">
                      {t('base.forgotPassword.successMessage')}
                    </Text>
                  </HStack>
                )}
                <HStack
                  w="full"
                  justify="center"
                  spacing={['1rem']}
                  flexDirection={['column', 'column', 'column', 'row', 'row']}
                >
                  <Button
                    w={['full', 'full', 'full', 'auto']}
                    size="lg"
                    variant="solid"
                    fontSize="18px"
                    leftIcon={<ArrowLeft />}
                    onClick={() => navigate(ROUTES.BASE.SIGN_IN)}
                  >
                    {t('base.forgotPassword.back')}
                  </Button>
                  <Button
                    size="lg"
                    type="submit"
                    variant="brand"
                    fontSize="18px"
                    isLoading={loading}
                    w={['full', 'full', 'full', 'auto']}
                  >
                    {t('base.forgotPassword.resetButton')}
                  </Button>
                </HStack>
                <Divider borderColor={useColorModeValue('gray.25', 'gray.600')} />
                <ThemeToggle />
              </VStack>
            </form>
          </RCard>
        </motion.div>
        <Box
          pos="absolute"
          zIndex={1}
          top="40%"
          w="200px"
          h="200px"
          filter="blur(125px)"
          bg="linear-gradient(156.26deg, rgba(214, 9, 40, 0.6) 8.79%, rgba(246, 40, 71, 0.35) 91.67%)"
        />
      </VStack>
    </HStack>
  );
};

export default ForgotPassword;
