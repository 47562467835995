import { HStack, Link, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Issue } from 'types';
import { serializePlatform } from 'utils/serializators';
import { usePlatformIcon } from 'utils/utils';

interface DetailsTabProps {
  issue: Issue;
}

const DetailsTab: React.FC<DetailsTabProps> = ({ issue }) => {
  const { t } = useTranslation();

  return (
    <VStack w="full" py={4} align="end" spacing={0}>
      <VStack w="full" align="start">
        <HStack align="end" spacing={4}>
          <Text fontSize="1.1rem" fontWeight={600}>
            {t('admin.dashboard.issues.table.title')}
          </Text>
          <Text fontSize=".95rem" color="gray.200" fontWeight={300}>
            {issue.title}
          </Text>
        </HStack>
        {/* <HStack align="end" spacing={4}>
          <Text fontSize="1.1rem" fontWeight={600}>
            {t("admin.dashboard.issues.table.category")}
          </Text>
          <Text fontSize=".95rem" color="gray.200" fontWeight={300}>
            {toCapitalize(issue.category)}
          </Text>
        </HStack> */}
      </VStack>
      <HStack w="full" spacing={8} align="start">
        <VStack w="50%" align="start" mt="1.5rem !important" spacing={1}>
          <Text fontSize="1.1rem" fontWeight={600}>
            {t('admin.dashboard.issues.table.detailDescription')}
          </Text>
          <Text fontSize=".95rem" color="gray.200" fontWeight={300}>
            {issue.description}&nbsp;
            <Link
              color="brand.500"
              textDecoration="underline"
              href={issue.knowledge_base_url}
              target="_blank"
              rel="noreferrer"
            >
              {t('admin.dashboard.issues.table.moreInfo')}
            </Link>
          </Text>
        </VStack>
        <VStack pt={6} w="50%" align="start" justify="end" spacing={1}>
          <Text fontSize="1.1rem" fontWeight={600}>
            {t('admin.dashboard.issues.table.suggestedSolution')}
          </Text>
          {issue.category === 'platform' ? (
            <Tabs>
              <TabList>
                {issue.recommendations.map((rec, idx) => (
                  <Tab
                    key={idx}
                    _selected={{
                      color: 'brand.500',
                      borderColor: 'currentcolor'
                    }}
                  >
                    {usePlatformIcon(rec.category)}
                    &nbsp;&nbsp;
                    {serializePlatform(rec.category)}
                  </Tab>
                ))}
              </TabList>
              <TabPanels>
                {issue.recommendations.map((rec, idx) => (
                  <TabPanel key={idx}>
                    <Text fontSize=".95rem" color="gray.200" fontWeight={300}>
                      {rec.description}&nbsp;
                      <Link
                        color="brand.500"
                        textDecoration="underline"
                        href={issue.knowledge_base_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('admin.dashboard.issues.table.moreInfo')}
                      </Link>
                    </Text>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          ) : (
            <VStack>
              {issue.recommendations.map((rec, idx) => (
                <Text key={idx} fontSize=".95rem" color="gray.200" fontWeight={300}>
                  {rec.description}&nbsp;
                  <Link
                    color="brand.500"
                    textDecoration="underline"
                    href={issue.knowledge_base_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('admin.dashboard.issues.table.moreInfo')}
                  </Link>
                </Text>
              ))}
            </VStack>
          )}
        </VStack>
      </HStack>
    </VStack>
  );
};

export default DetailsTab;
