import { Heading, VStack } from '@ramp/components';
import React, { useEffect } from 'react';
import { PRODUCT_NAME } from 'utils/constants';

const Import: React.FC = () => {
  useEffect(() => {
    window.document.title = `${PRODUCT_NAME} | Import`;

    const queryParams = new URLSearchParams(window.location.hash.substring(1));
    const token = queryParams.get('access_token');

    if (token) {
      if (window.opener && window.opener.setAuthCode) {
        window.opener.setAuthCode({ status: 'success', data: token });
        window.close();
      } else {
        window.close();
      }
    } else {
      if (window.opener && window.opener.setAuthCode) {
        window.opener.setAuthCode({ status: 'error', data: 'No token' });
        window.close();
      } else {
        window.close();
      }
    }
  }, []);

  return (
    <VStack
      w="full"
      maxW="480px"
      h="100vh"
      mx="auto"
      px={['16px']}
      pb={['16px']}
      pt={['16px']}
      alignItems="center"
      justifyContent="center"
    >
      <Heading textAlign="center">Google Workspace Import</Heading>
    </VStack>
  );
};

export default Import;
