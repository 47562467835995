import {
  Box,
  ColumnsType,
  Flex,
  HStack,
  RTable,
  SeverityNumTag,
  SimpleTable,
  Tag,
  Text,
  Tooltip
} from '@ramp/components';
import { themeColor } from '@ramp/theme';
import dayjs from 'dayjs';
import { AlertTriangle, Info, Network, Signal, Wifi } from 'lucide-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ClientNetworkTag from 'components/ClientNetworkTag';
import IssueIgnorePopover from 'components/IssueIgnorePopover';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { IssueDetail, NetworkScan, NetworkScanWithoutUsers } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import { parseRoute } from 'utils/utils';

interface NetworksTableProps {
  /**
   * Table id
   */
  id: string;

  /**
   * Render the table in a simple mode
   *
   * @default true
   */
  simple?: boolean;

  /**
   * Networks to display in the table
   */
  networks?: NetworkScan[] | NetworkScanWithoutUsers[];

  /**
   * Loading state of the table
   */
  loading?: boolean;

  /**
   * If true, the table will not display the users column
   *
   * @default false
   */
  withoutUsers?: boolean;

  /**
   * Default page size of the table
   *
   * @default 10
   */
  defaultPageSize?: number;

  /**
   * If defined, the table will render with the "Actions" column,
   * where the user can see the device detail and ignore/activate issue.
   */
  withIssue?: IssueDetail;

  /**
   * Defines if we want to render "affected_objects" or "ignored_objects"
   * and if render "ignore/activate issue" button in the table
   *
   * If `true`, the table will render the objects that are actively affected and the "ignore/activate issue" button.
   *
   * If `false`, the table will render all objects and the "ignore/activate issue" button will not be displayed.
   *
   * `withIssue` property must be defined to use this property.
   *
   * @default true
   */
  showAffected?: boolean;

  /**
   * Defines if we want to render the table in user view
   *
   * @default false
   */
  userView?: boolean;
}

interface NetworkScanRow extends NetworkScan {
  key: string;
}

interface NetworkScanWithoutUsersRow extends NetworkScanWithoutUsers {
  key: string;
}

const NetworksTable: React.FC<NetworksTableProps> = ({
  id,
  loading,
  networks = [],
  withoutUsers = false,
  defaultPageSize = 10,
  simple = true,
  withIssue,
  showAffected = true,
  userView = false
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAuthStore(store => store.user!);

  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const issueActionsColumn: ColumnsType<any> = useMemo(
    () => [
      {
        title: t('admin.dashboard.issues.table.actions'),
        key: 'actions',
        align: 'center' as const,
        render: (_: any, network: NetworkScanWithoutUsersRow | NetworkScanRow) => (
          <HStack justify="center" spacing={2}>
            <IssueIgnorePopover
              issue={{
                ...withIssue!,
                n_affected_objects: 1,
                n_ignored_objects: 1,
                recommendations: withIssue!.recommendations.map(r => ({
                  ...r,
                  n_affected_objects: r.affected_objects.length.toString(),
                  n_ignored_objects: r.ignored_objects.length.toString()
                }))
              }}
              codenames={[
                {
                  codename: withIssue!.codename,
                  object_ids: [network.id!]
                }
              ]}
              mode={showAffected ? 'ignore' : 'activate'}
              userView={userView}
            />
          </HStack>
        )
      }
    ],
    []
  );

  const columns: ColumnsType<NetworkScanRow> = useMemo(
    () => [
      {
        key: 'name',
        title: t('components.table.networks.name'),
        sorting: true,
        sortingOptions: {
          dataType: 'string'
        },
        render: (_, { name, network_type }) => (
          <HStack spacing={4}>
            <Flex
              w="32px"
              h="32px"
              p="0.5rem"
              alignItems="center"
              justifyContent="center"
              borderRadius="md"
              bg={themeColor('gray.10', 'gray.700')}
            >
              {network_type === 'wifi' && <Wifi width="1rem" />}
              {network_type === 'cellular' && <Signal width="1rem" />}
              {network_type === 'ethernet' && <Network width="1rem" />}
              {network_type === 'unknown' && <AlertTriangle width="1rem" />}
            </Flex>
            <Text fontSize="md" fontWeight={500}>
              {name}
            </Text>
          </HStack>
        )
      },
      {
        key: 'client_type',
        title: t('components.table.networks.clientNetworkType'),
        sorting: true,
        sortingOptions: {
          dataType: 'string',
          defaultSortOrder: 'asc'
        },
        render: (_, { client_type: clientNetworkType }) => <ClientNetworkTag type={clientNetworkType} />
      },
      {
        key: 'public_ip',
        title: `${t('admin.networks.detail.publicIp')} (IPv4)`,
        render: (_, { public_ip }) => {
          if (!public_ip || !public_ip.ipv4) {
            return <Text fontStyle="italic">{t('admin.notifications.filter.severity.unknown')}</Text>;
          }

          return <Text>{public_ip.ipv4}</Text>;
        }
      },
      {
        key: 'default_gateway',
        title: t('components.table.networks.defaultGateway'),
        render: (_, { default_gateway_ip }) => {
          if (!default_gateway_ip || default_gateway_ip.length === 0) {
            return <Text fontStyle="italic">{t('admin.notifications.filter.severity.unknown')}</Text>;
          }

          return <Text>{default_gateway_ip.join(', ')}</Text>;
        }
      },
      {
        key: 'dns',
        title: 'DNS',
        render: (_, { dns }) => {
          if (!dns || dns.length === 0) return <Text fontStyle="italic">Unknown</Text>;

          return <Text>{dns.join(', ')}</Text>;
        }
      },
      {
        key: 'users',
        title: t('components.table.networks.users'),
        sorting: true,
        sortingOptions: {
          dataType: 'number'
        },
        render: (_, { users }) => <Text>{users.length}</Text>
      },
      {
        key: 'riskScore',
        title: t('components.table.networks.riskScore'),
        align: 'center',
        sorting: true,
        sortingOptions: {
          dataType: 'number',
          sortingFn: (a, b) => (a.original.score || 0) - (b.original.score || 0)
        },
        render: (_, { score }) => (
          <HStack spacing={1} justifyContent="center" alignItems="end">
            <SeverityNumTag severityScore={score} />
            <Text fontSize="md" lineHeight="8px">
              /10
            </Text>
          </HStack>
        )
      },
      {
        key: 'is_safe',
        title: t('components.table.networks.autoVpnActive'),
        sorting: true,
        align: 'center',
        sortingOptions: {
          dataType: 'string',
          sortingFn: (a, b) => (a.original.is_safe ? 1 : 0) - (b.original.is_safe ? 1 : 0)
        },
        render: (_, { is_safe }) => {
          return (
            <HStack w="full" justifyContent="center">
              <Tooltip placement="top" label={t('components.table.networks.autoVpnActiveTooltip')}>
                {is_safe ? (
                  <Tag colorScheme="error" w="fit-content" display="flex" alignItems="center" gap={1}>
                    <Info size={12} />
                    <Box pos="relative" top="1px">
                      {t('admin.networks.detail.externalScanTab.table.no')}
                    </Box>
                  </Tag>
                ) : (
                  <Tag colorScheme="success" w="fit-content" display="flex" alignItems="center" gap={1}>
                    <Info size={12} />
                    <Box pos="relative" top="1px">
                      {t('admin.networks.detail.externalScanTab.table.yes')}
                    </Box>
                  </Tag>
                )}
              </Tooltip>
            </HStack>
          );
        }
      },
      {
        key: 'last_scan_done',
        title: t('components.table.networks.lastScanDone'),
        align: 'center',
        sorting: true,
        sortingOptions: {
          sortingFn: (a, b) => dayjs(b.original.last_scan_done).diff(dayjs(a.original.last_scan_done))
        },
        render: (_, { last_scan_done }) => dayjs(last_scan_done).format('DD. MM. YYYY')
      },
      ...(withIssue ? issueActionsColumn : [])
    ],
    []
  );

  const columnsWithoutUsers: ColumnsType<NetworkScanWithoutUsersRow> = useMemo(
    () => [
      {
        key: 'name',
        title: t('components.table.networks.name'),
        sorting: true,
        sortingOptions: {
          dataType: 'string'
        },
        render: (_, { name, network_type }) => (
          <HStack spacing={4}>
            <Flex
              w="32px"
              h="32px"
              p="0.5rem"
              alignItems="center"
              justifyContent="center"
              borderRadius="md"
              bg={themeColor('gray.10', 'gray.700')}
            >
              {network_type === 'wifi' && <Wifi width="1rem" />}
              {network_type === 'cellular' && <Signal width="1rem" />}
              {network_type === 'ethernet' && <Network width="1rem" />}
              {network_type === 'unknown' && <AlertTriangle width="1rem" />}
            </Flex>
            <Text fontSize="md" fontWeight={500}>
              {name}
            </Text>
          </HStack>
        )
      },
      {
        key: 'client_type',
        title: t('components.table.networks.clientNetworkType'),
        sorting: true,
        sortingOptions: {
          dataType: 'string'
        },
        render: (_, { client_type: clientNetworkType }) => <ClientNetworkTag type={clientNetworkType} />
      },
      {
        key: 'default_gateway',
        title: t('components.table.networks.defaultGateway'),
        render: (_, { default_gateway_ip }) => {
          if (!default_gateway_ip || default_gateway_ip.length === 0) return <Text fontStyle="italic">Unknown</Text>;

          return <Text>{default_gateway_ip.join(', ')}</Text>;
        }
      },
      {
        key: 'dns',
        title: 'DNS',
        render: (_, { dns }) => {
          if (!dns || dns.length === 0) return <Text fontStyle="italic">Unknown</Text>;

          return <Text>{dns.join(', ')}</Text>;
        }
      },
      {
        title: t('components.table.networks.riskScore'),
        key: 'score',
        align: 'center',
        sorting: true,
        sortingOptions: {
          dataType: 'number',
          sortingFn: ({ original: a }, { original: b }) => (a.score || 0) - (b.score || 0)
        },
        render: (_, { score }) => (
          <HStack spacing={1} justifyContent="center" alignItems="end">
            <SeverityNumTag severityScore={score} />
            <Text fontSize="md" lineHeight="8px">
              /10
            </Text>
          </HStack>
        )
      },
      {
        key: 'last_scan_done',
        title: t('components.table.networks.lastScanDone'),
        align: 'center',
        sorting: true,
        sortingOptions: {
          defaultSortOrder: 'desc'
        },
        render: (_, { last_scan_done }) => dayjs(last_scan_done).format('DD. MM. YYYY')
      },
      ...(withIssue ? issueActionsColumn : [])
    ],
    []
  );

  // @ts-ignore
  const formattedNetworks: NetworkScanRow[] | NetworkScanWithoutUsersRow[] = useMemo(() => {
    return networks.map((n, idx) => ({ ...n, key: n.id || idx.toString() }));
  }, [networks]);

  if (simple) {
    return (
      <SimpleTable<NetworkScanRow | NetworkScanWithoutUsersRow>
        data={formattedNetworks}
        // @ts-ignore
        columns={withoutUsers ? columnsWithoutUsers : columns}
        pagination={{
          showTotal: true,
          defaultPageSize: pageSize,
          onPageSizeChange: newPageSize => setPageSize(newPageSize),
          showGoToPage: true,
          showPageSizeSelector: true,
          pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
        }}
        onRowClick={
          !withIssue
            ? ({ id: networkId, network_type: type }) => {
                // If withoutUsers is set to true, we are on the user page -> navigate to the user network detail page
                if (withoutUsers) {
                  navigate(
                    parseRoute(user, ROUTES.USER.NETWORKS.DETAIL, {
                      type,
                      id: networkId!
                    })
                  );
                } else {
                  navigate(
                    parseRoute(user, ROUTES.ADMIN.NETWORKS.DETAIL, {
                      type,
                      id: networkId!
                    })
                  );
                }
              }
            : undefined
        }
        loading={loading}
        interleaveRows
      />
    );
  }

  return (
    <RTable<NetworkScanRow | NetworkScanWithoutUsersRow>
      data={formattedNetworks}
      // @ts-ignore
      columns={withoutUsers ? columnsWithoutUsers : columns}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
      }}
      onRowClick={
        !withIssue
          ? ({ id: networkId, network_type: type }) => {
              // If withoutUsers is set to true, we are on the user page -> navigate to the user network detail page
              if (withoutUsers) {
                navigate(
                  parseRoute(user, ROUTES.USER.NETWORKS.DETAIL, {
                    type,
                    id: networkId!
                  })
                );
              } else {
                navigate(
                  parseRoute(user, ROUTES.ADMIN.NETWORKS.DETAIL, {
                    type,
                    id: networkId!
                  })
                );
              }
            }
          : undefined
      }
      loading={loading}
      interleaveRows
    />
  );
};

export default NetworksTable;
