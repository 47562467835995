import React from 'react';
import { useTranslation } from 'react-i18next';

import { NetworkType, Nullable } from 'types';
import { toCapitalize } from 'utils/utils';

import networkCardManufacturers from './networkCardManufacturers.json';
import platforms from './platforms.json';
import protocols from './protocols.json';
import vendors from './vendors.json';

interface IJSONObject {
  [index: string]: string;
}

type SerializePlatform = (osName: string) => string;
export const serializePlatform: SerializePlatform = platform => {
  const p: IJSONObject = platforms;

  return p[platform.toLowerCase()] || toCapitalize(platform);
};

type SerializeVendor = (vendor: string) => string;
export const serializeVendor: SerializeVendor = vendor => {
  const v: IJSONObject = vendors;

  return v[vendor.toLowerCase()] || vendor;
};

type SerializeNetworkType = (networkType: NetworkType) => React.ReactNode;
export const serializeNetworkType: SerializeNetworkType = networkType => {
  const { t } = useTranslation();

  switch (networkType) {
    case 'wifi':
      return t('components.networkType.wifi');
    case 'cellular':
      return t('components.networkType.cellular');
    case 'ethernet':
      return t('components.networkType.ethernet');
    case 'unknown':
      return t('components.networkType.unknown');
  }
};

type SerializeNetworkProtocolType = (portNumber: number) => Nullable<string>;

export const serializeNetworkProtocolType: SerializeNetworkProtocolType = portNumber => {
  const p: IJSONObject = protocols;

  return p[portNumber.toString()] || null;
};

type SerializeNetworkCardManufacturer = (networkCard: string) => string;

export const serializeNetworkCardManufacturer: SerializeNetworkCardManufacturer = networkCard => {
  const n: IJSONObject = networkCardManufacturers;

  return n[networkCard] || networkCard;
};
