import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack
} from '@ramp/components';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import { useAuthStore } from 'store';
import { APIError } from 'types';
import { notify } from 'utils/notifications';

interface Disable2FAModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Disable2FAModal: React.FC<Disable2FAModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { user_id: userId } = useAuthStore(store => store.user!);
  const setOtpEnabled = useAuthStore(store => store.setOtpEnabled);

  const [showLoading, setShowLoading] = useState<boolean>(false);

  const disable2FA: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    setShowLoading(true);

    api.users
      .disableOtp(userId)
      .then(async () => {
        await queryClient.invalidateQueries(['user', userId]);
        setOtpEnabled(false);
        onClose();
        setShowLoading(false);
        notify.success({
          title: t('admin.users.detail.disable2fa.successTitle'),
          description: t('admin.users.detail.disable2fa.successDescription2')
        });
      })
      .catch((err: APIError) => {
        onClose();
        setShowLoading(false);
        notify.error({
          title: t('admin.users.detail.disable2fa.errorTitle'),
          description: err.description
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnEsc>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={disable2FA} style={{ width: '100%' }}>
          <ModalBody>
            <VStack spacing={4} p={4} align="center" justify="center">
              <Text fontWeight={500} fontSize="1.25rem" textAlign="center">
                Disable 2FA?
              </Text>
              <Text fontWeight={300} fontSize="1rem" textAlign="center">
                Are you sure you want to disable Two-Factor Authentication?
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack w="full" align="center" justify="center" spacing={2}>
              <Button size="sm" variant="borderless" onClick={onClose}>
                Cancel
              </Button>
              <Button size="sm" type="submit" isLoading={showLoading}>
                Disable
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default Disable2FAModal;
