import auth from './auth';
import clients from './clients';
import devices from './devices';
import firms from './firms';
import google from './google';
import mars from './mars';
import microsoft from './microsoft';
import networks from './networks';
import recommendations from './recommendations';
import users from './users';

export default {
  auth,
  mars,
  firms,
  users,
  clients,
  devices,
  networks,
  google,
  microsoft,
  recommendations
};
