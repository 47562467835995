import { VStack } from '@ramp/components';
import React from 'react';

import { AffectedEmail, Client, IssueDetail, NetworkDevice, NetworkScanWithoutUsers, UserData } from 'types';

import AffectedEmailsTable from 'components/tables/AffectedEmailsTable';
import CustomersTable from 'components/tables/CustomersTable';
import DevicesEvaluationTable, {
  DeviceUserEvaluationWithIssueCodename
} from 'components/tables/DevicesEvaluationTable';
import NetworkDeviceSnapshotsTable from 'components/tables/NetworkDeviceSnapshotsTable';
import NetworksTable from 'components/tables/NetworksTable';
import UsersTable from 'components/tables/UsersTable';

interface AffectedEntitiesTabProps {
  /**
   * Issue detail
   */
  issue: IssueDetail;

  /**
   * Simple mode for the table
   *
   * @default false
   */
  simple?: boolean;

  /**
   * Defines if we want to render "affected_objects" or "ignored_objects"
   * and if render "ignore/activate issue" button in the table
   *
   * If true, the table will render the objects that are actively affected and the "ignore/activate issue" button
   * If false, the table will render all objects and the "ignore/activate issue" button will not be displayed
   *
   * @default true
   */
  showAffected?: boolean;

  /**
   * Defines if we want to render the table in user view
   *
   * @default false
   */
  userView?: boolean;
}

const AffectedEntitiesTab: React.FC<AffectedEntitiesTabProps> = ({
  issue,
  simple = false,
  showAffected = true,
  userView = false
}) => {
  if (issue.object_type === 'device') {
    const getObjects = () => {
      const devices: DeviceUserEvaluationWithIssueCodename[] = [];

      issue.recommendations.forEach(r => {
        if (showAffected) {
          r.affected_objects.forEach(o => {
            devices.push({
              ...o,
              codename: r.codename
            } as DeviceUserEvaluationWithIssueCodename);
          });
        } else {
          r.ignored_objects.forEach(o => {
            devices.push({
              ...o,
              codename: r.codename
            } as DeviceUserEvaluationWithIssueCodename);
          });
        }
      });

      return devices;
    };

    return (
      <VStack w="full" py={4} align="end" spacing={0}>
        <DevicesEvaluationTable
          id="affected_devices"
          devices={getObjects()}
          defaultPageSize={5}
          simple={simple}
          withIssue={issue}
          showAffected={showAffected}
          userView={userView}
        />
      </VStack>
    );
  }

  if (issue.object_type === 'network') {
    return (
      <VStack w="full" py={4} align="end" spacing={0}>
        <NetworksTable
          id="affected_networks"
          networks={
            showAffected
              ? (issue.affected_objects as NetworkScanWithoutUsers[])
              : (issue.ignored_objects as NetworkScanWithoutUsers[])
          }
          withIssue={issue}
          showAffected={showAffected}
          simple={false}
          withoutUsers
          userView={userView}
        />
      </VStack>
    );
  }

  if (issue.object_type === 'client') {
    return (
      <VStack w="full" py={4} align="end" spacing={0}>
        <CustomersTable
          id="affected_clients"
          defaultCustomers={showAffected ? (issue.affected_objects as Client[]) : (issue.ignored_objects as Client[])}
          filteredCustomers={showAffected ? (issue.affected_objects as Client[]) : (issue.ignored_objects as Client[])}
        />
      </VStack>
    );
  }

  if (issue.object_type === 'network_device') {
    return (
      <VStack w="full" py={4} align="end" spacing={0}>
        <NetworkDeviceSnapshotsTable
          id="affected_network_devices"
          deviceSnapshots={
            (showAffected ? (issue.affected_objects as NetworkDevice[]) : (issue.ignored_objects as NetworkDevice[]))[0]
              .snapshots
          }
        />
      </VStack>
    );
  }

  if (issue.object_type === 'email') {
    return (
      <VStack w="full" py={4} align="end" spacing={0}>
        <AffectedEmailsTable
          id="email_breach_scans_table"
          emails={
            showAffected ? (issue.affected_objects as AffectedEmail[]) : (issue.ignored_objects as AffectedEmail[])
          }
          withIssue={issue}
          showAffected={showAffected}
          userView={userView}
        />
      </VStack>
    );
  }

  if (issue.object_type === 'user') {
    return (
      <VStack w="full" py={4} align="end" spacing={0}>
        <UsersTable
          id="affected_users"
          users={showAffected ? (issue.affected_objects as UserData[]) : (issue.ignored_objects as UserData[])}
          withIssue={issue}
          showAffected={showAffected}
          userView={userView}
        />
      </VStack>
    );
  }

  return null;
};

export default AffectedEntitiesTab;
