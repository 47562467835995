import { Tag } from '@ramp/components';
import React from 'react';

import { Nullable } from 'types';
import pkg from '../../../package.json';

interface SensorAppVersionProps {
  version: Nullable<string>;
  latestVersion?: Nullable<string>;
}

const SensorAppVersion: React.FC<SensorAppVersionProps> = ({ version, latestVersion }) => {
  const deviceVersionNumbers: string[] = version ? version.split('.') : [];
  const webVersionNumbers: string[] = latestVersion ? latestVersion.split('.') : pkg.version.split('.');

  let deviceVersionSum = 0;
  deviceVersionNumbers.forEach((number, idx) => (deviceVersionSum += idx < 2 ? parseInt(number) : 0));

  let webVersionSum = 0;
  webVersionNumbers.forEach((number, idx) => (webVersionSum += idx < 2 ? parseInt(number) : 0));

  // Unknown version
  if (!deviceVersionSum) {
    return <Tag colorScheme="gray">Unknown</Tag>;
  }

  // Updated version
  if (deviceVersionSum >= webVersionSum) {
    return <Tag colorScheme="success">v{version}</Tag>;
  }

  // Outdated version
  return <Tag colorScheme="error">v{version}</Tag>;
};

export default SensorAppVersion;
