import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack
} from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface UserConfirmModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  okText?: string;
  onOk?: React.FormEventHandler<HTMLFormElement>;
}

const UserConfirmModal: React.FC<UserConfirmModalProps> = ({ isOpen, onClose, title, description, okText, onOk }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnEsc>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onOk} style={{ width: '100%' }}>
          <ModalBody>
            <VStack spacing={4} p={4} align="center" justify="center">
              <Text fontWeight={500} fontSize="1.25rem" textAlign="center">
                {title}
              </Text>
              <Text fontWeight={300} fontSize="1rem" textAlign="center">
                {description}
              </Text>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack w="full" align="center" justify="center" spacing={2}>
              <Button size="sm" variant="borderless" onClick={onClose}>
                {t('admin.users.detail.cancel')}
              </Button>
              <Button size="sm" variant="brand" type="submit">
                {okText}
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UserConfirmModal;
