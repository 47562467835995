import { HStack, useColorModeValue } from '@ramp/components';
import { clearAuthTokens, getAccessToken, isLoggedIn } from 'axios-jwt';
import React, { useEffect } from 'react';

import api from 'api';
import { useAuthStore, useLayoutStore } from 'store';
import { accessTokenExpire, extractUserFromToken, getEntityIdFromUrl } from 'utils/utils';

import pkg from '../../../package.json';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AppLoaderProps {}

const AppLoader: React.FC<AppLoaderProps> = () => {
  const { setLoading, setNoUsersInCompany, setNoScansInCompany, theme, setTheme } = useLayoutStore(store => store);
  const { loginUserWithAccessToken, loginUserWithRefreshToken } = useAuthStore(store => store);

  const splittedHost = window.location.host.split('.');
  const userType = window.location.pathname.split('/')[1];
  const entityIdFromUrl = getEntityIdFromUrl();

  useEffect(() => {
    // Check if there was an update to the app, and if so, logout the user
    if (localStorage.getItem('sc_version') !== pkg.version) {
      clearAuthTokens();
      localStorage.setItem('sc_version', pkg.version);
    }

    // Make API call to get theme, only if we're on the subdomain
    if (splittedHost.length === 3) {
      api.firms.getFirmsTheme(splittedHost[0]).then(firmTheme => setTheme(firmTheme));
    }

    // If user has access token in localStorage & it's not expired
    if (isLoggedIn() && !accessTokenExpire()) {
      const user = extractUserFromToken(getAccessToken()!);
      const entityId = entityIdFromUrl || user.entity_id!;

      setTheme({
        ...theme,
        logo_primary_light: user.logo_primary_light,
        logo_primary_dark: user.logo_primary_dark,
        logo_secondary_light: user.logo_secondary_light,
        logo_secondary_dark: user.logo_secondary_dark,
        theme: user.theme,
        color_scheme: user.color_scheme
      });

      setTimeout(() => {
        loginUserWithAccessToken(entityId);

        if (userType === 'admin') {
          api.clients
            .getUsers(entityId)
            .then(users => {
              api.clients
                .getDevices({ client_id: entityId })
                .then(devices => {
                  if (users.length <= 1) setNoUsersInCompany(true);
                  if (devices.length === 0) setNoScansInCompany(true);

                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                });
            })
            .catch(() => {
              setLoading(false);
            });
        } else if (userType === 'user') {
          api.users
            .getDevices(user.user_id)
            .then(devices => {
              if (devices.length === 0) setNoScansInCompany(true);

              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      }, 500);
    }

    // If user has access token in localStorage, but it's expired
    if (isLoggedIn() && accessTokenExpire()) {
      const user = extractUserFromToken(getAccessToken()!);
      const entityId = entityIdFromUrl || user.entity_id!;

      setTimeout(() => {
        loginUserWithRefreshToken(entityId);
        setLoading(false);
      }, 500);
    }

    // If user doesn't have access token in localStorage
    if (!isLoggedIn()) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, []);

  return (
    <HStack w="100vw" h="100vh" alignItems="center" justifyContent="center" bg={useColorModeValue('white', 'gray.900')}>
      <img src={theme.logo_secondary_dark} className="breathAnimation" style={{ width: '64px' }} alt="Redamp logo" />
    </HStack>
  );
};

export default AppLoader;
