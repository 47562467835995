import { Tag } from '@ramp/components';
import React from 'react';

interface PermissionStatusProps {
  status?: string;
}

const PermissionStatus: React.FC<PermissionStatusProps> = ({ status }) => {
  const color = () => {
    switch (status) {
      case 'unknown':
        return 'unknown';
      case 'normal':
        return 'success';
      case 'dangerous':
        return 'error';
      default:
        return 'unknown';
    }
  };

  return (
    <Tag size="md" colorScheme={color()}>
      {status?.toUpperCase() || 'UNKNOWN'}
    </Tag>
  );
};

export default PermissionStatus;
