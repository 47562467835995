import { Heading, Search, useColorModeValue, VStack } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface AppSearchProps {
  searchedApp: string;
  setSearchedApp: React.Dispatch<React.SetStateAction<string>>;
}

const AppSearch: React.FC<AppSearchProps> = ({ searchedApp, setSearchedApp }) => {
  const { t } = useTranslation();

  return (
    <VStack align="start" w="full">
      <Heading color={useColorModeValue('black', 'gray.400')} fontSize="1.5rem" fontWeight={600}>
        {t('admin.devices.detail.apps.appSearch.title')}
      </Heading>
      <Search
        w="100%"
        size="lg"
        value={searchedApp}
        placeholder={t('admin.devices.detail.apps.appSearch.placeholder')}
        onChange={e => setSearchedApp(e.target.value)}
      />
    </VStack>
  );
};

export default AppSearch;
