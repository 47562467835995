import { Box, HStack, VStack } from '@ramp/components';
import React, { useEffect } from 'react';

import BasePageLeftPanel from 'components/BasePageLeftPanel';
import { useAuthStore } from 'store';
import { PRODUCT_NAME } from 'utils/constants';

import ResetPasswordForm from './ResetPasswordForm';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResetPasswordProps {}

const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const logoutUser = useAuthStore(store => store.logoutUser);

  useEffect(() => {
    window.document.title = `${PRODUCT_NAME} | Reset Password`;
    logoutUser();
  }, []);

  return (
    <HStack w="full" h="100vh" spacing="64px" p="32px">
      <BasePageLeftPanel w={['500px', '500px', '320px', '360px', '480px']} />
      <VStack
        pos="relative"
        w={['100%', '100%', 'calc(100% - 320px)', 'calc(100% - 360px)', 'calc(100% - 480px)']}
        marginInline={['0 !important', '0 !important', '64px 0px', '64px 0px', '64px 0px']}
        h="full"
        alignItems="center"
        justifyContent="center"
      >
        <ResetPasswordForm />
        <Box
          pos="absolute"
          zIndex={1}
          top="60%"
          w="200px"
          h="200px"
          filter="blur(125px)"
          bg="linear-gradient(156.26deg, rgba(214, 9, 40, 0.6) 8.79%, rgba(246, 40, 71, 0.35) 91.67%)"
        />
      </VStack>
    </HStack>
  );
};

export default ResetPassword;
