import { ColumnsType, Flex, HStack, RCard, SeverityNumTag, SimpleTable, Text } from '@ramp/components';
import { themeColor } from '@ramp/theme';
import dayjs from 'dayjs';
import { AtSign } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LeaksTable from 'components/tables/DataBreachedEmailsTable/LeaksTable';
import { EmailScan } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';

import { useDeviceDetail } from '../../DeviceDetailProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PrivacyTabProps {}

interface PrivacyRow extends Omit<EmailScan, 'users'> {
  key: string;
}

const PrivacyTab: React.FC<PrivacyTabProps> = () => {
  const { t } = useTranslation();
  const { scan } = useDeviceDetail();

  if (!scan) return null;

  const { privacy } = scan;

  if (!privacy) return null;

  const emailsRows = privacy.email_scans.map((email, idx) => ({
    ...email,
    key: idx.toString()
  }));

  const columns: ColumnsType<PrivacyRow> = [
    {
      key: 'email',
      title: t('components.table.emailBreaches.email'),
      sorting: true,
      sortingOptions: {
        dataType: 'string'
      },
      render: (_, { email }) => (
        <HStack spacing={4}>
          <Flex
            w="32px"
            h="32px"
            p="0.5rem"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            bg={themeColor('gray.10', 'gray.700')}
          >
            <AtSign width="1rem" />
          </Flex>
          <Text fontSize="md" fontWeight={500}>
            {email}
          </Text>
        </HStack>
      )
    },
    {
      key: 'breaches',
      title: t('components.table.emailBreaches.breaches'),
      align: 'center',
      sorting: true,
      sortingOptions: {
        dataType: 'number'
      },
      render: (_, { breaches }) => breaches.length
    },
    {
      key: 'riskScore',
      title: t('components.table.emailBreaches.riskScore'),
      align: 'center',
      sorting: true,
      sortingOptions: {
        dataType: 'number'
      },
      render: (_, { score }) => (
        <HStack spacing={1} justifyContent="center" alignItems="end">
          <SeverityNumTag severityScore={score} />
          <Text fontSize="md" lineHeight="8px">
            /10
          </Text>
        </HStack>
      )
    },
    {
      key: 'scanRequested',
      title: t('components.table.emailBreaches.scanRequested'),
      align: 'center',
      sorting: true,
      render: (_, { scan_requested }) => dayjs(scan_requested).format('DD. MM. YYYY')
    },
    {
      key: 'scanDone',
      title: t('components.table.emailBreaches.lastScanDone'),
      align: 'center',
      sorting: true,
      sortingOptions: {
        defaultSortOrder: 'desc'
      },
      render: (_, { scan_done }) => {
        if (scan_done) {
          return dayjs(scan_done).format('DD. MM. YYYY');
        }

        return t('components.table.emailBreaches.processing');
      }
    }
  ];

  return (
    <RCard w="full">
      <SimpleTable<PrivacyRow>
        data={emailsRows}
        columns={columns}
        expandable={{
          rowExpandable: () => true,
          render: email => (
            <LeaksTable
              emailId={email.id}
              usersIds={[]}
              id="email_breaches_table"
              breaches={email.breaches}
              hideUpdateStatus
            />
          )
        }}
        pagination={{
          showTotal: true,
          defaultPageSize: 10,
          showGoToPage: true,
          showPageSizeSelector: true,
          pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
        }}
        interleaveRows
      />
    </RCard>
  );
};

export default PrivacyTab;
