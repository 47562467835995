import { Box, DateRangePickerButton, FilterBar, IFilter, RSelect, RSelectOption } from '@ramp/components';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Building, HelpCircle, Home, Network, Signal, Warehouse, Wifi } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiCalendar } from 'react-icons/fi';

import { NetworkScan, NetworkScanWithoutUsers } from 'types';

interface NetworksFilterProps {
  networks?: NetworkScan[] | NetworkScanWithoutUsers[];
  setNetworks: React.Dispatch<React.SetStateAction<NetworkScan[] | NetworkScanWithoutUsers[]>>;
}

dayjs.extend(isBetween);

const NetworksFilter: React.FC<NetworksFilterProps> = ({ networks = [], setNetworks }) => {
  const { t } = useTranslation();

  const filters: IFilter[] = [
    {
      id: 'network_type',
      type: 'select',
      label: t('components.networksFilter.networkType.label'),
      icon: () => <Network size="16px" />,
      disabled: () => false,
      active: () => true,
      items: [
        { icon: () => <Wifi size="16px" />, value: 'wifi', content: 'Wi-Fi' },
        { icon: () => <Signal size="16px" />, value: 'cellular', content: 'Cellular' },
        { icon: () => <Network size="16px" />, value: 'ethernet', content: 'Ethernet' }
      ],
      onFilter: (data: NetworkScan[], value) => data.filter(n => n.network_type === value),
      render: (filter, _, onValueChange) => (
        <RSelect
          size="sm"
          filterId={filter.id}
          label={filter.label}
          value={filter.selected ? filter.selected.value : undefined}
          onChange={newValue => onValueChange(filter.id, newValue)}
          placeholder={t('components.networksFilter.networkType.placeholder')}
          leftIcon={filter.icon && <filter.icon />}
        >
          {filter.items &&
            filter.items.map(item => (
              <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                {item.content}
              </RSelectOption>
            ))}
        </RSelect>
      )
    },
    {
      id: 'lastScan',
      type: 'date',
      label: t('components.networksFilter.lastScan.label'),
      icon: FiCalendar,
      disabled: () => false,
      active: () => true,
      items: [],
      onFilter: (data: NetworkScan[], value) => {
        const { startDate, endDate } = value as { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs };

        return data.filter(n => dayjs(n.last_scan_done).isBetween(startDate, endDate, 'day', '[]'));
      },
      render: (filter, _filters, onValueChange) => {
        return (
          <DateRangePickerButton
            startDate={filter.selected?.startDate}
            endDate={filter.selected?.endDate}
            onChange={(startDate, endDate) => {
              onValueChange(filter.id, { startDate, endDate });
            }}
          />
        );
      }
    },
    {
      id: 'client_type',
      type: 'select',
      label: t('components.networksFilter.clientNetworkType.label'),
      icon: () => <Building size="16px" />,
      disabled: () => false,
      active: () => false,
      items: [
        {
          icon: () => <Building size="16px" />,
          value: 'office_network',
          content: t('components.networkType.office')
        },
        {
          icon: () => <Warehouse size="16px" />,
          value: 'remote_office_network',
          content: t('components.networkType.remoteOffice')
        },
        {
          icon: () => <Home size="16px" />,
          value: 'home_office_network',
          content: t('components.networkType.homeOffice')
        },
        {
          icon: () => <HelpCircle size="16px" />,
          value: 'third_party_network',
          content: t('components.networkType.thirdParty')
        }
      ],
      onFilter: (data: NetworkScan[], value) => data.filter(n => n.client_type === value),
      render: (filter, _, onValueChange) => (
        <RSelect
          size="sm"
          filterId={filter.id}
          label={filter.label}
          value={filter.selected ? filter.selected.value : undefined}
          onChange={newValue => onValueChange(filter.id, newValue)}
          placeholder={t('components.networksFilter.clientNetworkType.placeholder')}
          leftIcon={filter.icon && <filter.icon />}
        >
          {filter.items &&
            filter.items.map(item => (
              <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                {item.content}
              </RSelectOption>
            ))}
        </RSelect>
      )
    }
  ];

  return (
    <Box w="full">
      <FilterBar
        tableId="networks"
        filters={filters}
        data={networks as NetworkScan[]}
        setData={filteredNetworks => setNetworks(filteredNetworks)}
        searchFilter={{
          placeholder: t('components.networksFilter.searchBy'),
          onFilter: (data: NetworkScan[], value) =>
            data.filter(n => n.name?.toLowerCase().includes(value.toLowerCase()))
        }}
      />
    </Box>
  );
};

export default NetworksFilter;
