import { AxiosError } from 'axios';
import { ErrorFields, Nullable, SimpleAppScan } from 'types';

import axios, { handleError } from './_defaults';

const getAppScan = (platform: string, package_name: string, version?: Nullable<string>): Promise<SimpleAppScan> =>
  new Promise<SimpleAppScan>((resolve, reject) => {
    axios
      .post<SimpleAppScan>('mars/scans/static', { platform, package_name, version })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

export default {
  getAppScan
};
