import { useQuery } from '@tanstack/react-query';
import React, { lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import api from 'api';
import { renderFirmAdminRoutes } from 'router/firmadmin/FirmAdminRoutes';
import ROUTES from 'router/routes';
import { useAuthStore, useLayoutStore } from 'store';
import { getEntityIdFromUrl, parseRoute } from 'utils/utils';

import { NavigateWrapper } from 'router/NavigateWrapper';
import withSuperAdminLayout from './withSuperAdminLayout';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SuperAdminRoutesProps {}

const AccountSettings = lazy(() => import('pages/admin/account_settings'));
const PartnersAdd = lazy(() => import('pages/superadmin/partners/add'));
const PartnersList = lazy(() => import('pages/superadmin/partners/list'));
const SuperAdminDashboard = lazy(() => import('pages/superadmin/dashboard'));
const PartnerDetail = lazy(() => import('pages/superadmin/partners/detail'));

const SuperAdminRoutes: React.FC<SuperAdminRoutesProps> = () => {
  const setTheme = useLayoutStore(store => store.setTheme);
  const { user, loginUserAsPartnerAdmin, loginUserAsAdmin } = useAuthStore(store => store);
  const inClientNamespace = window.location.pathname.split('/')[2] === 'client';
  const inPartnerNamespace = window.location.pathname.split('/')[1] === 'partneradmin';
  const firmAdminEntityId = inPartnerNamespace ? window.location.pathname.split('/')[2] : null;
  const clientAdminEntityId = inClientNamespace ? getEntityIdFromUrl()! : null;

  const partner = firmAdminEntityId
    ? useQuery(['partner', firmAdminEntityId], () => api.firms.getPartner(firmAdminEntityId))
    : useQuery([], () => undefined, {
        onError: () => {}
      });

  const client = clientAdminEntityId
    ? useQuery(['client', clientAdminEntityId], () => api.clients.getClient(clientAdminEntityId))
    : useQuery([], () => undefined, {
        onError: () => {}
      });

  useEffect(() => {
    if (!clientAdminEntityId || !client?.data) return;

    (async () => {
      const clientFirm = await api.firms.getPartner(client.data.firm_id);
      const firmTheme = await api.firms.getFirmsTheme(clientFirm.firm_alias);

      setTheme(firmTheme);
      loginUserAsPartnerAdmin({ entityId: clientFirm.id, partnerName: clientFirm.name, previousUser: user });

      const firmCustomers = await api.firms.getClients(clientFirm.id);
      loginUserAsAdmin({
        entityId: clientAdminEntityId,
        previousUser: {
          ...user!,
          entity_id: clientFirm.id
        },
        customers: firmCustomers,
        clientName: client.data.name
      });
    })();
  }, [client]);

  useEffect(() => {
    if (!firmAdminEntityId || !partner?.data) return;

    api.firms.getFirmsTheme(partner.data.firm_alias).then(firmTheme => {
      setTheme(firmTheme);
      loginUserAsPartnerAdmin({ entityId: firmAdminEntityId, partnerName: partner.data.name, previousUser: user });
    });
  }, [partner]);

  return (
    <React.Suspense>
      <Routes>
        <Route path={ROUTES.SUPERADMIN.DASHBOARD} element={withSuperAdminLayout(SuperAdminDashboard)} />
        <Route path={ROUTES.SUPERADMIN.PARTNERS.ADD} element={withSuperAdminLayout(PartnersAdd)} />
        <Route path={ROUTES.SUPERADMIN.PARTNERS.LIST} element={withSuperAdminLayout(PartnersList)} />
        <Route path={ROUTES.SUPERADMIN.PARTNERS.DETAIL} element={withSuperAdminLayout(PartnerDetail)} />
        <Route path={ROUTES.SUPERADMIN.SETTINGS} element={withSuperAdminLayout(AccountSettings)} />

        {renderFirmAdminRoutes(user!, true)}

        <Route path="*" element={<NavigateWrapper to={parseRoute(user!, ROUTES.SUPERADMIN.DASHBOARD)} />} />
      </Routes>
    </React.Suspense>
  );
};

export default SuperAdminRoutes;
