import { Box, Button, Heading, HStack, Spinner, Text, VStack } from '@ramp/components';
import { CheckCircleSuccess, ErrorCircle } from '@ramp/icons';
import { clearAuthTokens } from 'axios-jwt';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import api from 'api';
import Logo from 'components/Logo';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { APIError } from 'types';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FirmVerificationProps {}

const FirmVerification: React.FC<FirmVerificationProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const logoutUser = useAuthStore(store => store.logoutUser);
  const user = useAuthStore(store => store.user);
  const { invitationId } = useParams<{ invitationId: string }>();

  const [state, setState] = useState<null | 'activated' | 'already_activated' | 'error'>(null);

  useEffect(() => {
    logoutUser();
    clearAuthTokens();

    if (invitationId) {
      window.document.title = t('base.firmVerification.title');
    }
  }, []);

  useEffect(() => {
    // If we have an invitationId from the URL and the user is not logged in
    if (invitationId && !user) {
      api.auth
        .verifyInvitation(invitationId)
        .then(() => {
          setState('activated');
        })
        .catch((err: APIError) => {
          if (err.code === 400) {
            return setState('already_activated');
          }

          return setState('error');
        });
    }
  }, [invitationId, user]);

  return (
    <Box display="flex" h="100vh" alignItems="center" justifyContent="center">
      <motion.div initial={{ opacity: 0, y: -100 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: 100 }}>
        <VStack w="full" spacing="3rem">
          <VStack align="center" justify="center" spacing={0}>
            <Logo />
            <VStack
              p={['16px', '20px', '24px', '28px', '32px']}
              spacing={['2rem', '2.25rem', '2.5rem', '2.75rem', '3rem']}
              alignItems="center"
              justifyContent="center"
            >
              {/* Loading */}
              {!state && <Spinner size="md" color="brand.500" />}

              {/* Received response & account was activated */}
              {state && state === 'activated' && (
                <>
                  <VStack spacing="8px" alignItems="center">
                    <Heading
                      as="h2"
                      textAlign="center"
                      fontSize={['24px', '32px', '38px', '42px', '48px']}
                      fontWeight={800}
                    >
                      {t('base.firmVerification.heading')}
                    </Heading>
                    <Text
                      w={['320px', '460px', '540px', '600px', '680px']}
                      textAlign="center"
                      fontSize={['14px', '14px', '16px', '16px', '16px']}
                      fontWeight={400}
                      color="gray.50"
                    >
                      {t('base.firmVerification.description')}
                    </Text>
                  </VStack>
                  <CheckCircleSuccess size={180} />
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.25 }}
                    style={{ width: '100%', marginTop: '3rem' }}
                  >
                    <HStack w="full" alignItems="center" justifyContent="center">
                      <Button
                        h="48px"
                        w={['full', '75%', '50%']}
                        variant="solid"
                        fontSize="18px"
                        rightIcon={<ArrowRight width="18px" height="18px" />}
                        onClick={() => navigate(ROUTES.BASE.SIGN_IN)}
                      >
                        {t('base.firmVerification.nextButton')}
                      </Button>
                    </HStack>
                  </motion.div>
                </>
              )}

              {/* Received response & account was already activated */}
              {state && state === 'already_activated' && (
                <>
                  <VStack spacing="8px" alignItems="center">
                    <Heading
                      as="h2"
                      textAlign="center"
                      fontSize={['24px', '32px', '38px', '42px', '48px']}
                      fontWeight={800}
                    >
                      {t('base.firmVerification.alreadyVerified.heading')}
                    </Heading>
                    <Text
                      w={['320px', '460px', '540px', '600px', '680px']}
                      textAlign="center"
                      fontSize={['14px', '14px', '16px', '16px', '16px']}
                      fontWeight={400}
                      color="gray.50"
                    >
                      {t('base.firmVerification.alreadyVerified.description')}
                    </Text>
                  </VStack>
                  <ErrorCircle size={180} />
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.25 }}
                    style={{ width: '100%', marginTop: '3rem' }}
                  >
                    <HStack w="full" alignItems="center" justifyContent="center">
                      <Button
                        h="48px"
                        w={['full', '75%', '50%']}
                        variant="solid"
                        fontSize="18px"
                        rightIcon={<ArrowRight width="18px" height="18px" />}
                        onClick={() => navigate(ROUTES.BASE.SIGN_IN)}
                      >
                        {t('base.firmVerification.nextButton')}
                      </Button>
                    </HStack>
                  </motion.div>
                </>
              )}

              {/* Received response with error */}
              {state && state === 'error' && (
                <>
                  <VStack spacing="8px" alignItems="center">
                    <Heading
                      as="h2"
                      textAlign="center"
                      fontSize={['24px', '32px', '38px', '42px', '48px']}
                      fontWeight={800}
                    >
                      {t('base.firmVerification.headingError')}
                    </Heading>
                    <Text
                      w={['320px', '460px', '540px', '600px', '680px']}
                      textAlign="center"
                      fontSize={['14px', '14px', '16px', '16px', '16px']}
                      fontWeight={400}
                      color="gray.50"
                    >
                      {t('base.firmVerification.descriptionError')}
                    </Text>
                  </VStack>
                  <ErrorCircle size={180} />
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.25 }}
                    style={{ width: '100%', marginTop: '3rem' }}
                  >
                    <HStack w="full" alignItems="center" justifyContent="center">
                      <Button
                        as="a"
                        h="48px"
                        // eslint-disable-next-line max-len
                        href={t('base.firmVerification.errorEmail')}
                        w={['full', '75%', '50%']}
                        variant="solid"
                        fontSize="18px"
                        rightIcon={<ArrowRight width="18px" height="18px" />}
                      >
                        {t('base.firmVerification.nextButtonError')}
                      </Button>
                    </HStack>
                  </motion.div>
                </>
              )}
            </VStack>
          </VStack>
        </VStack>
      </motion.div>
    </Box>
  );
};

export default FirmVerification;
