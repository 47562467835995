import { Button, HStack, RPage, RStep, RSteps, Text, useRSteps, VStack } from '@ramp/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import AppDownloadBadge from 'components/AppDownloadBadge';
import NotApprovedClientInfoBox from 'components/NotApprovedClientInfoBox';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { PRODUCT_NAME } from 'utils/constants';
import { parseRoute } from 'utils/utils';

interface NoDataPageProps {
  heading: string;
  pageLoading: boolean;
  noUsersInCompany?: boolean;
  noScansInCompany?: boolean;
}

const NoDataPage: React.FC<NoDataPageProps> = ({ pageLoading, noUsersInCompany, noScansInCompany }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAuthStore(store => store.user!);

  const { activeStep, setActiveStep } = useRSteps(
    noUsersInCompany === undefined || noUsersInCompany ? (user.client_approved ? 0 : 1) : 1
  );

  useEffect(() => {
    if (noUsersInCompany === undefined || noUsersInCompany) {
      setActiveStep(0);
    } else {
      setActiveStep(1);
    }
  }, [noUsersInCompany, noScansInCompany]);

  const renderCorrectNumberOfSteps = () => {
    if (user.client_approved) {
      return (
        <RSteps activeStep={activeStep} orientation="vertical">
          <RStep
            label={t('admin.dashboard.noData.firstStep.title')}
            description={t('admin.dashboard.noData.firstStep.description')}
          >
            <VStack w="full" align="start" py={4}>
              <Button variant="brand" size="md" onClick={() => navigate(parseRoute(user, ROUTES.ADMIN.USERS.ADD))}>
                {t('admin.dashboard.noData.firstStep.button')}
              </Button>
            </VStack>
          </RStep>
          <RStep
            label={t('admin.dashboard.noData.setupApp.title')}
            description={t('admin.dashboard.noData.setupApp.description')}
          />
          <RStep
            label={t('admin.dashboard.noData.secondStep.title')}
            description={t('admin.dashboard.noData.secondStep.description')}
          >
            <HStack spacing={4} py={4} alignItems="start">
              <AppDownloadBadge store="app-store" />
              <AppDownloadBadge store="google-play" />
              <AppDownloadBadge store="microsoft-store" architecture="64bit" />
              <AppDownloadBadge store="microsoft-store" architecture="32bit" />
            </HStack>
          </RStep>
          <RStep
            label={t('admin.dashboard.noData.thirdStep.title')}
            description={t('admin.dashboard.noData.thirdStep.description')}
          />
        </RSteps>
      );
    }

    return (
      <RSteps activeStep={activeStep} orientation="vertical">
        <RStep
          label={t('admin.dashboard.noData.secondStep.title')}
          description={t('admin.dashboard.noData.secondStep.description')}
        >
          <HStack spacing={4} py={4} alignItems="start">
            <AppDownloadBadge store="app-store" />
            <AppDownloadBadge store="google-play" />
            <AppDownloadBadge store="microsoft-store" architecture="64bit" />
            <AppDownloadBadge store="microsoft-store" architecture="32bit" />
          </HStack>
        </RStep>
        <RStep
          label={t('admin.dashboard.noData.setupApp.title')}
          description={t('admin.dashboard.noData.setupApp.description')}
        />
        <RStep
          label={t('admin.dashboard.noData.thirdStep.title')}
          description={t('admin.dashboard.noData.thirdStep.description')}
        />
      </RSteps>
    );
  };

  return (
    <RPage
      px={6}
      pt={8}
      heading={t('admin.dashboard.noData.title')}
      infoBox={!user.client_approved && user.client_approved !== null && <NotApprovedClientInfoBox />}
      title={`${PRODUCT_NAME} | ${t('admin.dashboard.noData.title')}`}
      loading={pageLoading}
    >
      <Text fontSize="xl" fontWeight="500" textAlign="left" mb={16}>
        {t('admin.dashboard.noData.description')}
      </Text>
      <VStack w="full" h="calc(100vh - 24rem)" align="center" justify="center">
        {renderCorrectNumberOfSteps()}
      </VStack>
    </RPage>
  );
};

export default NoDataPage;
