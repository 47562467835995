import { Box, FilterBar, IFilter, RSelect, RSelectOption, StyleProps } from '@ramp/components';
import { AtSign, Building2, Globe, Monitor, Network, SquareCheckBig, User, X } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FiAlertTriangle, FiGlobe } from 'react-icons/fi';

import { Issue } from 'types';
import { getSeverityFromScore } from 'utils/utils';

interface BasicIssuesFilterProps extends StyleProps {
  issues?: Issue[];
  setIssues: React.Dispatch<React.SetStateAction<Issue[]>>;
}

const BasicIssuesFilter: React.FC<BasicIssuesFilterProps> = ({ issues = [], setIssues, ...props }) => {
  const { t } = useTranslation();

  const filters: IFilter[] = [
    {
      id: 'status',
      type: 'select',
      label: 'Status',
      icon: () => <SquareCheckBig size={16} />,
      disabled: () => false,
      active: () => true,
      items: [
        {
          icon: () => <SquareCheckBig size={16} />,
          value: 'active',
          content: t('admin.dashboard.issues.filter.status.active')
        },
        { icon: () => <X size={16} />, value: 'ignored', content: t('admin.dashboard.issues.filter.status.ignored') }
      ],
      onFilter: (data: Issue[], value) =>
        data.filter(issue => {
          if (value === 'active') {
            return issue.n_affected_objects > 0;
          }

          return issue.n_ignored_objects > 0;
        }),
      defaultValue: 'active',
      render: (filter, _, onValueChange) => (
        <RSelect
          size="sm"
          filterId={filter.id}
          label={filter.label}
          value={filter.selected ? filter.selected.value : undefined}
          defaultValue={filter.selected ? filter.selected.value || filter.defaultValue : filter.defaultValue}
          onChange={newValue => onValueChange(filter.id, newValue)}
          placeholder={t('admin.dashboard.issues.filter.severitySelect')}
          leftIcon={filter.icon && <filter.icon />}
        >
          {filter.items &&
            filter.items.map(item => (
              <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                {item.content}
              </RSelectOption>
            ))}
        </RSelect>
      )
    },
    {
      id: 'severity',
      type: 'select',
      label: t('admin.dashboard.issues.filter.severity.title'),
      icon: FiAlertTriangle,
      disabled: () => false,
      active: () => true,
      items: [
        { icon: FiAlertTriangle, value: 'high', content: t('admin.dashboard.issues.filter.severity.high') },
        { icon: FiAlertTriangle, value: 'medium', content: t('admin.dashboard.issues.filter.severity.medium') },
        { icon: FiAlertTriangle, value: 'low', content: t('admin.dashboard.issues.filter.severity.low') }
      ],
      onFilter: (data: Issue[], value) => data.filter(device => getSeverityFromScore(device.score) === value),
      render: (filter, _, onValueChange) => (
        <RSelect
          size="sm"
          filterId={filter.id}
          label={filter.label}
          value={filter.selected ? filter.selected.value : undefined}
          onChange={newValue => onValueChange(filter.id, newValue)}
          placeholder={t('admin.dashboard.issues.filter.severitySelect')}
          leftIcon={filter.icon && <filter.icon />}
        >
          {filter.items &&
            filter.items.map(item => (
              <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                {item.content}
              </RSelectOption>
            ))}
        </RSelect>
      )
    },
    {
      id: 'object_type',
      type: 'select',
      label: t('admin.dashboard.issues.filter.objectType'),
      icon: FiGlobe,
      disabled: () => false,
      active: () => true,
      items: [
        { icon: () => <Globe size={16} />, value: 'network', content: t('components.issueObjectType.network') },
        { icon: () => <Monitor size={16} />, value: 'device', content: t('components.issueObjectType.device') },
        { icon: () => <Building2 size={16} />, value: 'client', content: t('components.issueObjectType.client') },
        { icon: () => <AtSign size={16} />, value: 'email', content: t('components.issueObjectType.email') },
        { icon: () => <User size={16} />, value: 'user', content: t('components.issueObjectType.user') },
        {
          icon: () => <Network size={16} />,
          value: 'network_device',
          content: t('components.issueObjectType.networkDevice')
        }
      ],
      onFilter: (data: Issue[], value) => {
        return data.filter(issue => issue.object_type === value);
      },
      render: (filter, _, onValueChange) => (
        <RSelect
          size="sm"
          filterId={filter.id}
          label={filter.label}
          value={filter.selected ? filter.selected.value : undefined}
          onChange={newValue => onValueChange(filter.id, newValue)}
          placeholder={t('admin.dashboard.issues.filter.objectTypeSelect')}
          leftIcon={filter.icon && <filter.icon />}
        >
          {filter.items &&
            filter.items.map(item => (
              <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                {item.content}
              </RSelectOption>
            ))}
        </RSelect>
      )
    }
  ];

  return (
    <Box w="full" {...props}>
      <FilterBar
        tableId="issues"
        filters={filters}
        data={issues}
        setData={filteredIssues => setIssues(filteredIssues)}
      />
    </Box>
  );
};

export default BasicIssuesFilter;
