import { Tag, useColorMode } from '@ramp/components';
import { colorHex } from '@ramp/theme';
import { getAccessToken } from 'axios-jwt';
import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import isEqual from 'lodash/isEqual';
import {
  AtSign,
  Bluetooth,
  Bug as LuBug,
  Cross as LuCross,
  Factory,
  Glasses as LuGlasses,
  GraduationCap,
  Lightbulb,
  LucideProps,
  MonitorX,
  Network,
  Paintbrush,
  SearchX,
  ShieldCheck,
  SmartphoneCharging,
  TimerReset,
  Unlock,
  Usb
} from 'lucide-react';
import { IconBaseProps } from 'react-icons';
import { DiAndroid, DiApple, DiClojureAlt, DiWindows } from 'react-icons/di';
import {
  FiCheckSquare,
  FiDownloadCloud,
  FiEdit,
  FiMail,
  FiMessageSquare,
  FiSend,
  FiSmartphone,
  FiXOctagon
} from 'react-icons/fi';
import { VscTerminalLinux } from 'react-icons/vsc';
import { decodeToken } from 'react-jwt';

import {
  AppCategory,
  AppState,
  DecodedToken,
  DeviceApps,
  NotificationState,
  NotificationType,
  Nullable,
  ProblematicApplication,
  ProcessedAppInfo,
  RiskScore,
  Role,
  Severity,
  User
} from 'types';

type CopyFuncType = (text: string) => Promise<boolean>; // Return success
export const copyToClipboard: CopyFuncType = async text => {
  if (!navigator?.clipboard) {
    return false;
  }

  // Try to save to clipboard then save it in the state if worked
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    return false;
  }
};

type EllipsisFuncType = (text: string, size: number) => string;
export const ellipsis: EllipsisFuncType = (text, size) => `${text.split(' ').slice(0, size).join(' ')}...`;

type ToCapitalizeFuncType = (value: string) => string;
export const toCapitalize: ToCapitalizeFuncType = value => value[0].toUpperCase() + value.slice(1);

type SeverityLevelFuncType = (severity: Severity) => number;
export const getSeverityLevel: SeverityLevelFuncType = severity => {
  switch (severity) {
    case 'critical':
      return 4;
    case 'high':
      return 3;
    case 'medium':
      return 2;
    case 'low':
      return 1;
    case 'none':
      return 0;
    default:
      return -1;
  }
};

type SeverityFromScore = (score?: number | null) => 'No Data' | 'none' | 'low' | 'medium' | 'high';
export const getSeverityFromScore: SeverityFromScore = score => {
  if (score === undefined || score === null) return 'No Data';

  if (score < 4) {
    return 'low';
  }
  if (score < 7) {
    return 'medium';
  }
  if (score <= 10) {
    return 'high';
  }

  return 'none';
};

type SeveritySorterFuncType = (aSeverity: Severity, bSeverity: Severity) => number;
export const severitySorter: SeveritySorterFuncType = (aSeverity, bSeverity) => {
  const aLevel = getSeverityLevel(aSeverity);
  const bLevel = getSeverityLevel(bSeverity);

  return aLevel > bLevel ? 0 : -1;
};

type ExtractUserFromTokenFuncType = (accessToken?: string) => User;
export const extractUserFromToken: ExtractUserFromTokenFuncType = accessToken => {
  const decodedToken: DecodedToken = decodeToken(accessToken || getAccessToken() || '') as DecodedToken;

  return {
    user_id: decodedToken.user_id,
    employer_name: decodedToken.employer_name,
    entity_id: decodedToken.entity_id,
    name: decodedToken.name,
    email: decodedToken.email,
    position: decodedToken.position,
    role: decodedToken.role,
    otp_enabled: decodedToken.otp_enabled,
    scan_frequency_in_seconds: decodedToken.scan_frequency_in_seconds,
    theme: decodedToken.theme,
    logo_primary_dark: decodedToken.logo_primary_dark,
    logo_primary_light: decodedToken.logo_primary_light,
    logo_secondary_dark: decodedToken.logo_secondary_dark,
    logo_secondary_light: decodedToken.logo_secondary_light,
    color_scheme: decodedToken.color_scheme,
    client_approved: decodedToken.client_approved
  };
};

type AccessTokenExpire = () => boolean;
export const accessTokenExpire: AccessTokenExpire = () => {
  const { exp: tokenExpiration }: DecodedToken = decodeToken(getAccessToken() || '') as DecodedToken;

  const tokenExpirationDate = new Date(tokenExpiration * 1000);
  const currentDate = new Date();

  return dayjs(currentDate).isAfter(tokenExpirationDate);
};

export const usePlatformIcon = (platformName: string, props?: IconBaseProps) => {
  const { colorMode } = useColorMode();
  const platformNameFormatted = platformName.toLowerCase();

  switch (platformNameFormatted) {
    case 'ios':
      return colorMode === 'dark'
        ? DiApple({ color: colorHex('gray.50'), ...props })
        : DiApple({ color: 'rgb(85 85 85 / 50%)', ...props });
    case 'ipados':
      return colorMode === 'dark'
        ? DiApple({ color: colorHex('gray.50'), ...props })
        : DiApple({ color: 'rgb(85 85 85 / 50%)', ...props });
    case 'macos':
      return colorMode === 'dark'
        ? DiApple({ color: colorHex('gray.50'), ...props })
        : DiApple({ color: 'rgb(85 85 85 / 50%)', ...props });
    case 'android':
      return DiAndroid({ color: '#3DDC84', ...props });
    case 'windows':
      return DiWindows({ color: '#00A4EF', ...props });
    case 'linux':
      return VscTerminalLinux({ color: '#facc00', ...props });
    case 'email':
      return colorMode === 'dark' ? (
        <AtSign color={colorHex('gray.50')} size={16} {...props} />
      ) : (
        <AtSign color="rgb(85 85 85 / 50%)" size={16} {...props} />
      );
    case 'network':
      return colorMode === 'dark' ? (
        <Network color={colorHex('gray.50')} size={16} {...props} />
      ) : (
        <Network color="rgb(85 85 85 / 50%)" size={16} {...props} />
      );
    default:
      return DiClojureAlt({ color: '#000000', ...props });
  }
};

type HasRoleFuncType = (role: Role, roleNumber: number) => boolean;
export const hasRole: HasRoleFuncType = (role, roleNumber) => role === roleNumber;

type GetRoleFuncType = (role: Role) => 'user' | 'admin' | 'firmadmin' | 'superadmin' | 'none';
export const getRole: GetRoleFuncType = role => {
  switch (role) {
    case Role.user:
      return 'user';
    case Role.admin:
      return 'admin';
    case Role.firmadmin:
      return 'firmadmin';
    case Role.superadmin:
      return 'superadmin';
    default:
      return 'none';
  }
};

type GetRoleByNumberFuncType = (role: number, t?: TFunction) => 'user' | 'admin' | 'firmadmin' | 'superadmin';
export const getRoleByNumber: GetRoleByNumberFuncType = (role, t) => {
  switch (role) {
    case 1:
      return t ? t('admin.users.filters.user') : 'user';
    case 2:
      return 'admin';
    case 3:
      return 'firmadmin';
    case 4:
      return 'superadmin';
    default:
      return t ? t('admin.users.filters.user') : 'user';
  }
};

type CompareRiskScoresFuncType = (a: RiskScore, b: RiskScore) => number;
export const compareRiskScores: CompareRiskScoresFuncType = (a, b) => {
  if (!a.date || !b.date) return 0;
  if (dayjs(a.date).isAfter(b.date)) return 1;
  if (dayjs(a.date).isBefore(b.date)) return -1;
  return 0;
};

type GetProcessedAppInfo = (apps: DeviceApps | undefined) => ProcessedAppInfo | undefined;
export const getProcessedAppsObject: GetProcessedAppInfo = apps => {
  if (apps === undefined) return undefined;

  let updated = 0;
  let unupdated = 0;

  // eslint-disable-next-line no-plusplus
  apps.evaluated_applications.map(app => (app.updated ? updated++ : unupdated++));

  const filteredProblematicFromEvaluated = apps.evaluated_applications.filter(app => app.security_score > 0);
  const problematicFromEvaluated: ProblematicApplication[] = filteredProblematicFromEvaluated.map(app => ({
    package_name: app.package_name,
    security_score: app.security_score,
    version: app.latest_available_version,
    update_date: app.latest_available_update_timestamp,
    maintained: app.maintained,
    malicious: app.categories.includes(AppCategory.MALICIOUS),
    installed_from: app.installed_from,
    analysis_score: app.analysis_score,
    application_name: app.application_name
  }));

  const filteredProblematicFromNotEvaluated = apps.not_evaluated_applications.filter(app =>
    app.categories.includes(AppCategory.MALICIOUS)
  );
  const problematicFromNotEvaluated: ProblematicApplication[] = filteredProblematicFromNotEvaluated.map(app => ({
    package_name: app.package_name,
    security_score: app.security_score,
    version: app.version,
    update_date: app.update_timestamp,
    maintained: false,
    malicious: true,
    installed_from: app.installed_from,
    analysis_score: app.analysis_score,
    application_name: app.application_name
  }));

  const thirdParty = apps.not_evaluated_applications.filter(
    app =>
      !app.categories.includes(AppCategory.SYSTEM) &&
      (app.mars_state === AppState.CATEGORIZED || app.mars_state === AppState.REDAMP_TESTING)
  );

  const verified = apps.evaluated_applications.filter(app => app.security_score === 0);

  const system = apps.not_evaluated_applications.filter(
    app =>
      app.mars_state === AppState.CATEGORIZED &&
      (app.categories.includes(AppCategory.SYSTEM) || app.categories.includes(AppCategory.VENDOR_PREINSTALLED))
  );

  const inVerification = apps.not_evaluated_applications.filter(
    app => app.mars_state === AppState.NOT_FOUND || app.mars_state === AppState.IN_VERIFICATION
  );

  const unupdatedApps = apps.evaluated_applications.filter(app => !app.updated);

  return {
    problematic: [...problematicFromEvaluated, ...problematicFromNotEvaluated],
    third_party: thirdParty,
    verified,
    system,
    in_verification: inVerification,
    unupdated: unupdatedApps,
    updated_count: updated,
    unupdated_count: unupdated
  };
};

type HasUpperCaseFuncType = (input: string) => boolean;
export const hasUpperCase: HasUpperCaseFuncType = input => /[A-Z]/.test(input);

type HasLowerCaseFuncType = (input: string) => boolean;
export const hasLowerCase: HasLowerCaseFuncType = input => /[a-z]/.test(input);

type HasNumberFuncType = (input: string) => boolean;
export const hasNumber: HasNumberFuncType = input => /\d/.test(input);

export const REGEX = {
  UPPERCASE_LETTER: /[A-Z]/,
  LOWERCASE_LETTER: /[a-z]/,
  NUMBER: /\d/,
  SPECIAL_LETTER: /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
  // eslint-disable-next-line no-useless-escape,max-len
  EMAIL:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NAME_ALIAS: /^[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/,
  UUIDV4: /.{8}-.{4}-.{4}-.{4}-.{12}/,
  PARTNER_SUBDOMAIN: /^https?:\/\/partner\.[\w.-]+\.[\w.-]+(:\d+)?$/
};

type HasSpecialCharFuncType = (input: string) => boolean;
export const hasSpecialChar: HasSpecialCharFuncType = input => REGEX.SPECIAL_LETTER.test(input);

type GetEnvironmentFuncType = () => 'local' | 'dev' | 'rc' | 'prod';
export const getEnvironment: GetEnvironmentFuncType = () => {
  const { hostname } = window.location;

  switch (hostname) {
    case 'localhost':
      return 'local';
    case 'partner.redamp.localhost':
      return 'local';
    case 'securitycheck-dev.ictmedia.cz':
      return 'dev';
    case 'partner.ictmedia.cz':
      return 'dev';
    case 'securitycheck-rc.redamp.eu':
      return 'rc';
    case 'partner.redamp.eu':
      return 'rc';
    case 'app.redamp.io':
      return 'prod';
    case 'partner.redamp.io':
      return 'prod';
    default:
      // For partner domains e.g. https://partner.redamp.io
      if (hostname.includes('.redamp.io')) return 'prod';

      return 'local';
  }
};

type ParseRouteFuncType = (
  user: User,
  route: string,
  routeParams?: Record<string, string>,
  queryParams?: Record<string, string>
) => string;
export const parseRoute: ParseRouteFuncType = (user, route, routeParams, queryParams) => {
  let parsedRoute = route;

  if (user.entity_id) parsedRoute = parsedRoute.replace(':entityId', user.entity_id);

  if (routeParams) {
    Object.keys(routeParams).forEach(paramKey => {
      parsedRoute = parsedRoute.replace(`:${paramKey}`, routeParams[paramKey]);
    });
  }

  if (queryParams) {
    Object.keys(queryParams).forEach(paramKey => {
      parsedRoute += `?${paramKey}=${queryParams[paramKey]}`;
    });
  }

  return parsedRoute;
};

export const formatNotificationType = (type: NotificationType, t?: TFunction) => {
  switch (type) {
    case NotificationType.CUSTOM_NOTIFICATION:
      return t ? t('components.notificationType.customNotification') : 'Custom Notification';
    case NotificationType.BACKGROUND_SCAN:
      return t ? t('components.notificationType.backgroundScanRequest') : 'Background Scan Request';
    case NotificationType.BACKGROUND_SCAN_DONE:
      return t ? t('components.notificationType.threatsInfo') : 'Threats Info';
    case NotificationType.PATCH:
      return t ? t('components.notificationType.patch') : 'Patch';
    case NotificationType.VENDOR_UPDATES:
      return t ? t('components.notificationType.vendorUpdates') : 'Vendor Updates';
    case NotificationType.OS_END_OF_LIFE:
      return t ? t('components.notificationType.osEndOfLife') : 'OS End of Life';
    case NotificationType.DEVICE_END_OF_LIFE:
      return t ? t('components.notificationType.deviceEndOfLife') : 'Device End of Life';
    case NotificationType.ROOTED:
      return 'Rooted';
    case NotificationType.JAILBROKEN:
      return 'Jailbroken';
    case NotificationType.SCREEN_LOCK:
      return t ? t('components.notificationType.screenLock') : 'Screen Lock';
    case NotificationType.BLUETOOTH:
      return 'Bluetooth';
    case NotificationType.USB_DEBUG:
      return 'USB Debug';
    case NotificationType.EMAIL_SCAN_DONE:
      return t ? t('components.notificationType.emailScan') : 'Email Scan';
    case NotificationType.NEW_SECURITY_PATCH:
      return t ? t('components.notificationType.newSecurityPatch') : 'New Security Patch';
    case NotificationType.UNSCANNED_DEVICE:
      return t ? t('components.notificationType.unscannedDevice') : 'Unscanned Device';
    case NotificationType.GENERAL_RECOMMENDATION:
      return t ? t('components.notificationType.generalRecommendation') : 'General Recommendation';
    case NotificationType.SCHEDULED_RECOMMENDATION:
      return t ? t('components.notificationType.scheduledRecommendation') : 'Scheduled Recommendation';
    case NotificationType.SAFE_NETWORKS_CHANGED:
      return t ? t('components.notificationType.safeNetworksChanged') : 'Safe Networks Changed';
    case NotificationType.WIN_CMD_NETWORK_DEVICE_SCAN:
      return t ? t('components.notificationType.winCmdNetworkDeviceScan') : 'Remote Network Devices Scan';
    case NotificationType.NEW_QUIZ_ASSIGNMENT:
      return t ? t('components.notificationType.newQuizAssignment') : 'New Quiz Assignment';
    default:
      return t ? t('components.notificationType.unknownNotification') : 'Unknown Notification Type';
  }
};

export const formatNotificationState = (state: NotificationState, t?: TFunction) => {
  switch (state) {
    case NotificationState.SEND:
      return t ? t('components.notificationState.sent') : 'Sent';
    case NotificationState.RECEIVED:
      return t ? t('components.notificationState.received') : 'Received';
    case NotificationState.READ:
      return t ? t('components.notificationState.read') : 'Read';
    case NotificationState.FAILED:
      return t ? t('components.notificationState.failed') : 'Failed';
    default:
      return t ? t('components.notificationState.unknown') : 'Unknown';
  }
};

export const useNotificationTypeIcon = (type: NotificationType, luProps?: LucideProps) => {
  switch (type) {
    case NotificationType.CUSTOM_NOTIFICATION:
      return FiEdit({});
    case NotificationType.BACKGROUND_SCAN:
      return FiSmartphone({});
    case NotificationType.PATCH:
      return <Paintbrush {...luProps} />;
    case NotificationType.VENDOR_UPDATES:
      return <Factory {...luProps} />;
    case NotificationType.OS_END_OF_LIFE:
      return <MonitorX {...luProps} />;
    case NotificationType.ROOTED:
      return <SmartphoneCharging {...luProps} />;
    case NotificationType.JAILBROKEN:
      return <SmartphoneCharging {...luProps} />;
    case NotificationType.SCREEN_LOCK:
      return <Unlock {...luProps} />;
    case NotificationType.BLUETOOTH:
      return <Bluetooth {...luProps} />;
    case NotificationType.USB_DEBUG:
      return <Usb {...luProps} />;
    case NotificationType.BACKGROUND_SCAN_DONE:
      return <LuBug {...luProps} />;
    case NotificationType.EMAIL_SCAN_DONE:
      return FiMail({});
    case NotificationType.NEW_SECURITY_PATCH:
      return <LuCross {...luProps} />;
    case NotificationType.GENERAL_RECOMMENDATION:
      return <Lightbulb {...luProps} />;
    case NotificationType.UNSCANNED_DEVICE:
      return <SearchX {...luProps} />;
    case NotificationType.SCHEDULED_RECOMMENDATION:
      return <TimerReset {...luProps} />;
    case NotificationType.SAFE_NETWORKS_CHANGED:
      return <ShieldCheck {...luProps} />;
    case NotificationType.WIN_CMD_NETWORK_DEVICE_SCAN:
      return <Network {...luProps} />;
    case NotificationType.NEW_QUIZ_ASSIGNMENT:
      return <GraduationCap {...luProps} />;
    default:
      return FiMessageSquare({});
  }
};

export const useNotificationStateIcon = (type: NotificationType, state: NotificationState, luProps?: LucideProps) => {
  if (type === NotificationType.BACKGROUND_SCAN) {
    switch (state) {
      case NotificationState.READ:
        return <LuGlasses {...luProps} />;
      case NotificationState.RECEIVED:
        return FiDownloadCloud({});
      case NotificationState.SEND:
        return FiSend({});
      case NotificationState.FAILED:
        return FiXOctagon({});
      default:
        return FiSend({});
    }
  } else {
    switch (state) {
      case NotificationState.SEND:
        return FiCheckSquare({});
      case NotificationState.FAILED:
        return FiXOctagon({});
      default:
        return FiSend({});
    }
  }
};

// Returns 1 if the first version is greater, 0 if they are equal, -1 if the first version is lower
export const compareAppVersions: (v1: string, v2: string) => 1 | 0 | -1 = (v1, v2) => {
  const version1Numbers: string[] = v1.split('.');
  const version2Numbers: string[] = v2.split('.');

  let version1Sum = 0;
  version1Numbers.forEach((number, idx) => (version1Sum += idx < 2 ? parseInt(number) : 0));

  let version2Sum = 0;
  version2Numbers.forEach((number, idx) => (version2Sum += idx < 2 ? parseInt(number) : 0));

  if (version1Sum > version2Sum) return 1;
  if (version1Sum < version2Sum) return -1;

  return 0;
};

export const getSeverityTextColor = (
  severity?: null | string | 'unknown' | 'low' | 'medium' | 'high' | 'safe' | 'warning' | 'risk'
) => {
  switch (severity) {
    case 'high':
      return 'var(--chakra-colors-error-500)';
    case 'medium':
      return 'var(--chakra-colors-warning-500)';
    case 'low':
      return 'var(--chakra-colors-success-500)';
    case 'unknown':
      return 'var(--chakra-colors-unknown-500)';
    case 'safe':
      return 'var(--chakra-colors-success-500)';
    case 'warning':
      return 'var(--chakra-colors-warning-500)';
    case 'risk':
      return 'var(--chakra-colors-error-500)';
    default:
      return 'var(--chakra-colors-unknown-500)';
  }
};

export const generateRandomBetween = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const renderAppRecommendation = (recommendationId: string, recommendation: string) => {
  switch (recommendationId) {
    case 'app_malicious':
      return (
        <Tag bg="error.500" color="white" whiteSpace="nowrap">
          {recommendation}
        </Tag>
      );
    case 'app_not_maintained':
      return (
        <Tag bg="warning.500" color="white" whiteSpace="nowrap">
          {recommendation}
        </Tag>
      );
    case 'app_not_updated':
      return (
        <Tag bg="blue.500" color="white" whiteSpace="nowrap">
          {recommendation}
        </Tag>
      );
    case 'app_third_party':
      return (
        <Tag bg="warning.500" color="white" whiteSpace="nowrap">
          {recommendation}
        </Tag>
      );
    default:
      return <Tag whiteSpace="nowrap">{recommendation}</Tag>;
  }
};

export const getRecommendationRisk = (score?: Nullable<number>) => {
  if (score === undefined) return 'unknown';
  if (score === null) return 'unknown';
  if (score < 4) return 'safe';
  if (score < 7) return 'warning';
  if (score <= 10) return 'risk';
  return 'unknown';
};

export const fileToBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

export const setFavicon = (favicon: string) => {
  const links = document.querySelectorAll<HTMLLinkElement>("link[rel*='icon']");
  links.forEach(link => {
    link.href = favicon;
  });
};

export const getEntityIdFromUrl = () => {
  const entityId = window.location.href.match(REGEX.UUIDV4);

  return entityId ? entityId[0] : null;
};

export const onPartnerSubdomain = () => REGEX.PARTNER_SUBDOMAIN.test(window.location.origin);

export const translateFormError = (
  t: TFunction,
  type: 'min' | 'max' | 'email' | 'required' | 'lowercase' | 'uppercase' | 'number' | 'special',
  args?: { field: string; num?: number }
) => {
  switch (type) {
    case 'min':
      return t('components.form.min', { field: args!.field, num: args!.num });
    case 'max':
      return t('components.form.max', { field: args!.field, num: args!.num });
    case 'email':
      return t('components.form.email');
    case 'lowercase':
      return t('components.form.lowercase', { field: args!.field, num: args!.num });
    case 'uppercase':
      return t('components.form.uppercase', { field: args!.field, num: args!.num });
    case 'number':
      return t('components.form.number', { field: args!.field, num: args!.num });
    case 'special':
      return t('components.form.special', { field: args!.field, num: args!.num });
    case 'required':
      return t('components.form.isRequired', { field: args!.field });
    default:
      return 'Unknown error';
  }
};

export const convertMillisToTime: (millis: number) => string = millis => {
  const minutes = Math.floor((millis / (1000 * 60)) % 60);
  const hours = Math.floor((millis / (1000 * 60 * 60)) % 24);
  const days = Math.floor(millis / (1000 * 60 * 60 * 24));

  return `${days}d ${hours}h ${minutes}m`;
};

export const convertSecondsToTime: (seconds: number) => string = seconds => {
  const days = Math.floor(seconds / (3600 * 24));
  seconds %= 3600 * 24;
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);

  return `${days}d ${hours}h ${minutes}m`;
};

/**
 * Get supported language from i18n language
 * @param i18nLanguage
 */
export const getSupportedLanguage: (i18nLanguage: string) => string = i18nLanguage => {
  if (i18nLanguage.includes('en')) return 'en';
  if (i18nLanguage.includes('cs')) return 'cs';
  if (i18nLanguage.includes('de')) return 'de';

  return 'en';
};

export const getCountryCode = (language: string) => {
  const parsedLanguage = language.includes('-') ? language.split('-')[0].toLowerCase() : language.toLowerCase();

  if (parsedLanguage === 'en') return 'gb';
  if (parsedLanguage === 'cs') return 'cz';
  if (parsedLanguage === 'de') return 'de';
  return 'gb';
};

export const getCountryLanguage = (countryIso3: string) => {
  switch (countryIso3.toLowerCase()) {
    case 'cze':
      return 'cs';
    case 'deu':
      return 'de';
    case 'aut':
      return 'de';
    case 'svk':
      return 'en';
    default:
      return 'en';
  }
};

export const ipToNumber: (ip: string) => number = ip => {
  return ip.split('.').reduce((acc, octet) => (acc << 8) + parseInt(octet, 10), 0);
};

export const compareMacAddresses: (a: string, b: string) => number = (a, b) => {
  const aParts = a.split(':');
  const bParts = b.split(':');

  for (let i = 0; i < 6; i++) {
    const aHex = parseInt(aParts[i], 16);
    const bHex = parseInt(bParts[i], 16);
    if (aHex !== bHex) {
      return aHex - bHex;
    }
  }

  // If all parts are equal, return 0
  return 0;
};

export const errorWrapper = <T extends (...args: any[]) => any>(func: T): T => {
  return ((...args: Parameters<T>): ReturnType<T> | undefined => {
    try {
      return func(...args);
    } catch (error) {
      return undefined;
    }
  }) as T;
};

export const compareArrayOfObjects = <T,>(
  oldArray: T[],
  newArray: T[],
  key: keyof T | ((args: T) => string)
): { newItems: T[]; changedItems: T[]; removedItems: T[] } => {
  const newItems: T[] = [];
  const changedItems: T[] = [];
  const removedItems: T[] = [];

  // Create a Map for quick lookup
  const map1 = new Map<string, T>();
  const map2 = new Map<string, T>();

  // Populate Map for oldArray
  for (const item of oldArray) {
    const keyValue = typeof key === 'function' ? key(item) : (item[key] as unknown as string);
    map1.set(keyValue, item);
  }

  // Populate Map for newArray
  for (const item of newArray) {
    const keyValue = typeof key === 'function' ? key(item) : (item[key] as unknown as string);
    map2.set(keyValue, item);
  }
  // Check for new items and changed items
  for (const [keyValue, item2] of map2) {
    const item1 = map1.get(keyValue);

    if (!item1) {
      // Item not found in oldArray => it's a new item
      newItems.push(item2);
    } else if (!isEqual(item1, item2)) {
      // Item found in both but data attribute is different
      changedItems.push(item2);
    }
  }

  // Check for removed items
  for (const [keyValue, item1] of map1) {
    if (!map2.has(keyValue)) {
      // Item not found in array2 => it's a removed item
      removedItems.push(item1);
    }
  }

  return {
    newItems,
    changedItems,
    removedItems
  };
};
