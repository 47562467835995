import { RCard, RPage, VStack } from '@ramp/components';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import NoDataPage from 'components/NoDataPage';
import NetworksTable from 'components/tables/NetworksTable';
import NetworksFilter from 'pages/admin/networks/list/NetworksFilter';
import { useAuthStore, useLayoutStore } from 'store';
import { NetworkScanWithoutUsers } from 'types';
import { PRODUCT_NAME } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NetworksListProps {}

const NetworksList: React.FC<NetworksListProps> = () => {
  const { t } = useTranslation();
  const user = useAuthStore(store => store.user!);
  const { noUsersInCompany, noScansInCompany } = useLayoutStore(store => store);

  const startDate = dayjs().add(-1, 'year').format('YYYY-MM-DD');
  const endDate = dayjs().format('YYYY-MM-DD');

  const [defaultNetworks, setDefaultNetworks] = React.useState<NetworkScanWithoutUsers[]>([]);
  const [filteredNetworks, setFilteredNetworks] = React.useState<NetworkScanWithoutUsers[]>([]);

  const networks = useQuery(['networks', user.user_id], () => api.users.getNetworks(user.user_id, startDate, endDate), {
    onSuccess: data => {
      setFilteredNetworks(data);
      setDefaultNetworks(data);
    }
  });

  if (noScansInCompany) {
    return (
      <NoDataPage
        heading={t('admin.networks.heading')}
        pageLoading={networks.isLoading}
        noUsersInCompany={noUsersInCompany}
        noScansInCompany={noScansInCompany}
      />
    );
  }

  return (
    <RPage
      px={6}
      pt={8}
      heading={t('admin.networks.heading')}
      title={t('admin.networks.title', { productName: PRODUCT_NAME })}
    >
      <VStack w="full" align="start" spacing={4}>
        <NetworksFilter
          networks={defaultNetworks}
          setNetworks={fNetworks => setFilteredNetworks(fNetworks as NetworkScanWithoutUsers[])}
        />
        <RCard w="full" titleOutside={false}>
          <NetworksTable
            id="user_networks_table"
            networks={filteredNetworks}
            loading={networks.isLoading}
            withoutUsers
          />
        </RCard>
      </VStack>
    </RPage>
  );
};

export default NetworksList;
