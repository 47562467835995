import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@ramp/components';
import React, { useState } from 'react';

import Enter2FACode from './Enter2FACode';
import QRCodeScan from './QRCodeScan';

interface Enable2FAModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Enable2FAModal: React.FC<Enable2FAModalProps> = ({ isOpen, onClose }) => {
  const [qrCodeScanned, setQrCodeScanned] = useState<boolean>(false);

  const onModalClose = () => {
    setQrCodeScanned(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {!qrCodeScanned ? <QRCodeScan setQrCodeScanned={setQrCodeScanned} /> : <Enter2FACode onClose={onModalClose} />}
      </ModalContent>
    </Modal>
  );
};

export default Enable2FAModal;
