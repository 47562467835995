import { HStack, Link, PropertyCard, Text, VStack } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getSeverityFromScore, getSeverityTextColor } from 'utils/utils';

import { AndroidScan } from '../../../../../../types/api/devices';
import { useDeviceDetail } from '../../DeviceDetailProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OsSettingsProps {}

const OsSettings: React.FC<OsSettingsProps> = () => {
  const { t } = useTranslation();
  const { platform, scan, recommendations } = useDeviceDetail();

  if (!platform || !scan || !recommendations) return null;

  const { device, device_settings: deviceSettings, global_score: globalScore } = scan;

  if (!device || !deviceSettings || !globalScore) return null;

  const osSettingsScore = globalScore.os_settings_score || 0;
  const osSettingsSeverity = getSeverityFromScore(osSettingsScore);

  const settingsRecommendations = {
    rooted:
      platform === 'ios' || platform === 'ipados'
        ? recommendations.find(rec => rec.group_codename === 'jailbroken') || null
        : recommendations.find(rec => rec.group_codename === 'rooted') || null,
    screen_lock: recommendations.find(rec => rec.group_codename === 'screen_lock') || null,
    bluetooth: recommendations.find(rec => rec.group_codename === 'bluetooth') || null,
    usb_debug: recommendations.find(rec => rec.group_codename === 'usb_debug') || null,
    nfc: recommendations.find(rec => rec.group_codename === 'nfc') || null
  };

  const renderDescription = (description: string, link: string) => {
    return (
      <>
        {description}&nbsp;
        <Link color="brand.500" textDecoration="underline" href={link} target="_blank" rel="noreferrer">
          {t('admin.dashboard.issues.table.moreInfo')}
        </Link>
      </>
    );
  };

  return (
    <VStack w={{ sm: 'full', lg: '50%' }} mt={{ sm: '2rem', lg: 0 }} ml={{ lg: '1rem', xl: '2rem' }}>
      <HStack w="full" alignItems="center" justifyContent="space-between">
        <Text fontSize={{ sm: '24px', md: '28px', lg: '24px', xl: '28px' }} fontWeight={700}>
          {t('admin.devices.detail.device.osSettings').toUpperCase()}
        </Text>
        <HStack spacing={0} alignItems="end">
          <Text
            fontWeight={700}
            color={getSeverityTextColor(osSettingsSeverity)}
            fontSize={{ sm: '24px', md: '28px', lg: '24px', xl: '28px' }}
          >
            {osSettingsScore.toFixed(1)}
          </Text>
          <Text fontSize={{ sm: '18px', md: '20px', lg: '18px', xl: '20px' }} fontWeight={700}>
            /10
          </Text>
        </HStack>
      </HStack>
      <VStack w="full" spacing={4}>
        {(platform === 'ios' || platform === 'ipados') && (
          <PropertyCard
            w="full"
            status={
              settingsRecommendations.rooted
                ? settingsRecommendations.rooted.severity
                : t('admin.devices.detail.device.unknown')
            }
            title={
              settingsRecommendations.rooted
                ? settingsRecommendations.rooted.group
                : t('admin.devices.detail.device.jailbreak')
            }
            description={
              settingsRecommendations.rooted ? (
                renderDescription(
                  settingsRecommendations.rooted.recommendation_description,
                  settingsRecommendations.rooted.knowledge_base_url
                )
              ) : (
                <i>{t('admin.devices.detail.device.noRecommendation')}</i>
              )
            }
          />
        )}
        {platform === 'android' && (
          <PropertyCard
            w="full"
            status={
              settingsRecommendations.rooted
                ? settingsRecommendations.rooted.severity
                : t('admin.devices.detail.device.unknown')
            }
            title={
              settingsRecommendations.rooted
                ? settingsRecommendations.rooted.group
                : t('admin.devices.detail.device.rooted')
            }
            description={
              settingsRecommendations.rooted ? (
                renderDescription(
                  settingsRecommendations.rooted.recommendation_description,
                  settingsRecommendations.rooted.knowledge_base_url
                )
              ) : (
                <i>{t('admin.devices.detail.device.noRecommendation')}</i>
              )
            }
          />
        )}
        {platform !== 'windows' && (
          <PropertyCard
            w="full"
            status={
              settingsRecommendations.screen_lock
                ? settingsRecommendations.screen_lock.severity
                : t('admin.devices.detail.device.unknown')
            }
            title={
              settingsRecommendations.screen_lock
                ? settingsRecommendations.screen_lock.group
                : t('admin.devices.detail.device.screenLock')
            }
            description={
              settingsRecommendations.screen_lock ? (
                renderDescription(
                  settingsRecommendations.screen_lock.recommendation_description,
                  settingsRecommendations.screen_lock.knowledge_base_url
                )
              ) : (
                <i>{t('admin.devices.detail.device.noRecommendation')}</i>
              )
            }
          />
        )}
        {platform === 'android' && (
          <PropertyCard
            w="full"
            status={
              settingsRecommendations.usb_debug
                ? settingsRecommendations.usb_debug.severity
                : t('admin.devices.detail.device.unknown')
            }
            title={
              settingsRecommendations.usb_debug
                ? settingsRecommendations.usb_debug.group
                : t('admin.devices.detail.device.usbDebug')
            }
            description={
              settingsRecommendations.usb_debug ? (
                renderDescription(
                  settingsRecommendations.usb_debug.recommendation_description,
                  settingsRecommendations.usb_debug.knowledge_base_url
                )
              ) : (
                <i>{t('admin.devices.detail.device.noRecommendation')}</i>
              )
            }
          />
        )}
        <PropertyCard
          w="full"
          // @ts-ignore
          status={
            settingsRecommendations.nfc
              ? settingsRecommendations.nfc.severity
              : t('admin.devices.detail.device.unknown')
          }
          title={settingsRecommendations.nfc ? settingsRecommendations.nfc.group : 'NFC'}
          description={
            settingsRecommendations.nfc ? (
              renderDescription(
                settingsRecommendations.nfc.recommendation_description,
                settingsRecommendations.nfc.knowledge_base_url
              )
            ) : (
              <i>{t('admin.devices.detail.device.noRecommendation')}</i>
            )
          }
        />
        <PropertyCard
          w="full"
          // @ts-ignore
          status={
            settingsRecommendations.bluetooth
              ? settingsRecommendations.bluetooth.severity
              : t('admin.devices.detail.device.unknown')
          }
          title={settingsRecommendations.bluetooth ? settingsRecommendations.bluetooth.group : 'Bluetooth'}
          description={
            settingsRecommendations.bluetooth ? (
              renderDescription(
                settingsRecommendations.bluetooth.recommendation_description,
                settingsRecommendations.bluetooth.knowledge_base_url
              )
            ) : (
              <i>{t('admin.devices.detail.device.noRecommendation')}</i>
            )
          }
        />
        <PropertyCard
          w="full"
          // @ts-ignore
          status={(scan as AndroidScan).device_settings!.safesurf_status ? 'safe' : 'warning'}
          title="SafeSurf VPN"
          description={renderDescription(
            (scan as AndroidScan).device_settings!.safesurf_status
              ? t('admin.devices.detail.device.safeSurf.enabled')
              : t('admin.devices.detail.device.safeSurf.disabled'),
            'https://kb.redamp.io/docs/security-recommendations/traffic-filtering#safe-surfing-od-redampio'
          )}
        />
      </VStack>
    </VStack>
  );
};

export default OsSettings;
