import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { RampProvider } from '@ramp/components';
import { Font } from '@react-pdf/renderer';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import AppLoader from 'components/AppLoader';
import MobileUnsupportedModal from 'components/MobileUnsupportedModal';
import SomethingWentWrong from 'components/SomethingWentWrong';
import Router from 'router';
import { useLayoutStore } from 'store/layout';
import { APIError } from 'types';
import { msalConfig } from 'utils/azureConfig';
import 'utils/fonts';
import 'utils/locales';
import { notify } from 'utils/notifications';
import { getEnvironment } from 'utils/utils';

import './main.css';

// Importing locales for dayjs
import 'dayjs/locale/cs';
import 'dayjs/locale/de';

import pkg from '../package.json';

getEnvironment();

const msalInstance = new PublicClientApplication(msalConfig);

Font.register({
  family: 'Rubik',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UE80V4bVkA.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-NYi1UE80V4bVkA.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-2Y-1UE80V4bVkA.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/rubik/v28/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-4I-1UE80V4bVkA.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE0UwdYPFkJ1O.ttf',
      fontStyle: 'italic',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tvE0UwdYPFkJ1O.ttf',
      fontStyle: 'italic',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8uDFEUwdYPFkJ1O.ttf',
      fontStyle: 'italic',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/rubik/v28/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8u6FEUwdYPFkJ1O.ttf',
      fontStyle: 'italic',
      fontWeight: 700
    }
  ]
});
Font.registerHyphenationCallback(word => [word]);

const App: React.FC = () => {
  const { t } = useTranslation();
  const loading = useLayoutStore(store => store.loading);
  const { color_scheme: brandColorScheme } = useLayoutStore(store => store.theme);

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // @ts-ignore
            onError: (err: APIError) => {
              notify.error({
                title: t('components.notification.error', { error: err.code }),
                description: err.description
              });

              // If the error is 4xx, redirect to the home page
              if (400 <= (err.code || 0) && (err.code || 0) <= 499) {
                window.location.replace('/');
              }
            },
            cacheTime: 0,
            staleTime: Infinity,
            refetchOnWindowFocus: false
          }
        }
      }),
    []
  );

  const setDayJsLocale = () => {
    const locale = localStorage.getItem('i18nextLng') || 'en';
    dayjs.locale(locale);
  };

  useEffect(() => {
    setDayJsLocale();
  }, []);

  return (
    <RampProvider brand={brandColorScheme} initialColorMode="dark" appVersion={`v${pkg.version}`}>
      <ErrorBoundary FallbackComponent={SomethingWentWrong}>
        <MsalProvider instance={msalInstance}>
          <QueryClientProvider client={queryClient}>
            <MobileUnsupportedModal />
            {loading ? <AppLoader /> : <Router />}
          </QueryClientProvider>
        </MsalProvider>
      </ErrorBoundary>
    </RampProvider>
  );
};

export default App;
