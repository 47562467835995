import { HStack, StyleProps, Text, useColorModeValue } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NoDataCardContentProps extends StyleProps {}

const NoDataCardContent: React.FC<NoDataCardContentProps> = props => {
  const { t } = useTranslation();

  return (
    <HStack w="full" h="full" align="center" justify="center" {...props}>
      <Text fontWeight={500} fontSize="1.15rem" color={useColorModeValue('gray.400', 'gray.600')}>
        {t('components.noDataCardContent.text')}
      </Text>
    </HStack>
  );
};

export default NoDataCardContent;
