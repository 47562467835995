import { Button } from '@ramp/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { APIError, Platforms } from 'types';
import { notify, useNotificationSend } from 'utils/notifications';

import RemoteControlCard from '../RemoteControlCard';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NetworkDeviceScanCardProps {}

const NetworkDeviceScanCard: React.FC<NetworkDeviceScanCardProps> = () => {
  const { t } = useTranslation();
  const notification = useNotificationSend();
  const { id: deviceId } = useParams<{ entityId: string; platform: Platforms; id: string }>();

  const [notificationLoading, setNotificationLoading] = useState<boolean>(false);

  const onScanClick = () => {
    setNotificationLoading(true);

    notification.win
      .networkDeviceScan(deviceId!, undefined)
      .then(() => {
        setNotificationLoading(false);

        notify.success({
          title: t('admin.devices.detail.remoteControl.networkDeviceScan.not.success.title'),
          description: t('admin.devices.detail.remoteControl.networkDeviceScan.not.success.description')
        });
      })
      .catch((err: APIError) => {
        setNotificationLoading(false);

        notify.error({
          title: t('admin.devices.detail.remoteControl.networkDeviceScan.not.error.title'),
          description: err.description
        });
      });
  };

  return (
    <RemoteControlCard
      title={t('admin.devices.detail.remoteControl.networkDeviceScan.title')}
      description={t('admin.devices.detail.remoteControl.networkDeviceScan.description')}
      rightContent={
        <Button variant="brand" onClick={onScanClick} isLoading={notificationLoading} isDisabled>
          {t('admin.devices.detail.remoteControl.networkDeviceScan.button')}
        </Button>
      }
    />
  );
};

export default NetworkDeviceScanCard;
