import { Button, ColumnsType, HStack, RTable, Tag, Text, Tooltip, VStack } from '@ramp/components';
import dayjs from 'dayjs';
import { Archive, CheckSquare, ChevronRight, Key, Loader, Lock, Mail, MailCheck, XSquare } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import TagWithIcon from 'components/TagWithIcon';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { IssueDetail, UserData } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import { getRoleByNumber, parseRoute, toCapitalize, usePlatformIcon } from 'utils/utils';

import IssueIgnorePopover from 'components/IssueIgnorePopover';
import DevicesFilter from '../../DevicesFilter';
import DevicesEvaluationTable from '../DevicesEvaluationTable';

interface UsersTableProps {
  /**
   * Table id
   */
  id: string;

  /**
   * Default page size for the table
   */
  defaultPageSize?: number;

  /**
   * Users to display in the table
   */
  users?: UserData[];

  /**
   * Loading state of the table
   */
  loading?: boolean;

  /**
   * Simple mode for the table
   */
  simple?: boolean;

  /**
   * Selectable mode for the table
   */
  selectable?: boolean;

  /**
   * Callback function that is called when the selected users change
   */
  onSelectedUsersChange?: (selectedUsers: UserData[]) => void;

  /**
   * If defined, the table will render with the "Actions" column,
   * where the user can see the device detail and ignore/activate issue.
   */
  withIssue?: IssueDetail;

  /**
   * Defines if we want to render "affected_objects" or "ignored_objects"
   * and if render "ignore/activate issue" button in the table
   *
   * If `true`, the table will render the objects that are actively affected and the "ignore/activate issue" button.
   *
   * If `false`, the table will render all objects and the "ignore/activate issue" button will not be displayed.
   *
   * `withIssue` property must be defined to use this property.
   *
   * @default true
   */
  showAffected?: boolean;

  /**
   * Defines if we want to render the table in user view
   *
   * @default false
   */
  userView?: boolean;
}

interface UserRow extends UserData {
  key: number;
}

const UsersTable: React.FC<UsersTableProps> = ({
  id,
  defaultPageSize = 25,
  users = [],
  loading,
  simple = false,
  selectable = false,
  onSelectedUsersChange,
  withIssue,
  showAffected = true,
  userView = false
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loggedUser = useAuthStore(store => store.user!);
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize || 25);

  const issueActionsColumn: ColumnsType<UserRow> = useMemo(
    () => [
      {
        title: t('admin.dashboard.issues.table.actions'),
        key: 'actions',
        align: 'center' as const,
        render: (_: any, user: UserRow) => (
          <HStack justify="center" spacing={2}>
            <Button
              as={Link}
              to={parseRoute(loggedUser, ROUTES.ADMIN.USERS.DETAIL, {
                id: user.id
              })}
              size="sm"
              variant="outline"
              rightIcon={<ChevronRight size={16} />}
            >
              {t('admin.dashboard.issues.table.details')}
            </Button>
            <IssueIgnorePopover
              issue={{
                ...withIssue!,
                n_affected_objects: 1,
                n_ignored_objects: 1,
                recommendations: withIssue!.recommendations.map(r => ({
                  ...r,
                  n_affected_objects: r.affected_objects.length.toString(),
                  n_ignored_objects: r.ignored_objects.length.toString()
                }))
              }}
              codenames={[
                {
                  codename: withIssue!.codename,
                  object_ids: [user.id]
                }
              ]}
              mode={showAffected ? 'ignore' : 'activate'}
              userView={userView}
            />
          </HStack>
        )
      }
    ],
    []
  );

  const simpleColumns: ColumnsType<UserRow> = useMemo(
    () => [
      {
        title: t('admin.users.filters.name'),
        key: 'name',
        sorting: true,
        sortingOptions: {
          dataType: 'string',
          defaultSortOrder: 'asc'
        }
      },
      {
        key: 'email',
        title: 'Email',
        sorting: true
      },
      {
        title: t('admin.users.filters.status'),
        key: 'status',
        align: 'center',
        sorting: true,
        render: value => {
          switch (value) {
            case 'active':
              return (
                <TagWithIcon colorScheme="success" icon={<CheckSquare size="1rem" />}>
                  {t('admin.users.filters.active')}
                </TagWithIcon>
              );
            case 'pending':
              return (
                <TagWithIcon colorScheme="warning" icon={<Loader size="1rem" />}>
                  {t('admin.users.filters.pending')}
                </TagWithIcon>
              );
            case 'email_verification':
              return (
                <TagWithIcon colorScheme="warning" icon={<MailCheck size="1rem" />}>
                  {t('admin.users.filters.emailVerification')}
                </TagWithIcon>
              );
            case 'password_reset':
              return (
                <TagWithIcon colorScheme="warning" icon={<Lock size="1rem" />}>
                  {t('admin.users.filters.passwordReset')}
                </TagWithIcon>
              );
            case 'password_reset_activation':
              return (
                <TagWithIcon colorScheme="blue" icon={<Mail size="1rem" />}>
                  {t('admin.users.filters.invited')}
                </TagWithIcon>
              );
            case 'password_set_verification':
              return (
                <TagWithIcon colorScheme="warning" icon={<Key size="1rem" />}>
                  {t('admin.users.filters.passwordVerification')}
                </TagWithIcon>
              );
            case 'disabled':
              return (
                <TagWithIcon colorScheme="error" icon={<XSquare size="1rem" />}>
                  {t('admin.users.filters.blocked')}
                </TagWithIcon>
              );
            case 'archived':
              return (
                <TagWithIcon colorScheme="unknown" icon={<Archive size="1rem" />}>
                  {t('admin.users.filters.archived')}
                </TagWithIcon>
              );
            default:
              return <Tag>{toCapitalize(value)}</Tag>;
          }
        }
      },
      {
        title: t('admin.users.filters.role'),
        key: 'role',
        align: 'center',
        sorting: true,
        render: role => (
          <Tag>
            {/*// @ts-ignore */}
            <Text style={{ textWrap: 'nowrap', inlineSize: 'max-content' }}>
              {toCapitalize(getRoleByNumber(role || 0, t))}
            </Text>
          </Tag>
        )
      },
      ...(withIssue ? issueActionsColumn : [])
    ],
    []
  );

  const columns: ColumnsType<UserRow> = useMemo(
    () => [
      {
        title: t('admin.users.filters.name'),
        key: 'name',
        sorting: true,
        sortingOptions: {
          dataType: 'string',
          defaultSortOrder: 'asc'
        }
      },
      {
        key: 'email',
        title: 'Email',
        sorting: true
      },
      {
        title: t('admin.users.filters.status'),
        key: 'status',
        align: 'center',
        sorting: true,
        render: value => {
          switch (value) {
            case 'active':
              return (
                <TagWithIcon colorScheme="success" icon={<CheckSquare size="1rem" />}>
                  {t('admin.users.filters.active')}
                </TagWithIcon>
              );
            case 'pending':
              return (
                <TagWithIcon colorScheme="warning" icon={<Loader size="1rem" />}>
                  {t('admin.users.filters.pending')}
                </TagWithIcon>
              );
            case 'email_verification':
              return (
                <TagWithIcon colorScheme="warning" icon={<MailCheck size="1rem" />}>
                  {t('admin.users.filters.emailVerification')}
                </TagWithIcon>
              );
            case 'password_reset':
              return (
                <TagWithIcon colorScheme="warning" icon={<Lock size="1rem" />}>
                  {t('admin.users.filters.passwordReset')}
                </TagWithIcon>
              );
            case 'password_reset_activation':
              return (
                <TagWithIcon colorScheme="blue" icon={<Mail size="1rem" />}>
                  {t('admin.users.filters.invited')}
                </TagWithIcon>
              );
            case 'password_set_verification':
              return (
                <TagWithIcon colorScheme="warning" icon={<Key size="1rem" />}>
                  {t('admin.users.filters.passwordVerification')}
                </TagWithIcon>
              );
            case 'disabled':
              return (
                <TagWithIcon colorScheme="error" icon={<XSquare size="1rem" />}>
                  {t('admin.users.filters.blocked')}
                </TagWithIcon>
              );
            case 'archived':
              return (
                <TagWithIcon colorScheme="unknown" icon={<Archive size="1rem" />}>
                  {t('admin.users.filters.archived')}
                </TagWithIcon>
              );
            default:
              return <Tag>{toCapitalize(value)}</Tag>;
          }
        }
      },
      {
        title: t('admin.users.filters.role'),
        key: 'role',
        align: 'center',
        sorting: true,
        render: role => (
          <Tag>
            {/*// @ts-ignore */}
            <Text style={{ textWrap: 'nowrap', inlineSize: 'max-content' }}>
              {toCapitalize(getRoleByNumber(role || 0, t))}
            </Text>
          </Tag>
        )
      },
      ...(!withIssue
        ? [
            {
              key: 'otp_enabled',
              title: t('admin.users.filters.2faStatus'),
              align: 'center',
              sorting: true,
              sortingOptions: {
                dataType: 'string',
                sortingFn: ({ original: a }: { original: UserRow }, { original: b }: { original: UserRow }) => {
                  return (b.otp_enabled ? 1 : 0) - (a.otp_enabled ? 1 : 0);
                }
              },
              render: (otp_enabled: boolean) => (
                <Tag colorScheme={otp_enabled ? 'green' : 'error'}>
                  {otp_enabled ? t('admin.users.filters.enabled') : t('admin.users.filters.disabled')}
                </Tag>
              )
            } as const
          ]
        : []),
      {
        key: 'mobile_devices',
        title: t('components.table.users.mobileDevices'),
        align: 'center',
        children: [
          {
            title: usePlatformIcon('android'),
            key: 'android_devices',
            align: 'center',
            sorting: true,
            sortingOptions: {
              dataType: 'number',
              sortingFn: ({ original: a }, { original: b }) => a.android_devices - b.android_devices
            },
            render: (_, usr) => (
              <Tooltip placement="top" label={`Android: ${usr.android_devices}`}>
                <HStack w="full" align="center" justify="center">
                  <Text>{usr.android_devices}</Text>
                </HStack>
              </Tooltip>
            )
          },
          {
            title: usePlatformIcon('ios'),
            key: 'ios_devices',
            align: 'center',
            sorting: true,
            sortingOptions: {
              dataType: 'number',
              sortingFn: ({ original: a }, { original: b }) => a.ios_devices - b.ios_devices
            },
            render: (_, usr) => (
              <Tooltip placement="top" label={`iOS: ${usr.ios_devices}`}>
                <HStack w="full" align="center" justify="center">
                  <Text>{usr.ios_devices}</Text>
                </HStack>
              </Tooltip>
            )
          },
          {
            title: usePlatformIcon('ipados'),
            key: 'ipados_devices',
            align: 'center',
            sorting: true,
            sortingOptions: {
              dataType: 'number',
              sortingFn: ({ original: a }, { original: b }) => a.ipados_devices - b.ipados_devices
            },
            render: (_, usr) => (
              <Tooltip placement="top" label={`iPadOS: ${usr.ipados_devices}`}>
                <HStack w="full" align="center" justify="center">
                  <Text>{usr.ipados_devices}</Text>
                </HStack>
              </Tooltip>
            )
          }
        ]
      },
      {
        key: 'desktop_devices',
        title: t('components.table.users.desktopDevices'),
        align: 'center',
        width: '180px',
        children: [
          {
            title: usePlatformIcon('macos', {
              style: { marginRight: 'auto', marginLeft: 'auto' }
            }),
            width: '50px',
            key: 'macos_devices',
            align: 'center',
            sorting: true,
            sortingOptions: {
              dataType: 'number',
              sortingFn: ({ original: a }, { original: b }) => a.macos_devices - b.macos_devices
            },
            render: (_, usr) => (
              <Tooltip placement="top" label={`macOS: ${usr.macos_devices}`}>
                <HStack w="full" align="center" justify="center">
                  <Text>{usr.macos_devices}</Text>
                </HStack>
              </Tooltip>
            )
          },
          {
            title: usePlatformIcon('windows', {
              style: { marginRight: 'auto', marginLeft: 'auto' }
            }),
            key: 'windows_devices',
            align: 'center',
            sorting: true,
            width: '50px',
            sortingOptions: {
              dataType: 'number',
              sortingFn: ({ original: a }, { original: b }) => a.windows_devices - b.windows_devices
            },
            render: (_, usr) => (
              <Tooltip placement="top" label={`Windows: ${usr.windows_devices}`}>
                <HStack w="full" align="center" justify="center">
                  <Text>{usr.windows_devices}</Text>
                </HStack>
              </Tooltip>
            )
          }
        ]
      },
      ...(!withIssue
        ? [
            {
              key: 'registered',
              title: t('admin.users.filters.accountCreated'),
              sorting: true,
              render: (registered: string) => dayjs(registered).format('DD. MM. YYYY')
            } as const,
            {
              key: 'last_login',
              title: t('admin.users.filters.lastLogin'),
              sorting: true,
              sortingOptions: {
                dataType: 'string',
                sortingFn: ({ original: a }: { original: UserRow }, { original: b }: { original: UserRow }) => {
                  return dayjs(a.last_login || 0).isBefore(dayjs(b.last_login || 0)) ? 1 : -1;
                }
              },
              render: (lastLogin: string) =>
                lastLogin ? dayjs(lastLogin).format('DD. MM. YYYY (HH:mm:ss)') : t('components.permissionRow.never')
            } as const
          ]
        : []),
      ...(withIssue
        ? [
            {
              title: t('admin.dashboard.issues.table.actions'),
              key: 'actions',
              align: 'center' as const,
              render: (_: any, user: UserRow) => (
                <HStack justify="center" spacing={2}>
                  <Button
                    as={Link}
                    to={parseRoute(loggedUser, ROUTES.ADMIN.USERS.DETAIL, {
                      id: user.id
                    })}
                    size="sm"
                    variant="outline"
                    rightIcon={<ChevronRight size={16} />}
                  >
                    {t('admin.dashboard.issues.table.details')}
                  </Button>
                  <IssueIgnorePopover
                    issue={{
                      ...withIssue,
                      n_affected_objects: 1,
                      n_ignored_objects: 1,
                      recommendations: withIssue!.recommendations.map(r => ({
                        ...r,
                        n_affected_objects: r.affected_objects.length.toString(),
                        n_ignored_objects: r.ignored_objects.length.toString()
                      }))
                    }}
                    codenames={[
                      {
                        codename: withIssue.codename,
                        object_ids: [user.id]
                      }
                    ]}
                    mode={showAffected ? 'ignore' : 'activate'}
                    userView={false}
                  />
                </HStack>
              )
            }
          ]
        : [])
    ],
    []
  );

  const formattedUsers: UserRow[] = useMemo(() => {
    return users.map((user, key) => ({ key, ...user }));
  }, [users]);

  return (
    <RTable
      columns={simple ? simpleColumns : columns}
      data={formattedUsers}
      loading={loading}
      onRowClick={
        !withIssue
          ? user =>
              navigate(
                parseRoute(loggedUser, ROUTES.ADMIN.USERS.DETAIL, {
                  id: user.id
                })
              )
          : undefined
      }
      selectable={
        selectable
          ? {
              rowSelectable: () => true,
              onChange: selectedRows =>
                onSelectedUsersChange ? onSelectedUsersChange(selectedRows as unknown as UserData[]) : null
            }
          : undefined
      }
      expandable={
        simple
          ? undefined
          : {
              rowExpandable: () => true,
              render: user => {
                const [filteredDevices, setFilteredDevices] = useState(user.devices);

                return (
                  <VStack w="full" py={4} align="end" spacing={8}>
                    <DevicesFilter devices={user.devices} setDevices={setFilteredDevices} showSearch={true} />
                    <DevicesEvaluationTable
                      id="users_devices"
                      loading={false}
                      devices={filteredDevices}
                      defaultPageSize={10}
                      simple
                    />
                  </VStack>
                );
              }
            }
      }
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
      }}
      interleaveRows
    />
  );
};

export default UsersTable;
