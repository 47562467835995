import { AxiosError } from 'axios';

import { ErrorFields, GeneralRecommendation, LocalRecommendation } from 'types';

import axios, { handleError } from './_defaults';

const getGeneralRecommendations = (): Promise<GeneralRecommendation[]> =>
  new Promise<GeneralRecommendation[]>((resolve, reject) => {
    axios
      .get<GeneralRecommendation[]>('/recommendations/general')
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getLocalRecommendations = (): Promise<LocalRecommendation[]> =>
  new Promise<LocalRecommendation[]>((resolve, reject) => {
    axios
      .get<LocalRecommendation[]>('/recommendations/local')
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

export default {
  getGeneralRecommendations,
  getLocalRecommendations
};
