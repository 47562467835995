import { Grid, Spinner, VStack } from '@ramp/components';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import PlatformStatsCard from 'pages/admin/dashboard/PlatformStatsCard';
import RiskScoreCard from 'pages/admin/dashboard/RiskScoreCard';
import { DeviceUserEvaluation, Issue } from 'types';

interface UserRiskScoreCardProps {
  userId: string;
  title?: string;
  issues?: Issue[];
  devices?: DeviceUserEvaluation[];
}

const UserRiskScoreCard: React.FC<UserRiskScoreCardProps> = ({ userId, title, issues, devices }) => {
  const { t } = useTranslation();

  const defaultStartDate = dayjs().add(-1, 'month').format('YYYY-MM-DD');
  const defaultEndDate = dayjs().format('YYYY-MM-DD');

  if (!issues || !devices) return null;

  const riskScores = useQuery(['users', userId, 'riskScores'], () =>
    api.users.getRiskScores(userId, defaultStartDate, defaultEndDate)
  );

  const devicesStats = useQuery(['users', userId, 'devicesStats'], () => api.users.getDevicesStats(userId));

  if (riskScores.isLoading || devicesStats.isLoading) {
    return (
      <VStack w="full" justify="center" py={24}>
        <Spinner size="md" color="brand.500" />
      </VStack>
    );
  }

  return (
    <Grid w="full" mt="1rem !important" templateColumns="3fr 1fr" gap={6}>
      <RiskScoreCard title={title || t('admin.users.detail.userRiskScore')} riskScores={riskScores.data} />
      <PlatformStatsCard devicesStats={devicesStats.data} />
    </Grid>
  );
};

export default UserRiskScoreCard;
