import { RCard, RPage, VStack } from '@ramp/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import DevicesFilter from 'components/DevicesFilter';
import NoDataPage from 'components/NoDataPage';
import DevicesEvaluationTable from 'components/tables/DevicesEvaluationTable';
import { useAuthStore, useLayoutStore } from 'store';
import { DeviceUserEvaluation } from 'types';
import { PRODUCT_NAME } from 'utils/constants';

const DevicesList: React.FC = () => {
  const { t } = useTranslation();
  const user = useAuthStore(store => store.user!);
  const { noUsersInCompany, noScansInCompany, setNoScansInCompany } = useLayoutStore(store => store);

  const [loading, setLoading] = useState(true);
  const [defaultDevices, setDefaultDevices] = useState<DeviceUserEvaluation[]>([]);
  const [filteredDevices, setFilteredDevices] = useState<DeviceUserEvaluation[]>([]);

  useEffect(() => {
    api.users.getDevices(user.user_id).then(data => {
      setLoading(false);
      setFilteredDevices(data);
      setDefaultDevices(data);
      setNoScansInCompany(data.length === 0);
    });
  }, []);

  if (noScansInCompany) {
    return (
      <NoDataPage
        heading={t('admin.devices.heading')}
        pageLoading={loading}
        noUsersInCompany={noUsersInCompany}
        noScansInCompany={noScansInCompany}
      />
    );
  }

  return (
    <RPage
      px={6}
      pt={8}
      loading={false}
      heading={t('admin.devices.heading')}
      title={t('admin.devices.title', { productName: PRODUCT_NAME })}
    >
      <VStack w="full" align="start">
        <DevicesFilter devices={defaultDevices} setDevices={setFilteredDevices} showSearch={false} />
        <RCard w="full" titleOutside={false}>
          <DevicesEvaluationTable id="devices_table" devices={filteredDevices} loading={loading} />
        </RCard>
      </VStack>
    </RPage>
  );
};

export default DevicesList;
