import { Grid, GridItem, RSelectOptionV2, RSelectV2, Text, VStack } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import CountryFlag from 'components/CountryFlag';
import { APIError, UserDataWithIssues } from 'types';
import { notify } from 'utils/notifications';
import { getCountryCode } from 'utils/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CommunicationLanguageSettingProps {
  user?: UserDataWithIssues;
}

const CommunicationLanguageSetting: React.FC<CommunicationLanguageSettingProps> = ({ user }) => {
  if (!user) return null;

  const { t } = useTranslation();
  const [innerLanguage, setInnerLanguage] = React.useState<string>(user.language);

  const updateLanguage = (language: string) => {
    setInnerLanguage(language);

    if (language !== innerLanguage) {
      api.users
        .updateMe({
          country: user.country,
          language: language || user.language
        })
        .then(() => {
          notify.success({
            title: t('admin.settings.communicationLangauge.notificationSuccess.title'),
            description: t('admin.settings.communicationLangauge.notificationSuccess.description')
          });
        })
        .catch((err: APIError) => {
          setInnerLanguage(user.language);
          notify.error({
            title: t('admin.settings.communicationLangauge.notificationFail.title'),
            description: err.description
          });
        });
    }
  };

  return (
    <Grid w="full" templateColumns="1fr 1.4fr" gap={14}>
      <GridItem w="100%">
        <VStack w="full" align="start">
          <Text fontWeight={600} fontSize="1.25rem">
            {t('admin.settings.communicationLanguage.title')}
          </Text>
          <Text fontWeight={300} fontSize="1rem">
            {t('admin.settings.communicationLanguage.description')}
          </Text>
        </VStack>
      </GridItem>
      <GridItem w="100%">
        <VStack w="full" h="62px" align="start" justify="center" spacing={6}>
          <RSelectV2
            size="lg"
            w="320px"
            label={t('components.languageSelect.label')}
            icon={<CountryFlag countryCode={getCountryCode(user.language)} style={{ marginRight: '0.5rem' }} />}
            value={innerLanguage}
            onChange={language => updateLanguage(language as string)}
          >
            <RSelectOptionV2 icon={<CountryFlag countryCode="gb" />} value="en">
              English
            </RSelectOptionV2>
            <RSelectOptionV2 icon={<CountryFlag countryCode="cz" />} value="cs">
              Čeština
            </RSelectOptionV2>
            <RSelectOptionV2 icon={<CountryFlag countryCode="de" />} value="de">
              Deutsch
            </RSelectOptionV2>
          </RSelectV2>
        </VStack>
      </GridItem>
    </Grid>
  );
};

export default CommunicationLanguageSetting;
