import { RCard, VStack } from '@ramp/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Issue } from 'types';

import BasicIssuesFilter from 'components/BasicIssuesFilter';
import BasicIssuesTable from 'components/tables/BasicIssuesTable';
import { useAuthStore } from 'store';

interface IssuesCardProps {
  issues?: Issue[];
}

const IssuesCard: React.FC<IssuesCardProps> = ({ issues }) => {
  const { t } = useTranslation();
  const { user_id: userId } = useAuthStore(store => store.user!);
  const [filteredIssues, setFilteredIssues] = useState<Issue[]>(issues || []);

  return (
    <RCard w="full" title={t('admin.dashboard.platformStats.issues')} titleOutside={false}>
      <VStack w="full" align="start" spacing={4}>
        <BasicIssuesFilter issues={issues} setIssues={setFilteredIssues} />
        <BasicIssuesTable id="issues_table" issues={filteredIssues} userId={userId} userView />
      </VStack>
    </RCard>
  );
};

export default IssuesCard;
