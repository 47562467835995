import { RCard, RPage, VStack } from '@ramp/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import NotApprovedClientInfoBox from 'components/NotApprovedClientInfoBox';
import LocalThreatsTable from 'components/tables/LocalThreatsTable';
import { useAuthStore } from 'store';
import { LocalRecommendation } from 'types';
import { PRODUCT_NAME } from 'utils/constants';
import { notify } from 'utils/notifications';

import LocalThreatsFilter from './LocalThreatsFilter';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LocalThreatsListProps {}

const LocalThreatsList: React.FC<LocalThreatsListProps> = () => {
  const { t } = useTranslation();
  const { client_approved: clientApproved } = useAuthStore(store => store.user!);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [defaultLocalThreats, setDefaultLocalThreats] = useState<LocalRecommendation[]>([]);
  const [filteredLocalThreats, setFilteredLocalThreats] = useState<LocalRecommendation[]>([]);

  useEffect(() => {
    api.recommendations
      .getLocalRecommendations()
      .then(data => {
        setDefaultLocalThreats(data);
        setFilteredLocalThreats(data);
        setIsLoading(false);
      })
      .catch(err => {
        notify.error({
          title: t('admin.localThreats.notification.titleError'),
          description: err.description
        });
      });
  }, []);

  return (
    <RPage
      heading={t('admin.localThreats.heading')}
      infoBox={!clientApproved && clientApproved !== null && <NotApprovedClientInfoBox />}
      title={t('admin.localThreats.title', { productName: PRODUCT_NAME })}
      px={6}
      pt={8}
      loading={false}
    >
      <VStack w="full" align="start" spacing={0}>
        <LocalThreatsFilter localThreats={defaultLocalThreats} setLocalThreats={setFilteredLocalThreats} />
        <RCard w="full" titleOutside={false}>
          <LocalThreatsTable id="local_threats_table" localThreats={filteredLocalThreats} loading={isLoading} />
        </RCard>
      </VStack>
    </RPage>
  );
};

export default LocalThreatsList;
