import { Description, DescriptionItem, HStack, Tag, Text } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { AndroidExtendedDeviceInfo } from 'types/api/devices';
import { convertSecondsToTime, toCapitalize } from 'utils/utils';

interface AndroidExtendedInfoProps {
  info: AndroidExtendedDeviceInfo;
}

const AndroidExtendedInfo: React.FC<AndroidExtendedInfoProps> = ({ info }) => {
  const { t } = useTranslation();

  return (
    <Description size="md" columns={{ sm: 1, md: 1, lg: 2, xl: 4, '2xl': 4 }}>
      <DescriptionItem label={t('admin.devices.detail.sim.status')}>
        <HStack justify="start" spacing={2}>
          <Text>{toCapitalize(info.sim.status)}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.sim.operatorName')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.sim.operator_name}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.sim.multiSimSupported.text')}>
        <HStack justify="start" spacing={2}>
          {info.sim.is_multi_sim_supported ? (
            <Tag colorScheme="success">{t('admin.devices.detail.sim.multiSimSupported.supported')}</Tag>
          ) : (
            <Tag colorScheme="error">{t('admin.devices.detail.sim.multiSimSupported.notSupported')}</Tag>
          )}
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.sim.dataEnabled.text')}>
        <HStack justify="start" spacing={2}>
          {info.sim.is_data_enabled ? (
            <Tag colorScheme="success">{t('admin.devices.detail.sim.dataEnabled.enabled')}</Tag>
          ) : (
            <Tag colorScheme="error">{t('admin.devices.detail.sim.dataEnabled.disabled')}</Tag>
          )}
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.battery.status')}>
        <HStack justify="start" spacing={2}>
          <Text>{toCapitalize(info.battery_status)}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.display.resolution')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.display.res}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.display.hdr')}>
        <HStack justify="start" spacing={2}>
          {info.display.hdr ? (
            <Tag colorScheme="success">{t('admin.devices.detail.sim.multiSimSupported.supported')}</Tag>
          ) : (
            <Tag colorScheme="error">{t('admin.devices.detail.sim.multiSimSupported.notSupported')}</Tag>
          )}
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.camera.front')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.camera.front_mp}MPx</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.camera.back')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.camera.back_mp}MPx</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.ram')}>
        <HStack justify="start" spacing={2}>
          <Text>
            {(info.ram.actual_usage / 10e8).toFixed(2)}GB / {(info.ram.total_memory / 10e8).toFixed(2)}GB
          </Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.storage')}>
        <HStack justify="start" spacing={2}>
          <Text>
            {(info.storage.actual_storage / 10e8).toFixed(2)}GB / {(info.storage.total_storage / 10e8).toFixed(2)}GB
          </Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.cpu.name')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.cpu.name}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.cpu.architecture')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.cpu.architecture}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.cpu.numberOfCores')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.cpu.number_of_cores}</Text>
        </HStack>
      </DescriptionItem>
      <DescriptionItem label={t('admin.devices.detail.uptime')}>
        <HStack justify="start" spacing={2}>
          <Text>{info.uptime ? convertSecondsToTime(info.uptime) : t('admin.devices.detail.device.unknown')}</Text>
        </HStack>
      </DescriptionItem>
    </Description>
  );
};

export default AndroidExtendedInfo;
