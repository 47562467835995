import { AxiosError } from 'axios';
import { getAccessToken } from 'axios-jwt';
import dayjs from 'dayjs';
import {
  Client,
  ClientCount,
  CreateClient,
  DevicesStats,
  DeviceUserEvaluation,
  ErrorFields,
  IssueStats,
  PartnerAdmin,
  RiskScore
} from 'types';
import { CreatePartner, Firm, FirmTheme, UpdatePartner } from 'types/api/firms';

import axios, { handleError } from './_defaults';

const getClients = (firm_id: string): Promise<Client[]> =>
  new Promise<Client[]>((resolve, reject) => {
    axios
      .get<Client[]>(`/firms/${firm_id}/clients`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getPartners = (): Promise<Firm[]> =>
  new Promise<Firm[]>((resolve, reject) => {
    axios
      .get<Firm[]>('/firms')
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const createPartner = (partner: Omit<CreatePartner, 'admin_password_confirmation'>): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios
      .post<void>('/firms', partner)
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getPartner = (partner_id: string): Promise<Firm> =>
  new Promise<Firm>((resolve, reject) => {
    axios
      .get<Firm>(`/firms/${partner_id}`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

// eslint-disable-next-line max-len
const createPartnerClient = (
  partner_id: string,
  client: Omit<CreateClient, 'admin_password_confirmation'>
): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios
      .post<void>(`/firms/${partner_id}/clients`, client)
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getUsers = (firm_id: string): Promise<PartnerAdmin[]> =>
  new Promise<PartnerAdmin[]>((resolve, reject) => {
    axios
      .get<PartnerAdmin[]>(`/firms/${firm_id}/users`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

interface GetDeviceType {
  client_id: string;
  platform?: string;
  manufacturer?: string;
  marketing_name?: string;
  device?: string;
  start_date?: dayjs.Dayjs;
  end_date?: dayjs.Dayjs;
  device_name?: string;
}

const getDevices: (o: GetDeviceType) => Promise<DeviceUserEvaluation[]> = ({
  client_id,
  platform,
  manufacturer,
  marketing_name,
  device,
  start_date,
  end_date,
  device_name
}) =>
  new Promise<DeviceUserEvaluation[]>((resolve, reject) => {
    const params = {
      platform,
      manufacturer,
      marketing_name,
      device,
      start_date: start_date?.format('YYYY-MM-DD'),
      end_date: end_date?.format('YYYY-MM-DD'),
      device_name
    };

    axios
      .get<DeviceUserEvaluation[]>(`/clients/${client_id}/devices`, { params })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

// eslint-disable-next-line max-len
const getDevicesStats = (firm_id: string): Promise<DevicesStats> =>
  new Promise<DevicesStats>((resolve, reject) => {
    axios
      .get<DevicesStats>(`/firms/${firm_id}/stats/devices`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

// eslint-disable-next-line max-len
const getIssuesStats = (firm_id: string, start_date: string, end_date: string): Promise<IssueStats> =>
  new Promise<IssueStats>((resolve, reject) => {
    axios
      .get<IssueStats>(`/firms/${firm_id}/stats/issues`, { params: { start_date, end_date } })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

// eslint-disable-next-line max-len
const getRiskScoreStats = (firm_id: string, start_date: string, end_date: string): Promise<RiskScore[]> =>
  new Promise<RiskScore[]>((resolve, reject) => {
    axios
      .get<RiskScore[]>(`/firms/${firm_id}/stats/risk-scores`, { params: { start_date, end_date } })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const getNumberOfClients = (firm_id: string): Promise<ClientCount> =>
  new Promise<ClientCount>((resolve, reject) => {
    axios
      .get<ClientCount>(`/firms/${firm_id}/clients/count`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const updatePartner = (partner_id: string, partner: UpdatePartner): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    const formData = new FormData();
    if (partner.logo_primary_dark) formData.append('logo_primary_dark', partner.logo_primary_dark);
    if (partner.logo_primary_light) formData.append('logo_primary_light', partner.logo_primary_light);
    if (partner.logo_secondary_dark) formData.append('logo_secondary_dark', partner.logo_secondary_dark);
    if (partner.logo_secondary_light) formData.append('logo_secondary_light', partner.logo_secondary_light);

    formData.append('firm_data', JSON.stringify(partner.firm_data));

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getAccessToken()}`
    };

    axios
      .post<void>(`/firms/${partner_id}`, formData, { headers })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(err));
  });

const getFirmsTheme = (firm_alias: string) =>
  new Promise<FirmTheme>((resolve, reject) => {
    axios
      .get(`/firms/${firm_alias}/theme`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const updatePartnerStatus = (firm_id: string, status: 'active' | 'archived' | 'disabled'): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios
      .patch<void>(`/firms/${firm_id}/status`, { status })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

export default {
  getPartners,
  createPartner,
  createPartnerClient,
  getPartner,
  updatePartner,
  getClients,
  getDevices,
  getDevicesStats,
  getIssuesStats,
  getRiskScoreStats,
  getNumberOfClients,
  getFirmsTheme,
  getUsers,
  updatePartnerStatus
};
