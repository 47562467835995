import { Box, Button, Heading, HStack, Text, useColorModeValue, VStack } from '@ramp/components';
import { Lightbulb } from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import { QuizChoice, UserQuizAssignment } from 'types';

export interface QuizPageProps {
  assignment: UserQuizAssignment;
  assignmentToken: string;
}

interface ShuffledQuizChoice extends QuizChoice {
  real_index: number;
}

const QuizPage: React.FC<QuizPageProps> = ({ assignment, assignmentToken }) => {
  const quiz = useMemo(() => assignment.quiz, []);

  const DEFAULT_ACTIVE_QUESTION = assignment.answers.length;
  const DEFAULT_SCORE = [
    ...assignment.answers.map(answer => answer.answer_score),
    ...Array(quiz.questions.length - assignment.answers.length).fill(0)
  ];

  const { t } = useTranslation();

  const [activeQuestion, setActiveQuestion] = useState(DEFAULT_ACTIVE_QUESTION);
  const [score, setScore] = useState(DEFAULT_SCORE);
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const [showResult, setShowResult] = useState(false);
  const [savingAnswer, setSavingAnswer] = useState(false);
  const [savingAnswerError, setSavingAnswerError] = useState<string | null>(null);

  const CURRENT_SHUFFLED_ANSWERS: ShuffledQuizChoice[] = useMemo(() => {
    const answersWithRealIndex: ShuffledQuizChoice[] = quiz.questions[activeQuestion].choices.map((answer, idx) => ({
      ...answer,
      real_index: idx
    }));

    return answersWithRealIndex.sort(() => Math.random() - 0.5);
  }, [activeQuestion]);

  const startQuestionTime = useMemo(() => new Date(), [activeQuestion]);

  const nextQuestion = () => {
    const tempSelectedAnswer: ShuffledQuizChoice = CURRENT_SHUFFLED_ANSWERS[selectedAnswer!];

    setScore(prev => {
      const newScore = [...prev];
      newScore[activeQuestion] = tempSelectedAnswer.is_correct ? 1 : 0;
      return newScore;
    });
    setSelectedAnswer(null);

    // If last question, show result / else go to next question
    if (activeQuestion === quiz.questions.length - 1) {
      setShowResult(true);
    } else {
      setActiveQuestion(activeQuestion + 1);
    }
  };

  const recordAnswer = (idx: number) => {
    const spentMiliseconds = new Date().getTime() - startQuestionTime.getTime();
    setSelectedAnswer(idx);

    const tempSelectedAnswer: ShuffledQuizChoice = CURRENT_SHUFFLED_ANSWERS[idx];

    // Send result to the backend
    setSavingAnswer(true);
    setSavingAnswerError(null);
    api.users
      .recordUserQuizAnswer(assignmentToken, activeQuestion, tempSelectedAnswer.real_index, spentMiliseconds)
      .catch(() => {
        setSavingAnswerError(t('base.test.savingError'));
      })
      .finally(() => {
        setSavingAnswer(false);
      });
  };

  const percentage = score.reduce((acc, curr) => acc + curr, 0) / quiz.questions.length;

  const getResultText = () => {
    if (percentage <= 1 / 3) {
      return t('base.test.text.onethird');
    }

    if (percentage <= 2 / 3) {
      return t('base.test.text.twothird');
    }

    if (percentage < 1) {
      return t('base.test.text.lessthenone');
    }

    return t('base.test.text.oneequalone');
  };

  if (showResult) {
    return (
      <VStack w="full" h="100%" spacing="64px">
        <Heading size="xl">{t('base.test.testResult')}</Heading>
        <VStack w="full" h="fit-content" spacing="16px" alignItems="center" justifyContent="center">
          <HStack w="full" h="fit-content" spacing="8px" alignItems="end" justifyContent="center">
            <Text fontSize="64px" fontWeight="bold" lineHeight="1">
              {score.reduce((acc, curr) => acc + curr, 0)}
            </Text>
            <Text fontSize="xl" lineHeight="1" color="gray.400" position="relative" bottom="8px">
              / {quiz.questions.length}
            </Text>
          </HStack>
          <Text fontSize="sm" color="gray.300">
            {t('base.test.testResultText', {
              correct: score.reduce((acc, curr) => acc + curr, 0),
              total: quiz.questions.length
            })}
          </Text>
        </VStack>
        <HStack
          w="full"
          h="fit-content"
          spacing="8px"
          alignItems="center"
          justifyContent="center"
          // bg="gray.750"
          px="16px"
          py="8px"
          borderRadius="md"
        >
          <Text fontSize="md" textAlign="center">
            {getResultText()}
          </Text>
        </HStack>

        <VStack w="full" mt="auto" spacing="12px" alignItems="center" justifyContent="center">
          <Text fontSize="sm" color="gray.300" textAlign="center">
            {t('base.test.closeText')}
          </Text>
          <Button
            size="lg"
            w="full"
            variant="brand"
            onClick={() => {
              // @ts-ignore
              window.location.replace('https://redamp.io/app-link/quizzes');
            }}
          >
            {t('base.test.closeButton')}
          </Button>
        </VStack>
      </VStack>
    );
  }

  return (
    <VStack w="full" h="full" spacing="16px">
      {/* Header */}
      <HStack w="full" h="12px" spacing="4px">
        {quiz.questions.map((_, idx) => (
          <Box
            key={idx}
            w="full"
            h="8px"
            bg={
              activeQuestion > idx
                ? score[idx] === 1
                  ? 'success.500'
                  : 'error.500'
                : activeQuestion === idx
                  ? useColorModeValue('gray.400', 'gray.600')
                  : useColorModeValue('gray.25', 'gray.750')
            }
            borderRadius="full"
          />
        ))}
      </HStack>

      {/* Question & Answers */}
      <VStack w="full" h="full" alignItems="start" justifyContent="space-between">
        {/* Question */}
        <VStack w="full" h="fit-content" spacing="0px" alignItems="start">
          <Text fontSize="sm" color="gray.500">
            {t('base.test.question')} {activeQuestion + 1} {t('base.test.from')} {quiz.questions.length}
          </Text>
          <Heading size="xl">{quiz.questions[activeQuestion].text}</Heading>
        </VStack>

        {/* Answers, takeaway and next button */}
        <VStack w="full" h="fit-content" spacing="16px" alignItems="end" justifyContent="end">
          {/* Answers */}
          <VStack w="full" h="fit-content" spacing="8px" alignItems="end" justifyContent="end" mt="auto">
            {CURRENT_SHUFFLED_ANSWERS.map((answer, idx) => (
              <HStack
                key={idx}
                bg={useColorModeValue('gray.100', 'gray.750')}
                w="full"
                borderRadius="md"
                spacing="8px"
                px="4px"
                py="4px"
                border="1px solid"
                cursor={selectedAnswer !== null ? 'not-allowed' : 'pointer'}
                borderColor={
                  selectedAnswer === idx
                    ? answer.is_correct
                      ? 'success.500'
                      : 'error.500'
                    : selectedAnswer !== null
                      ? answer.is_correct
                        ? 'success.500'
                        : 'transparent'
                      : 'transparent'
                }
                onClick={selectedAnswer === null ? () => recordAnswer(idx) : undefined}
              >
                <HStack
                  w={8}
                  h={8}
                  alignItems="center"
                  justifyContent="center"
                  bg={useColorModeValue('gray.300', 'gray.800')}
                  borderRadius="md"
                >
                  <Text fontSize="sm" w={8} textAlign="center">
                    {String.fromCharCode(65 + idx)}
                  </Text>
                </HStack>
                <Text fontSize="sm">{answer.text}</Text>
              </HStack>
            ))}
          </VStack>

          {selectedAnswer !== null && (
            <>
              {/* Takeaway */}
              <HStack
                w="full"
                h="fit-content"
                spacing="8px"
                alignItems="center"
                bg={useColorModeValue('gray.100', 'gray.750')}
                px="8px"
                py="8px"
                borderRadius="md"
                color={useColorModeValue('gray.600', 'gray.400')}
              >
                <Box w={4} h={4}>
                  <Lightbulb size={16} />
                </Box>
                <Text fontSize="sm" lineHeight="1">
                  {CURRENT_SHUFFLED_ANSWERS[selectedAnswer].is_correct
                    ? t('base.test.great')
                    : t('base.test.unfortunately')}
                  &nbsp;
                  {quiz.questions[activeQuestion].takeaway}
                </Text>
              </HStack>

              {savingAnswerError && (
                <Text margin="0 auto" fontSize="sm" color="error.500" textAlign="center">
                  {savingAnswerError}
                </Text>
              )}

              {/* Next button */}
              <Button
                variant="brand"
                w="full"
                size="md"
                onClick={savingAnswerError ? () => recordAnswer(selectedAnswer!) : nextQuestion}
                isLoading={savingAnswer}
              >
                {savingAnswer
                  ? t('base.test.savingTheAnswer')
                  : savingAnswerError
                    ? t('base.test.tryAgain')
                    : activeQuestion === quiz.questions.length - 1
                      ? t('base.test.displayResults')
                      : t('base.test.nextQuestion')}
              </Button>
            </>
          )}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default QuizPage;
