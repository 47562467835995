import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  VStack
} from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { APIError } from 'types';
import { notify, useNotificationSend } from 'utils/notifications';

interface CustomNotificationModalProps {
  isOpen: boolean;
  onClose: () => void;
  setNotificationLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomNotificationModal: React.FC<CustomNotificationModalProps> = ({
  isOpen,
  onClose,
  setNotificationLoading
}) => {
  const notification = useNotificationSend();
  const { t } = useTranslation();
  const { id: deviceId } = useParams<{ id: string }>();

  const [title, setTitle] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');

  const onOk: React.FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    setNotificationLoading(true);
    onClose();
    notification
      .send(deviceId!, { title, message })
      .then(() => {
        notify.success({
          title: t('admin.devices.detail.customNotification.successTitle'),
          description: t('admin.devices.detail.customNotification.successDescription')
        });
        setTitle('');
        setMessage('');
        setNotificationLoading(false);
      })
      .catch((err: APIError) => {
        notify.error({
          title: t('admin.devices.detail.customNotification.errorTitle'),
          description: err.description
        });
        setNotificationLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnEsc>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onOk} style={{ width: '100%' }}>
          <ModalHeader>Send Custom Notification</ModalHeader>
          <ModalBody>
            <VStack w="full" align="start" spacing={4}>
              <FormControl>
                <FormLabel htmlFor="title">Title</FormLabel>
                <Input
                  id="title"
                  type="text"
                  onChange={e => setTitle(e.target.value)}
                  value={title}
                  placeholder="Enter title"
                  autoFocus
                />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="message">Message</FormLabel>
                <Textarea
                  id="message"
                  onChange={e => setMessage(e.target.value)}
                  value={message}
                  placeholder="Enter message"
                />
              </FormControl>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <HStack w="full" align="center" justify="end" spacing={2}>
              <Button size="sm" variant="borderless" onClick={onClose}>
                Cancel
              </Button>
              <Button size="sm" variant="brand" type="submit" disabled={!title || !message}>
                Send
              </Button>
            </HStack>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CustomNotificationModal;
