import { Box, FilterBar, IFilter, RSelect, RSelectOption, StyleProps } from '@ramp/components';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import {
  AlertTriangle,
  Bluetooth,
  Bug,
  CheckCircle,
  Factory,
  HeartCrack,
  HelpCircle,
  Lightbulb,
  MonitorX,
  Network,
  Paintbrush,
  SearchX,
  ShieldCheck,
  SmartphoneCharging,
  TimerReset,
  Unlock,
  Usb,
  XOctagon
} from 'lucide-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FiCalendar,
  FiCheckSquare,
  FiEdit,
  FiMail,
  FiMessageSquare,
  FiSettings,
  FiSmartphone,
  FiUser,
  FiXSquare
} from 'react-icons/fi';

import DateRangePickerButton from 'components/DateRangePickerButton';
import { NotificationLog } from 'types';

interface NotificationsProps extends StyleProps {
  tableId: string;
  notifications?: NotificationLog[];
  setNotifications: React.Dispatch<React.SetStateAction<NotificationLog[]>>;
}

dayjs.extend(isBetween);

const NotificationsFilter: React.FC<NotificationsProps> = ({
  tableId,
  notifications = [],
  setNotifications,
  ...props
}) => {
  const { t } = useTranslation();

  const uniqueEmails: Set<string> = useMemo(() => {
    const emails = new Set<string>();
    notifications.map(not => {
      not.successful_sends.map(n => {
        return emails.add(n.device.owner.email);
      });
      return not.successful_sends.map(n => emails.add(n.device.owner.email));
    });

    return emails;
  }, [notifications]);

  const filters: IFilter[] = useMemo(
    () => [
      {
        id: 'notification_type',
        type: 'select',
        label: t('admin.notifications.filter.notificationType'),
        icon: FiMessageSquare,
        disabled: () => false,
        active: () => true,
        items: [
          {
            icon: () => <Bug size="1rem" />,
            value: 'background-scan-done',
            content: t('components.notificationType.threatsInfo')
          },
          {
            icon: () => <Paintbrush size="1rem" />,
            value: 'patch',
            content: t('components.notificationType.patch')
          },
          {
            icon: () => <Factory size="1rem" />,
            value: 'vendor_updates',
            content: t('components.notificationType.vendorUpdates')
          },
          {
            icon: () => <HeartCrack size="1rem" />,
            value: 'os_end_of_life',
            content: t('components.notificationType.osEndOfLife')
          },
          {
            icon: () => <MonitorX size="1rem" />,
            value: 'device_end_of_life',
            content: t('components.notificationType.deviceEndOfLife')
          },
          {
            icon: () => <SmartphoneCharging size="1rem" />,
            value: 'rooted',
            content: 'Rooted'
          },
          {
            icon: () => <SmartphoneCharging size="1rem" />,
            value: 'jailbroken',
            content: 'Jailbroken'
          },
          {
            icon: () => <Unlock size="1rem" />,
            value: 'screen_lock',
            content: t('components.notificationType.screenLock')
          },
          {
            icon: () => <Bluetooth size="1rem" />,
            value: 'bluetooth',
            content: 'Bluetooth'
          },
          {
            icon: () => <Usb size="1rem" />,
            value: 'usb_debug',
            content: 'USB Debug'
          },
          {
            icon: () => <Lightbulb size="1rem" />,
            value: 'general-recommendations',
            content: t('components.notificationType.generalRecommendation')
          },
          {
            icon: () => <TimerReset size="1rem" />,
            value: 'scheduled-recommendations',
            content: t('components.notificationType.scheduledRecommendation')
          },
          {
            icon: FiEdit,
            value: 'custom-notification',
            content: t('components.notificationType.customNotification')
          },
          {
            icon: FiSmartphone,
            value: 'background-scan',
            content: t('components.notificationType.backgroundScanRequest')
          },
          {
            icon: FiMessageSquare,
            value: 'new-security-patch',
            content: t('components.notificationType.newSecurityPatch')
          },
          {
            icon: FiMail,
            value: 'email-scan-done',
            content: t('components.notificationType.emailScan')
          },
          {
            icon: () => <SearchX size="1rem" />,
            value: 'unscanned-device',
            content: t('components.notificationType.unscannedDevice')
          },
          {
            icon: () => <ShieldCheck size="1rem" />,
            value: 'safe-networks-changed',
            content: t('components.notificationType.safeNetworksChanged')
          },
          {
            icon: () => <Network size="1rem" />,
            value: 'win_cmd_network_device_scan',
            content: t('components.notificationType.winCmdNetworkDeviceScan')
          }
        ],
        onFilter: (data: NotificationLog[], value) => data.filter(not => not.notification_type === value),
        render: (filter, _, onValueChange) => (
          <RSelect
            size="sm"
            filterId={filter.id}
            label={filter.label}
            value={filter.selected ? filter.selected.value : undefined}
            onChange={newValue => onValueChange(filter.id, newValue)}
            placeholder={t('admin.notifications.filter.selectNotificationType')}
            leftIcon={filter.icon && <filter.icon />}
          >
            {filter.items &&
              filter.items.map(item => (
                <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                  {item.content}
                </RSelectOption>
              ))}
          </RSelect>
        )
      },
      {
        id: 'sender',
        type: 'select',
        label: t('admin.notifications.filter.sender'),
        icon: FiUser,
        disabled: () => false,
        active: () => false,
        items: [
          { icon: FiUser, value: 'user', content: t('admin.notifications.filter.user') },
          { icon: FiSettings, value: 'system', content: t('admin.notifications.filter.system') }
        ],
        onFilter: (data: NotificationLog[], value) =>
          data.filter(not => {
            switch (value) {
              case 'user':
                return !!not.sender;
              case 'system':
                return not.sender === null;
            }
          }),
        render: (filter, _, onValueChange) => (
          <RSelect
            size="sm"
            filterId={filter.id}
            label={filter.label}
            value={filter.selected ? filter.selected.value : undefined}
            onChange={newValue => onValueChange(filter.id, newValue)}
            placeholder={t('admin.notifications.filter.selectSender')}
            leftIcon={filter.icon && <filter.icon />}
          >
            {filter.items &&
              filter.items.map(item => (
                <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                  {item.content}
                </RSelectOption>
              ))}
          </RSelect>
        )
      },
      {
        id: 'status',
        type: 'select',
        label: t('admin.notifications.filter.sendStatus'),
        icon: FiCheckSquare,
        disabled: () => false,
        active: () => false,
        items: [
          { icon: FiCheckSquare, value: 'success', content: t('admin.notifications.table.successful') },
          { icon: FiXSquare, value: 'fail', content: t('admin.notifications.table.failed') }
        ],
        onFilter: (data: NotificationLog[], value) =>
          data.filter(not => {
            switch (value) {
              case 'success':
                return not.successful_sends.length > 0;
              case 'fail':
                return not.failed_sends.length > 0;
            }
          }),
        render: (filter, _, onValueChange) => (
          <RSelect
            size="sm"
            filterId={filter.id}
            label={filter.label}
            value={filter.selected ? filter.selected.value : undefined}
            onChange={newValue => onValueChange(filter.id, newValue)}
            placeholder={t('admin.notifications.filter.selectSendStatus')}
            leftIcon={filter.icon && <filter.icon />}
          >
            {filter.items &&
              filter.items.map(item => (
                <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                  {item.content}
                </RSelectOption>
              ))}
          </RSelect>
        )
      },
      {
        id: 'recipient',
        type: 'select',
        label: t('admin.notifications.filter.recipient'),
        icon: FiUser,
        disabled: () => false,
        active: () => true,
        items: [...uniqueEmails].map(email => ({ icon: FiUser, value: email, content: email })),
        onFilter: (data: NotificationLog[], value) =>
          data.filter(not => {
            return (
              not.successful_sends.map(n => n.device.owner.email).includes(value as string) ||
              not.failed_sends.map(n => n.device.owner.email).includes(value as string)
            );
          }),
        render: (filter, _, onValueChange) => (
          <RSelect
            size="sm"
            filterId={filter.id}
            label={filter.label}
            value={filter.selected ? filter.selected.value : undefined}
            onChange={newValue => {
              onValueChange(filter.id, newValue);
            }}
            placeholder={t('components.table.emailBreaches.selectUser')}
            leftIcon={filter.icon && <filter.icon />}
            searchable
          >
            {filter.items &&
              filter.items.map(item => {
                return (
                  <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                    {item.content}
                  </RSelectOption>
                );
              })}
          </RSelect>
        )
      },
      {
        id: 'severity',
        type: 'select',
        label: t('components.table.basicIssues.severity'),
        icon: () => <AlertTriangle size="16px" />,
        disabled: () => false,
        active: () => false,
        items: [
          {
            icon: () => <XOctagon size="16px" />,
            value: 'risk',
            content: t('admin.notifications.filter.severity.risk')
          },
          {
            icon: () => <AlertTriangle size="16px" />,
            value: 'warning',
            content: t('admin.notifications.filter.severity.warning')
          },
          {
            icon: () => <CheckCircle size="16px" />,
            value: 'safe',
            content: t('admin.notifications.filter.severity.safe')
          },
          {
            icon: () => <HelpCircle size="16px" />,
            value: 'unknown',
            content: t('admin.notifications.filter.severity.unknown')
          }
        ],
        onFilter: (data: NotificationLog[], value) => data.filter(not => not.severity === value),
        render: (filter, _, onValueChange) => (
          <RSelect
            size="sm"
            filterId={filter.id}
            label={filter.label}
            value={filter.selected ? filter.selected.value : undefined}
            onChange={newValue => onValueChange(filter.id, newValue)}
            placeholder={t('admin.dashboard.issues.filter.severitySelect')}
            leftIcon={filter.icon && <filter.icon />}
          >
            {filter.items &&
              filter.items.map(item => (
                <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                  {item.content}
                </RSelectOption>
              ))}
          </RSelect>
        )
      },
      {
        id: 'sent',
        type: 'date',
        label: t('admin.notifications.filter.sentDate'),
        icon: FiCalendar,
        disabled: () => false,
        active: () => false,
        items: [],
        onFilter: (data: NotificationLog[], value) => {
          const { startDate, endDate } = value as { startDate: dayjs.Dayjs; endDate: dayjs.Dayjs };

          return data.filter(not => dayjs(not.created).isBetween(startDate, endDate, 'day', '[]'));
        },
        render: (filter, _filters, onValueChange) => {
          return (
            <DateRangePickerButton
              startDate={filter.selected?.startDate}
              endDate={filter.selected?.endDate}
              onChange={(startDate, endDate) => {
                onValueChange(filter.id, { startDate, endDate });
              }}
            />
          );
        }
      }
    ],
    [uniqueEmails]
  );

  if (uniqueEmails.size === 0) return null;

  return (
    <Box w="full" {...props}>
      <FilterBar
        tableId={tableId}
        filters={filters}
        data={notifications}
        setData={filteredNotifications => setNotifications(filteredNotifications)}
        searchFilter={{
          placeholder: t('admin.notifications.filter.searchBy'),
          onFilter: (data: NotificationLog[], searchedValue) =>
            data.filter(not => {
              if (not.sender === null && 'system'.includes(searchedValue.toLowerCase())) {
                return true;
              } else if (not.sender) {
                return not.sender.email.toLowerCase().includes(searchedValue.toLowerCase());
              }

              return false;
            })
        }}
      />
    </Box>
  );
};

export default NotificationsFilter;
