import { BrandTheme } from '@ramp/components';

import { AndroidPendingUpdates } from './api/devices';

export type Platforms = 'android' | 'ios' | 'ipados' | 'macos' | 'windows' | 'linux';

export type Severity = 'critical' | 'high' | 'medium' | 'low' | 'none';

export type Status =
  | 'active'
  | 'disabled'
  | 'pending'
  | 'archived'
  | 'email_verification'
  | 'password_reset'
  | 'password_reset_activation'
  | 'password_set_verification';

export type Nullable<T> = T | null;

export enum Role {
  none,
  user,
  admin,
  firmadmin,
  superadmin
}

export enum AppState {
  IN_VERIFICATION = 'in_verification',
  PLAYSTORE_FOUND = 'playstore_found',
  PLAYSTORE_TESTING = 'playstore_testing',
  NOT_FOUND = 'not_found',
  REDAMP_TESTING = 'redamp_testing',
  CATEGORIZED = 'categorized'
}

export enum AppCategory {
  SYSTEM = 'system',
  THIRD_PARTY = 'third_party',
  VENDOR_PREINSTALLED = 'vendor_preinstalled',
  MALICIOUS = 'malicious',
  REDAMP_VERIFIED = 'redamp_verified'
}

export interface LoginAsAdminProps {
  entityId: Nullable<string>;
  clientName: Nullable<string>;
  previousUser: Nullable<User>;
  customers: Client[];
}

export interface LoginAsPartnerAdminProps {
  entityId: Nullable<string>;
  partnerName: Nullable<string>;
  previousUser: Nullable<User>;
}

export type ClientStatus = 'active' | 'disabled' | 'archived';

export interface Client {
  id: string;
  name: string;
  firm_id: string;
  status: ClientStatus;
  contact_name: string;
  contact_email: string;
  scan_frequency_in_seconds: number;
  devices: FirmDevices;
  total_severity_count: SeverityCount;
  issues: FirmIssues;
  global_score: number;
  os_score: number;
  settings_score: number;
  application_score: number;
  device_score: number;
  country: string;
  street: string;
  city: string;
  zip_code: string;
  state: Nullable<string>;
  company_id: string;
  vat_id: Nullable<string>;
  is_approved: boolean;
}

export interface PartnerAdmin {
  id: string;
  email: string;
  name: string;
  position: Nullable<string>;
  status: Status;
  registered: string;
  last_login: Nullable<string>;
  otp_enabled: boolean;
  role: Role;
}

export interface CreateClient {
  name: string;
  admin_email: string;
  admin_password: string;
  admin_password_confirmation: string;
  contact_email: string;
  contact_name: string;
  country: string;
  street: string;
  city: string;
  zip_code: string;
  state?: string;
  company_id: string;
  vat_id?: string;
}

export interface GeneralRecommendation {
  id: string;
  platform: Platforms[];
  type: 'do' | 'consider_doing' | 'avoid';
  title: string;
  description: string;
  importness: number;
}

export interface LocalRecommendation extends GeneralRecommendation {
  language_code: string;
  url: string;
  regions: string[];
  created: string;
}

export interface UserCredentials {
  email: string;
  password: string;
}

export interface DecodedToken extends User {
  token_type: string;
  exp: number;
  jti: string;
}

export interface JWT {
  access: string;
  refresh: string;
}

export interface TokenSchema {
  access: Nullable<string>;
  refresh: Nullable<string>;
  otp_token: Nullable<string>;
}

export interface User {
  user_id: string;
  entity_id: Nullable<string>;
  name: Nullable<string>;
  email: Nullable<string>;
  employer_name: Nullable<string>;
  role: number;
  position: Nullable<string>;
  otp_enabled: boolean;
  scan_frequency_in_seconds: Nullable<number>;
  theme: 'light' | 'dark' | 'all';
  logo_primary_dark: string;
  logo_primary_light: string;
  logo_secondary_dark: string;
  logo_secondary_light: string;
  color_scheme: BrandTheme;
  client_approved: boolean;
}

export interface NotificationsResult {
  received_device_ids: Nullable<string[]>;
  not_found_device_ids: Nullable<string[]>;
  devices_without_token: Nullable<Device[]>;
  failed_send_attempts: Nullable<DeviceError[]>;
  successful_send_attempts: Nullable<Device[]>;
}

export interface TablePageSize {
  id: string;
  pageSize: number;
}

export interface UserData {
  id: string;
  email: string;
  name: string;
  position: Nullable<string>;
  status: Status;
  otp_enabled: boolean;
  registered: string;
  last_login: Nullable<string>;
  android_devices: number;
  ios_devices: number;
  ipados_devices: number;
  macos_devices: number;
  windows_devices: number;
  linux_devices: number;
  role: 0 | 1 | 2 | 3;
  devices: DeviceUserEvaluation[];
  country: string;
  language: string;
}

export interface UserDataWithIssues extends UserData {
  issues: Issue[];
}

export interface AppIssue {
  issue_codename: string;
  issue_title: string;
  issue_description: string;
  kb_link: string;
  score: Nullable<number>;
  solution_description: string;
}

export interface AndroidApplicationsInfo {
  security_score: Nullable<number>;
  evaluated: Nullable<AppInfo[]>;
  issues: AppIssue[];
}

export interface Vulnerability {
  cve: string;
  cwe: string;
  score: number;
  description: string;
  published: Nullable<string>;
}

export interface Device {
  id: string;
  name: Nullable<string>;
  app_version: Nullable<string>;
  marketing_name: Nullable<string>;
  device: Nullable<string>;
  model: Nullable<string>;
  platform: Nullable<string>;
  manufacturer: Nullable<string>;
  build: Nullable<string>;
}

export interface DeviceError {
  device: Device;
  error: Nullable<string>;
}

export interface ProblematicApplication {
  package_name: string;
  security_score: number;
  version: Nullable<string>;
  update_date: number;
  maintained: boolean;
  malicious: boolean;
  installed_from: Nullable<string>;
  analysis_score: Nullable<number>;
  application_name: Nullable<string>;
}

export interface AppUpdate {
  updated: boolean;
  maintained: boolean;
  latest_available_version: string;
  latest_available_update_timestamp: number;
}

export interface AppAnalysis {
  security_score: number;
  trackers_detection: number;
  virustotal_detection: number;
}

export interface AppInfo {
  security_score: number;
  package_name: string;
  application_name: Nullable<string>;
  eval_category: 'risky' | 'in_verification' | 'verified' | 'system';
  update: Nullable<AppUpdate>;
  analysis: Nullable<AppAnalysis>;
  issues: string[];
  details: string[];
}

export interface EvaluatedApplication {
  security_score: number;
  package_name: string;
  latest_available_version: string;
  latest_available_update_timestamp: number;
  maintained: boolean;
  updated: boolean;
  mars_state: string;
  categories: string[];
  installed_from: Nullable<string>;
  analysis_score: Nullable<number>;
  application_name: Nullable<string>;
}

export interface NotEvaluatedApplication {
  package_name: string;
  security_score: number;
  version: Nullable<string>;
  install_timestamp: number;
  update_timestamp: number;
  installed_from: Nullable<string>;
  mars_state: string;
  categories: string[];
  analysis_score: Nullable<number>;
  application_name: Nullable<string>;
}

export interface DeviceApps {
  evaluated_applications: EvaluatedApplication[];
  not_evaluated_applications: NotEvaluatedApplication[];
}

export interface ProcessedAppInfo {
  problematic: ProblematicApplication[];
  third_party: NotEvaluatedApplication[];
  verified: EvaluatedApplication[];
  system: NotEvaluatedApplication[];
  in_verification: NotEvaluatedApplication[];
  unupdated: EvaluatedApplication[];
  updated_count: number;
  unupdated_count: number;
}

export type DeviceStatus = 'active' | 'disabled' | 'pending' | 'archived';

export interface DeviceEvaluation {
  id: string;
  latest_available_app_version: Nullable<string>;
  created: string;
  invoked_by: Nullable<'user' | 'automatic'>;
  supported: Nullable<boolean>;
  global_score: number;
  device_score: Nullable<number>;
  applications_score: Nullable<number>;
  privacy_score: Nullable<number>;
  network_score: Nullable<number>;
  name: Nullable<string>;
  app_version: Nullable<string>;
  marketing_name: string;
  device: string;
  model: string;
  platform: string;
  manufacturer: string;
  build: Nullable<string>;
  status: DeviceStatus;
  device_supported: Nullable<boolean>;
}

export interface DeviceUserEvaluation extends DeviceEvaluation {
  user_id: Nullable<string>;
  user_email: Nullable<string>;
  user_name: Nullable<string>;
}

export interface DeviceScore {
  marketing_name: string;
  name: string;
  model: string;
  platform: string;
  manufacturer: string;
  device: string;
  end_of_life?: Nullable<string>;
  status: DeviceStatus;
  recommendations: DeviceRecommendation[];
}

export interface DeviceSettings {
  security_score: Nullable<number>;
  security_system: Nullable<'none' | 'pin' | 'fingerprint' | 'face_id'>;
  rooted: Nullable<boolean>;
  jailbreak?: Nullable<boolean>;
  nfc_status: Nullable<boolean>;
  bluetooth_status: Nullable<boolean>;
  usb_debug_status: Nullable<boolean>;
  firewall?: Nullable<boolean>;
  antivirus?: Nullable<{
    installed_antivirus: Nullable<{ display_name: string }[]>;
    is_enabled: Nullable<boolean>;
    security_score: Nullable<number>;
  }>;
  device_settings?: Nullable<{
    bitlocker: Nullable<boolean>;
    has_password: Nullable<boolean>;
    security_score: Nullable<number>;
  }>;
  face_id: Nullable<boolean>;
  retina_auth: Nullable<boolean>;
  secured_by_pin: Nullable<boolean>;
  fingerprint: Nullable<boolean>;
  recommendations: DeviceRecommendation[];
}

export interface DevicesStats {
  ios: PlatformStats;
  android: PlatformStats;
  windows: PlatformStats;
  ipados: PlatformStats;
  macos: PlatformStats;
  linux: PlatformStats;
}

export interface Dns {
  hostname: string;
}

export interface GlobalScore {
  security_score: number;
  device_score: number;
  privacy_score: Nullable<number>;
  network_score: Nullable<number>;
  apps_score: Nullable<number>;
  platform_score: Nullable<number>;
  os_settings_score: Nullable<number>;
  network_settings_score: Nullable<number>;
  network_traffic_score: Nullable<number>;
}

export interface IssueStats {
  current_severity_count: TotalSeverityCount;
  severity_counts: SeverityCount[];
}

export interface ShodanPortResult {
  port: number;
  timestamp: string;
  data?: Nullable<string>;
  transport: 'tcp' | 'udp';
  product?: Nullable<string>;
}

export interface Shodan80Result extends ShodanPortResult {
  ip: Nullable<number>;
  os: Nullable<string>;
  asn: Nullable<string>;
  cpe: Nullable<string[]>;
  isp: Nullable<string>;
  org: Nullable<string>;
  data: Nullable<string>;
  hash: Nullable<number>;
  http: Nullable<{
    host: Nullable<string>;
    html: Nullable<string>;
    title: Nullable<string>;
    robots: Nullable<string>;
    server: Nullable<string>;
    status: Nullable<number>;
    sitemap: Nullable<string>;
    securitytxt: Nullable<string>;
    location: Nullable<string>;
  }>;
  opts: Nullable<object>;
  cpe23: Nullable<string[]>;
  ip_str: Nullable<string>;
  domains: Nullable<string[]>;
}

export interface Shodan443Result extends Shodan80Result {
  ssl: Nullable<{
    alpn: Nullable<string[]>;
    cert: Nullable<{
      issued: Nullable<string>;
      issuer: Nullable<{
        C: string;
        O: string;
        CN: string;
      }>;
      expired: Nullable<boolean>;
      expires: Nullable<string>;
    }>;
    cipher: Nullable<{
      version: Nullable<string>;
      bits: Nullable<number>;
      name: Nullable<string>;
    }>;
    versions: Nullable<string[]>;
  }>;
  opts: Nullable<{
    vulns: Nullable<object>;
    heartbleed: Nullable<string>;
  }>;
}

export interface ShodanResult {
  area_code: Nullable<any>;
  asn: Nullable<string>;
  city: Nullable<string>;
  country_code: Nullable<string>;
  country_name: Nullable<string>;
  data: Nullable<ShodanPortResult[]>;
  domains: Nullable<string[]>;
  hostnames: Nullable<string[]>;
  ip: Nullable<number>;
  ip_str: Nullable<string>;
  isp: Nullable<string>;
  last_update: Nullable<string>;
  latitude: Nullable<number>;
  longitude: Nullable<number>;
  org: Nullable<string>;
  ports: Nullable<number[]>;
  region_code: Nullable<string>;
  tags: Nullable<string[]>;
}

export interface ExternalNetworkScan {
  created: string;
  public_ip: string;
  result_data: Nullable<ShodanResult>;
}

export interface DeviceOpenPort {
  port: number;
  protocol: 'TCP' | 'UDP' | 'TCP/UDP';
}

export interface NetworkDeviceSnapshot {
  created: string;
  ip_address: Nullable<string>;
  name: Nullable<string>;
  tags: string[];
  open_ports: DeviceOpenPort[];
}

export interface NetworkDevice {
  mac_address: string;
  manufacturer: Nullable<string>;
  description: Nullable<string>;
  last_seen: string;
  snapshots: NetworkDeviceSnapshot[];
}

export interface NetworkInfo {
  security_score: Nullable<number>;
  latest_network_scan: NetworkScanInfo[];
  recommendations: DeviceRecommendation[];
}

export interface NetworkScanInfo {
  name: string;
  created: string;
  score: Nullable<number>;
  vpn: Nullable<string>;
  dns: Nullable<string[]>;
  dns_score: Nullable<number>;
  proxies: Nullable<string[]>;
  proxies_score: Nullable<number>;
  traffic_score: Nullable<number>;
  default_gateway_ip: Nullable<string[]>;
}

export interface TotalSeverityCount {
  critical: number;
  high: number;
  medium: number;
  low: number;
}

export interface SeverityCount {
  date: string;
  total: number;
  critical: number;
  high: number;
  medium: number;
  low: number;
}

export interface WindowsPendingUpdate {
  title: string;
  type: 'utDriver' | 'utSoftware';
  description: string;
  identity: string;
  is_mandatory: boolean;
  download_priority: 'dpLow' | 'dpNormal' | 'dpHigh' | 'dpExtraHigh';
}

export interface OsInfo {
  version: string;
  patch: string;
  patch_type: 'beta' | 'dev';
  security_score: number;
  patched_vulnerabilities: number;
  unpatched_vulnerabilities: number;
  applied_patches: number;
  unapplied_patches: number;
  latest_available_patch: string;
  supported: boolean;
  // Windows specific
  architecture?: Nullable<string>;
  bitlocker?: Nullable<boolean>;
  boot_required?: Nullable<boolean>;
  current_build?: Nullable<string>;
  edition?: Nullable<string>;
  is_process_64_bit?: Nullable<boolean>;
  is_system_64_bit?: Nullable<boolean>;
  last_update_installation?: Nullable<string>;
  last_update_search?: Nullable<string>;
  pending_updates?: Nullable<WindowsPendingUpdate[]>;
  service_pack?: Nullable<string>;
  recommendations: DeviceRecommendation[];
}

export interface PlatformStats {
  devices_count: number;
  total_issues: number;
  avg_score: number;
}

export interface Proxy {
  hostname: string;
  port: number;
}

export interface RiskScore {
  date: Nullable<string>;
  global_score: Nullable<number>;
  application_score: Nullable<number>;
  os_score: Nullable<number>;
  settings_score: Nullable<number>;
}

export interface FirmIssues {
  android_issues: number;
  ios_issues: number;
}

export interface FirmDevices {
  scaned_devices: number;
  unupdated: number;
  rooted: number;
  count_android_devices: number;
  count_ios_devices: number;
  count_ipados_devices: number;
  count_macos_devices: number;
  count_windows_devices: number;
}

export interface SecurityEvaluation {
  app_version: string;
  data_version: string;
  invoked_by: 'user' | 'background' | 'unknown';
  os: OsInfo;
  device: DeviceScore;
  device_settings: DeviceSettings;
  network: NetworkInfo;
  applications: { security_score: Nullable<number> };
  global_score: GlobalScore;
  pending_updates: Nullable<AndroidPendingUpdates>;
  user: {
    email: string;
    id: string;
    name: string;
  };
}

export interface VerificationWithStatus {
  api_key: string;
  android_app_url: string;
  ios_app_url: string;
  base_url: string;
  status: Status;
  is_password_set: boolean;
  email: string;
}

export interface IssueSolution {
  platform: string;
  description: string;
  created: string;
}

export type IssueCategory = 'user' | 'location' | 'platform' | 'app';

// eslint-disable-next-line max-len
export type IssueCodename =
  | 'safe_patch'
  | 'safe_patch_windows'
  | 'missing_installable_patch'
  | 'missing_patch_windows'
  | 'missing_uninstallable_patch'
  | 'beta_version'
  | 'dev_version'
  | 'vendor_updates_installed'
  | 'vendor_updates_pending'
  | 'vendor_updates_unknown'
  | 'supported_os'
  | 'supported_os_windows'
  | 'unsupported_os'
  | 'unsupported_os_windows'
  | 'supported_device'
  | 'unsupported_device'
  | 'not_rooted'
  | 'rooted'
  | 'not_jailbroken'
  | 'jailbroken'
  | 'screen_lock_safe'
  | 'missing_biometrics'
  | 'no_screen_lock'
  | 'bluetooth_off'
  | 'bluetooth_on'
  | 'nfc_off'
  | 'nfc_on'
  | 'usb_debug_off'
  | 'usb_debug_on'
  | 'proxy_safe'
  | 'proxy_open'
  | 'vpn_off'
  | 'vpn_on'
  | 'dns_public'
  | 'dns_unsafe'
  | 'network_secured'
  | 'network_security_unknown'
  | 'network_security_warning'
  | 'network_security_risk'
  | 'traffic_secured'
  | 'traffic_unknown'
  | 'traffic_warning'
  | 'traffic_risk'
  | 'email_scan_safe'
  | 'email_scan_unknown'
  | 'email_scan_warning'
  | 'email_scan_risk'
  | 'disk_encrypted_windows'
  | 'disk_unencrypted_windows'
  | 'safe_driver_update_windows'
  | 'missing_driver_update_windows'
  | 'safe_system_sw_update'
  | 'missing_system_sw_update'
  | 'antivirus_enabled'
  | 'antivirus_not_active'
  | 'safe_storages'
  | 'unknown_storages'
  | 'warning_storages'
  | 'risk_storages'
  | 'uptime_safe'
  | 'uptime_unknown'
  | 'uptime_warning'
  | 'uptime_risk'
  | 'no_available_update_apps'
  | 'available_update_apps'
  | 'app_discontinued'
  | 'app_malicious'
  | 'app_not_maintained'
  | 'app_not_recommended'
  | 'app_not_updated'
  | 'app_progressive_web_app'
  | 'app_third_party'
  | 'app_discontinued_aggregated'
  | 'app_malicious_aggregated'
  | 'app_not_maintained_aggregated'
  | 'app_not_recommended_aggregated'
  | 'app_not_updated_aggregated'
  | 'app_progressive_web_app_aggregated'
  | 'app_third_party_aggregated';

export type IssueObjectType = 'network' | 'device' | 'client' | 'email' | 'network_device' | 'user';

export interface Issue {
  title: string;
  description: string;
  codename: IssueCodename;
  object_type: IssueObjectType;
  score: Nullable<number>;
  category: IssueCategory;
  knowledge_base_url: string;
  n_affected_objects: number;
  n_ignored_objects: number;
  recommendations: Recommendation[];
}

export type RecommendationCodename =
  | 'safe_patch_android'
  | 'safe_patch_ios'
  | 'safe_patch_ipados'
  | 'safe_patch_windows'
  | 'missing_installable_patch_android'
  | 'missing_installable_patch_ios'
  | 'missing_installable_patch_ipados'
  | 'missing_patch_windows'
  | 'missing_uninstallable_patch_android'
  | 'beta_version_android'
  | 'beta_version_ios'
  | 'beta_version_ipados'
  | 'dev_version_android'
  | 'dev_version_ios'
  | 'dev_version_ipados'
  | 'vendor_updates_installed_android'
  | 'vendor_updates_pending_android'
  | 'vendor_updates_unknown_android'
  | 'supported_os_android'
  | 'supported_os_ios'
  | 'supported_os_ipados'
  | 'supported_os_windows'
  | 'unsupported_os_android'
  | 'unsupported_os_ios'
  | 'unsupported_os_ipados'
  | 'unsupported_os_windows'
  | 'supported_device_android'
  | 'supported_device_ios'
  | 'supported_device_ipados'
  | 'unsupported_device_android'
  | 'unsupported_device_ios'
  | 'unsupported_device_ipados'
  | 'not_rooted_android'
  | 'rooted_android'
  | 'not_jailbroken_ios'
  | 'not_jailbroken_ipados'
  | 'jailbroken_ios'
  | 'jailbroken_ipados'
  | 'screen_lock_safe_ios'
  | 'screen_lock_safe_ipados'
  | 'screen_lock_safe_android'
  | 'missing_biometrics_ios'
  | 'missing_biometrics_ipados'
  | 'missing_biometrics_android'
  | 'no_screen_lock_ios'
  | 'no_screen_lock_ipados'
  | 'no_screen_lock_android'
  | 'bluetooth_off_android'
  | 'bluetooth_off_ios'
  | 'bluetooth_off_ipados'
  | 'bluetooth_on_android'
  | 'bluetooth_on_ios'
  | 'bluetooth_on_ipados'
  | 'nfc_off_android'
  | 'nfc_off_ios'
  | 'nfc_on_android'
  | 'nfc_on_ios'
  | 'usb_debug_off_android'
  | 'usb_debug_off_ios'
  | 'usb_debug_on_android'
  | 'usb_debug_on_ios'
  | 'proxy_safe'
  | 'proxy_open'
  | 'vpn_off'
  | 'vpn_on'
  | 'dns_public'
  | 'dns_unsafe'
  | 'network_secured'
  | 'network_security_unknown'
  | 'network_security_warning'
  | 'network_security_risk'
  | 'traffic_secured'
  | 'traffic_unknown'
  | 'traffic_warning'
  | 'traffic_risk'
  | 'email_scan_safe'
  | 'email_scan_unknown'
  | 'email_scan_warning'
  | 'email_scan_risk'
  | 'disk_encrypted_windows'
  | 'disk_unencrypted_windows'
  | 'safe_driver_update_windows'
  | 'missing_driver_update_windows'
  | 'safe_system_sw_update'
  | 'missing_system_sw_update'
  | 'antivirus_enabled'
  | 'antivirus_not_active'
  | 'safe_storages'
  | 'unknown_storages'
  | 'warning_storages'
  | 'risk_storages'
  | 'uptime_safe'
  | 'uptime_unknown'
  | 'uptime_warning'
  | 'uptime_risk'
  | 'no_available_update_apps'
  | 'available_update_apps'
  | 'app_discontinued'
  | 'app_malicious'
  | 'app_not_maintained'
  | 'app_not_recommended'
  | 'app_not_updated'
  | 'app_progressive_web_app'
  | 'app_third_party'
  | 'app_discontinued_aggregated'
  | 'app_malicious_aggregated'
  | 'app_not_maintained_aggregated'
  | 'app_not_recommended_aggregated'
  | 'app_not_updated_aggregated'
  | 'app_progressive_web_app_aggregated'
  | 'app_third_party_aggregated';

export type RecommendationCategory =
  | 'android'
  | 'ios'
  | 'ipados'
  | 'macos'
  | 'windows'
  | 'linux'
  | 'network'
  | 'email'
  | 'user'
  | 'client';

export interface Recommendation {
  codename: RecommendationCodename;
  description: string;
  category: RecommendationCategory;
  resolvable: boolean;
  n_affected_objects: string;
}

export interface RecommendationDetail {
  codename: RecommendationCodename;
  description: string;
  category: RecommendationCategory;
  resolvable: boolean;
  affected_objects:
    | DeviceUserEvaluation[]
    | NetworkScanWithoutUsers[]
    | UserData[]
    | Client[]
    | AffectedEmail[]
    | NetworkDevice[];
  ignored_objects:
    | DeviceUserEvaluation[]
    | NetworkScanWithoutUsers[]
    | UserData[]
    | Client[]
    | AffectedEmail[]
    | NetworkDevice[];
}

export interface IssueDetail {
  title: string;
  description: string;
  codename: IssueCodename;
  object_type: IssueObjectType;
  score: Nullable<number>;
  category: IssueCategory;
  knowledge_base_url: string;
  affected_objects:
    | DeviceUserEvaluation[]
    | NetworkScanWithoutUsers[]
    | UserData[]
    | Client[]
    | AffectedEmail[]
    | NetworkDevice[];
  ignored_objects:
    | DeviceUserEvaluation[]
    | NetworkScanWithoutUsers[]
    | UserData[]
    | Client[]
    | AffectedEmail[]
    | NetworkDevice[];
  recommendations: RecommendationDetail[];
}

export interface CVEIssue {
  score: number;
  description: string;
  published: string;
  cve: string;
  device?: string;
  solution: string;
  related_to: {
    os: boolean;
    vendor: boolean;
    user: boolean;
  };
  affected_devices: DeviceUserEvaluation[];
}

export interface ClientCount {
  count: number;
}

export interface Settings {
  language: 'en' | 'de';
}

export interface ErrorFields {
  detail: string;
}

export interface APIError {
  code?: number;
  description?: string;
}

export interface QRCodeAPIData {
  api_key: string;
  endpoint_url: string;
}

export interface EmailSendResponse {
  successfully_send: string[];
  unsuccessfully_send: { email: string; error: string }[];
}

export interface NotificationSender {
  id: string;
  email: string;
  name: string;
  position: string;
  status: 'active' | 'disabled' | 'pending';
  registered: string;
  role: Role;
}

export interface Notification {
  id: string;
  created: string;
  modified: Nullable<string>;
  title: Nullable<string>;
  message: Nullable<string>;
  notification_type: Nullable<string>;
  data: Nullable<Record<string, unknown>>;
  custom_data: Nullable<Record<string, unknown>>;
}

export enum NotificationType {
  CUSTOM_NOTIFICATION = 'custom-notification',
  BACKGROUND_SCAN = 'background-scan',
  BACKGROUND_SCAN_DONE = 'background-scan-done',
  PATCH = 'patch',
  VENDOR_UPDATES = 'vendor_updates',
  OS_END_OF_LIFE = 'os_end_of_life',
  DEVICE_END_OF_LIFE = 'device_end_of_life',
  ROOTED = 'rooted',
  JAILBROKEN = 'jailbroken',
  SCREEN_LOCK = 'screen_lock',
  BLUETOOTH = 'bluetooth',
  USB_DEBUG = 'usb_debug',
  EMAIL_SCAN_DONE = 'email-scan-done',
  NEW_SECURITY_PATCH = 'new-security-patch',
  GENERAL_RECOMMENDATION = 'general-recommendations',
  UNSCANNED_DEVICE = 'unscanned-device',
  SCHEDULED_RECOMMENDATION = 'scheduled-recommendations',
  SAFE_NETWORKS_CHANGED = 'safe-networks-changed',
  WIN_CMD_NETWORK_DEVICE_SCAN = 'win_cmd_network_device_scan',
  NEW_QUIZ_ASSIGNMENT = 'new-quiz-assignment'
}

export enum NotificationState {
  FAILED = 'failed',
  SEND = 'send',
  RECEIVED = 'received',
  READ = 'read'
}

export interface NotificationDevice {
  id: string;
  model: Nullable<string>;
  marketing_name: Nullable<string>;
  name: Nullable<string>;
  platform: Nullable<string>;
  manufacturer: Nullable<string>;
  owner: { id: string; email: string };
}

export interface SuccessfulNotificationResult {
  notification_log_id: string;
  notification_state: NotificationState;
  message_id: string;
  device: NotificationDevice;
}

export interface FailedNotificationResult {
  notification_log_id: string;
  notification_state: NotificationState;
  error: string;
  error_message: string;
  device: NotificationDevice;
}

export type NotificationSeverity = 'unknown' | 'safe' | 'warning' | 'risk';

export interface NotificationLog {
  batch_id: string;
  created: string;
  title: Nullable<string>;
  body: Nullable<string>;
  notification_type: NotificationType;
  severity: Nullable<NotificationSeverity>;
  sender: Nullable<{ id: string; email: string }>;
  successful_sends: SuccessfulNotificationResult[];
  failed_sends: FailedNotificationResult[];
}

export interface CellularNetwork {
  telecom_name: Nullable<string>;
  created: Nullable<string>;
  score: Nullable<number>;
  vpn: Nullable<boolean>;
  dns: Nullable<Dns[]>;
  dns_score: Nullable<number>;
  proxies: Nullable<Proxy[]>;
  proxies_score: Nullable<number>;
  traffic_score: Nullable<number>;
  recommendations: DeviceRecommendation[];
  network_settings_score: Nullable<number>;
}

export interface Network {
  vpn: Nullable<boolean>;
  score: number;
  network_security: Nullable<number>;
  network_connection_type: 'wifi' | 'cellular';
  created: string;
}

export interface WifiInfo {
  id: string;
  vpn: Nullable<boolean>;
  score: number;
  default_gateway_ip: Nullable<string[]>;
  dns: Nullable<string[]>;
  scan_created: string;
}

export interface WifiNetwork {
  ssid: Nullable<string>;
  score: Nullable<number>;
  created: Nullable<string>;
  vpn: Nullable<boolean>;
  dns: Nullable<Dns[]>;
  dns_score: Nullable<number>;
  proxies: Nullable<Proxy[]>;
  proxies_score: Nullable<number>;
  traffic_score: Nullable<number>;
  network_security: Nullable<string>;
  network_security_score: Nullable<number>;
  recommendations: DeviceRecommendation[];
  network_settings_score: Nullable<number>;
}

export interface UnidentifiedWifi {
  score: number;
  created: string;
  vpn: Nullable<boolean>;
  dns: Nullable<Dns[]>;
  dns_score: Nullable<number>;
  proxies: Nullable<Proxy[]>;
  proxies_score: Nullable<number>;
  default_gateway_ip: Nullable<string[]>;
  network_security: Nullable<string>;
  network_security_score: Nullable<number>;
}

export interface EthernetInfo {
  id: string;
  score: number;
  vpn: Nullable<boolean>;
  default_gateway_ip: Nullable<string[]>;
  dns: Nullable<string[]>;
  proxies: Nullable<string[]>;
  scan_created: string;
}

export interface EthernetNetwork {
  ssid: Nullable<string>;
  created: Nullable<string>;
  score: Nullable<number>;
  vpn: Nullable<boolean>;
  dns: Nullable<Dns[]>;
  dns_score: Nullable<number>;
  proxies: Nullable<Proxy[]>;
  proxies_score: Nullable<number>;
  traffic_score: Nullable<number>;
  recommendations: DeviceRecommendation[];
  network_settings_score: Nullable<number>;
}

export type NetworkType = 'wifi' | 'cellular' | 'ethernet' | 'unknown';

// eslint-disable-next-line max-len
export type ClientNetworkType =
  | 'third_party_network'
  | 'office_network'
  | 'remote_office_network'
  | 'home_office_network';

export interface NetworkUser {
  id: string;
  email: string;
  name: string;
  last_scan_done: string;
  number_of_devices: number;
  devices: DeviceEvaluation[];
}

export type NetworkProtocol = 'TCP' | 'UDP' | 'TCP/UDP';

export interface FirewallCheck {
  port: number;
  protocol: NetworkProtocol;
  name: string;
  type: string;
  severity: 'high' | 'medium' | 'low' | 'unknown';
  blocked: boolean;
}

export interface PublicIP {
  ipv4: Nullable<string>;
  ipv6: Nullable<string>;
}

export interface NetworkScan {
  id: Nullable<string>;
  name: Nullable<string>;
  last_scan_done: string;
  network_type: NetworkType;
  client_type: ClientNetworkType;
  score: Nullable<number>;
  vpn: Nullable<string>;
  dns: Nullable<string[]>;
  dns_score: Nullable<number>;
  proxies: Nullable<string[]>;
  proxies_score: Nullable<number>;
  traffic_score: Nullable<number>;
  firewall_score: Nullable<number>;
  firewall_check: Nullable<FirewallCheck[]>;
  default_gateway_ip: Nullable<string[]>;
  network_settings_score: Nullable<number>;
  recommendations: DeviceRecommendation[];
  public_ip: Nullable<PublicIP>;
  users: NetworkUser[];
  external_scan_approval: Nullable<'granted' | 'rejected'>;
  is_safe: boolean;
}

export interface NetworkScanWithoutUsers {
  id: Nullable<string>;
  name: Nullable<string>;
  last_scan_done: string;
  network_type: NetworkType;
  client_type: ClientNetworkType;
  score: Nullable<number>;
  vpn: Nullable<string>;
  dns: Nullable<string[]>;
  dns_score: Nullable<number>;
  proxies: Nullable<string[]>;
  proxies_score: Nullable<number>;
  traffic_score: Nullable<number>;
  default_gateway_ip: Nullable<string[]>;
  network_settings_score: Nullable<number>;
  recommendations: DeviceRecommendation[];
  public_ip: PublicIP;
  is_safe: boolean;
}

export interface PrivacyInfo {
  security_score: number;
  email_scans: EmailScan[];
  recommendations: DeviceRecommendation[];
}

export interface UserEmailScan {
  id: string;
  email: string;
  scan_requested: string;
  scan_done: Nullable<string>;
  breaches: EmailBreach[];
  score: Nullable<number>;
  data_classes: Nullable<string[]>;
  top_secret_data_classes: Nullable<string[]>;
  secret_data_classes: Nullable<string[]>;
  confidential_data_classes: Nullable<string[]>;
  sensitive_data_classes: Nullable<string[]>;
  restricted_data_classes: Nullable<string[]>;
  protected_data_classes: Nullable<string[]>;
  not_categorized_data_classes: Nullable<string[]>;
}

export interface EmailScan extends UserEmailScan {
  users: {
    id: string;
    name: string;
    email: string;
  }[];
}

export interface AffectedEmail {
  id: string;
  email: string;
  scan_requested: string; // date-time
  scan_done: Nullable<string>; // date-time
  n_breaches: number;
  users: {
    id: string;
    name: string;
    email: string;
  }[];
}

export interface ProcessingEmailScan {
  id: string;
  email: string;
  scan_requested: string;
}

export interface EmailBreach {
  id: string;
  name: string;
  title: string;
  domain: string;
  breach_date: string;
  added_date: string;
  modified_date: string;
  pwn_count: number;
  description: string;
  data_classes: string[];
  top_secret_data_classes: string[];
  secret_data_classes: string[];
  classified_data_classes: string[];
  confidential_data_classes: string[];
  sensitive_data_classes: string[];
  restricted_data_classes: string[];
  protected_data_classes: string[];
  not_categorized_data_classes: string[];
  is_verified: boolean;
  is_fabricated: boolean;
  is_sensitive: boolean;
  is_retired: boolean;
  is_spam_list: boolean;
  logo_path: string;
  status: 'active' | 'resolved';
}

export interface EmailBreachScan {
  breaches: EmailBreach[];
  security_score: number;
  finished_email_scans: EmailScan[];
  processing_email_scans: ProcessingEmailScan[];
}

export interface UserEmailBreachScan {
  security_score: number;
  finished_email_scans: UserEmailScan[];
  processing_email_scans: ProcessingEmailScan[];
}

export interface AppScanDetection {
  /** Total */
  total: number;
  /** Detected */
  detected: number;
}

export interface AppScanPermission {
  id?: number;
  name: string;
  status: string;
  info?: string;
  description?: string;
}

export interface SimpleAppScan {
  package_name: string;
  app_name: string;
  app_version: string;
  avg_cvss?: number;
  security_score?: number;
  trackers_detection?: AppScanDetection;
  virus_detection?: AppScanDetection;
  /** Permissions */
  permissions?: AppScanPermission[];
}

export interface UserImportError {
  key: string;
  message: string;
}

export interface UserInfo {
  id: Nullable<string>;
  name: string;
  email: string;
}

export interface ImportUser {
  id: string;
  username: string;
  // role: 'user' | 'administrator',
  role: string;
  email: string;
  position?: string;
  country: string;
  language: string;
  errors?: UserImportError[];
}

export interface ImportUserForExport {
  username: string;
  // 'user' = 1 | 'administrator' = 2 | 'firmadmin' = 3,
  role: number;
  email: string;
  position?: string;
  language: string;
  country: string;
  errors?: UserImportError[];
}

export interface PartnerAdminImport {
  email: string;
  username: Nullable<string>;
  position: Nullable<string>;
}

export interface DeviceRecommendation {
  group: string;
  group_codename: string;
  title: string;
  codename: string;
  issue_description: string;
  issue_category: 'user' | 'location' | 'platform' | 'app';
  recommendation_description: string;
  recommendation_category: 'android' | 'ios' | 'ipados' | 'windows' | 'macos' | 'linux' | 'network' | 'email';
  recommendation_type: string;
  resolvable: boolean;
  score: number;
  severity: 'unknown' | 'safe' | 'warning' | 'risk';
  knowledge_base_url: string;
}

export interface AndroidAppIcons {
  package_icons: Record<string, Nullable<string>>;
}

export interface QuizChoice {
  text: string;
  is_correct: boolean;
}

export type QuestionType = 'single_correct' | 'multiple_correct';

export interface QuizQuestion {
  text: string;
  takeaway: string;
  question_type: QuestionType;
  choices: QuizChoice[];
  max_score: number;
}

export interface Quiz {
  id: string;
  title: string;
  description: string;
  questions: QuizQuestion[];
  max_score: number;
  keywords: string[];
  created: string;
}

export type QuizAssignmentStatus = 'assigned' | 'in_progress' | 'completed';

export interface AssignedUser {
  user: {
    id: string;
    name: string;
    email: string;
  };
  status: QuizAssignmentStatus;
  answers: QuizAnswer[];
  score: number;
  completion_date: Nullable<string>;
  success_rate: number;
}

export interface QuizAssignment {
  id: string;
  created: string;
  quiz: Quiz;
  assigned_users: AssignedUser[];
}

export interface QuizAnswer {
  question_indx: number;
  choice_indx: number;
  answer_score: number;
  is_answer_correct: boolean;
  spent_time_ms: number;
}

export interface UserQuizAssignment {
  user: {
    id: string;
    name: string;
    email: string;
  };
  quiz: Quiz;
  status: QuizAssignmentStatus;
  answers: QuizAnswer[];
  completion_date: Nullable<string>;
  score: number;
  assignment_token: string;
  quiz_assignment_id: string;
  success_rate: Nullable<number>;
  created: string;
}

export interface GoogleWorkspaceUser {
  email: string;
  is_admin: boolean;
  is_suspended: boolean;
  is_archived: boolean;
}

export interface IssueIgnoreCodename {
  codename: string;
  object_ids: string[] | 'all';
}

export interface IAddress {
  country: string;
  street: string;
  city: string;
  zip_code: string;
  state: string;
}
