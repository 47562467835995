import { RPage, VStack } from '@ramp/components';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import NoDataPage from 'components/NoDataPage';
import { useAuthStore, useLayoutStore } from 'store';
import { APIError, Issue } from 'types';
import { PRODUCT_NAME } from 'utils/constants';
import useOnQueryError from 'utils/hooks/useOnQueryError';

import BasicIssuesCard from './BasicIssuesCard';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IssuesProps {}

const IssuesList: React.FC<IssuesProps> = () => {
  const { t } = useTranslation();
  const user = useAuthStore(store => store.user!);
  const { noUsersInCompany, noScansInCompany } = useLayoutStore(store => store);

  const startDate = useMemo(() => dayjs().add(-1, 'year').format('YYYY-MM-DD'), []);
  const endDate = useMemo(() => dayjs().format('YYYY-MM-DD'), []);

  const issuesQuery = useQuery<Issue[], APIError>({
    queryKey: ['issues', user.user_id],
    queryFn: () =>
      api.users.getIssues(user.user_id, {
        start_date: startDate,
        end_date: endDate
      })
  });

  useOnQueryError(issuesQuery, {
    title: t('admin.issues.notification.titleError')
  });

  if (noScansInCompany) {
    return (
      <NoDataPage
        heading={t('admin.issues.heading')}
        pageLoading={issuesQuery.isLoading}
        noUsersInCompany={noUsersInCompany}
        noScansInCompany={noScansInCompany}
      />
    );
  }

  return (
    <RPage
      px={6}
      pt={8}
      loading={false}
      heading={t('admin.issues.heading')}
      title={t('admin.issues.title', { productName: PRODUCT_NAME })}
    >
      <VStack w="full" spacing={0}>
        <BasicIssuesCard issues={issuesQuery.data} isLoading={issuesQuery.isLoading} />
      </VStack>
    </RPage>
  );
};

export default IssuesList;
