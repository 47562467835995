import { Heading, HStack, RCard, Search, useColorModeValue, VStack } from '@ramp/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';

import WindowsAppsTable from 'components/tables/WindowsAppsTable';
import { WindowsApp } from 'types/api/devices';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SystemAppsProps {
  system_apps: WindowsApp[];
}

const SystemApps: React.FC<SystemAppsProps> = ({ system_apps }) => {
  const { t } = useTranslation();
  const [searchInApps, setSearchInApps] = useState<string>('');

  return (
    <VStack w="full" spacing={3}>
      <HStack w="full" justify="space-between" align="end">
        <Element name="system_apps">
          <Heading
            mt="2rem !important"
            mb="0.25rem !important"
            color={useColorModeValue('black', 'gray.400')}
            fontSize="1.5rem"
            fontWeight={600}
          >
            {t('admin.devices.detail.apps.systemApps')}
          </Heading>
        </Element>
        <Search
          w="360px"
          size="sm"
          value={searchInApps}
          // eslint-disable-next-line max-len
          placeholder={`${t('admin.devices.detail.apps.risky.searchIn')} ${t('admin.devices.detail.apps.apps')}`}
          onChange={e => setSearchInApps(e.target.value)}
        />
      </HStack>

      <RCard w="full">
        <VStack w="full" spacing={4}>
          <WindowsAppsTable id="windows_apps" apps={system_apps || []} searchFor={searchInApps} />
        </VStack>
      </RCard>
    </VStack>
  );
};

export default SystemApps;
