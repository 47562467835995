import { Box, Grid, Heading, HStack, RCard, Text, useColorModeValue, VStack } from '@ramp/components';
import { AnimatePresence, motion } from 'framer-motion';
import { ChevronDown } from 'lucide-react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SensorPermissionsCard from 'components/SensorPermissionsCard';
import BasicDeviceIssuesTable from 'components/tables/BasicDeviceIssuesTable';
import DeviceAdvancedIssuesTable from 'components/tables/DeviceAdvancedIssuesTable';
import { AndroidScan } from 'types/api/devices';

import { useDeviceDetail } from '../../DeviceDetailProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface OverviewTabProps {}

const OverviewTab: React.FC<OverviewTabProps> = () => {
  const { t } = useTranslation();
  const { scan, recommendations, cveIssues, platform } = useDeviceDetail();
  if (!scan || !recommendations) return null;

  const [showAdvancedIssues, setShowAdvancedIssues] = useState(false);

  const filteredRecommendations = recommendations?.filter(rec => rec.severity !== 'safe');

  return (
    <Box w="full">
      {/* Risk Score Evaluation section */}
      <VStack w="full" align="start" spacing="2rem">
        <Grid w="full" templateColumns={!['macos', 'windows'].includes(platform || '') ? '18fr 6fr' : '1fr'} gap={8}>
          <Box w="full">
            <Heading color={useColorModeValue('black', 'gray.400')} fontSize="1.5rem" fontWeight={600}>
              {t('admin.devices.detail.overview.basicIssues')}
            </Heading>
            <RCard w="full" mt="1rem !important">
              <BasicDeviceIssuesTable
                id="device_detail_basic_issues"
                defaultPageSize={10}
                issues={filteredRecommendations}
              />
            </RCard>
          </Box>
          {!['macos', 'windows'].includes(platform || '') && (
            <Box w="full">
              <Heading color={useColorModeValue('black', 'gray.400')} fontSize="1.5rem" fontWeight={600}>
                {t('admin.devices.detail.overview.sensorPermissions')}
              </Heading>
              <SensorPermissionsCard platform={platform} permissions={(scan as AndroidScan).permissions} />
            </Box>
          )}
        </Grid>

        <Box w="full">
          <HStack
            mb={4}
            w="fit-content"
            cursor="pointer"
            alignItems="center"
            color={useColorModeValue('black', 'gray.400')}
            onClick={() => setShowAdvancedIssues(prev => !prev)}
          >
            <Heading color={useColorModeValue('black', 'gray.400')} fontSize="1.5rem" fontWeight={600}>
              {t('admin.devices.detail.overview.advancedIssues')}
            </Heading>
            <ChevronDown
              size={32}
              strokeWidth={3}
              style={{
                transition: 'all .2s ease-in-out',
                transform: showAdvancedIssues ? 'rotate(-180deg)' : 'none'
              }}
            />
          </HStack>
          <AnimatePresence>
            {showAdvancedIssues && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0, y: 20 }}
                style={{ width: '100%' }}
              >
                <RCard w="full" mt="1rem !important">
                  <DeviceAdvancedIssuesTable
                    id="device_detail_advanced_issues"
                    defaultPageSize={10}
                    issues={cveIssues}
                  />
                </RCard>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {!showAdvancedIssues && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2 }}
                exit={{ opacity: 0, y: 20 }}
                style={{ width: '100%' }}
              >
                <VStack
                  w="full"
                  h="120px"
                  cursor="pointer"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="lg"
                  transition="all .2s ease-in-out"
                  bg={useColorModeValue('white', 'gray.750')}
                  _hover={{
                    bg: useColorModeValue('gray.10', 'gray.700')
                  }}
                  onClick={() => setShowAdvancedIssues(true)}
                >
                  <Text
                    fontSize="1rem"
                    fontWeight={500}
                    transition="all .2s ease-in-out"
                    color={useColorModeValue('gray.400', 'gray.500')}
                  >
                    {t('admin.devices.detail.overview.showAdvancedIssues')}
                  </Text>
                </VStack>
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      </VStack>
    </Box>
  );
};

export default OverviewTab;
