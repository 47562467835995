import { Tag } from '@ramp/components';
import { AtSign, Building2, CircleHelp, Globe, LayoutGrid, Monitor, Network, User } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IssueObjectType } from 'types';

interface IssueObjectTypeTagProps {
  type: IssueObjectType;
}

const IssueObjectTypeTag: React.FC<IssueObjectTypeTagProps> = ({ type }) => {
  const { t } = useTranslation();

  if (!type) return <Tag colorScheme="error">{t('components.networkType.unknown')}</Tag>;

  if (type === 'network') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap" paddingY={2}>
        <Globe size="16px" />
      </Tag>
    );
  }

  if (type === 'device') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap" paddingY={2}>
        <Monitor size="16px" />
      </Tag>
    );
  }

  if (type === 'client') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap" paddingY={2}>
        <Building2 size="16px" />
      </Tag>
    );
  }

  if (type === 'email') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap" paddingY={2}>
        <AtSign size="16px" />
      </Tag>
    );
  }

  if (type === 'network_device') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap" paddingY={2}>
        <Network size="16px" />
      </Tag>
    );
  }

  if (type === 'user') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap" paddingY={2}>
        <User size="16px" />
      </Tag>
    );
  }

  if (type === 'app') {
    return (
      <Tag colorScheme="gray" whiteSpace="nowrap" paddingY={2}>
        <LayoutGrid size="16px" />
      </Tag>
    );
  }

  return (
    <Tag colorScheme="gray" whiteSpace="nowrap" paddingY={2}>
      <CircleHelp size="16px" />
    </Tag>
  );
};

export default IssueObjectTypeTag;
