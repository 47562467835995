import { ColumnsType, Flex, HStack, RCard, SeverityNumTag, SimpleTable, Tag, Text } from '@ramp/components';
import { themeColor } from '@ramp/theme';
import dayjs from 'dayjs';
import { AlertTriangle, Network, Signal, Wifi } from 'lucide-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NetworkScan } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import { serializeNetworkType } from 'utils/serializators';

import { useDeviceDetail } from '../../DeviceDetailProvider';
import NetworkDetail from './NetworkDetail';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NetworksTabProps {}

interface NetworkScanRow extends NetworkScan {
  key: string;
}

const NetworksTab: React.FC<NetworksTabProps> = () => {
  const { t } = useTranslation();
  const { networks } = useDeviceDetail();

  if (!networks) return null;

  const columns: ColumnsType<NetworkScanRow> = [
    {
      key: 'name',
      title: t('admin.devices.detail.network.name'),
      sorting: true,
      sortingOptions: {
        dataType: 'string'
      },
      render: (_, { name, network_type }) => (
        <HStack spacing={4}>
          <Flex
            w="32px"
            h="32px"
            p="0.5rem"
            alignItems="center"
            justifyContent="center"
            borderRadius="md"
            bg={themeColor('gray.10', 'gray.700')}
          >
            {network_type === 'wifi' && <Wifi width="1rem" />}
            {network_type === 'cellular' && <Signal width="1rem" />}
            {network_type === 'ethernet' && <Network width="1rem" />}
            {network_type === 'unknown' && <AlertTriangle width="1rem" />}
          </Flex>
          <Text fontSize="md" fontWeight={500}>
            {name}
          </Text>
        </HStack>
      )
    },
    {
      key: 'type',
      title: t('admin.devices.detail.network.type'),
      align: 'center',
      sorting: true,
      sortingOptions: {
        dataType: 'string'
      },
      render: (_, { network_type }) => (
        <Tag bg="unknown.500" color="white">
          <HStack spacing={2}>
            {network_type === 'wifi' && <Wifi width="1rem" />}
            {network_type === 'cellular' && <Signal width="1rem" />}
            {network_type === 'ethernet' && <Network width="1rem" />}
            {network_type === 'unknown' && <AlertTriangle width="1rem" />}
            <Text color="white" lineHeight="15px">
              {serializeNetworkType(network_type)}
            </Text>
          </HStack>
        </Tag>
      )
    },
    {
      key: 'riskScore',
      title: t('admin.devices.detail.network.riskScore'),
      align: 'center',
      sorting: true,
      sortingOptions: {
        dataType: 'number'
      },
      render: (_, { score }) => (
        <HStack spacing={1} justifyContent="center" alignItems="end">
          <SeverityNumTag severityScore={score} />
          <Text fontSize="md" lineHeight="8px">
            /10
          </Text>
        </HStack>
      )
    },
    {
      key: 'last_scan_done',
      title: t('admin.devices.detail.network.lastScan'),
      align: 'center',
      sorting: true,
      sortingOptions: {
        defaultSortOrder: 'desc'
      },
      render: (_, { last_scan_done }) => dayjs(last_scan_done).format('DD. MM. YYYY')
    }
  ];

  const formattedNetworks: NetworkScanRow[] = useMemo(() => {
    return networks.map((net, idx) => ({ key: net.id || idx.toString(), ...net }));
  }, [networks]);

  return (
    <RCard w="full">
      <SimpleTable<NetworkScanRow>
        data={formattedNetworks}
        columns={columns}
        expandable={{
          rowExpandable: () => true,
          render: net => <NetworkDetail network={net} />
        }}
        initOpenItemId={nets => {
          let firstItem = nets[0];
          let firstItemIdx = 0;

          nets.forEach((net, idx) => {
            if (
              net.last_scan_done &&
              firstItem.last_scan_done &&
              dayjs(net.last_scan_done).toDate().getTime() > dayjs(firstItem.last_scan_done).toDate().getTime()
            ) {
              firstItem = net;
              firstItemIdx = idx;
            }
          });

          return firstItemIdx.toString();
        }}
        pagination={{
          showTotal: true,
          defaultPageSize: 5,
          showGoToPage: true,
          showPageSizeSelector: true,
          pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
        }}
        interleaveRows
      />
    </RCard>
  );
};

export default NetworksTab;
