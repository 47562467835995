import { AxiosError } from 'axios';

import {
  ClientNetworkType,
  ErrorFields,
  ExternalNetworkScan,
  NetworkDevice,
  NetworkScan,
  NetworkType,
  NetworkUser
} from 'types';

import axios, { handleError } from './_defaults';

// eslint-disable-next-line max-len
const getNetwork = (user_id: string, type: NetworkType, id: string): Promise<NetworkScan> =>
  new Promise<NetworkScan>((resolve, reject) => {
    axios
      .get<NetworkScan>(`/users/${user_id}/networks/${id}/${type}`)
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

// eslint-disable-next-line max-len
const getNetworkUsers = (
  client_id: string,
  network_id: string,
  start_date: string,
  end_date: string
): Promise<NetworkUser[]> =>
  new Promise<NetworkUser[]>((resolve, reject) => {
    axios
      .get<NetworkUser[]>(`/clients/${client_id}/networks/${network_id}/users`, { params: { start_date, end_date } })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

const updateNetwork = (
  client_id: string,
  network_id: string,
  client_type: ClientNetworkType,
  is_safe: boolean
): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    axios
      .patch(`/clients/${client_id}/networks/${network_id}`, { client_type, is_safe })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

// eslint-disable-next-line max-len
const getNetworkDevices = (
  client_id: string,
  network_id: string,
  start_date: string,
  end_date: string
): Promise<NetworkDevice[]> =>
  new Promise<NetworkDevice[]>((resolve, reject) => {
    // eslint-disable-next-line max-len
    axios
      .get<NetworkDevice[]>(`/clients/${client_id}/networks/${network_id}/devices`, {
        params: { start_date, end_date }
      })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

// eslint-disable-next-line max-len
const getNetworkExternalScans = (
  client_id: string,
  network_id: string,
  start_date: string,
  end_date: string
): Promise<ExternalNetworkScan[]> =>
  new Promise<ExternalNetworkScan[]>((resolve, reject) => {
    // eslint-disable-next-line max-len
    axios
      .get<ExternalNetworkScan[]>(`/clients/${client_id}/networks/${network_id}/external-scans`, {
        params: { start_date, end_date }
      })
      .then(res => resolve(res.data))
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

// eslint-disable-next-line max-len
const updateExternalScanPermission = (
  client_id: string,
  network_id: string,
  approval: 'granted' | 'rejected'
): Promise<void> =>
  new Promise<void>((resolve, reject) => {
    // eslint-disable-next-line max-len
    axios
      .patch(`/clients/${client_id}/networks/${network_id}/external-scan-approval`, undefined, { params: { approval } })
      .then(() => resolve())
      .catch((err: AxiosError<ErrorFields>) => reject(handleError(err)));
  });

export default {
  getNetwork,
  getNetworkUsers,
  updateNetwork,
  getNetworkDevices,
  getNetworkExternalScans,
  updateExternalScanPermission
};
