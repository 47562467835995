import byteSize from 'byte-size';
import BarList from 'components/BarList';
import { useTranslation } from 'react-i18next';
import { MacOSExtendedDeviceInfo, WindowsExtendedDeviceInfo } from 'types/api/devices';

interface StorageChartProps {
  drives: WindowsExtendedDeviceInfo['storage'] | MacOSExtendedDeviceInfo['storage'];
}

const StorageChart = ({ drives }: StorageChartProps) => {
  const { t } = useTranslation();

  return (
    <BarList
      labels={{
        name: t('admin.devices.detail.device.storagesDetail.drive'),
        usageSize: t('admin.devices.detail.device.storagesDetail.usage'),
        usagePercentage: `${t('admin.devices.detail.device.storagesDetail.usage')} (%)`
      }}
      values={drives.map((drive, index) => {
        const driveName =
          'disk_name' in drive ? drive.disk_name : `${t('admin.devices.detail.device.storagesDetail.drive')}-${index}`;
        const actualUsage = drive.actual_usage || 0;
        const totalStorage = drive.total_storage || 0;

        const actualUsagePercentage = (actualUsage / totalStorage) * 100;

        return {
          name: `${driveName}`,
          usageSize: `${byteSize(actualUsage).toString()} / ${byteSize(totalStorage).toString()}`,
          usagePercentage: `${Math.ceil(actualUsagePercentage)}%`,
          barWidth: actualUsagePercentage
        };
      })}
    />
  );
};

export default StorageChart;
