import { Grid, GridItem, Text, VStack } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LanguageSelect from 'components/LanguageSelect';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LanguageSettingProps {}

const LanguageSetting: React.FC<LanguageSettingProps> = () => {
  const { t } = useTranslation();

  return (
    <Grid w="full" templateColumns="1fr 1.4fr" gap={14}>
      <GridItem w="100%">
        <VStack w="full" align="start">
          <Text fontWeight={600} fontSize="1.25rem">
            {t('admin.settings.language.title')}
          </Text>
          <Text fontWeight={300} fontSize="1rem">
            {t('admin.settings.language.description')}
          </Text>
        </VStack>
      </GridItem>
      <GridItem w="100%">
        <VStack w="full" h="62px" align="start" justify="center" spacing={6}>
          <LanguageSelect width="320px" size="lg" />
        </VStack>
      </GridItem>
    </Grid>
  );
};

export default LanguageSetting;
