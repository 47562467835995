import { Grid, GridItem, HStack, SeverityNumTag, Text, useColorModeValue } from '@ramp/components';
import { colorHex } from '@ramp/theme';
import React from 'react';

import { PlatformStats as IPlatformStats } from 'types';
import { useWindowWidth } from 'utils/hooks/useWindowWidth';
import { serializePlatform } from 'utils/serializators';
import { usePlatformIcon } from 'utils/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PlatformStatsProps {
  name: string;
  platform?: IPlatformStats;
}

const PlatformStats: React.FC<PlatformStatsProps> = ({ name, platform }) => {
  if (!platform) return null;

  const { devices_count: totalDevices, total_issues: totalIssues, avg_score: avgScore } = platform;

  const windowWidth = useWindowWidth();

  return (
    <Grid
      w="100%"
      templateColumns={windowWidth > 1700 ? '2.25fr 1.25fr 1.25fr 2.25fr' : '1.25fr 1.25fr 1.25fr 1.25fr'}
      alignItems="center"
      padding={windowWidth > 1700 ? '0.5rem' : '0.65rem 0.5rem'}
      borderBottom={useColorModeValue(`1px solid ${colorHex('gray.25')}`, `1px solid ${colorHex('gray.700')}`)}
      _last={{ borderBottom: 'none' }}
    >
      <GridItem textAlign="left">
        <HStack>
          {windowWidth > 1700 && <Text fontSize="1.25rem">{usePlatformIcon(name!)}</Text>}
          <Text fontWeight={500}>{serializePlatform(name!)}</Text>
        </HStack>
      </GridItem>
      <GridItem textAlign="center">{totalDevices || 0}</GridItem>
      <GridItem textAlign="center">{totalIssues || 0}</GridItem>
      <GridItem>
        <HStack justify={windowWidth > 1700 ? 'center' : 'end'} spacing={0}>
          <SeverityNumTag size={windowWidth > 1700 ? 'md' : 'sm'} severityScore={avgScore} />
          {avgScore && windowWidth > 1400 && (
            <Text pos="relative" top="0.5rem" left="0.2rem">
              /10
            </Text>
          )}
        </HStack>
      </GridItem>
    </Grid>
  );
};

export default PlatformStats;
