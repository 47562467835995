import { Heading, RStat, SimpleGrid, useColorModeValue, VStack } from '@ramp/components';
import { AlertTriangle, Loader, Settings, Verified } from 'lucide-react';
import React from 'react';
import { Link as ScrollLink } from 'react-scroll/modules';

import { useTranslation } from 'react-i18next';
import { AppInfo } from 'types';

interface StatisticsProps {
  filteredApps: AppInfo[];
}

const Statistics: React.FC<StatisticsProps> = ({ filteredApps }) => {
  const { t } = useTranslation();

  const stats = {
    risky: filteredApps.filter(app => app.eval_category === 'risky'),
    inVerification: filteredApps.filter(app => app.eval_category === 'in_verification'),
    verified: filteredApps.filter(app => app.eval_category === 'verified'),
    system: filteredApps.filter(app => app.eval_category === 'system')
  };

  return (
    <VStack align="start" w="full">
      <Heading
        mt="2rem !important"
        mb="0.25rem !important"
        color={useColorModeValue('black', 'gray.400')}
        fontSize="1.5rem"
        fontWeight={600}
      >
        {t('admin.devices.detail.apps.statistics.title')}
      </Heading>
      <SimpleGrid w="full" minChildWidth="260px" spacing={['16px', '16px', '16px', '24px', '32px']}>
        <ScrollLink to="risky_apps" smooth={true}>
          <RStat
            icon={<AlertTriangle size="32px" />}
            number={stats.risky.length}
            description={t('admin.devices.detail.apps.statistics.risky')}
            type="redGradient"
            w="full"
            // @ts-ignore
            _hover={{ cursor: 'pointer' }}
          />
        </ScrollLink>
        <ScrollLink to="in_verification_apps" smooth={true}>
          <RStat
            icon={<Loader size="32px" />}
            number={stats.inVerification.length}
            description={t('admin.devices.detail.apps.statistics.inVerification')}
            type="yellowGradient"
            w="full"
            // @ts-ignore
            _hover={{ cursor: 'pointer' }}
          />
        </ScrollLink>
        <ScrollLink to="verified_apps" smooth={true}>
          <RStat
            icon={<Verified size="32px" />}
            number={stats.verified.length}
            description={t('admin.devices.detail.apps.statistics.verified')}
            type="greenGradient"
            w="full"
            // @ts-ignore
            _hover={{ cursor: 'pointer' }}
          />
        </ScrollLink>
        <ScrollLink to="system_apps" smooth={true}>
          <RStat
            icon={<Settings size="32px" />}
            number={stats.system.length}
            description={t('admin.devices.detail.apps.statistics.system')}
            type="darkGradient"
            w="full"
            // @ts-ignore
            _hover={{ cursor: 'pointer' }}
          />
        </ScrollLink>
      </SimpleGrid>
    </VStack>
  );
};

export default Statistics;
