import { ColumnsType, SimpleTable, Text } from '@ramp/components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MacOSApp } from 'types/api/devices';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MacOSAppsTableProps {
  id: string;
  apps?: MacOSApp[];
  searchFor?: string;
  unupdated?: boolean;
  selectable?: boolean;
  onSelectionChange?: (selectedRows: MacOSAppsRow[]) => void;
}

interface MacOSAppsRow extends MacOSApp {
  key: number;
}

const MacOSAppsTable: React.FC<MacOSAppsTableProps> = ({
  id,
  apps = [],
  searchFor = '',
  selectable = false,
  onSelectionChange: onSelectedChanged
}) => {
  const { t } = useTranslation();

  const columns: ColumnsType<MacOSAppsRow> = [
    {
      title: t('components.table.macOSApps.name'),
      key: 'name',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
        defaultSortOrder: 'asc'
      }
    },
    {
      title: t('components.table.macOSApps.version'),
      key: 'version',
      sorting: true,
      sortingOptions: {
        dataType: 'string'
      },
      render: version => (
        <Text color={version ? 'success.500' : 'error.500'}>
          {version || t('components.table.macOSApps.unknownVersion')}
        </Text>
      )
    },
    {
      title: t('components.table.macOSApps.obtainedFrom'),
      key: 'obtained_from',
      sorting: true,
      sortingOptions: {
        dataType: 'string'
      },
      render: obtainedFrom => (
        <Text color={['Apple', 'IdentifiedDeveloper', 'AppStore'].includes(obtainedFrom) ? 'success.500' : 'error.500'}>
          {['Apple', 'IdentifiedDeveloper'].includes(obtainedFrom)
            ? t('components.table.macOSApps.registeredDeveloper')
            : obtainedFrom === 'AppStore'
              ? 'App Store'
              : t('components.table.macOSApps.unknownDeveloper')}
        </Text>
      )
    },
    {
      title: t('components.table.macOSApps.signedBy'),
      key: 'signed_by',
      sorting: true,
      sortingOptions: {
        dataType: 'string'
      },
      render: signedBy => {
        if (
          signedBy === 'SoftwareSigning,AppleCodeSigningCertificationAuthority,AppleRootCA' ||
          // eslint-disable-next-line max-len
          signedBy === 'AppleMacOSApplicationSigning,AppleWorldwideDeveloperRelationsCertificationAuthority,AppleRootCA'
        ) {
          return <Text color="success.500">Apple, Inc.</Text>;
        }

        if (signedBy) {
          return <Text color="success.500">{signedBy.split(',')[0].split(':')[1]}</Text>;
        }

        return <Text color="error.500">{t('admin.devices.detail.apps.notSigned')}</Text>;
      }
    }
  ];

  const filteredApps: MacOSAppsRow[] = useMemo(() => {
    const formattedApps: MacOSAppsRow[] = apps.map((app, key) => ({
      key,
      ...app
    }));

    return formattedApps.filter(app => {
      return (
        app.name.toLowerCase().includes(searchFor.toLowerCase()) ||
        (app.name && app.name.toLowerCase().includes(searchFor.toLowerCase()))
      );
    });
  }, [apps, searchFor]);

  return (
    <SimpleTable<MacOSAppsRow>
      id={id}
      columns={columns}
      data={filteredApps}
      pagination={{
        showTotal: true,
        defaultPageSize: 10,
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
      }}
      interleaveRows
      selectable={
        selectable && filteredApps.length > 0
          ? {
              rowSelectable: () => true,
              // @ts-ignore
              onChange: selectedRows => onSelectedChanged && onSelectedChanged(selectedRows as MacOSAppsRow[])
            }
          : undefined
      }
    />
  );
};

export default MacOSAppsTable;
