import {
  Button,
  HStack,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useColorModeValue,
  useDisclosure
} from '@ramp/components';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import { useAuthStore } from 'store';
import { Issue, IssueIgnoreCodename } from 'types';
import { notify } from 'utils/notifications';

interface IssueIgnorePopoverProps {
  issue: Issue;
  codenames: IssueIgnoreCodename[];
  userView?: boolean;
  mode: 'ignore' | 'activate';
}

const IssueIgnorePopover: React.FC<IssueIgnorePopoverProps> = ({ issue, codenames, userView = false, mode }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { entity_id: clientId, user_id: userId } = useAuthStore(store => store.user!);

  const [isLoading, setIsLoading] = useState(false);

  const renderObjectsText = (numOfAffectedObjects: number, objectType: string): string => {
    if (objectType === 'device') {
      return numOfAffectedObjects > 1
        ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.devices')}`
        : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.device')}`;
    }

    if (objectType === 'network') {
      return numOfAffectedObjects > 1
        ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.networks')}`
        : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.network')}`;
    }

    if (objectType === 'client') {
      return numOfAffectedObjects > 1
        ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.clients')}`
        : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.client')}`;
    }

    if (objectType === 'network_device') {
      return numOfAffectedObjects > 1
        ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.devices')}`
        : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.device')}`;
    }

    if (objectType === 'email') {
      return numOfAffectedObjects > 1
        ? numOfAffectedObjects < 5
          ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.emails')}`
          : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.emails2')}`
        : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.email')}`;
    }

    if (objectType === 'user') {
      return numOfAffectedObjects > 1
        ? numOfAffectedObjects < 5
          ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.users')}`
          : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.users2')}`
        : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.user2')}`;
    }

    return numOfAffectedObjects.toString();
  };

  const handleIssueIgnore = () => {
    setIsLoading(true);

    if (mode === 'ignore') {
      if (userView) {
        api.users
          .ignoreIssues(userId!, codenames)
          .then(() => {
            setIsLoading(false);
            onClose();

            notify.success({
              title: t('admin.dashboard.issues.table.ignoreIssue.success.title'),
              description: t('admin.dashboard.issues.table.ignoreIssue.success.description', {
                issue: issue.title,
                objects: renderObjectsText(issue.n_affected_objects, issue.object_type)
              })
            });

            queryClient.invalidateQueries({ queryKey: ['issues', userId] });
            queryClient.invalidateQueries({ queryKey: ['issue', issue.codename] });
          })
          .catch(() => {
            setIsLoading(false);
            onClose();

            notify.error({
              title: t('admin.dashboard.issues.table.ignoreIssue.error.title'),
              description: t('admin.dashboard.issues.table.ignoreIssue.error.description', {
                issue: issue.title
              })
            });
          });
      } else {
        api.clients
          .ignoreIssues(clientId!, codenames)
          .then(() => {
            setIsLoading(false);
            onClose();

            notify.success({
              title: t('admin.dashboard.issues.table.ignoreIssue.success.title'),
              description: t('admin.dashboard.issues.table.ignoreIssue.success.description', {
                issue: issue.title,
                objects: renderObjectsText(issue.n_affected_objects, issue.object_type)
              })
            });

            queryClient.invalidateQueries({ queryKey: ['issues', clientId] });
            queryClient.invalidateQueries({ queryKey: ['issue', issue.codename] });
          })
          .catch(() => {
            setIsLoading(false);
            onClose();

            notify.error({
              title: t('admin.dashboard.issues.table.ignoreIssue.error.title'),
              description: t('admin.dashboard.issues.table.ignoreIssue.error.description', {
                issue: issue.title
              })
            });
          });
      }
    } else {
      if (userView) {
        api.users
          .activateIssues(userId!, codenames)
          .then(() => {
            setIsLoading(false);
            onClose();

            notify.success({
              title: t('admin.dashboard.issues.table.activateIssue.success.title'),
              description: t('admin.dashboard.issues.table.activateIssue.success.description', {
                issue: issue.title,
                objects: renderObjectsText(issue.n_affected_objects, issue.object_type)
              })
            });

            queryClient.invalidateQueries({ queryKey: ['issues', userId] });
            queryClient.invalidateQueries({ queryKey: ['issue', issue.codename] });
          })
          .catch(() => {
            setIsLoading(false);
            onClose();

            notify.error({
              title: t('admin.dashboard.issues.table.activateIssue.error.title'),
              description: t('admin.dashboard.issues.table.activateIssue.error.description', {
                issue: issue.title
              })
            });
          });
      } else {
        api.clients
          .activateIssues(clientId!, codenames)
          .then(() => {
            setIsLoading(false);
            onClose();

            notify.success({
              title: t('admin.dashboard.issues.table.activateIssue.success.title'),
              description: t('admin.dashboard.issues.table.activateIssue.success.description', {
                issue: issue.title,
                objects: renderObjectsText(issue.n_affected_objects, issue.object_type)
              })
            });

            queryClient.invalidateQueries({ queryKey: ['issues', clientId] });
            queryClient.invalidateQueries({ queryKey: ['issue', issue.codename] });
          })
          .catch(() => {
            setIsLoading(false);
            onClose();

            notify.error({
              title: t('admin.dashboard.issues.table.activateIssue.error.title'),
              description: t('admin.dashboard.issues.table.activateIssue.error.description', {
                issue: issue.title
              })
            });
          });
      }
    }
  };

  return (
    <Popover placement="top" isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button variant="brand" size="sm" onClick={onToggle} isLoading={isLoading}>
          {mode === 'ignore'
            ? issue.n_affected_objects > 1
              ? t('admin.dashboard.issues.table.ignoreAll')
              : t('admin.dashboard.issues.table.ignore')
            : issue.n_ignored_objects > 1
              ? t('admin.dashboard.issues.table.unignoreAll')
              : t('admin.dashboard.issues.table.unignore')}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent borderColor={useColorModeValue('gray.200', 'gray.650')}>
          <PopoverHeader borderColor={useColorModeValue('gray.200', 'gray.650')}>
            <Text fontWeight={600}>
              {mode === 'ignore'
                ? t('admin.dashboard.issues.table.ignoreIssue.title')
                : t('admin.dashboard.issues.table.activateIssue.title')}
            </Text>
          </PopoverHeader>
          <PopoverCloseButton onClick={onClose} />
          <PopoverBody>
            {mode === 'ignore'
              ? t('admin.dashboard.issues.table.ignoreIssue.description', {
                  objects: renderObjectsText(issue.n_affected_objects, issue.object_type)
                })
              : t('admin.dashboard.issues.table.activateIssue.description', {
                  objects: renderObjectsText(issue.n_ignored_objects, issue.object_type)
                })}
          </PopoverBody>
          <PopoverFooter borderColor={useColorModeValue('gray.200', 'gray.650')}>
            <HStack w="full" justifyContent="flex-end">
              <Button variant="outline" size="sm" onClick={onClose}>
                {t('admin.dashboard.issues.table.cancel')}
              </Button>
              <Button size="sm" variant="brand" isLoading={isLoading} onClick={handleIssueIgnore}>
                {mode === 'ignore'
                  ? issue.n_affected_objects > 1
                    ? t('admin.dashboard.issues.table.ignoreAll')
                    : t('admin.dashboard.issues.table.ignore')
                  : issue.n_ignored_objects > 1
                    ? t('admin.dashboard.issues.table.unignoreAll')
                    : t('admin.dashboard.issues.table.unignore')}
              </Button>
            </HStack>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default IssueIgnorePopover;
