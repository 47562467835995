import { UseToastOptions } from '@chakra-ui/react';
import { createStandaloneToast } from '@ramp/components';
import { theme } from '@ramp/theme';

// import useNotify, { setProps } from './useNotify';
import { NotificationProvider } from './NotificationProvider';
import { useNotificationSend } from './useNotificationSend';

import type { INotification } from './useNotificationSend';

const { ToastContainer, toast: innerToast } = createStandaloneToast({
  theme,
  defaultOptions: {
    position: 'bottom-right',
    duration: 5000,
    isClosable: false,
    containerStyle: {
      maxWidth: '380px'
    }
  }
});

const notify = {
  success: (options: UseToastOptions) => innerToast({ ...options, status: 'success' }),
  info: (options: UseToastOptions) => innerToast({ ...options, status: 'info' }),
  warning: (options: UseToastOptions) => innerToast({ ...options, status: 'warning' }),
  error: (options: UseToastOptions) => innerToast({ ...options, status: 'error' })
};

export { useNotificationSend, NotificationProvider, notify, ToastContainer };
export type { INotification };
