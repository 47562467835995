import axios from 'axios';

export interface GWUser {
  kind: string;
  id: string;
  etag: string;
  primaryEmail: string;
  name: {
    givenName: string;
    familyName: string;
    fullName: string;
  };
  isAdmin: boolean;
  isDelegatedAdmin: boolean;
  lastLoginTime: string;
  creationTime: string;
  agreedToTerms: boolean;
  suspended: boolean;
  archived: boolean;
  changePasswordAtNextLogin: boolean;
  ipWhitelisted: boolean;
  emails: Array<{
    address: string;
    primary?: boolean; // `primary` is optional as it may not be present in all email objects
  }>;
  languages: Array<{
    languageCode: string;
    preference: string;
  }>;
  aliases: string[];
  nonEditableAliases: string[];
  customerId: string;
  orgUnitPath: string;
  isMailboxSetup: boolean;
  isEnrolledIn2Sv: boolean;
  isEnforcedIn2Sv: boolean;
  includeInGlobalAddressList: boolean;
  thumbnailPhotoUrl: string;
  thumbnailPhotoEtag: string;
  recoveryPhone: string;
}

const getAllUsersFromGW = (accessToken: string): Promise<GWUser[]> =>
  new Promise<GWUser[]>((resolve, reject) => {
    axios
      .get('https://admin.googleapis.com/admin/directory/v1/users', {
        params: {
          customer: 'my_customer',
          maxResults: 500, // This is a hard limit for the Google Admin API per page
          orderBy: 'email'
        },
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then(response => resolve(response.data.users))
      .catch(error => reject(error));
  });

export default {
  getAllUsersFromGW
};
