import { RTab, RTabList, RTabPanel, RTabPanels, RTabs, VStack } from '@ramp/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import api from 'api';
import { AppInfo, Platforms } from 'types';
import { AndroidScan, IosScan } from 'types/api/devices';

import { useDeviceDetail } from '../../DeviceDetailProvider';
import AppSearch from './AppSearch';
import InVerificationApps from './InVerificationApps';
import RequestedApps from './RequestedApps';
import RiskyApps from './RiskyApps';
import Statistics from './Statistics';
import SystemApps from './SystemApps';
import VerifiedApps from './VerifiedApps';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AppsTabProps {}

const AppsTab: React.FC<AppsTabProps> = () => {
  const { t } = useTranslation();
  const { platform, scanDate, scan, setAppsIcons } = useDeviceDetail();
  const { id } = useParams<{ platform: Platforms; id: string }>();

  if (!scan || !scanDate || !id) return null;

  const { applications } = scan as AndroidScan | IosScan;

  const [searchedApp, setSearchedApp] = useState<string>('');
  const [filteredApps, setFilteredApps] = useState<AppInfo[]>(applications ? applications.evaluated || [] : []);

  const [searchInSystem, setSearchInSystem] = useState<string>('');
  const [showSystemApps, setShowSystemApps] = useState<boolean>(false);

  useEffect(() => {
    if (platform === 'android') {
      api.devices.getAndroidAppsIcons(id, scanDate).then(data => setAppsIcons(data.package_icons));
    }
  }, []);

  useEffect(() => {
    if (applications && applications.evaluated) {
      if (searchedApp.length >= 3) {
        const apps = applications.evaluated.filter(app => app.package_name.toLowerCase().includes(searchedApp));
        setFilteredApps(apps);
      } else {
        setFilteredApps(applications.evaluated);
      }
    }
  }, [searchedApp]);

  return (
    <VStack align="start" w="full">
      {platform === 'android' && (
        <RTabs w="full" isLazy>
          <RTabList mx="auto">
            <RTab key="overview">{t('admin.devices.detail.apps.installed').toUpperCase()}</RTab>
            <RTab key="device">{t('admin.devices.detail.apps.requested.title').toUpperCase()}</RTab>
          </RTabList>
          <RTabPanels mt={4}>
            <RTabPanel key="installed">
              <AppSearch searchedApp={searchedApp} setSearchedApp={setSearchedApp} />

              <Statistics filteredApps={filteredApps} />

              <RiskyApps filteredApps={filteredApps} issues={applications ? applications.recommendations : undefined} />

              <InVerificationApps filteredApps={filteredApps} />

              <VerifiedApps filteredApps={filteredApps} />

              <SystemApps
                showSystemApps={showSystemApps}
                setShowSystemApps={setShowSystemApps}
                filteredApps={filteredApps}
                searchInSystem={searchInSystem}
                setSearchInSystem={setSearchInSystem}
              />
            </RTabPanel>
            <RTabPanel key="requested">
              <RequestedApps />
            </RTabPanel>
          </RTabPanels>
        </RTabs>
      )}

      {(platform === 'ios' || platform === 'ipados') && <RequestedApps />}
    </VStack>
  );
};

export default AppsTab;
