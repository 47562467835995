import { HStack, Text } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NoDataTabProps {}

const NoDataTab: React.FC<NoDataTabProps> = () => {
  const { t } = useTranslation();

  return (
    <HStack w="full" align="center" justify="center" h={360}>
      <Text color="gray.200" fontSize="1.25rem" fontWeight={500}>
        {t('components.noDataTab.text')}
      </Text>
    </HStack>
  );
};

export default NoDataTab;
