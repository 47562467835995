import {
  Alert,
  AlertIcon,
  Button,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RCard,
  RPage,
  SingleDatePicker,
  Text,
  useColorModeValue,
  VStack
} from '@ramp/components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowUp, AiOutlineForm, AiOutlineNotification, AiOutlineScan } from 'react-icons/ai';
import { FaChevronDown } from 'react-icons/fa';
import { FiArchive, FiCheckSquare, FiSettings } from 'react-icons/fi';
import { Link, Navigate, useParams } from 'react-router-dom';

import api from 'api';
import NotApprovedClientInfoBox from 'components/NotApprovedClientInfoBox';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { APIError, EmailScan, Platforms } from 'types';
import { MacOSApps, MacOSScan, WindowsApps, WindowsScan } from 'types/api/devices';
import { PRODUCT_NAME } from 'utils/constants';
import { notify, useNotificationSend } from 'utils/notifications';
import { parseRoute, toCapitalize } from 'utils/utils';

import CustomNotificationModal from './CustomNotificationModal';
import { DeviceDetailProvider } from './DeviceDetailProvider';
import DeviceInfoCard from './DeviceInfoCard';
import Evaluation from './Evaluation';
import MacOSDeviceDescription from './Evaluation/OverviewTab/macos/MacOSDeviceDecription';
import WindowsDeviceDescription from './Evaluation/OverviewTab/windows/WindowsDeviceDescription';
import ScorePanel from './ScorePanel';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeviceDetailProps {}

const DeviceDetail: React.FC<DeviceDetailProps> = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const notification = useNotificationSend();
  const user = useAuthStore(store => store.user!);
  const { id, platform, entityId } = useParams<{ entityId: string; platform: Platforms; id: string }>();

  if (!id || !platform) return <Navigate to={parseRoute(user!, ROUTES.ADMIN.DASHBOARD)} />;

  const [notificationLoading, setNotificationLoading] = useState<boolean>(false);
  const [deviceSettingsLoading, setDeviceSettingsLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<string | 'latest'>('latest');
  const [editDeviceName, setEditDeviceName] = useState<string>('');
  const [showCustomNotificationModal, setShowCustomNotificationModal] = useState<boolean>(false);
  const [latestScanDate, setLatestScanDate] = useState<string>();

  const scanDates = useQuery(['deviceDetail', id, 'dates'], () => api.devices.getScanDates(id));
  const scan = useQuery(['deviceDetail', id, 'scan', selectedDate], () =>
    api.devices.getScan(id, platform, selectedDate)
  );
  const scanCveIssues = useQuery(['deviceDetail', id, 'cveIssues', selectedDate], () =>
    api.devices.getScanCveIssues(id, selectedDate)
  );
  const networksStartDay = dayjs().add(-1, 'month').format('YYYY-MM-DD');
  const networksEndDay = dayjs().format('YYYY-MM-DD');
  const networks = useQuery(['deviceDetail', id, 'networks', networksStartDay, networksEndDay], () =>
    api.devices.getDeviceNetworks(id, networksStartDay, networksEndDay)
  );
  const recommendations = useQuery(['deviceDetail', id, 'recommendations', selectedDate], () =>
    api.devices.getRecommendations(id, selectedDate)
  );

  const [privacy, setPrivacy] = useState<EmailScan[] | undefined>(undefined);

  const [windowsApps, setWindowsApps] = useState<WindowsApps | undefined>(undefined);
  const [macOSApps, setMacOSApps] = useState<MacOSApps | undefined>(undefined);

  useEffect(() => {
    if (platform === 'windows') {
      api.devices
        .getWindowsApps(id, selectedDate)
        .then(data => setWindowsApps(data))
        .catch((err: APIError) => {
          notify.error({
            // eslint-disable-next-line max-len
            title: `[${err.code}] ${t('components.notification.default.title', { errOfModel: t('admin.devices.detail.apps.apps') })}`,
            // eslint-disable-next-line max-len
            description: `[${err.code}] ${t('components.notification.default.description', { errOfModel: t('admin.devices.detail.apps.apps') })}`
          });
          setWindowsApps(undefined);
        });
    }
  }, []);

  useEffect(() => {
    if (platform === 'macos') {
      api.devices
        .getMacOSApps(id, selectedDate)
        .then(data => setMacOSApps(data))
        .catch((err: APIError) => {
          notify.error({
            // eslint-disable-next-line max-len
            title: `[${err.code}] ${t('components.notification.default.title', { errOfModel: t('admin.devices.detail.apps.apps') })}`,
            // eslint-disable-next-line max-len
            description: `[${err.code}] ${t('components.notification.default.description', { errOfModel: t('admin.devices.detail.apps.apps') })}`
          });
          setMacOSApps(undefined);
        });
    }
  }, []);

  useEffect(() => {
    if (scanDates.data) {
      setLatestScanDate(dayjs(scanDates.data[scanDates.data.length - 1]).format('YYYY-MM-DD'));
    }
  }, [scanDates.data]);

  useEffect(() => {
    setPageLoading(
      scanDates.isLoading ||
        scan.isLoading ||
        scanCveIssues.isLoading ||
        networks.isLoading ||
        privacy === undefined ||
        recommendations.isLoading
    );
  }, [
    scanDates.isLoading,
    scan.isLoading,
    scanCveIssues.isLoading,
    networks.isLoading,
    privacy,
    recommendations.isLoading
  ]);

  useEffect(() => {
    if (scan.data) {
      setEditDeviceName(scan.data.device ? scan.data.device.name : '');
      api.users
        .getPrivacyBreaches(scan.data.user ? scan.data.user.id || '' : '')
        .then(data => {
          const emails = data.finished_email_scans;
          data.processing_email_scans.forEach(email =>
            emails.push({
              ...email,
              breaches: [],
              scan_done: null,
              score: null,
              data_classes: null,
              top_secret_data_classes: null,
              secret_data_classes: null,
              confidential_data_classes: null,
              sensitive_data_classes: null,
              restricted_data_classes: null,
              protected_data_classes: null,
              not_categorized_data_classes: null,
              users: []
            })
          );

          setPrivacy(emails);
        })
        .catch((err: APIError) => {
          notify.error({
            title: t('admin.devices.detail.privacyError', { errCode: err.code }),
            description: err.description
          });
          setPrivacy([]);
        });
    }
  }, [scan.data]);

  let deviceFormattedName = '';

  if (scan.data && scanDates.data) {
    deviceFormattedName = scan.data.device
      ? scan.data.device.marketing_name || toCapitalize(scan.data.device.device || '')
      : '';
  } else {
    return null;
  }

  const selectedDateToDate =
    selectedDate === 'latest'
      ? dayjs(scanDates.data[scanDates.data.length - 1]).toDate()
      : dayjs(selectedDate).toDate();

  // eslint-disable-next-line max-len
  const title = `${toCapitalize(scan.data ? (scan.data.device ? scan.data.device.manufacturer || '' : '') : '')} ${deviceFormattedName}`;

  const renderInfoBox = () => {
    const showNotLatestScanInfo = latestScanDate
      ? dayjs(selectedDateToDate).format('YYYY-MM-DD') !== latestScanDate
      : null;
    const showArchivedDeviceInfo = scan.data && scan.data.device && scan.data.device.status === 'archived';

    if (showNotLatestScanInfo || showArchivedDeviceInfo) {
      return (
        <Alert my="1rem !important" status="warning" bgColor="warning.400" borderRadius="8px">
          <AlertIcon color="black" />
          <VStack align="start" spacing={0}>
            {showArchivedDeviceInfo && (
              <HStack color="black" spacing={0}>
                <Text fontWeight={500} fontSize=".9rem">
                  {t('admin.devices.detail.archivedInfo')}
                </Text>
              </HStack>
            )}
            {showNotLatestScanInfo && (
              <HStack color="black" spacing={0}>
                <Text fontWeight={500} fontSize=".9rem">
                  <div dangerouslySetInnerHTML={{ __html: t('admin.devices.detail.notLatest.first') }} />
                </Text>
                <Text fontWeight={300}>
                  <div dangerouslySetInnerHTML={{ __html: t('admin.devices.detail.notLatest.second') }} />
                </Text>
                <Text
                  fontWeight={300}
                  textDecor="underline"
                  _hover={{ cursor: 'pointer' }}
                  onClick={() => setSelectedDate('latest')}
                >
                  {t('admin.devices.detail.notLatest.third')}
                </Text>
                <Text>.</Text>
              </HStack>
            )}
          </VStack>
        </Alert>
      );
    }

    return undefined;
  };

  return (
    <RPage
      heading={title}
      infoBox={!user.client_approved && user.client_approved !== null ? <NotApprovedClientInfoBox /> : renderInfoBox()}
      title={`${PRODUCT_NAME} | ${title}`}
      px={6}
      pt={8}
      breadcrumbs={[
        { name: 'Devices', as: Link, linkTo: parseRoute(user!, ROUTES.ADMIN.DEVICES.LIST, { entityId: entityId! }) },
        {
          name: deviceFormattedName,
          as: Link,
          linkTo: parseRoute(user!, ROUTES.ADMIN.DEVICES.DETAIL, { platform, id })
        }
      ]}
      loading={pageLoading}
      headingRightSide={
        <HStack align="flex-end">
          <VStack align="flex-end">
            <Text fontWeight={500}>{t('admin.devices.detail.scanDate')}</Text>
            <HStack justify="flex-end">
              <Heading as="h3" fontSize="1.25rem" fontWeight={600}>
                {dayjs(scan.data.created).format('D. MMMM HH:mm, YYYY')}
              </Heading>
              <Popover placement="bottom-end">
                <PopoverTrigger>
                  <Button size="sm" variant="borderless">
                    <FaChevronDown />
                  </Button>
                </PopoverTrigger>
                <PopoverContent w="full" border="none">
                  <SingleDatePicker
                    date={selectedDateToDate}
                    onChange={date => setSelectedDate(dayjs(date).format('YYYY-MM-DD'))}
                    minDate={dayjs(scanDates.data[0]).toDate()}
                    maxDate={dayjs(scanDates.data[scanDates.data.length - 1]).toDate()}
                    disabledDay={date => !scanDates.data?.includes(dayjs(date).format('YYYY-MM-DD'))}
                    renderDayDot={day => scanDates.data?.includes(dayjs(day).format('YYYY-MM-DD'))}
                  />
                </PopoverContent>
              </Popover>
            </HStack>
          </VStack>
          <Menu>
            <MenuButton as={Button} size="md" isLoading={notificationLoading} variant="borderless">
              {!notificationLoading ? <AiOutlineNotification /> : null}
            </MenuButton>
            <MenuList w="180px">
              <MenuItem
                icon={<AiOutlineScan />}
                onClick={() => {
                  setNotificationLoading(true);
                  notification
                    .send(id, notification.DEFAULT_NOTIFICATIONS.ASK_FOR_SCAN)
                    .then(() => {
                      setNotificationLoading(false);
                      notify.success({
                        title: t('admin.devices.detail.notificationSuccess.title'),
                        description: t('admin.devices.detail.notificationSuccess.description')
                      });
                    })
                    .catch((err: APIError) => {
                      notify.error({
                        title: t('admin.devices.detail.notificationError.title'),
                        description: err.description
                      });
                      setNotificationLoading(false);
                    });
                }}
              >
                {t('admin.devices.detail.askForScan')}
              </MenuItem>
              {/*TODO: replace latestVersion with actual latest_app_version*/}
              {scan.data.app_version < scan.data.app_version && (
                <MenuItem
                  icon={<AiOutlineArrowUp />}
                  onClick={() => {
                    setNotificationLoading(true);
                    notification
                      .send(id, notification.DEFAULT_NOTIFICATIONS.UPDATE_RDS)
                      .then(() => {
                        setNotificationLoading(false);
                        notify.success({
                          title: t('admin.devices.detail.notificationSuccess.title'),
                          description: t('admin.devices.detail.notificationSuccess.description')
                        });
                      })
                      .catch((err: APIError) => {
                        notify.error({
                          title: t('admin.devices.detail.notificationError.title'),
                          description: err.description
                        });
                        setNotificationLoading(false);
                      });
                  }}
                >
                  {t('admin.devices.detail.updateRDS')}
                </MenuItem>
              )}
              <MenuItem icon={<AiOutlineForm />} onClick={() => setShowCustomNotificationModal(true)}>
                {t('admin.devices.detail.customNotification.title')}
              </MenuItem>
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton as={Button} size="md" isLoading={deviceSettingsLoading} variant="borderless">
              {!deviceSettingsLoading ? <FiSettings /> : null}
            </MenuButton>
            <MenuList w="180px">
              {scan.data.device ? (
                scan.data.device.status === 'active' ? (
                  <MenuItem
                    icon={<FiArchive />}
                    onClick={() => {
                      setDeviceSettingsLoading(true);
                      api.devices
                        .updateDeviceStatus(id, 'archived')
                        .then(async () => {
                          await queryClient.invalidateQueries(['deviceDetail', id, 'scan', selectedDate]);
                          notify.success({
                            title: t('admin.devices.detail.archivedSuccess.title'),
                            description: t('admin.devices.detail.archivedSuccess.description')
                          });
                          setDeviceSettingsLoading(false);
                        })
                        .catch((err: APIError) => {
                          notify.error({
                            title: t('admin.devices.detail.archivedError.title'),
                            description: err.description
                          });
                          setDeviceSettingsLoading(false);
                        });
                    }}
                  >
                    {t('admin.devices.detail.archiveDevice')}
                  </MenuItem>
                ) : (
                  <MenuItem
                    icon={<FiCheckSquare />}
                    onClick={() => {
                      setDeviceSettingsLoading(true);
                      api.devices
                        .updateDeviceStatus(id, 'active')
                        .then(async () => {
                          await queryClient.invalidateQueries(['deviceDetail', id, 'scan', selectedDate]);
                          notify.success({
                            title: t('admin.devices.detail.activationSuccess.title'),
                            description: t('admin.devices.detail.activationSuccess.description')
                          });
                          setDeviceSettingsLoading(false);
                        })
                        .catch((err: APIError) => {
                          notify.error({
                            title: t('admin.devices.detail.activationError.title'),
                            description: err.description
                          });
                          setDeviceSettingsLoading(false);
                        });
                    }}
                  >
                    {t('admin.devices.detail.activateDevice')}
                  </MenuItem>
                )
              ) : null}
            </MenuList>
          </Menu>
        </HStack>
      }
    >
      <DeviceDetailProvider
        initScanDate={
          selectedDate === 'latest'
            ? dayjs(scanDates.data[scanDates.data.length - 1]).format('YYYY-MM-DD')
            : dayjs(selectedDate).format('YYYY-MM-DD')
        }
        initPlatform={platform}
        initScan={scan.data}
        initCveIssues={scanCveIssues.data}
        initNetworks={networks.data}
        initRecommendations={recommendations.data}
        initWindowsApps={windowsApps}
        initMacOSApps={macOSApps}
      >
        {scan.data.device && (
          <RCard
            w="full"
            titleOutside={false}
            title={t('admin.devices.detail.deviceInfo')}
            titleStyle={{ color: useColorModeValue('black', 'gray.400') }}
          >
            {platform === 'windows' ? (
              <WindowsDeviceDescription
                scan={scan.data as WindowsScan}
                editDeviceName={editDeviceName}
                selectedDate={selectedDate}
              />
            ) : platform === 'macos' ? (
              <MacOSDeviceDescription
                scan={scan.data as MacOSScan}
                editDeviceName={editDeviceName}
                selectedDate={selectedDate}
              />
            ) : (
              <DeviceInfoCard />
            )}
          </RCard>
        )}
        <ScorePanel />
        <Evaluation />
        <CustomNotificationModal
          isOpen={showCustomNotificationModal}
          onClose={() => setShowCustomNotificationModal(false)}
          setNotificationLoading={setNotificationLoading}
        />
      </DeviceDetailProvider>
    </RPage>
  );
};

export default DeviceDetail;
