import { RCard, RPage, VStack } from '@ramp/components';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import NoDataPage from 'components/NoDataPage';
import NotificationsTable from 'components/tables/NotificationsTable';
import NotificationsFilter from 'pages/admin/notifications/list/NotificationsFilters';
import { useAuthStore, useLayoutStore } from 'store';
import { NotificationLog } from 'types';
import { PRODUCT_NAME } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NotificationsListProps {}

const NotificationsList: React.FC<NotificationsListProps> = () => {
  const { t } = useTranslation();
  const user = useAuthStore(store => store.user!);
  const { noUsersInCompany, noScansInCompany } = useLayoutStore(store => store);

  const [defaultNotifications, setDefaultNotifications] = React.useState<NotificationLog[]>([]);
  const [filteredNotifications, setFilteredNotifications] = React.useState<NotificationLog[]>([]);

  const notifications = useQuery(['notifications', user.user_id], () => api.users.getNotificationLog(user.user_id), {
    onSuccess: data => {
      setDefaultNotifications(data);
      setFilteredNotifications(data);
    }
  });

  if (noScansInCompany) {
    return (
      <NoDataPage
        heading={t('admin.notifications.heading')}
        pageLoading={notifications.isLoading}
        noUsersInCompany={noUsersInCompany}
        noScansInCompany={noScansInCompany}
      />
    );
  }

  return (
    <RPage
      px={6}
      pt={8}
      heading={t('admin.notifications.heading')}
      title={t('admin.notifications.title', { productName: PRODUCT_NAME })}
    >
      <VStack w="full" spacing={4}>
        <NotificationsFilter
          tableId="notifications_list_table"
          notifications={defaultNotifications}
          setNotifications={setFilteredNotifications}
        />
        <RCard w="full">
          <NotificationsTable
            id="notifications_list_table"
            notifications={filteredNotifications}
            loading={notifications.isLoading}
          />
        </RCard>
      </VStack>
    </RPage>
  );
};

export default NotificationsList;
