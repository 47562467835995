import { Button, ColumnsType, HStack, RTable, Tag, Text, Tooltip, useColorModeValue, VStack } from '@ramp/components';
import countries from 'country-list-js';
import dayjs from 'dayjs';
import { ArrowRight } from 'lucide-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LocalRecommendation } from 'types';

import CountryFlag from 'components/CountryFlag';
import TextTruncateTooltip from 'components/TextTruncateTooltip';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';

interface LocalThreatsTableProps {
  id: string;
  loading?: boolean;
  defaultPageSize?: number;
  localThreats: LocalRecommendation[];
}

interface LocalThreatRow extends LocalRecommendation {
  key: number | string;
}

const LocalThreatsTable: React.FC<LocalThreatsTableProps> = ({ id, defaultPageSize = 50, localThreats, loading }) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const formattedLocalThreats: LocalThreatRow[] = useMemo(() => {
    return localThreats.map((rec, key) => ({ key, ...rec }));
  }, [localThreats]);

  const getRegionsLabel = (regions: string[]) => {
    let finalLabel = '';

    regions.forEach(region => {
      const country = countries.findByIso3(region.toUpperCase());
      if (country) {
        finalLabel += `${country.name}, `;
      } else {
        finalLabel += `${region}, `;
      }
    });

    return finalLabel.slice(0, -2);
  };

  const columns: ColumnsType<LocalThreatRow> = useMemo(
    () => [
      {
        key: 'regions',
        width: '20%',
        title: t('components.table.basicIssues.regions'),
        sorting: true,
        sortingOptions: {
          dataType: 'number',
          sortingFn: ({ original: a }, { original: b }) => (a.regions || []).length - (b.regions || []).length
        },
        render: (_, { regions }) => {
          const initRegions = regions.sort();

          return (
            <Tooltip label={getRegionsLabel(initRegions)}>
              <HStack justify="center" spacing={1}>
                {initRegions.slice(0, 4).map(region => {
                  const country = countries.findByIso3(region.toUpperCase());

                  if (!country) {
                    return null;
                  }

                  return (
                    <Tag key={region}>
                      <CountryFlag
                        countryCode={country.code.iso2}
                        style={{
                          position: 'relative',
                          top: '2px'
                        }}
                      />
                    </Tag>
                  );
                })}
                {initRegions.length > 4 && (
                  <Tag>
                    <Text fontSize="xs">+ {initRegions.length - 4}</Text>
                  </Tag>
                )}
              </HStack>
            </Tooltip>
          );
        }
      },
      {
        key: 'title',
        width: '20%',
        title: t('components.table.basicIssues.title'),
        render: (_, { title }) => <Text fontWeight={500}>{title}</Text>
      },
      {
        key: 'description',
        title: t('components.table.basicIssues.description'),
        width: '50%',
        render: (_, { description }) => (
          <TextTruncateTooltip text={description || ''} maxNumOfChars={240} noOfLines={2} />
        )
      },
      {
        key: 'created',
        width: '10%',
        sorting: true,
        sortingOptions: {
          defaultSortOrder: 'desc'
        },
        title: t('components.table.basicIssues.created'),
        render: (_, { created }) => <Text>{dayjs(created).format('DD. MM. YYYY')}</Text>
      },
      {
        key: 'moreInfo',
        width: '10%',
        title: t('components.table.basicIssues.moreInfo'),
        render: (_, { url }) => (
          <Button size="sm" rightIcon={<ArrowRight size={16} />} as="a" href={url} target="_blank">
            {t('components.table.basicIssues.moreInfo2')}
          </Button>
        )
      }
    ],
    []
  );

  return (
    <RTable<LocalThreatRow>
      data={formattedLocalThreats}
      columns={columns}
      loading={loading}
      expandable={{
        rowExpandable: () => true,
        render: localThreat => {
          const initRegions = localThreat.regions.sort();

          return (
            <HStack w="full" py={4} pr={8} align="start" spacing={4}>
              <VStack
                w="33%"
                align="start"
                spacing={4}
                p={4}
                bg={useColorModeValue('gray.25', 'gray.750')}
                borderRadius="md"
              >
                <VStack w="full" spacing={0} align="start">
                  <Text fontSize="sm" color="gray.400">
                    {t('components.table.basicIssues.title')}
                  </Text>
                  <Text fontSize="md" fontWeight={500}>
                    {localThreat.title}
                  </Text>
                </VStack>
                <VStack w="full" spacing={0} align="start">
                  <Text fontSize="sm" color="gray.400">
                    {t('components.table.basicIssues.detailedDescription')}
                  </Text>
                  <Text fontSize="md" fontWeight={500}>
                    {localThreat.description}
                  </Text>
                </VStack>
              </VStack>
              <VStack
                w="33%"
                align="start"
                spacing={4}
                p={4}
                bg={useColorModeValue('gray.25', 'gray.750')}
                borderRadius="md"
              >
                <VStack w="full" spacing={0} align="start">
                  <Text fontSize="sm" color="gray.400">
                    {t('components.table.basicIssues.threatForRegions')}
                  </Text>
                  <Tooltip label={getRegionsLabel(initRegions)}>
                    <HStack justify="center" spacing={1}>
                      {initRegions.slice(0, 4).map(region => {
                        const country = countries.findByIso3(region.toUpperCase());

                        if (!country) {
                          return null;
                        }

                        return (
                          <Tag key={region}>
                            <CountryFlag
                              countryCode={country.code.iso2}
                              style={{
                                position: 'relative',
                                top: '2px'
                              }}
                            />
                          </Tag>
                        );
                      })}
                      {initRegions.length > 4 && (
                        <Tag>
                          <Text fontSize="xs">+ {initRegions.length - 4}</Text>
                        </Tag>
                      )}
                    </HStack>
                  </Tooltip>
                </VStack>
                <VStack w="full" spacing={0} align="start">
                  <Text fontSize="sm" color="gray.400">
                    {t('components.table.basicIssues.moreInfo3')}
                  </Text>
                  <Text
                    fontSize="md"
                    color="brand.500"
                    textDecoration="underline"
                    as="a"
                    href={localThreat.url}
                    target="_blank"
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    {t('components.table.basicIssues.moreInfo2')}
                    <ArrowRight size={14} />
                  </Text>
                </VStack>
              </VStack>
            </HStack>
          );
        }
      }}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
      }}
      interleaveRows
    />
  );
};

export default LocalThreatsTable;
