import { Button, ColumnsType, HStack, RTable, SeverityNumTag, Text } from '@ramp/components';
import dayjs from 'dayjs';
import { ChevronRight } from 'lucide-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import IssueIgnorePopover from 'components/IssueIgnorePopover';
import SensorAppVersion from 'components/SensorAppVersion';
import UserTooltip from 'components/UserTooltip';
import ROUTES from 'router/routes';
import { useAuthStore } from 'store';
import { DeviceUserEvaluation, IssueDetail, Role } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import { serializePlatform, serializeVendor } from 'utils/serializators';
import { hasRole, parseRoute, usePlatformIcon } from 'utils/utils';

export interface DeviceUserEvaluationWithIssueCodename extends DeviceUserEvaluation {
  codename?: string;
}

interface DevicesEvaluationTableProps {
  /**
   * Table id
   */
  id: string;

  /**
   * Array of devices to render
   */
  devices?: DeviceUserEvaluationWithIssueCodename[];

  /**
   * Loading state
   *
   * @default false
   */
  loading?: boolean;

  /**
   * Simple mode for the table
   *
   * @default false
   */
  simple?: boolean;

  /**
   * Selectable mode for the table
   *
   * @default false
   */
  selectable?: boolean;

  /**
   * Default page size of the table
   *
   * @default 50
   */
  defaultPageSize?: number;

  /**
   * Defines the devices that are selected by default
   */
  defaultSelectedDevices?: DeviceUserEvaluation[];

  /**
   * Callback function that is called when the selected devices change
   */
  onSelectedDevicesChange?: (selectedDevices: DeviceUserEvaluation[]) => void;

  /**
   * If defined, the table will render with the "Actions" column,
   * where the user can see the device detail and ignore/activate issue.
   */
  withIssue?: IssueDetail;

  /**
   * Defines if we want to render "affected_objects" or "ignored_objects"
   * and if render "ignore/activate issue" button in the table
   *
   * If `true`, the table will render the objects that are actively affected and the "ignore/activate issue" button.
   *
   * If `false`, the table will render all objects and the "ignore/activate issue" button will not be displayed.
   *
   * `withIssue` property must be defined to use this property.
   *
   * @default true
   */
  showAffected?: boolean;

  /**
   * Defines if we want to render the table in user view
   *
   * @default false
   */
  userView?: boolean;
}

interface DeviceEvaluationRow extends DeviceUserEvaluationWithIssueCodename {
  key: string;
}

const DevicesEvaluationTable: React.FC<DevicesEvaluationTableProps> = ({
  id,
  devices,
  loading = false,
  simple = false,
  selectable = false,
  defaultPageSize = 50,
  defaultSelectedDevices,
  onSelectedDevicesChange,
  withIssue,
  showAffected = true,
  userView = false
}) => {
  if (!devices) return null;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useAuthStore(store => store.user!);

  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const issueActionsColumn: ColumnsType<DeviceEvaluationRow> = useMemo(
    () => [
      {
        title: t('admin.dashboard.issues.table.actions'),
        key: 'actions',
        align: 'center' as const,
        render: (_: any, device: DeviceEvaluationRow) => (
          <HStack justify="center" spacing={2}>
            <Button
              as={Link}
              size="sm"
              variant="outline"
              rightIcon={<ChevronRight size={16} />}
              to={
                userView
                  ? parseRoute(user, ROUTES.USER.DEVICES.DETAIL, {
                      id: device.id,
                      platform: device.platform
                    })
                  : parseRoute(user, ROUTES.ADMIN.DEVICES.DETAIL, {
                      id: device.id,
                      platform: device.platform
                    })
              }
            >
              {t('admin.dashboard.issues.table.details')}
            </Button>
            <IssueIgnorePopover
              issue={{
                ...withIssue!,
                n_affected_objects: 1,
                n_ignored_objects: 1,
                recommendations: withIssue!.recommendations.map(r => ({
                  ...r,
                  n_affected_objects: r.affected_objects.length.toString(),
                  n_ignored_objects: r.ignored_objects.length.toString()
                }))
              }}
              codenames={[
                {
                  codename: device.codename || '',
                  object_ids: [device.id]
                }
              ]}
              mode={showAffected ? 'ignore' : 'activate'}
              userView={userView}
            />
          </HStack>
        )
      }
    ],
    [withIssue]
  );

  const simpleColumns: ColumnsType<DeviceEvaluationRow> = useMemo(
    () => [
      {
        title: t('components.table.deviceEvaluation.platform'),
        key: 'platform',
        width: '120px',
        align: 'center',
        sorting: true,
        sortingOptions: {
          dataType: 'string'
        },
        render: (platform: string) => (
          <HStack w="min-content" justify="center" spacing={2}>
            <Text fontSize="1.2rem">{usePlatformIcon(platform)}</Text>
            <Text>{serializePlatform(platform)}</Text>
          </HStack>
        )
      },
      {
        title: t('components.table.deviceEvaluation.vendor'),
        key: 'manufacturer',
        width: '140px',
        align: 'center',
        sorting: true,
        sortingOptions: {
          dataType: 'string'
        },
        render: (vendor: string) => serializeVendor(vendor)
      },
      {
        title: t('components.table.deviceEvaluation.model'),
        key: 'marketing_name',
        sorting: true,
        sortingOptions: {
          dataType: 'string'
        },
        render: (value, record) => value || record.model || record.device
      },
      {
        title: t('components.table.deviceEvaluation.riskScore'),
        key: 'riskScore',
        align: 'center',
        children: [
          {
            title: t('components.table.deviceEvaluation.device'),
            key: 'device_score',
            align: 'center',
            sorting: true,
            sortingOptions: {
              dataType: 'number'
            },
            render: (_, { device_score }) => (
              <HStack justify="center" spacing={0}>
                <SeverityNumTag severityScore={device_score} />
                {device_score && (
                  <Text pos="relative" top="0.5rem" left="0.2rem">
                    /10
                  </Text>
                )}
              </HStack>
            )
          },
          {
            title: t('components.table.deviceEvaluation.apps'),
            key: 'applications_score',
            align: 'center',
            width: '140px',
            sorting: true,
            sortingOptions: {
              dataType: 'number',
              sortingFn: ({ original: a }, { original: b }) => (a.applications_score || 0) - (b.applications_score || 0)
            },
            render: (_, { applications_score }) => {
              if (applications_score !== null) {
                return (
                  <HStack justify="center" spacing={0}>
                    <SeverityNumTag severityScore={applications_score} />
                    {applications_score && (
                      <Text pos="relative" top="0.5rem" left="0.2rem">
                        /10
                      </Text>
                    )}
                  </HStack>
                );
              }

              return <SeverityNumTag severityScore={applications_score} />;
            }
          },
          {
            title: t('components.table.deviceEvaluation.privacy'),
            key: 'privacy_score',
            align: 'center',
            width: '100px',
            sorting: true,
            sortingOptions: {
              dataType: 'number',
              sortingFn: (rowA, rowB) => {
                return (rowA.original.privacy_score || 0) - (rowB.original.privacy_score || 0);
              }
            },
            render: (_, { privacy_score }) => {
              if (privacy_score !== null) {
                return (
                  <HStack justify="center" spacing={0}>
                    <SeverityNumTag severityScore={privacy_score} />
                    {privacy_score !== undefined && (
                      <Text pos="relative" top="0.5rem" left="0.2rem">
                        /10
                      </Text>
                    )}
                  </HStack>
                );
              }

              return (
                <span>
                  <SeverityNumTag severityScore={privacy_score} />
                </span>
              );
            }
          },
          {
            title: t('components.table.deviceEvaluation.network'),
            key: 'network_score',
            align: 'center',
            width: '100px',
            sorting: true,
            sortingOptions: {
              dataType: 'number',
              sortingFn: (rowA, rowB) => {
                return (rowA.original.network_score || 0) - (rowB.original.network_score || 0);
              }
            },
            render: (_, { network_score }) => {
              if (network_score !== null) {
                return (
                  <HStack justify="center" spacing={0}>
                    <SeverityNumTag severityScore={network_score} />
                    {network_score !== undefined && (
                      <Text pos="relative" top="0.5rem" left="0.2rem">
                        /10
                      </Text>
                    )}
                  </HStack>
                );
              }

              return (
                <span>
                  <SeverityNumTag severityScore={network_score} />
                </span>
              );
            }
          }
        ]
      },
      ...(!withIssue
        ? [
            {
              title: t('components.table.deviceEvaluation.appVersion'),
              key: 'app_version',
              width: '140px',
              sorting: true,
              align: 'center' as const,
              sortingOptions: {},
              render: (
                _: any,
                { app_version: version, latest_available_app_version: latestVersion }: DeviceEvaluationRow
              ) => <SensorAppVersion version={version} latestVersion={latestVersion} />
            }
          ]
        : []),
      {
        title: t('components.table.deviceEvaluation.lastScan'),
        key: 'created',
        width: '140px',
        sorting: true,
        sortingOptions: {
          defaultSortOrder: 'desc'
        },
        render: created => dayjs(created).format('DD. MM. YYYY')
      },
      ...(withIssue ? issueActionsColumn : [])
    ],
    []
  );

  const columns: ColumnsType<DeviceEvaluationRow> = useMemo(
    () => [
      {
        title: t('components.table.deviceEvaluation.platform'),
        key: 'platform',
        width: '120px',
        align: 'center',
        sorting: true,
        sortingOptions: {
          dataType: 'string'
        },
        render: (platform: string) => (
          <HStack w="min-content" justify="center" spacing={2}>
            <Text fontSize="1.2rem">{usePlatformIcon(platform)}</Text>
            <Text>{serializePlatform(platform)}</Text>
          </HStack>
        )
      },
      {
        title: t('components.table.deviceEvaluation.vendor'),
        key: 'manufacturer',
        width: '140px',
        sorting: true,
        sortingOptions: {
          dataType: 'string'
        },
        render: vendor => serializeVendor(vendor)
      },
      {
        title: t('components.table.deviceEvaluation.model'),
        key: 'marketing_name',
        sorting: true,
        sortingOptions: {
          dataType: 'string'
        },
        render: (value, record) => value || record.model || record.device
      },
      {
        title: t('components.table.deviceEvaluation.user'),
        key: 'user',
        sorting: true,
        width: '240px',
        sortingOptions: {
          dataType: 'string',
          sortingFn: ({ original: a }, { original: b }) => {
            if (a.user_name && b.user_name) {
              return a.user_name.localeCompare(b.user_name);
            }

            return 0;
          }
        },
        render: (_, device) => <UserTooltip userId={device.user_id!} userName={device.user_name!} />
      },
      {
        title: t('components.table.deviceEvaluation.riskScore'),
        key: 'riskScore',
        align: 'center',
        children: [
          {
            title: t('components.table.deviceEvaluation.device'),
            key: 'device_score',
            align: 'center',
            width: '110px',
            sorting: true,
            sortingOptions: {
              dataType: 'number',
              sortingFn: (rowA, rowB) => {
                return (rowA.original.device_score || 0) - (rowB.original.device_score || 0);
              }
            },
            render: (_, device) => {
              return (
                <HStack justify="center" spacing={0}>
                  <SeverityNumTag severityScore={device.device_score} />
                  {device.device_score !== undefined && (
                    <Text pos="relative" top="0.5rem" left="0.2rem">
                      /10
                    </Text>
                  )}
                </HStack>
              );
            }
          },
          {
            title: t('components.table.deviceEvaluation.apps'),
            key: 'applications_score',
            align: 'center',
            width: '100px',
            sorting: true,
            sortingOptions: {
              dataType: 'number',
              sortingFn: (rowA, rowB) => {
                return (rowA.original.applications_score || 0) - (rowB.original.applications_score || 0);
              }
            },
            render: (_, { applications_score }) => {
              if (applications_score !== null) {
                return (
                  <HStack justify="center" spacing={0}>
                    <SeverityNumTag severityScore={applications_score} />
                    {applications_score !== undefined && (
                      <Text pos="relative" top="0.5rem" left="0.2rem">
                        /10
                      </Text>
                    )}
                  </HStack>
                );
              }

              return (
                <span>
                  <SeverityNumTag severityScore={applications_score} />
                </span>
              );
            }
          },
          {
            title: t('components.table.deviceEvaluation.privacy'),
            key: 'privacy_score',
            align: 'center',
            width: '100px',
            sorting: true,
            sortingOptions: {
              dataType: 'number',
              sortingFn: (rowA, rowB) => {
                return (rowA.original.privacy_score || 0) - (rowB.original.privacy_score || 0);
              }
            },
            render: (_, { privacy_score }) => {
              if (privacy_score !== null) {
                return (
                  <HStack justify="center" spacing={0}>
                    <SeverityNumTag severityScore={privacy_score} />
                    {privacy_score !== undefined && (
                      <Text pos="relative" top="0.5rem" left="0.2rem">
                        /10
                      </Text>
                    )}
                  </HStack>
                );
              }

              return (
                <span>
                  <SeverityNumTag severityScore={privacy_score} />
                </span>
              );
            }
          },
          {
            title: t('components.table.deviceEvaluation.network'),
            key: 'network_score',
            align: 'center',
            width: '100px',
            sorting: true,
            sortingOptions: {
              dataType: 'number',
              sortingFn: (rowA, rowB) => {
                return (rowA.original.network_score || 0) - (rowB.original.network_score || 0);
              }
            },
            render: (_, { network_score }) => {
              if (network_score !== null) {
                return (
                  <HStack justify="center" spacing={0}>
                    <SeverityNumTag severityScore={network_score} />
                    {network_score !== undefined && (
                      <Text pos="relative" top="0.5rem" left="0.2rem">
                        /10
                      </Text>
                    )}
                  </HStack>
                );
              }

              return (
                <span>
                  <SeverityNumTag severityScore={network_score} />
                </span>
              );
            }
          }
        ]
      },
      {
        title: t('components.table.deviceEvaluation.appVersion'),
        key: 'app_version',
        width: '140px',
        sorting: true,
        align: 'center',
        sortingOptions: {
          dataType: 'number'
        },
        render: (_, { app_version: version, latest_available_app_version: latestVersion }) => (
          <SensorAppVersion version={version} latestVersion={latestVersion} />
        )
      },
      {
        title: t('components.table.deviceEvaluation.lastScan'),
        key: 'created',
        width: '140px',
        sorting: true,
        sortingOptions: {
          defaultSortOrder: 'desc'
        },
        render: created => dayjs(created).format('DD. MM. YYYY')
      },
      ...(withIssue ? issueActionsColumn : [])
    ],
    []
  );

  const formattedDevices: DeviceEvaluationRow[] = useMemo(
    () => devices.map(device => ({ key: device.id, ...device })),
    [devices]
  );

  return (
    <RTable
      columns={simple ? simpleColumns : columns}
      data={formattedDevices}
      minWidth="1100px"
      onRowClick={
        !withIssue
          ? ({ id: deviceId, platform, user_id: userId }: DeviceEvaluationRow, e) => {
              const isMiddleClick = e.button === 1;

              if ((e.target as HTMLElement).id === 'user-link') {
                if (isMiddleClick) {
                  window
                    .open(
                      parseRoute(user, ROUTES.ADMIN.USERS.DETAIL, {
                        id: userId || ''
                      }),
                      '_blank'
                    )!
                    .focus();
                } else {
                  navigate(
                    parseRoute(user, ROUTES.ADMIN.USERS.DETAIL, {
                      id: userId || ''
                    })
                  );
                }
              } else {
                // If you're logged as user or you're on the user page, redirect to user's device detail
                if (hasRole(user.role, Role.user) || window.location.pathname.split('/')[1] === 'user') {
                  if (isMiddleClick) {
                    window
                      .open(
                        parseRoute(user, ROUTES.USER.DEVICES.DETAIL, {
                          id: deviceId,
                          platform
                        }),
                        '_blank'
                      )!
                      .focus();
                  } else {
                    navigate(
                      parseRoute(user, ROUTES.USER.DEVICES.DETAIL, {
                        id: deviceId,
                        platform
                      })
                    );
                  }
                } else {
                  if (isMiddleClick) {
                    window
                      .open(
                        parseRoute(user, ROUTES.ADMIN.DEVICES.DETAIL, {
                          id: deviceId,
                          platform
                        }),
                        '_blank'
                      )!
                      .focus();
                  } else {
                    navigate(
                      parseRoute(user, ROUTES.ADMIN.DEVICES.DETAIL, {
                        id: deviceId,
                        platform
                      })
                    );
                  }
                }
              }
            }
          : undefined
      }
      selectable={
        selectable
          ? {
              rowSelectable: () => true,
              onChange: selectedRows =>
                onSelectedDevicesChange
                  ? onSelectedDevicesChange(selectedRows as unknown as DeviceUserEvaluation[])
                  : null
            }
          : undefined
      }
      loading={loading}
      defaultSelectedRows={defaultSelectedDevices?.map(device => device.id) || []}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
      }}
      interleaveRows
    />
  );
};

export default DevicesEvaluationTable;
