import { HStack, Link, PropertyCard, Text, VStack } from '@ramp/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import StorageChart from 'components/StorageChart';
import { useDeviceDetail } from 'pages/admin/devices/detail/DeviceDetailProvider';
import { DeviceRecommendation, Nullable } from 'types';
import { MacOSScan } from 'types/api/devices';
import { getSeverityFromScore, getSeverityTextColor } from 'utils/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MacOSSettingsProps {}

const MacOSSettings: React.FC<MacOSSettingsProps> = () => {
  const { t } = useTranslation();
  const { platform, scan, recommendations } = useDeviceDetail();

  if (!platform || !scan || !scan.os || !recommendations) return null;

  const { device, device_settings: deviceSettings, global_score: globalScore } = scan as MacOSScan;

  if (!device || !deviceSettings || !globalScore) return null;

  const osSettingsScore = globalScore.os_settings_score || 0;
  const osSettingsSeverity = getSeverityFromScore(osSettingsScore);

  const settingsRecommendations: Record<string, Nullable<DeviceRecommendation>> = {
    disk_encryption: recommendations.find(rec => rec.group_codename === 'disk_encryption') || null,
    screen_lock: recommendations.find(rec => rec.group_codename === 'screen_lock') || null,
    storage: recommendations.find(rec => rec.group_codename === 'storages') || null,
    system_integrity_protection:
      recommendations.find(rec => rec.group_codename === 'system_integrity_protection') || null,
    uptime: recommendations.find(rec => rec.group_codename === 'uptime') || null
  };

  const renderDescription = (description: string, link: string) => {
    return (
      <>
        {description}&nbsp;
        <Link color="brand.500" textDecoration="underline" href={link} target="_blank" rel="noreferrer">
          {t('admin.dashboard.issues.table.moreInfo')}
        </Link>
      </>
    );
  };

  return (
    <VStack w={{ sm: 'full', lg: '50%' }} mt={{ sm: '2rem', lg: 0 }} ml={{ lg: '1rem', xl: '2rem' }}>
      <HStack w="full" alignItems="center" justifyContent="space-between">
        <Text fontSize={{ sm: '24px', md: '28px', lg: '24px', xl: '28px' }} fontWeight={700}>
          {t('admin.devices.detail.device.windowsOsSettings').toUpperCase()}
        </Text>
        <HStack spacing={0} alignItems="end">
          <Text
            fontWeight={700}
            color={getSeverityTextColor(osSettingsSeverity)}
            fontSize={{ sm: '24px', md: '28px', lg: '24px', xl: '28px' }}
          >
            {osSettingsScore.toFixed(1)}
          </Text>
          <Text fontSize={{ sm: '18px', md: '20px', lg: '18px', xl: '20px' }} fontWeight={700}>
            /10
          </Text>
        </HStack>
      </HStack>
      <VStack w="full" spacing={4}>
        <PropertyCard
          w="full"
          status={
            settingsRecommendations.screen_lock
              ? settingsRecommendations.screen_lock.severity
              : t('admin.devices.detail.device.unknown')
          }
          title={
            settingsRecommendations.screen_lock
              ? settingsRecommendations.screen_lock.group
              : t('admin.devices.detail.device.screenLock')
          }
          description={
            settingsRecommendations.screen_lock ? (
              renderDescription(
                settingsRecommendations.screen_lock.recommendation_description,
                settingsRecommendations.screen_lock.knowledge_base_url
              )
            ) : (
              <i>{t('admin.devices.detail.device.noRecommendation')}</i>
            )
          }
        />
        <PropertyCard
          w="full"
          status={
            settingsRecommendations.disk_encryption
              ? settingsRecommendations.disk_encryption.severity
              : t('admin.devices.detail.device.unknown')
          }
          title={
            settingsRecommendations.disk_encryption
              ? settingsRecommendations.disk_encryption.group
              : t('admin.devices.detail.device.diskEncryption')
          }
          description={
            settingsRecommendations.disk_encryption ? (
              renderDescription(
                settingsRecommendations.disk_encryption.recommendation_description,
                settingsRecommendations.disk_encryption.knowledge_base_url
              )
            ) : (
              <i>{t('admin.devices.detail.device.noRecommendation')}</i>
            )
          }
        />
        <PropertyCard
          w="full"
          status={settingsRecommendations.storage?.severity || 'unknown'}
          title={
            settingsRecommendations.storage
              ? settingsRecommendations.storage.group
              : t('admin.devices.detail.device.storages')
          }
          description={
            settingsRecommendations.storage ? (
              renderDescription(
                settingsRecommendations.storage.recommendation_description,
                settingsRecommendations.storage.knowledge_base_url
              )
            ) : (
              <i>{t('admin.devices.detail.device.noRecommendation')}</i>
            )
          }
          openable
        >
          <StorageChart drives={(scan as MacOSScan).extended_device_info!.storage} />
        </PropertyCard>
        <PropertyCard
          w="full"
          status={deviceSettings.firewall_enabled ? 'safe' : 'warning'}
          title="Firewall"
          description={
            !deviceSettings.firewall_enabled
              ? renderDescription(
                  t('admin.devices.detail.device.firewall.disabled'),
                  'https://support.apple.com/guide/mac-help/change-firewall-settings-on-mac-mh11783/mac'
                )
              : t('admin.devices.detail.device.firewall.enabled')
          }
        />
      </VStack>
    </VStack>
  );
};

export default MacOSSettings;
