import { VStack } from '@ramp/components';
import React, { useMemo } from 'react';

import { useDeviceDetail } from 'pages/admin/devices/detail/DeviceDetailProvider';

import AppSearch from '../AppsTab/AppSearch';
import InstalledApps from './InstalledApps';
import MacOSAppsStats from './MacOSAppsStats';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MacOSAppsTabProps {}

const MacOSAppsTab: React.FC<MacOSAppsTabProps> = () => {
  const { macOSApps } = useDeviceDetail();
  const [searchedApp, setSearchedApp] = React.useState<string>('');

  const filteredApps = useMemo(
    () => macOSApps?.evaluated_applications!.filter(app => app.name.toLowerCase().includes(searchedApp.toLowerCase())),
    [searchedApp]
  );

  const sortedApps = useMemo(() => filteredApps?.sort((a, b) => a.name.localeCompare(b.name)), [filteredApps]);

  return (
    <VStack w="full" spacing={4}>
      <AppSearch searchedApp={searchedApp} setSearchedApp={setSearchedApp} />
      <MacOSAppsStats apps={sortedApps || []} />
      <InstalledApps installed_apps={sortedApps || []} />
    </VStack>
  );
};

export default MacOSAppsTab;
