import { Heading, HStack, RStat, useColorModeValue, VStack } from '@ramp/components';
import { LayoutGrid } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { MacOSApp } from 'types/api/devices';

interface MacOSAppsStatsProps {
  apps: MacOSApp[];
}

const MacOSAppsStats: React.FC<MacOSAppsStatsProps> = ({ apps }) => {
  const { t } = useTranslation();

  return (
    <VStack align="start" w="full">
      <Heading
        mt="2rem !important"
        mb="0.25rem !important"
        color={useColorModeValue('black', 'gray.400')}
        fontSize="1.5rem"
        fontWeight={600}
      >
        {t('admin.devices.detail.apps.statistics.title')}
      </Heading>
      <HStack w="full" spacing={['16px', '16px', '16px']}>
        <RStat
          icon={<LayoutGrid size="32px" />}
          number={apps.length}
          description={t('admin.devices.detail.apps.statistics.count')}
          type="darkGradient"
          w="full"
          maxW="320px"
        />
      </HStack>
    </VStack>
  );
};

export default MacOSAppsStats;
