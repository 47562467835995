import { chakra, useColorModeValue } from '@ramp/components';

interface BarListProps<
  T extends {
    barWidth?: number;
    [key: string]: any;
  }
> {
  labels: Record<Exclude<keyof T, 'barWidth'>, string>;
  values: T[];
}

const BarList = <
  T extends {
    barWidth?: number;
    [key: string]: any;
  }
>({
  labels,
  values
}: BarListProps<T>) => {
  return (
    <chakra.table width="100%">
      <chakra.thead>
        <chakra.tr textColor="gray.400" fontSize="small">
          {Object.values(labels).map((label, index) => (
            <chakra.th
              key={index}
              width={index === 0 ? 'fit-content' : '20'}
              py="1"
              px="3"
              textAlign={index == 0 ? 'left' : 'right'}
              whiteSpace="nowrap"
            >
              {String(label)}
            </chakra.th>
          ))}
        </chakra.tr>
      </chakra.thead>
      <chakra.tbody>
        {values.map((row, colIndex) => {
          row.barWidth ??= -1;

          return (
            <chakra.tr key={colIndex}>
              {Object.entries(row)
                .filter(([key]) => key !== 'barWidth')
                .map(([key, value], index) => {
                  return index === 0 ? (
                    <chakra.td padding="3" key={key} position="relative">
                      {/* The bar itself */}
                      {row.barWidth != -1 && (
                        <chakra.div position="absolute" inset={0}>
                          <chakra.div
                            borderRadius="md"
                            my="1"
                            position="absolute"
                            left={0}
                            insetY={0}
                            width="100%"
                            backgroundColor={useColorModeValue('gray.200', 'gray.800')}
                          />
                          <chakra.div
                            position="absolute"
                            overflow="hidden"
                            borderRadius="md"
                            left={0}
                            insetY={0}
                            my="1"
                            width={`${row.barWidth}%`}
                            backgroundColor={useColorModeValue('gray.300', 'gray.700')}
                          >
                            <chakra.div
                              opacity={0.2}
                              position="absolute"
                              inset={0}
                              backgroundColor={row.barWidth! > 80 ? 'warning.500' : 'green.500'}
                            />
                          </chakra.div>
                        </chakra.div>
                      )}
                      {/* The bar itself */}

                      <chakra.span
                        {...(row.barWidth != -1 && { textColor: 'white' })}
                        position="relative"
                        display="flex"
                        justifyContent="space-between"
                        zIndex={10}
                      >
                        <chakra.span>{value}</chakra.span>
                        <chakra.span>{Math.ceil(row.barWidth!)}%</chakra.span>
                      </chakra.span>
                    </chakra.td>
                  ) : (
                    <chakra.td width="20" padding="3" textAlign="right" key={key}>
                      <chakra.span whiteSpace="nowrap">{value}</chakra.span>
                    </chakra.td>
                  );
                })}
            </chakra.tr>
          );
        })}
      </chakra.tbody>
    </chakra.table>
  );
};

export default BarList;
