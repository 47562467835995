import { flip, offset, shift, useFloating } from '@floating-ui/react';
import { Box, HStack, Spinner, Tag, Text, useColorModeValue, VStack } from '@ramp/components';
import { Android, Apple, Linux, User, Windows } from '@ramp/icons';
import React, { useEffect, useState } from 'react';
import { FiUser } from 'react-icons/fi';

import api from 'api';
import { UserData } from 'types';

type UserTooltipProps =
  | {
      userId: string;
      userName: string;
      user?: never;
    }
  | {
      userId?: never;
      userName?: never;
      user: UserData;
    };

const UserTooltip: React.FC<UserTooltipProps> = ({ userId, userName, user }) => {
  const { refs, floatingStyles } = useFloating({ placement: 'top', middleware: [offset(16), shift(), flip()] });

  const [isOpen, setIsOpen] = useState(false);
  const [tooltipUser, setTooltipUser] = useState<UserData>(user!);

  useEffect(() => {
    if (isOpen && !tooltipUser) api.users.getUser(userId!).then(setTooltipUser);
  }, [isOpen]);

  const colors = {
    'gray.800': useColorModeValue('white', 'gray.800'),
    'gray.750': useColorModeValue('gray.25', 'gray.750'),
    'gray.700': useColorModeValue('gray.10', 'gray.700')
  };

  return (
    <>
      <Tag
        ref={refs.setReference}
        colorScheme="unknown"
        id="user-link"
        _hover={{
          textDecor: 'underline'
        }}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <Text fontSize="sm">
          <FiUser />
        </Text>
        <Text fontSize="sm" id="user-link" pos="relative" top="1px" ml="0.25rem">
          {userName ?? user?.name}
        </Text>
      </Tag>
      {isOpen && (
        <VStack
          ref={refs.setFloating}
          style={floatingStyles}
          px="12px"
          py="12px"
          spacing="12px"
          borderRadius="12px"
          bg={colors['gray.800']}
          boxShadow="2xl"
          zIndex={1000}
        >
          {!tooltipUser && <Spinner size="md" color="brand.500" />}
          {tooltipUser && (
            <React.Fragment>
              <HStack w="full" pb="12px" borderBottom="1px solid" borderColor={colors['gray.700']}>
                <Box p="8px" borderRadius="8px" bg={colors['gray.750']}>
                  <User size={32} />
                </Box>
                <VStack spacing="0px" align="start">
                  <Text fontSize="16px" fontWeight="500">
                    {tooltipUser.name}
                  </Text>
                  <Text fontSize="14px" fontWeight="400">
                    {tooltipUser.email}
                  </Text>
                </VStack>
              </HStack>
              <HStack px="4px" spacing="24px">
                <HStack spacing="8px">
                  <Apple size={16} />
                  <Text fontSize="16px" fontWeight="500">
                    {tooltipUser.ios_devices}
                  </Text>
                </HStack>
                <HStack spacing="8px">
                  <Android size={16} />
                  <Text fontSize="16px" fontWeight="500">
                    {tooltipUser.android_devices}
                  </Text>
                </HStack>
                <HStack spacing="8px">
                  <Apple size={16} />
                  <Text fontSize="16px" fontWeight="500">
                    {tooltipUser.ipados_devices}
                  </Text>
                </HStack>
                <Box w="1px" h="18px" bg={colors['gray.700']} />
                <HStack spacing="8px">
                  <Apple size={16} />
                  <Text fontSize="16px" fontWeight="500">
                    {tooltipUser.macos_devices}
                  </Text>
                </HStack>
                <HStack spacing="8px">
                  <Windows size={16} />
                  <Text fontSize="16px" fontWeight="500">
                    {tooltipUser.windows_devices}
                  </Text>
                </HStack>
                <HStack spacing="8px">
                  <Linux size={16} />
                  <Text fontSize="16px" fontWeight="500">
                    {tooltipUser.linux_devices}
                  </Text>
                </HStack>
              </HStack>
            </React.Fragment>
          )}
        </VStack>
      )}
    </>
  );
};

export default UserTooltip;
