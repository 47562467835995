import { createStandaloneToast } from '@ramp/components';
import { theme } from '@ramp/theme';
import React from 'react';

interface NotificationsProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

const { ToastContainer } = createStandaloneToast({
  theme,
  defaultOptions: {
    position: 'bottom-right',
    duration: 5000,
    isClosable: false,
    containerStyle: {
      maxWidth: '380px'
    }
  }
});

export const NotificationProvider: React.FC<NotificationsProviderProps> = ({ children }) => (
  <>
    {children}
    <ToastContainer />
  </>
);
