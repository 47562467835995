import { Box, Flex, HStack, RCard, Text, useColorModeValue, VStack } from '@ramp/components';
import {
  BatteryCharging,
  BellRing,
  Bluetooth,
  LocateFixed,
  MapPin,
  RefreshCw,
  Smartphone,
  SmartphoneNfcIcon
} from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Nullable, Platforms } from 'types';
import { AndroidAppPermissionsInfo, IosAppPermissionsInfo } from 'types/api/devices';

interface SensorPermissionsCardProps {
  platform?: Platforms;
  permissions: Nullable<AndroidAppPermissionsInfo> | Nullable<IosAppPermissionsInfo>;
}

const PermissionRow: React.FC<{ icon: React.ReactNode; permission: string; value: string }> = ({
  icon,
  permission,
  value
}) => {
  const { t } = useTranslation();

  const valueColor = (val: string) => {
    switch (val) {
      case 'enabled':
        return 'success.500';
      case 'always':
        return 'success.500';
      case 'while_using':
        return 'success.500';
      case 'unrestricted':
        return 'success.500';
      case 'ask':
        return 'warning.500';
      case 'optimized':
        return 'warning.500';
      case 'never':
        return 'error.500';
      case 'restricted':
        return 'error.500';
      case 'disabled':
        return 'error.500';
      default:
        return 'unknown.500';
    }
  };

  const parseValue = (val: string) => {
    switch (val) {
      case 'enabled':
        return t('components.permissionRow.enabled').toUpperCase();
      case 'always':
        return t('components.permissionRow.always').toUpperCase();
      case 'while_using':
        return t('components.permissionRow.whileUsing').toUpperCase();
      case 'unrestricted':
        return t('components.permissionRow.unrestricted').toUpperCase();
      case 'ask':
        return t('components.permissionRow.ask').toUpperCase();
      case 'optimized':
        return t('components.permissionRow.optimized').toUpperCase();
      case 'never':
        return t('components.permissionRow.never').toUpperCase();
      case 'restricted':
        return t('components.permissionRow.restricted').toUpperCase();
      case 'disabled':
        return t('components.permissionRow.disabled').toUpperCase();
      case 'unknown':
        return t('components.permissionRow.unknown').toUpperCase();
      default:
        return val.toUpperCase();
    }
  };

  return (
    <HStack w="full" align="center" justify="space-between">
      <HStack align="center" spacing="16px">
        <Box p="6px" bg={useColorModeValue('gray.10', 'gray.700')} borderRadius="8px">
          {icon}
        </Box>
        <Text fontWeight="500">{permission}</Text>
      </HStack>
      <Text fontSize="16px" fontWeight={600} color={valueColor(value)}>
        {parseValue(value)}
      </Text>
    </HStack>
  );
};

const SensorPermissionsCard: React.FC<SensorPermissionsCardProps> = ({ platform, permissions }) => {
  const { t } = useTranslation();

  if (!platform || !permissions) {
    return (
      <RCard w="full">
        <Flex w="full" h="full" minH="240px" alignItems="center" justifyContent="center">
          <Text fontSize="1rem" textAlign="center" fontWeight={500} color="gray.400">
            {t('admin.devices.detail.overview.noPermissions')}
          </Text>
        </Flex>
      </RCard>
    );
  }

  if (platform === 'android') {
    const {
      location_permission: location,
      battery_restriction: battery,
      phone_permission: phone,
      nearby_devices_permission: devices,
      notifications,
      background_location_permission: backgroundLocation
    } = permissions as AndroidAppPermissionsInfo;

    return (
      <RCard w="full">
        <VStack w="full" spacing="16px">
          <PermissionRow
            icon={<BatteryCharging />}
            permission={t('admin.devices.detail.overview.permissions.batteryRestriction')}
            value={battery}
          />
          <PermissionRow
            icon={<MapPin />}
            permission={t('admin.devices.detail.overview.permissions.location')}
            value={location ? 'enabled' : 'disabled'}
          />
          <PermissionRow
            icon={<SmartphoneNfcIcon />}
            permission={t('admin.devices.detail.overview.permissions.nearbyDevices')}
            value={devices ? 'enabled' : 'disabled'}
          />
          <PermissionRow
            icon={<BellRing />}
            permission={t('admin.devices.detail.overview.permissions.notifications')}
            value={notifications ? 'enabled' : 'disabled'}
          />
          <PermissionRow
            icon={<Smartphone />}
            permission={t('admin.devices.detail.overview.permissions.phone')}
            value={phone ? 'enabled' : 'disabled'}
          />
          <PermissionRow
            icon={<LocateFixed />}
            permission={t('admin.devices.detail.overview.permissions.backgroundLocation')}
            value={backgroundLocation !== null ? (backgroundLocation ? 'enabled' : 'disabled') : 'unknown'}
          />
        </VStack>
      </RCard>
    );
  }

  if (platform === 'ios' || platform === 'ipados') {
    const {
      location_permission: location,
      bluetooth_permission: bluetooth,
      background_app_refresh_permission: backgroundRefresh,
      notifications
    } = permissions as IosAppPermissionsInfo;

    return (
      <RCard w="full">
        <VStack w="full" spacing="16px">
          <PermissionRow
            icon={<MapPin />}
            permission={t('admin.devices.detail.overview.permissions.location')}
            value={location}
          />
          <PermissionRow
            icon={<Bluetooth />}
            permission={t('admin.devices.detail.overview.permissions.bluetooth')}
            value={bluetooth ? 'enabled' : 'disabled'}
          />
          <PermissionRow
            icon={<BellRing />}
            permission={t('admin.devices.detail.overview.permissions.notifications')}
            value={notifications ? 'enabled' : 'disabled'}
          />
          <PermissionRow
            icon={<RefreshCw />}
            permission={t('admin.devices.detail.overview.permissions.backgroundAppRefresh')}
            value={backgroundRefresh ? 'enabled' : 'disabled'}
          />
        </VStack>
      </RCard>
    );
  }

  return null;
};

export default SensorPermissionsCard;
