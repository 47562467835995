import { HStack, PropertyCard, Text, VStack } from '@ramp/components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NetworkScan } from 'types';
import { serializeNetworkProtocolType } from 'utils/serializators';
import { getSeverityFromScore, getSeverityTextColor, toCapitalize } from 'utils/utils';

interface NetworkDetailProps {
  network: NetworkScan;
}

const NetworkDetail: React.FC<NetworkDetailProps> = ({ network }) => {
  const { t } = useTranslation();

  const recomm = {
    proxy: network.recommendations.find(rec => rec.group_codename === 'proxy'),
    vpn: network.recommendations.find(rec => rec.group_codename === 'vpn'),
    dns: network.recommendations.find(rec => rec.group_codename === 'dns'),
    network_security_type: network.recommendations.find(rec => rec.group_codename === 'network_security_type'),
    traffic: network.recommendations.find(rec => rec.group_codename === 'traffic')
  };

  const settingsScore =
    network.network_type !== t('admin.devices.detail.device.unknown') ? network.network_settings_score || 0 : 0;
  const settingsScoreSeverity = getSeverityFromScore(settingsScore);

  const trafficScore =
    network.network_type !== t('admin.devices.detail.device.unknown') ? network.traffic_score || 0 : 0;
  const trafficScoreSeverity = getSeverityFromScore(trafficScore);

  const firewallCheck = useMemo(() => {
    return network.firewall_check?.sort((a, b) => a.port - b.port);
  }, [network.firewall_check]);

  return (
    <HStack w="full" spacing={4} alignItems="start">
      <VStack w="50%" spacing={3} alignItems="start">
        <HStack w="full" alignItems="center" justifyContent="space-between">
          <Text fontSize="20px" fontWeight={700} color="gray.400">
            {t('admin.devices.detail.network.settings').toUpperCase()}
          </Text>
          <HStack spacing={0} alignItems="end">
            <Text
              fontWeight={700}
              color={getSeverityTextColor(settingsScoreSeverity)}
              fontSize={{ sm: '20px', md: '24px', lg: '20px', xl: '24px' }}
            >
              {settingsScore.toFixed(1)}
            </Text>
            <Text fontSize={{ sm: '14px', md: '16px', lg: '14px', xl: '16px' }} fontWeight={700}>
              /10
            </Text>
          </HStack>
        </HStack>
        <VStack w="full" spacing={2}>
          {['wifi', 'cellular'].includes(network.network_type) && (
            <PropertyCard
              w="full"
              title={
                recomm.network_security_type
                  ? recomm.network_security_type.group
                  : t('admin.devices.detail.network.networkSecurityType')
              }
              description={
                recomm.network_security_type ? (
                  recomm.network_security_type.recommendation_description
                ) : (
                  <i>{t('admin.devices.detail.device.noRecommendation')}</i>
                )
              }
              status={
                recomm.network_security_type
                  ? recomm.network_security_type.severity
                  : t('admin.devices.detail.device.unknown')
              }
            />
          )}
          <PropertyCard
            w="full"
            title={recomm.dns ? recomm.dns.group : 'DNS'}
            description={
              recomm.dns ? (
                recomm.dns.recommendation_description
              ) : (
                <i>{t('admin.devices.detail.device.noRecommendation')}</i>
              )
            }
            status={recomm.dns ? recomm.dns.severity : t('admin.devices.detail.device.unknown')}
            onSimpleTable
          />
          {/*<PropertyCard*/}
          {/*  w="full"*/}
          {/*  title={recomm.vpn ? recomm.vpn.group : 'VPN'}*/}
          {/*  description={recomm.vpn*/}
          {/*    ? recomm.vpn.recommendation_description*/}
          {/*    : <i>{t('admin.devices.detail.device.noRecommendation')}</i>*/}
          {/*  }*/}
          {/*  status={recomm.vpn*/}
          {/*    ? recomm.vpn.severity*/}
          {/*    : t('admin.devices.detail.device.unknown')*/}
          {/*  }*/}
          {/*/>*/}
          {/*<PropertyCard*/}
          {/*  w="full"*/}
          {/*  title={recomm.proxy ? recomm.proxy.group : 'Proxy'}*/}
          {/*  description={recomm.proxy*/}
          {/*    ? recomm.proxy.recommendation_description*/}
          {/*    : <i>{t('admin.devices.detail.device.noRecommendation')}</i>*/}
          {/*  }*/}
          {/*  status={recomm.proxy*/}
          {/*    ? recomm.proxy.severity*/}
          {/*    : t('admin.devices.detail.device.unknown')*/}
          {/*  }*/}
          {/*/>*/}
        </VStack>
      </VStack>
      <VStack w="50%" spacing={3} alignItems="start">
        <HStack w="full" alignItems="center" justifyContent="space-between">
          <Text fontSize="20px" fontWeight={700} color="gray.400">
            {t('admin.devices.detail.network.trafficSecurity').toUpperCase()}
          </Text>
          <HStack spacing={0} alignItems="end">
            <Text
              fontWeight={700}
              color={getSeverityTextColor(trafficScoreSeverity)}
              fontSize={{ sm: '20px', md: '24px', lg: '20px', xl: '24px' }}
            >
              {trafficScore.toFixed(1)}
            </Text>
            <Text fontSize={{ sm: '14px', md: '16px', lg: '14px', xl: '16px' }} fontWeight={700}>
              /10
            </Text>
          </HStack>
        </HStack>
        <VStack w="full" spacing={2}>
          <PropertyCard
            w="full"
            title={recomm.traffic ? recomm.traffic.group : t('admin.devices.detail.network.trafficProtection')}
            description={
              recomm.traffic ? (
                recomm.traffic.recommendation_description
              ) : (
                <i>{t('admin.devices.detail.device.noRecommendation')}</i>
              )
            }
            status={recomm.traffic ? recomm.traffic.severity : t('admin.devices.detail.device.unknown')}
          />
          <PropertyCard
            w="full"
            title={t('admin.networks.detail.outboundPortCheck.title')}
            description={
              !!firewallCheck && firewallCheck.length > 0 ? (
                t('admin.networks.detail.outboundPortCheck.description')
              ) : (
                <i>{t('admin.networks.detail.outboundPortCheck.descriptionError')}</i>
              )
            }
            status={!!firewallCheck && firewallCheck.length > 0 ? 'info' : 'unknown'}
            openable={!!firewallCheck && firewallCheck.length > 0}
          >
            {!!firewallCheck && firewallCheck.length > 0 && (
              <table width="100%">
                <thead style={{ fontSize: '15px' }}>
                  <tr
                    style={{
                      borderBottom: '1px solid var(--chakra-colors-gray-650)'
                    }}
                  >
                    <th style={{ textAlign: 'left', paddingBottom: '16px' }}>Port</th>
                    <th style={{ textAlign: 'left', paddingBottom: '16px' }}>
                      {t('admin.networks.detail.outboundPortCheck.protocol')}
                    </th>
                    <th style={{ textAlign: 'left', paddingBottom: '16px' }}>
                      {t('admin.networks.detail.outboundPortCheck.type')}
                    </th>
                    <th style={{ textAlign: 'center', paddingBottom: '16px' }}>
                      {t('admin.networks.detail.outboundPortCheck.blocked')}
                    </th>
                  </tr>
                </thead>
                <div style={{ width: '100%', height: '4px' }} />
                <tbody>
                  {firewallCheck.map(check => (
                    <tr key={check.port}>
                      <td style={{ textAlign: 'left', paddingTop: '8px' }}>
                        [{check.protocol}] {check.port}
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        {serializeNetworkProtocolType(check.port) ? (
                          serializeNetworkProtocolType(check.port)
                        ) : check.name === 'portquiz' ? (
                          <i>{t('admin.networks.detail.outboundPortCheck.any')}</i>
                        ) : (
                          check.name.toUpperCase()
                        )}
                      </td>
                      <td style={{ textAlign: 'left' }}>
                        {check.type === 'dynamic' ? (
                          <i>{t('admin.networks.detail.outboundPortCheck.dynamic')}</i>
                        ) : check.type === 'iana' ? (
                          'IANA'
                        ) : (
                          toCapitalize(check.type)
                        )}
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {check.blocked
                          ? t('admin.networks.detail.outboundPortCheck.yes')
                          : t('admin.networks.detail.outboundPortCheck.no')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </PropertyCard>
        </VStack>
      </VStack>
    </HStack>
  );
};

export default NetworkDetail;
