import { ColumnsType, RTable, Text } from '@ramp/components';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { NetworkDeviceSnapshot } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import { ipToNumber } from 'utils/utils';

interface NetworkDeviceSnapshotsTableProps {
  id: string;
  deviceSnapshots?: NetworkDeviceSnapshot[];
  defaultPageSize?: number;
}

interface NetworkDeviceSnapshotRow extends NetworkDeviceSnapshot {
  key: number;
}

const NetworkDeviceSnapshotsTable: React.FC<NetworkDeviceSnapshotsTableProps> = ({
  id,
  deviceSnapshots,
  defaultPageSize = 10
}) => {
  if (!deviceSnapshots) return null;

  const { t } = useTranslation();

  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const columns: ColumnsType<NetworkDeviceSnapshotRow> = useMemo(
    () => [
      {
        title: t('admin.networks.detail.devicesTable.name'),
        key: 'hostname',
        sorting: true,
        sortingOptions: {
          dataType: 'string',
          sortingFn: ({ original: a }, { original: b }) => {
            // eslint-disable-next-line max-len
            return (a.name || t('admin.networks.detail.devicesTable.generic')).localeCompare(
              b.name || t('admin.networks.detail.devicesTable.generic')
            );
          }
        },
        render: (_, { name }) => {
          return name ? (
            // eslint-disable-next-line max-len
            <Text fontSize="md" fontWeight={500}>
              {name}
            </Text>
          ) : (
            <Text fontSize="md" fontWeight={500} fontStyle="italic">
              {t('admin.networks.detail.devicesTable.generic')}
            </Text>
          );
        }
      },
      {
        title: t('admin.networks.detail.devicesTable.ipAddress'),
        key: 'ip_address',
        sorting: true,
        sortingOptions: {
          dataType: 'string',
          sortingFn: ({ original: a }, { original: b }) => {
            return ipToNumber(a.ip_address || '') - ipToNumber(b.ip_address || '');
          }
        },
        render: (_, { ip_address }) => {
          return ip_address ? (
            <Text fontSize="md">{ip_address}</Text>
          ) : (
            <Text fontSize="md" fontStyle="italic">
              {t('admin.notifications.filter.severity.unknown')}
            </Text>
          );
        }
      },
      {
        title: t('admin.networks.detail.devicesTable.lastSeen'),
        key: 'created',
        sorting: true,
        sortingOptions: {
          defaultSortOrder: 'desc'
        },
        render: (_, { created }) => <Text fontSize="md">{dayjs(created).format('DD. MM. YYYY (HH:mm)')}</Text>
      }
    ],
    []
  );

  const formattedSnapshots: NetworkDeviceSnapshotRow[] = useMemo(
    () => deviceSnapshots.map((snapshot, idx) => ({ key: idx, ...snapshot })),
    [deviceSnapshots]
  );

  return (
    <RTable<NetworkDeviceSnapshotRow>
      columns={columns}
      data={formattedSnapshots}
      minWidth="1100px"
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
      }}
      interleaveRows
    />
  );
};

export default NetworkDeviceSnapshotsTable;
