import useNativeLazyLoading from '@charlietango/use-native-lazy-loading';
import { ColumnsType, HStack, SimpleTable, Text, VStack } from '@ramp/components';
import { BoxSelect } from 'lucide-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import TextTruncateTooltip from 'components/TextTruncateTooltip';
import { useDeviceDetail } from 'pages/admin/devices/detail/DeviceDetailProvider';
import { AppInfo, AppIssue } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import { renderAppRecommendation } from 'utils/utils';

interface RiskyAppsTableProps {
  id: string;
  apps: AppInfo[];
  issues?: Record<string, AppIssue>;
  searchFor?: string;
}

interface RiskyAppRow extends AppInfo {
  key: number;
}

const RiskyAppsTable: React.FC<RiskyAppsTableProps> = ({ apps, issues, searchFor = '' }) => {
  const { t } = useTranslation();
  const { appsIcons } = useDeviceDetail();

  const columns: ColumnsType<RiskyAppRow> = [
    {
      title: t('components.table.riskyApps.name'),
      key: 'package_name',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
        defaultSortOrder: 'asc',
        sortingFn: (a, b) => {
          const aName = a.original.application_name || a.original.package_name;
          const bName = b.original.application_name || b.original.package_name;
          return aName.localeCompare(bName);
        }
      },
      render: (_, app) => {
        const supportsLazyLoading = useNativeLazyLoading();
        const { ref, inView } = useInView({
          delay: 50,
          threshold: 0,
          triggerOnce: true,
          skip: !supportsLazyLoading
        });

        return (
          <HStack ref={ref} spacing="16px">
            {appsIcons && appsIcons[app.package_name] ? (
              inView ? (
                <img
                  src={appsIcons[app.package_name] || ''}
                  alt={app.package_name}
                  loading="lazy"
                  style={{ width: '32px', height: '32px', borderRadius: '8px' }}
                />
              ) : null
            ) : (
              <BoxSelect size="32px" />
            )}
            <Text>{app.application_name || app.package_name}</Text>
          </HStack>
        );
      }
    },
    {
      title: t('components.table.riskyApps.reason'),
      width: '380px',
      key: 'issue',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
        sortingFn: (a, b) => {
          const aIssue = issues ? issues[a.original.issues[0]].issue_title : '';
          const bIssue = issues ? issues[b.original.issues[0]].issue_title : '';
          return aIssue.localeCompare(bIssue);
        }
      },
      render: (_, app) => {
        return (
          <VStack spacing={8} alignItems="flex-start">
            {app.issues.map(issueId => {
              const issue = issues ? issues[issueId] : undefined;
              if (issue) return renderAppRecommendation(issueId, issue.issue_title);
              return null;
            })}
          </VStack>
        );
      }
    },
    {
      key: 'description',
      title: t('components.table.riskyApps.description'),
      render: (_, app) => (
        <VStack alignItems="flex-start">
          {app.issues.map(issueId => {
            const issue = issues ? issues[issueId] : undefined;
            if (issue) return <TextTruncateTooltip text={issue.issue_description} maxNumOfChars={160} />;
            return null;
          })}
        </VStack>
      )
    },
    {
      title: t('components.table.riskyApps.solution'),
      key: 'solution',
      render: (_, app) => (
        <VStack spacing={8} alignItems="flex-start">
          {app.issues.map(issueId => {
            const issue = issues ? issues[issueId] : undefined;
            if (issue) return <TextTruncateTooltip text={issue.solution_description} maxNumOfChars={160} />;
            return null;
          })}
        </VStack>
      )
    }
    // {
    //   title: t('components.table.riskyApps.details'),
    //   key: 'details',
    //   render: (_, app) => {
    //     if (app.details.length === 0) return <Text fontStyle="italic">No details at the moment</Text>;

    //     return <TextTruncateTooltip text={app.details.join(', ')} maxNumOfChars={160} />;
    //   },
    // },
  ];

  const filteredApps: RiskyAppRow[] = useMemo(() => {
    const formattedApps: RiskyAppRow[] = apps.map((app, key) => ({ key, ...app }));

    return formattedApps.filter(app => app.package_name.toLowerCase().includes(searchFor));
  }, [apps, searchFor]);

  return (
    <SimpleTable
      columns={columns}
      data={filteredApps}
      pagination={{
        showTotal: true,
        defaultPageSize: 10,
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
      }}
      interleaveRows
    />
  );
};

export default RiskyAppsTable;
