import { ColumnsType, HStack, RTable, Text } from '@ramp/components';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import IssueIgnorePopover from 'components/IssueIgnorePopover';
import { AffectedEmail, IssueDetail } from 'types';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';
import useTablePageSize from 'utils/hooks/useTablePageSize';

import { ScanStatusIndicator } from '../DataBreachedEmailsTable/ScanStatusIndicator';

interface AffectedEmailsTableProps {
  /**
   * Table id
   */
  id: string;

  /**
   * Emails to display in the table
   */
  emails?: AffectedEmail[];

  /**
   * Loading state of the table
   *
   * @default false
   */
  loading?: boolean;

  /**
   * Default page size of the table
   *
   * @default 50
   */
  defaultPageSize?: number;

  /**
   * Show pagination controls of the table
   *
   * @default true
   */
  showPaginationControls?: boolean;

  /**
   * If defined, the table will render with the "Actions" column,
   * where the user can see the device detail and ignore/activate issue.
   */
  withIssue?: IssueDetail;

  /**
   * Defines if we want to render "affected_objects" or "ignored_objects"
   * and if render "ignore/activate issue" button in the table
   *
   * If `true`, the table will render the objects that are actively affected and the "ignore/activate issue" button.
   *
   * If `false`, the table will render all objects and the "ignore/activate issue" button will not be displayed.
   *
   * `withIssue` property must be defined to use this property.
   *
   * @default true
   */
  showAffected?: boolean;

  /**
   * Defines if we want to render the table in user view
   *
   * @default false
   */
  userView?: boolean;
}

interface AffectedEmailRow extends AffectedEmail {
  key: string;
}

const AffectedEmailsTable: React.FC<AffectedEmailsTableProps> = ({
  id,
  emails,
  loading,
  defaultPageSize = 50,
  showPaginationControls = true,
  withIssue,
  showAffected = true,
  userView = false
}) => {
  if (!emails) return null;

  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize);

  const issueActionsColumn: ColumnsType<AffectedEmailRow> = useMemo(
    () => [
      {
        title: t('admin.dashboard.issues.table.actions'),
        key: 'actions',
        align: 'center' as const,
        render: (_: any, email: AffectedEmailRow) => (
          <HStack justify="center" spacing={2}>
            <IssueIgnorePopover
              issue={{
                ...withIssue!,
                n_affected_objects: 1,
                n_ignored_objects: 1,
                recommendations: withIssue!.recommendations.map(r => ({
                  ...r,
                  n_affected_objects: r.affected_objects.length.toString(),
                  n_ignored_objects: r.ignored_objects.length.toString()
                }))
              }}
              codenames={[
                {
                  codename: withIssue!.codename,
                  object_ids: [email.id]
                }
              ]}
              mode={showAffected ? 'ignore' : 'activate'}
              userView={userView}
            />
          </HStack>
        )
      }
    ],
    []
  );

  const columns: ColumnsType<AffectedEmailRow> = [
    {
      title: t('components.table.emailBreaches.email'),
      key: 'email',
      sorting: true,
      sortingOptions: {
        dataType: 'string',
        multiSortOrder: 2
      }
    },
    {
      title: t('components.table.emailBreaches.activeBreaches'),
      key: 'n_breaches',
      sorting: true,
      align: 'right',
      sortingOptions: {
        dataType: 'number',
        defaultSortOrder: 'desc',
        sortingFn: ({ original: a }, { original: b }) => a.n_breaches - b.n_breaches
      },
      render: breachCount => {
        if (breachCount === -1) return 0;
        if (breachCount === -2) return '-';

        return (
          <Text fontWeight={breachCount > 0 ? '500' : 'normal'} color={breachCount > 0 ? 'brand.500' : 'initial'}>
            {breachCount}
          </Text>
        );
      }
    },
    {
      title: t('components.table.emailBreaches.scanRequested'),
      key: 'scan_requested',
      align: 'left',
      sorting: true,
      sortingOptions: {
        multiSortOrder: 1
      },
      render: created => dayjs(created).format('DD. MM. YYYY')
    },
    {
      title: t('components.table.emailBreaches.lastScanDone'),
      key: 'scan_done',
      align: 'left',
      sorting: true,
      sortingOptions: {
        sortingFn: (a, b) => {
          if (!a.original.scan_done) return 1;
          if (!b.original.scan_done) return -1;
          return a.original.scan_done < b.original.scan_done ? -1 : 1;
        },
        multiSortOrder: 3
      },
      render: doneAt => {
        return doneAt ? dayjs(doneAt).format('DD. MM. YYYY') : <ScanStatusIndicator status="inProgress" />;
      }
    },
    ...(withIssue ? issueActionsColumn : [])
  ];

  const formattedEmails: AffectedEmailRow[] = useMemo(
    () =>
      emails.map(email => ({
        key: email.id,
        ...email
      })),
    [emails]
  );

  return (
    <RTable
      w="full"
      h="full"
      columns={columns}
      data={formattedEmails}
      loading={loading}
      pagination={
        showPaginationControls
          ? {
              showTotal: true,
              defaultPageSize: pageSize,
              onPageSizeChange: newPageSize => setPageSize(newPageSize),
              showGoToPage: true,
              showPageSizeSelector: true,
              pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
            }
          : {
              showTotal: false,
              showGoToPage: false,
              showPageSizeSelector: false,
              pageSize: 5
            }
      }
      interleaveRows
    />
  );
};

export default AffectedEmailsTable;
