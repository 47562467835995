import { VStack } from '@ramp/components';
import React, { useMemo } from 'react';

import { useDeviceDetail } from 'pages/admin/devices/detail/DeviceDetailProvider';
import AppSearch from '../AppsTab/AppSearch';
import InstalledApps from './InstalledApps';
import SystemApps from './SystemApps';
import UnupdatedApps from './UnupdatedApps';
import WindowsAppsStats from './WindowsAppsStats';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface WindowsAppsTabProps {}

const WindowsAppsTab: React.FC<WindowsAppsTabProps> = () => {
  const { windowsApps } = useDeviceDetail();
  const [searchedApp, setSearchedApp] = React.useState<string>('');

  const filteredApps = useMemo(
    () =>
      windowsApps?.evaluated_applications!.filter(app =>
        app.display_name.toLowerCase().includes(searchedApp.toLowerCase())
      ) ?? [],
    [searchedApp]
  );

  const sortedApps = useMemo(
    () => ({
      not_updated: filteredApps?.filter(app => app.available_version && !app.system_component),
      installed: filteredApps?.filter(app => !app.available_version && !app.system_component),
      system: filteredApps?.filter(app => app.system_component)
    }),
    [filteredApps]
  );

  return (
    <VStack w="full" spacing={4}>
      <AppSearch searchedApp={searchedApp} setSearchedApp={setSearchedApp} />
      <WindowsAppsStats sortedApps={sortedApps} />
      <UnupdatedApps unupdated_apps={sortedApps.not_updated || []} />
      <InstalledApps installed_apps={sortedApps.installed || []} />
      <SystemApps system_apps={sortedApps.system || []} />
    </VStack>
  );
};

export default WindowsAppsTab;
