import { Box, FilterBar, IFilter, RSelect, RSelectOption, StyleProps } from '@ramp/components';
import countries from 'country-list-js';
import { Globe } from 'lucide-react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CountryFlag from 'components/CountryFlag';
import { LocalRecommendation } from 'types';

interface LocalThreatsFilterProps extends StyleProps {
  localThreats?: LocalRecommendation[];
  setLocalThreats: React.Dispatch<React.SetStateAction<LocalRecommendation[]>>;
}

const LocalThreatsFilter: React.FC<LocalThreatsFilterProps> = ({ localThreats = [], setLocalThreats, ...props }) => {
  const { t } = useTranslation();

  const filters: IFilter[] = useMemo(
    () => [
      {
        id: 'regions',
        type: 'select',
        label: t('components.table.basicIssues.regions'),
        icon: () => <Globe size={16} />,
        disabled: () => false,
        active: () => true,
        items: [
          {
            icon: () => <CountryFlag countryCode="CZ" style={{ position: 'relative', top: 2 }} />,
            value: 'cz',
            content: 'Czech Republic'
          },
          {
            icon: () => <CountryFlag countryCode="SK" style={{ position: 'relative', top: 2 }} />,
            value: 'sk',
            content: 'Slovakia'
          },
          {
            icon: () => <CountryFlag countryCode="AT" style={{ position: 'relative', top: 2 }} />,
            value: 'at',
            content: 'Austria'
          },
          {
            icon: () => <CountryFlag countryCode="DE" style={{ position: 'relative', top: 2 }} />,
            value: 'de',
            content: 'Germany'
          }
        ],
        onFilter: (data: LocalRecommendation[], value: string) =>
          data.filter(it => {
            const country = countries.findByIso2(value.toUpperCase());
            return it.regions.includes(country?.code.iso3.toLowerCase());
          }),
        render: (filter, _, onValueChange) => (
          <RSelect
            size="sm"
            filterId={filter.id}
            label={filter.label}
            value={filter.selected ? filter.selected.value : undefined}
            onChange={newValue => onValueChange(filter.id, newValue)}
            placeholder={t('admin.localThreats.filter.regionSelect')}
            leftIcon={filter.icon && <filter.icon />}
          >
            {filter.items &&
              filter.items.map(item => (
                <RSelectOption key={item.value} value={item.value} icon={<item.icon />}>
                  {item.content}
                </RSelectOption>
              ))}
          </RSelect>
        )
      }
    ],
    []
  );

  return (
    <Box w="full" {...props}>
      <FilterBar<LocalRecommendation>
        tableId="local_threats_table"
        filters={filters}
        data={localThreats}
        setData={setLocalThreats}
      />
    </Box>
  );
};

export default LocalThreatsFilter;
