import { Box, useRMenu } from '@ramp/components';
import React from 'react';

import { useWindowWidth } from 'utils/hooks/useWindowWidth';

interface PageOverlayProps {
  breakpoint: number;
}

const PageOverlay: React.FC<PageOverlayProps> = ({ breakpoint }) => {
  const windowWidth = useWindowWidth();
  const { collapsed, setMenuCollapsed } = useRMenu();

  return (
    <Box
      w="100vw"
      h="100%"
      pos="fixed"
      top={0}
      bg="rgba(0,0,0,0.75)"
      transition="add 0.25s ease-in-out"
      zIndex={1}
      display={windowWidth < breakpoint && !collapsed ? 'block' : 'none'}
      onClick={() => setMenuCollapsed(true)}
    />
  );
};

export default PageOverlay;
