import cidrRegex from 'cidr-regex';

export const SeverityColumnFilters = [
  {
    text: 'Critical',
    value: 'critical'
  },
  {
    text: 'High',
    value: 'high'
  },
  {
    text: 'Medium',
    value: 'medium'
  },
  {
    text: 'Low',
    value: 'low'
  },
  {
    text: 'None',
    value: 'none'
  }
];

export const PRODUCT_NAME = 'Redamp.io';

export const DEFAULT_PAGE_SIZES = [5, 10, 25, 50, 100, 200];

export const EU_COUNTRIES: string[] = [
  'AUT', // Austria
  'BEL', // Belgium
  'BGR', // Bulgaria
  'HRV', // Croatia
  'CYP', // Cyprus
  'CZE', // Czech Republic
  'DNK', // Denmark
  'EST', // Estonia
  'FIN', // Finland
  'FRA', // France
  'DEU', // Germany
  'GRC', // Greece
  'HUN', // Hungary
  'IRL', // Ireland
  'ITA', // Italy
  'LTU', // Lithuania
  'LUX', // Luxembourg
  'LVA', // Latvia
  'MLT', // Malta
  'NLD', // Netherlands
  'POL', // Poland
  'PRT', // Portugal
  'ROU', // Romania
  'SVK', // Slovakia
  'SVN', // Slovenia
  'ESP', // Spain
  'SWE' // Sweden
  // "CHE", // Switzerland (not in EU but sometimes included in discussions)
  // "NOR", // Norway (not in EU but included in Schengen)
  // "ISL", // Iceland (not in EU but included in Schengen)
  // "LIE"  // Liechtenstein (not in EU but included in Schengen)
];

export const REGEXES = {
  PHONE: /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
  CIDR: cidrRegex({
    exact: true
  }),
  DOMAIN: /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/
} as const;
