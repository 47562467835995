import axios from 'axios';

export interface EntraIDUser {
  businessPhones: string[];
  displayName: string;
  givenName: string | null;
  jobTitle: string | null;
  mail: string;
  mobilePhone: string | null;
  officeLocation: string | null;
  preferredLanguage: string | null;
  surname: string | null;
  userPrincipalName: string;
  id: string;
}

const getAllUsersFromEntraID = (accessToken: string): Promise<EntraIDUser[]> =>
  new Promise<EntraIDUser[]>((resolve, reject) => {
    axios
      .get('https://graph.microsoft.com/v1.0/users', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      .then(response => resolve(response.data.value))
      .catch(error => reject(error));
  });

export default {
  getAllUsersFromEntraID
};
