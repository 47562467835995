import { HStack, Tag, TagProps, Text } from '@ramp/components';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TagWithIconProps extends TagProps {
  icon: React.ReactNode;
}

const TagWithIcon: React.FC<TagWithIconProps> = ({ icon, ...props }) => {
  return (
    <Tag {...props}>
      <HStack spacing={1}>
        {icon}
        <Text lineHeight="1rem" pos="relative" top="1px">
          {props.children}
        </Text>
      </HStack>
    </Tag>
  );
};

export default TagWithIcon;
