import { RPage } from '@ramp/components';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import ROUTES from 'router/routes';

import api from 'api';
import NetworkInfoPanel from 'pages/admin/networks/detail/NetworkInfoPanel';
import { useAuthStore } from 'store';
import { NetworkType } from 'types';
import { parseRoute } from 'utils/utils';

import NetworkEvaluation from './NetworkEvaluation';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface NetworkDetailProps {}

const NetworkDetail: React.FC<NetworkDetailProps> = () => {
  const { t } = useTranslation();
  const user = useAuthStore(store => store.user);
  const { type, id, entityId } = useParams<{ type: NetworkType; id: string; entityId: string }>();

  const network = useQuery(['network', type, id], () => api.networks.getNetwork(user!.user_id, type!, id!));

  return (
    <RPage
      px={6}
      pt={8}
      heading={network.data?.name || ''}
      title={`${t('admin.networks.heading')} | ${network.data?.name}`}
      breadcrumbs={[
        {
          name: t('admin.networks.heading'),
          as: Link,
          linkTo: parseRoute(user!, ROUTES.ADMIN.NETWORKS.LIST, { entityId: entityId! })
        },
        {
          name: network.data?.name || '',
          as: Link,
          linkTo: parseRoute(user!, ROUTES.ADMIN.NETWORKS.DETAIL, { type: type!, id: id! })
        }
      ]}
      loading={network.isLoading}
    >
      <NetworkInfoPanel network={network.data} />
      <NetworkEvaluation network={network.data} />
    </RPage>
  );
};

export default NetworkDetail;
