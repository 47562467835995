import { Heading, HStack, RCard, Search, useColorModeValue, VStack } from '@ramp/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Element } from 'react-scroll';

import RiskyAppsTable from 'components/tables/RiskyAppsTable';
import { AppInfo, AppIssue, Nullable } from 'types';

interface RiskyAppsProps {
  appsIcons?: Record<string, Nullable<string>>;
  filteredApps: AppInfo[];
  issues?: Record<string, AppIssue>;
}

const RiskyApps: React.FC<RiskyAppsProps> = ({ filteredApps, issues }) => {
  const { t } = useTranslation();
  const riskyApps = filteredApps.filter(app => app.eval_category === 'risky');

  const [searchInRisky, setSearchInRisky] = useState('');
  const [filteredRisky, setFilteredRisky] = useState(riskyApps);

  useEffect(() => {
    const apps = filteredApps.filter(app => app.eval_category === 'risky');
    const filteredRiskyApps = apps.filter(app => {
      const appName = (app.application_name || app.package_name).toLowerCase();
      return appName.includes(searchInRisky.toLowerCase());
    });

    setFilteredRisky(filteredRiskyApps);
  }, [filteredApps, searchInRisky]);

  console.log('riskyApps', riskyApps);

  return (
    <VStack w="full" spacing={3}>
      <HStack w="full" justify="space-between" align="end">
        <Element name="risky_apps">
          <Heading
            mt="2rem !important"
            mb="0.25rem !important"
            color={useColorModeValue('black', 'gray.400')}
            fontSize="1.5rem"
            fontWeight={600}
          >
            {t('admin.devices.detail.apps.risky.title')}
          </Heading>
        </Element>
        <Search
          w="360px"
          size="sm"
          value={searchInRisky}
          // eslint-disable-next-line max-len
          placeholder={`${t('admin.devices.detail.apps.risky.searchIn')} '${t('admin.devices.detail.apps.statistics.risky')}'`}
          onChange={e => setSearchInRisky(e.target.value)}
        />
      </HStack>

      <RCard w="full">
        <VStack w="full" spacing={4}>
          <RiskyAppsTable id="device_detail_risky_apps" apps={filteredRisky} issues={issues} />
        </VStack>
      </RCard>
    </VStack>
  );
};

export default RiskyApps;
