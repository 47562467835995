import { Box, Button, HStack, RInput, Text, useColorModeValue, VStack } from '@ramp/components';
import { FormikErrors, useFormik } from 'formik';
import { Key } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object, SchemaOf, string } from 'yup';

import api from 'api';
import { useAuthStore } from 'store';
import { APIError } from 'types';
import { REGEX } from 'utils/utils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChangePasswordFormProps {}

interface IChangePassword {
  currentPassword: string;
  newPassword: string;
  newPasswordAgain: string;
}

const ChangePasswordValidationSchema: SchemaOf<IChangePassword> = object().shape({
  currentPassword: string()
    .min(10, 'Password is too short. It must contain at least 10 characters.')
    .matches(REGEX.LOWERCASE_LETTER, 'Password must contain at least 1 lower case letter.')
    .matches(REGEX.UPPERCASE_LETTER, 'Password must contain at least 1 upper case letter.')
    .matches(REGEX.NUMBER, 'Password must contain at least 1 digit.')
    .matches(REGEX.SPECIAL_LETTER, 'Password must contain at least 1 special character.')
    .required('"New Password" is required field.'),
  newPassword: string()
    .min(10, 'Password is too short. It must contain at least 10 characters.')
    .matches(REGEX.LOWERCASE_LETTER, 'Password must contain at least 1 lower case letter.')
    .matches(REGEX.UPPERCASE_LETTER, 'Password must contain at least 1 upper case letter.')
    .matches(REGEX.NUMBER, 'Password must contain at least 1 digit.')
    .matches(REGEX.SPECIAL_LETTER, 'Password must contain at least 1 special character.')
    .required('"New Password" is required field.'),
  newPasswordAgain: string().required('"New Password Again" is required field.')
});

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = () => {
  const { t } = useTranslation();
  const { user_id: userId } = useAuthStore(store => store.user!);
  const logoutUser = useAuthStore(store => store.logoutUser);

  const [apiError, setApiError] = useState<string>();
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);
  const [disableUpdate, setDisableUpdate] = useState<boolean>(true);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);

  const { handleChange, handleSubmit, values, errors } = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      newPasswordAgain: ''
    },
    validationSchema: ChangePasswordValidationSchema,
    validate: ({ newPassword, newPasswordAgain }) => {
      const validationErrors: FormikErrors<IChangePassword> = {};

      if (newPassword.length > 0 && newPasswordAgain.length > 0) {
        if (newPassword !== newPasswordAgain) {
          validationErrors.newPasswordAgain = t('base.resetPassword.validation.passwordNotSame');
        }
      }

      if (apiError) {
        validationErrors.currentPassword = apiError;
      }

      return validationErrors;
    },
    onSubmit: passwords => {
      setApiError(undefined);
      setLoadingUpdate(true);

      api.users
        .changePassword(userId, passwords.currentPassword, passwords.newPassword)
        .then(() => {
          setLoadingUpdate(false);
          setApiSuccess(true);
          setTimeout(() => logoutUser(), 5500);
        })
        .catch((e: APIError) => {
          setLoadingUpdate(false);
          setApiError(e.description);
          setDisableUpdate(true);
        });
    }
  });

  useEffect(() => {
    const isPasswordEmpty = values.newPassword.length === 0 || values.newPasswordAgain.length === 0;
    const isPasswordError = errors.newPassword || errors.newPasswordAgain;

    if (isPasswordEmpty || isPasswordError) {
      setDisableUpdate(true);
    } else {
      setDisableUpdate(false);
    }
  }, [
    values.currentPassword,
    values.newPassword,
    values.newPasswordAgain,
    errors.newPassword,
    errors.newPasswordAgain
  ]);

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <VStack align="start" w="full" spacing="1rem">
        <RInput
          w="full"
          size="lg"
          icon={<Key />}
          type="password"
          id="currentPassword"
          label={t('admin.accountSettings.passwordChange.currentPassword')}
          error={errors.currentPassword}
          isError={!!errors.currentPassword}
          onChange={handleChange}
          value={values.currentPassword}
          required
        />
        <RInput
          w="full"
          size="lg"
          icon={<Key />}
          type="password"
          id="newPassword"
          label={t('base.resetPassword.newPassword')}
          error={errors.newPassword}
          isError={!!errors.newPassword}
          onChange={handleChange}
          value={values.newPassword}
          required
        />
        <RInput
          w="full"
          size="lg"
          icon={<Key />}
          type="password"
          id="newPasswordAgain"
          label={t('base.resetPassword.passwordConfirmation')}
          error={errors.newPasswordAgain}
          isError={!!errors.newPasswordAgain}
          onChange={handleChange}
          value={values.newPasswordAgain}
          required
        />
        {apiError && (
          <VStack w="full" align="start" py={2} px={4} bg={useColorModeValue('gray.10', 'gray.700')} borderRadius="8px">
            <Text fontWeight={600} fontSize="0.85rem" color="brand.500" mt="0 !important">
              {apiError}
            </Text>
          </VStack>
        )}
        {apiSuccess && (
          <VStack
            w="full"
            align="center"
            py={2}
            px={4}
            bg={useColorModeValue('gray.10', 'gray.700')}
            borderRadius="8px"
          >
            <Text fontWeight={600} textAlign="center" fontSize="0.85rem" color="green.500" mt="0 !important">
              {t('admin.accountSettings.passwordChange.currentMessage')}
            </Text>
          </VStack>
        )}
        <HStack w="full" alignItems="center" justifyContent="end" flexDir={['column', 'column', 'row']}>
          <Box w="50%" />
          <Button
            size="md"
            w={['full', 'full', '50%']}
            type="submit"
            variant="brand"
            fontSize="18px"
            borderRadius="8px"
            alignSelf="end"
            disabled={disableUpdate}
            isLoading={loadingUpdate}
          >
            {t('admin.accountSettings.passwordChange.update')}
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};

export default ChangePasswordForm;
