import { RCard, RPage, VStack } from '@ramp/components';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import api from 'api';
import NoDataPage from 'components/NoDataPage';
import EmailBreachScansTable from 'components/tables/DataBreachedEmailsTable';
import { useAuthStore, useLayoutStore } from 'store';
import { EmailScan } from 'types';
import { PRODUCT_NAME } from 'utils/constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PrivacyProtectionProps {}

const PrivacyProtection: React.FC<PrivacyProtectionProps> = () => {
  const { t } = useTranslation();
  const userId = useAuthStore(store => store.user!.user_id);
  const { noUsersInCompany, noScansInCompany } = useLayoutStore(store => store);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [allEmails, setAllEmails] = useState<EmailScan[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryEmails = useQuery(['user', userId, 'breaches'], () => api.users.getPrivacyBreaches(userId), {
    cacheTime: 0,
    onSuccess: data => {
      const emails = data.finished_email_scans;

      data.processing_email_scans.forEach(email =>
        emails.push({
          ...email,
          breaches: [],
          scan_done: null,
          score: null,
          data_classes: null,
          top_secret_data_classes: null,
          secret_data_classes: null,
          confidential_data_classes: null,
          sensitive_data_classes: null,
          restricted_data_classes: null,
          protected_data_classes: null,
          not_categorized_data_classes: null,
          users: []
        })
      );

      setAllEmails(emails);
      setIsLoading(false);
    }
  });

  if (noScansInCompany) {
    return (
      <NoDataPage
        heading={t('admin.privacy.heading')}
        pageLoading={isLoading}
        noUsersInCompany={noUsersInCompany}
        noScansInCompany={noScansInCompany}
      />
    );
  }

  return (
    <RPage
      px={6}
      pt={8}
      loading={false}
      heading={t('admin.privacy.heading')}
      title={t('admin.privacy.title', { productName: PRODUCT_NAME })}
    >
      <VStack w="full" align="start" spacing={4}>
        <RCard w="full" title={t('admin.privacy.emailBreaches')} titleOutside={false}>
          <EmailBreachScansTable
            id="email_breach_scans_table"
            loading={isLoading}
            emailBreachScans={allEmails}
            userView
          />
        </RCard>
      </VStack>
    </RPage>
  );
};

export default PrivacyProtection;
