import React, { createContext, useContext, useState } from 'react';

import { DeviceRecommendation, NetworkScan, Nullable, Platforms, Vulnerability } from 'types';
import { AndroidScan, IosScan, LinuxScan, MacOSApps, MacOSScan, WindowsApps, WindowsScan } from 'types/api/devices';

export type Scan = AndroidScan | IosScan | MacOSScan | WindowsScan | LinuxScan;

export interface DeviceDetailProviderProps {
  initScanDate?: string;
  initPlatform?: Platforms;
  initScan?: Scan;
  initCveIssues?: Vulnerability[];
  initNetworks?: NetworkScan[];
  initRecommendations?: DeviceRecommendation[];
  initAppsIcons?: Record<string, Nullable<string>>;
  initWindowsApps?: WindowsApps;
  initMacOSApps?: MacOSApps;
  children?: React.ReactNode;
}

interface DeviceDetailContextType {
  scanDate?: string;
  setScanDate: (value?: string) => void;
  platform?: Platforms;
  setPlatform: (value: Platforms) => void;
  scan?: Scan;
  setScan: (value?: Scan) => void;
  cveIssues?: Vulnerability[];
  setCveIssues: (value: Vulnerability[]) => void;
  networks?: NetworkScan[];
  setNetworks: (value: NetworkScan[]) => void;
  recommendations?: DeviceRecommendation[];
  setRecommendations: (value: DeviceRecommendation[]) => void;
  appsIcons?: Record<string, Nullable<string>>;
  setAppsIcons: (value?: Record<string, Nullable<string>>) => void;
  windowsApps?: WindowsApps;
  setWindowsApps: (value?: WindowsApps) => void;
  macOSApps?: MacOSApps;
  setMacOSApps: (value?: MacOSApps) => void;
}

export const DeviceDetailContext = createContext({} as DeviceDetailContextType);

export function useDeviceDetail() {
  const context = useContext(DeviceDetailContext);
  if (context === undefined) {
    throw new Error('useDeviceDetail must be used within a DeviceDetailProvider');
  }
  return context;
}

export const DeviceDetailProvider: React.FC<DeviceDetailProviderProps> = ({
  initScanDate,
  initPlatform,
  initScan,
  initCveIssues,
  initNetworks,
  initRecommendations,
  initAppsIcons,
  initWindowsApps,
  initMacOSApps,
  children
}) => {
  const [scanDate, setScanDate] = useState<string | undefined>(initScanDate);
  const [platform, setPlatform] = useState<Platforms | undefined>(initPlatform);
  const [scan, setScan] = useState<Scan | undefined>(initScan);
  const [cveIssues, setCveIssues] = useState<Vulnerability[] | undefined>(initCveIssues);
  const [networks, setNetworks] = useState<NetworkScan[] | undefined>(initNetworks);
  const [recommendations, setRecommendations] = useState<DeviceRecommendation[] | undefined>(initRecommendations);
  const [appsIcons, setAppsIcons] = useState<Record<string, Nullable<string>> | undefined>(initAppsIcons);
  const [windowsApps, setWindowsApps] = useState<WindowsApps | undefined>(initWindowsApps);
  const [macOSApps, setMacOSApps] = useState<MacOSApps | undefined>(initMacOSApps);

  const context = {
    scanDate,
    setScanDate,
    platform,
    setPlatform,
    scan,
    setScan,
    cveIssues,
    setCveIssues,
    networks,
    setNetworks,
    recommendations,
    setRecommendations,
    appsIcons,
    setAppsIcons,
    windowsApps,
    setWindowsApps,
    macOSApps,
    setMacOSApps
  };

  return <DeviceDetailContext.Provider value={context}>{children}</DeviceDetailContext.Provider>;
};
