import React, { useEffect } from 'react';

import { useColorMode, useRampTheme } from '@ramp/components';
import { BrowserRouter } from 'react-router-dom';
import { useAuthStore, useLayoutStore } from 'store';
import { Role } from 'types';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { hasRole, setFavicon } from 'utils/utils';
import AdminRoutes from './admin/AdminRoutes';
import BaseRoutes from './base/BaseRoutes';
import FirmAdminRoutes from './firmadmin/FirmAdminRoutes';
import SuperAdminRoutes from './superadmin/SuperAdminRoutes';
import UserRoutes from './user/UserRoutes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RouterProps {}

const CurrentRoutes = () => {
  const { user, loginAsAdmin, loginAsPartnerAdmin } = useAuthStore(store => store);

  // If user is not logged in -> BaseRouter
  if (!user) return <BaseRoutes />;

  // If user is logged in & has user role -> UserRouter
  if (user && hasRole(Role.user, user.role)) return <UserRoutes />;

  // If user is logged in & has admin role -> AdminRouter
  if (user && hasRole(Role.admin, user.role)) return <AdminRoutes />;

  // If user is logged in & has firmadmin role -> FirmAdminRouter
  if (user && hasRole(Role.firmadmin, user.role)) {
    if (!loginAsAdmin.entityId) return <FirmAdminRoutes />;
    return <AdminRoutes />;
  }

  // If user is logged in & has superadmin role -> SuperAdminRouter
  if (user && hasRole(Role.superadmin, user.role)) {
    if (loginAsAdmin.entityId) return <AdminRoutes />;
    if (loginAsPartnerAdmin.entityId) return <FirmAdminRoutes />;

    return <SuperAdminRoutes />;
  }

  return <BaseRoutes />;
};

const Router: React.FC<RouterProps> = () => {
  const { colorMode, setColorMode } = useColorMode();
  const setTheme = useLayoutStore(store => store.setTheme);
  const theme = useLayoutStore(store => store.theme);
  const { setBrand } = useRampTheme();
  const { user } = useAuthStore(store => store);

  const [firstRender, setFirstRender] = React.useState(true);

  useEffect(() => {
    setFavicon(theme.logo_secondary_dark);
    if (setBrand) setBrand(theme.color_scheme);

    if (theme.theme === 'all') return;
    if (theme.theme === colorMode) return;

    setColorMode(theme.theme);
  }, [theme]);

  // If user is logged in -> set theme
  if (user && firstRender) {
    setTheme({
      ...theme,
      logo_primary_light: user.logo_primary_light,
      logo_primary_dark: user.logo_primary_dark,
      logo_secondary_light: user.logo_secondary_light,
      logo_secondary_dark: user.logo_secondary_dark,
      theme: user.theme,
      color_scheme: user.color_scheme
    });

    setFirstRender(false);
  }

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <CurrentRoutes />
      </QueryParamProvider>
    </BrowserRouter>
  );
};

export default Router;
