import { Flex } from '@ramp/components';
import React from 'react';

import MacOSSettings from './MacOSSettings';
import MobilePlatform from './MobilePlatform';
import OsSettings from './OsSettings';
import WindowsOSSettings from './WindowsOSSettings';

import { useDeviceDetail } from '../../DeviceDetailProvider';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DeviceTabProps {}

const DeviceTab: React.FC<DeviceTabProps> = () => {
  const { platform } = useDeviceDetail();

  return (
    <Flex w="full" flexDirection={{ sm: 'column', lg: 'row' }}>
      <MobilePlatform />
      {platform === 'windows' ? <WindowsOSSettings /> : platform === 'macos' ? <MacOSSettings /> : <OsSettings />}
    </Flex>
  );
};

export default DeviceTab;
