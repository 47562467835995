import {
  Box,
  Button,
  HStack,
  ModalBody,
  ModalFooter,
  Spinner,
  Text,
  useColorModeValue,
  VStack
} from '@ramp/components';
import { useQuery } from '@tanstack/react-query';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import api from 'api';
import CopyButton from 'components/CopyButton';
import { useAuthStore } from 'store';

interface QRCodeScanProps {
  setQrCodeScanned: React.Dispatch<React.SetStateAction<boolean>>;
}

const QRCodeScan: React.FC<QRCodeScanProps> = ({ setQrCodeScanned }) => {
  const { t } = useTranslation();
  const { user_id: userId } = useAuthStore(store => store.user!);
  const secretLink2FA = useQuery(['twoFactorSecret', userId], () => api.users.linkOtp(userId), {
    refetchOnWindowFocus: false
  });

  const [secret2FACode, setSecretFACode] = useState<string>('');

  useEffect(() => {
    if (secretLink2FA.data) {
      const parsedSecretLink = queryString.parse(secretLink2FA.data);
      setSecretFACode((parsedSecretLink[Object.keys(parsedSecretLink)[1]] as string) || '');
    }
  }, [secretLink2FA.data]);

  return (
    <form onSubmit={() => setQrCodeScanned(true)} style={{ width: '100%' }}>
      <ModalBody>
        <VStack spacing={4} p={4} align="center" justify="center">
          <Text fontWeight={500} fontSize="1.25rem" textAlign="center">
            {t('admin.accountSettings.2faModal.firstTitle')}
          </Text>
          <Text fontWeight={300} fontSize="1rem" textAlign="center">
            {t('admin.accountSettings.2faModal.firstDescription')}
          </Text>
          <Box pt={4}>
            {secretLink2FA.isLoading && <Spinner size="md" color="brand.500" />}
            {secretLink2FA.data && (
              <VStack spacing={8}>
                <Box p={4} borderRadius={8} bgColor="white">
                  <QRCode value={secretLink2FA.data} size={180} />
                </Box>
                <VStack>
                  <Text color="brand.500">{t('admin.accountSettings.2faModal.info')}</Text>
                  <HStack>
                    <Box
                      textShadow={useColorModeValue('0 0 8px #000', '0 0 8px #fff')}
                      color="transparent"
                      transition="all .2s ease-in-out"
                      _hover={{
                        textShadow: useColorModeValue('0 0 0px #000', '0 0 0px #fff'),
                        color: useColorModeValue('#000', '#fff')
                      }}
                    >
                      <Text fontWeight={300}>{secret2FACode}</Text>
                    </Box>
                    <CopyButton size="sm" variant="borderless" dataToCopy={secret2FACode} />
                  </HStack>
                </VStack>
              </VStack>
            )}
          </Box>
        </VStack>
      </ModalBody>

      <ModalFooter mb={3}>
        <HStack w="full" align="center" justify="center" spacing={2}>
          <Button variant="brand" size="sm" type="submit">
            {t('admin.accountSettings.2faModal.firstBtn')}
          </Button>
        </HStack>
      </ModalFooter>
    </form>
  );
};

export default QRCodeScan;
