import { ColumnsType, HStack, RTable, SeverityTag, Text, Tooltip, useColorModeValue } from '@ramp/components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PAGE_SIZES } from 'utils/constants';

import IssueIgnorePopover from 'components/IssueIgnorePopover';
import IssueObjectTypeTag from 'components/IssueObjectTypeTag';
import { Issue } from 'types';
import useTablePageSize from 'utils/hooks/useTablePageSize';
import { getSeverityFromScore } from 'utils/utils';

import BasicIssuesTableDetail from './BasicIssuesTableDetail';

interface BasicIssuesTableProps {
  id: string;
  defaultPageSize?: number;
  issues?: Issue[];
  loading?: boolean;
  simple?: boolean;
  userView?: boolean;
  userId?: string;
}

interface IssueRow extends Issue {
  key: number;
}

const BasicIssuesTable: React.FC<BasicIssuesTableProps> = ({
  id,
  defaultPageSize,
  issues = [],
  loading,
  simple = false,
  userView = false,
  userId
}) => {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useTablePageSize(id, defaultPageSize || 10);

  const renderObjectsText = (numOfAffectedObjects: number, objectType: string): string => {
    if (objectType === 'device') {
      return numOfAffectedObjects > 1
        ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.devices')}`
        : numOfAffectedObjects === 0
          ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.devices')}`
          : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.device')}`;
    }

    if (objectType === 'network') {
      return numOfAffectedObjects > 1
        ? numOfAffectedObjects < 5
          ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.networks2')}`
          : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.networks')}`
        : numOfAffectedObjects === 0
          ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.networks')}`
          : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.network')}`;
    }

    if (objectType === 'client') {
      return numOfAffectedObjects > 1
        ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.clients')}`
        : numOfAffectedObjects === 0
          ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.clients2')}`
          : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.client')}`;
    }

    if (objectType === 'network_device') {
      return numOfAffectedObjects > 1
        ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.devices')}`
        : numOfAffectedObjects === 0
          ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.devices')}`
          : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.device')}`;
    }

    if (objectType === 'email') {
      return numOfAffectedObjects > 1
        ? numOfAffectedObjects < 5
          ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.emails')}`
          : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.emails2')}`
        : numOfAffectedObjects === 0
          ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.emails2')}`
          : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.email')}`;
    }

    if (objectType === 'user') {
      return numOfAffectedObjects > 1
        ? numOfAffectedObjects < 5
          ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.users')}`
          : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.users2')}`
        : numOfAffectedObjects === 0
          ? `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.users2')}`
          : `${numOfAffectedObjects} ${t('admin.dashboard.issues.table.user')}`;
    }

    return numOfAffectedObjects.toString();
  };

  const columns: ColumnsType<IssueRow> = useMemo(
    () => [
      {
        title: t('admin.dashboard.issues.table.severity'),
        key: 'score',
        width: '120px',
        sorting: true,
        align: 'center',
        sortingOptions: {
          dataType: 'number',
          defaultSortOrder: 'desc',
          sortingFn: (a, b) => (a.original.score || 0) - (b.original.score || 0)
        },
        render: score => <SeverityTag size="md" severity={getSeverityFromScore(score)} />
      },
      {
        title: t('admin.dashboard.issues.table.title'),
        key: 'title',
        width: '360px',
        render: (_, { object_type, title }) => (
          <HStack>
            <IssueObjectTypeTag type={object_type} />
            <Text noOfLines={1} fontWeight={600}>
              {title}
            </Text>
          </HStack>
        )
      },
      {
        title: t('admin.dashboard.issues.table.activeCount'),
        key: 'n_affected_objects',
        width: '120px',
        sorting: true,
        align: 'center',
        sortingOptions: {
          dataType: 'number',
          sortingFn: (a, b) => a.original.n_affected_objects - b.original.n_affected_objects
        },
        render: (numOfAffectedObjects: number, { object_type }) => renderObjectsText(numOfAffectedObjects, object_type)
      },
      {
        title: t('admin.dashboard.issues.table.ignoredCount'),
        key: 'n_ignored_objects',
        width: '120px',
        sorting: true,
        align: 'center',
        sortingOptions: {
          dataType: 'number',
          sortingFn: (a, b) => a.original.n_ignored_objects - b.original.n_ignored_objects
        },
        render: (numOfAffectedObjects: number, { object_type }) => renderObjectsText(numOfAffectedObjects, object_type)
      },
      {
        title: t('admin.dashboard.issues.table.description'),
        key: 'description',
        width: '35%',
        render: (value: string) => (
          <Tooltip label={value} placement="top">
            <Text noOfLines={2} color={useColorModeValue('black', 'white')}>
              {value}
            </Text>
          </Tooltip>
        )
      },
      {
        title: t('admin.dashboard.issues.table.actions'),
        key: 'actions',
        width: '120px',
        align: 'center',
        sorting: false,
        render: (_, issue) => (
          <IssueIgnorePopover
            issue={issue}
            codenames={issue.recommendations.map(r => ({
              codename: r.codename,
              object_ids: 'all'
            }))}
            userView={userView}
            mode={issue.n_affected_objects > 0 ? 'ignore' : 'activate'}
          />
        )
      }
    ],
    []
  );

  const formattedIssues: IssueRow[] = useMemo(() => {
    return issues.map((issue, key) => ({ key, ...issue }));
  }, [issues]);

  return (
    <RTable
      columns={columns}
      data={formattedIssues}
      loading={loading}
      expandable={{
        rowExpandable: () => true,
        render: issue => <BasicIssuesTableDetail issue={issue} simple={simple} userView={userView} userId={userId} />
      }}
      pagination={{
        showTotal: true,
        defaultPageSize: pageSize,
        onPageSizeChange: newPageSize => setPageSize(newPageSize),
        showGoToPage: true,
        showPageSizeSelector: true,
        pageSizeSelectorOptions: DEFAULT_PAGE_SIZES
      }}
      interleaveRows
    />
  );
};

export default BasicIssuesTable;
