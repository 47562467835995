import compact from 'lodash/compact';
import { v4 as uuidv4 } from 'uuid';
import { array, object, SchemaOf, string } from 'yup';
import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { Header } from 'pages/admin/users/import/steps/ImportUsers/CSVImport/validation';
import { ImportUser, ImportUserForExport, UserImportError } from 'types';
import i18n from 'utils/locales';

export interface ImportStore {
  header?: Header;
  setHeader: (header?: Header) => void;
  users: ImportUser[];
  setUsers: (users: ImportUser[]) => void;
  setUsersByEmails: (emails: string[]) => void;
  getUsersWithErrors: () => ImportUser[];
  parseForExport: () => ImportUserForExport[];
  hasImportError: () => boolean;
  validateUsers: () => void;
  addUser: (user: ImportUser) => void;
  addUserByEmail: (email: string) => void;
  removeUser: (user: ImportUser) => void;
  removeUserByEmail: (email: string) => void;
  removeUserById: (id: string) => void;
  updateUser: (updatedUser: ImportUser) => void;
  updateUserByEmail: (oldEmail: string, newEmail: string) => void;
  setErrors: (id: string, errors: string[]) => void;
}

const userSchema: SchemaOf<ImportUser> = object().shape({
  id: string().required(),
  username: string()
    .min(3, 'username ' + i18n.t('components.form.min', { field: i18n.t('admin.users.filters.name'), num: 3 }))
    .required('username ' + i18n.t('components.form.isRequired', { field: i18n.t('admin.users.filters.name') })),
  email: string()
    .email('email ' + i18n.t('admin.users.import.secondSlide.csv.validation.validEmail'))
    .required('email ' + i18n.t('components.form.isRequired', { field: 'E-Mail' })),
  role: string()
    .equals(['user', 'administrator'], 'role ' + i18n.t('admin.users.import.secondSlide.csv.validation.role'))
    .required('role ' + i18n.t('components.form.isRequired', { field: i18n.t('admin.users.detail.editModal.role') })),
  country: string().required(),
  language: string().required(),
  position: string().notRequired(),
  errors: array().notRequired()
});

export type ValidateUser = (user: ImportUser) => Promise<void>;
export const validateUser: ValidateUser = user =>
  new Promise((resolve, reject) => {
    userSchema
      .validate(user, { abortEarly: false })
      .then(() => resolve())
      .catch(err => {
        const errors = err.errors.map((e: string) => {
          const firstSpaceIndex = e.indexOf(' ');
          const parsedError = e.slice(firstSpaceIndex + 1);

          return {
            key: e.split(' ')[0],
            message: parsedError
          };
        });

        reject(errors);
      });
  });

type CheckEmailDuplicates = (userEmail: string, allUsers: ImportUser[]) => UserImportError[];
const checkEmailDuplicates: CheckEmailDuplicates = (userEmail, allUsers) => {
  if (allUsers.filter(u => u.email === userEmail).length >= 2) {
    return [{ key: 'email', message: i18n.t('admin.users.import.secondSlide.csv.validation.uniqueEmail') }];
  }

  return [];
};

type CheckUsernameDuplicates = (username: string, allUsers: ImportUser[]) => UserImportError[];
const checkUsernameDuplicates: CheckUsernameDuplicates = (username, allUsers) => {
  if (allUsers.filter(u => u.username === username).length >= 2) {
    return [{ key: 'username', message: i18n.t('admin.users.import.secondSlide.csv.validation.uniqueUsername') }];
  }

  return [];
};

export const useImportStore = create<ImportStore>()(
  devtools((set, getState) => ({
    header: undefined,

    setHeader: header => set({ header }),

    users: [],

    validateUsers: async () => {
      const state = getState();

      const val = async () => {
        const results = state.users.map(async usr => {
          const duplicateEmailError = checkEmailDuplicates(usr.email, state.users);
          const duplicateUsernameError = checkUsernameDuplicates(usr.username, state.users);

          return validateUser(usr)
            .then(() => {
              return { ...usr, errors: [...duplicateEmailError, ...duplicateUsernameError] };
            })
            .catch((errors: UserImportError[]) => {
              return { ...usr, errors: [...errors, ...duplicateEmailError, ...duplicateUsernameError] };
            });
        });

        return Promise.all(results);
      };

      const updatedUsers = await val();

      set({ users: updatedUsers });
    },

    getUsersWithErrors: () => {
      const state = getState();

      return state.users.filter(u => u.errors && u.errors.length !== 0);
    },

    hasImportError: () => {
      const state = getState();

      return state.users.some(u => u.errors && u.errors.length !== 0);
    },

    parseForExport: () => {
      return getState().users.map(u => ({
        ...u,
        id: undefined,
        role: u.role === 'administrator' ? 2 : 1,
        country: 'cze',
        language: 'cs'
      }));
    },

    setUsers: users => set({ users }),

    setUsersByEmails: emails => {
      const users = emails.map(async email => {
        const state = getState();
        const userExists = state.users.some(u => u.email === email);

        if (!userExists) {
          let newUser: ImportUser = {
            id: uuidv4(),
            username: email.split('@')[0],
            email,
            role: 'user' as const,
            country: 'cze',
            language: 'cs'
          };

          await validateUser(newUser).catch((errors: UserImportError[]) => {
            newUser = { ...newUser, errors };
          });

          return newUser;
        }
      });

      Promise.all(users).then(usrs => {
        set(state => ({ users: [...state.users, ...compact(usrs)] }));
      });
    },

    addUser: user =>
      set(state => {
        const userExists = state.users.some(u => u.email === user.email);
        if (!userExists) {
          return { users: [...state.users, user] };
        } else {
          return state;
        }
      }),

    addUserByEmail: email => {
      const state = getState();
      const userExists = state.users.some(u => u.email === email);

      if (!userExists) {
        const newUser = {
          id: uuidv4(),
          username: email.split('@')[0],
          email,
          role: 'user' as const,
          country: 'cze',
          language: 'cs'
        };

        validateUser(newUser)
          .then(() => set({ users: [...state.users, newUser] }))
          .catch((errors: UserImportError[]) => set({ users: [...state.users, { ...newUser, errors }] }));
      } else {
        return state;
      }
    },

    removeUser: user => set(state => ({ users: state.users.filter(u => u !== user) })),

    removeUserByEmail: email =>
      set(state => ({
        users: state.users.filter(u => u.email !== email)
      })),

    removeUserById: id =>
      set(state => ({
        users: state.users.filter(u => u.id !== id)
      })),

    updateUser: updatedUser => {
      const state = getState();

      validateUser(updatedUser)
        .then(async () => {
          set(() => ({
            users: state.users.map(u => (u.id === updatedUser.id ? { ...updatedUser, errors: undefined } : u))
          }));

          await state.validateUsers();
        })
        .catch(async (errors: UserImportError[]) => {
          set(() => ({
            users: state.users.map(u =>
              u.id === updatedUser.id
                ? {
                    ...updatedUser,
                    errors
                  }
                : u
            )
          }));

          await state.validateUsers();
        });
    },

    updateUserByEmail: (oldEmail, newEmail) =>
      set(state => ({
        users: state.users.map(u =>
          u.email === oldEmail
            ? {
                ...u,
                username: newEmail.split('@')[0],
                email: newEmail
              }
            : u
        )
      })),

    setErrors: (id: string, errors: string[]) =>
      set(state => ({
        users: state.users.map(u =>
          u.id === id
            ? {
                ...u,
                errors: errors.map(e => ({
                  key: e.split(' ')[0],
                  message: e
                }))
              }
            : u
        )
      }))
  }))
);
